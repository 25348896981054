import { theme } from '@maersktankersdigital/web-components'
import { Popper } from '@mui/base'
import { useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

interface Props {
  children: React.ReactElement
  content: string
}

const StyledToolTip = styled.div`
  font-family: ${theme.FONTS.families.regular};
  border-radius: 4px;
  padding: 10px 15px;
  background-color: ${COLORS.secondary.darkBlue};
  color: ${COLORS.primary.white};
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  max-width: 400px;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  &::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${COLORS.secondary.darkBlue};
  }
`

function ExposureToolTip({ children, content }: Props) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleMouseEnter = () => {
    setOpen(true)
  }

  const handleMouseLeave = () => {
    setOpen(false)
  }

  return (
    <div>
      <div
        ref={anchorRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        style={{ paddingTop: '20px' }}
      >
        <StyledToolTip>{content}</StyledToolTip>
      </Popper>
    </div>
  )
}

export default ExposureToolTip
