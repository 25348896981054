/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
  Decimal: { input: any; output: any }
}

export type MtOpenVesselsResponse = {
  __typename?: 'MTOpenVesselsResponse'
  latestCreatedDate: Scalars['DateTime']['output']
  openVessels: Array<OpenMtVessels>
  totalCount: Scalars['Int']['output']
  totalExposure: Array<TotalExposure>
}

export type OpenMtVessels = {
  __typename?: 'OpenMTVessels'
  Maersk: Array<OpenVesselData>
  exposureArea: Scalars['String']['output']
  fromDate: Scalars['DateTime']['output']
}

export type OpenVesselData = {
  __typename?: 'OpenVesselData'
  ID: Scalars['String']['output']
  cargoGrade?: Maybe<Scalars['String']['output']>
  loadingAreaETA?: Maybe<Scalars['DateTime']['output']>
  localLoadingAreaETA?: Maybe<Scalars['DateTime']['output']>
  localOpenDate?: Maybe<Scalars['DateTime']['output']>
  openDate?: Maybe<Scalars['DateTime']['output']>
  openPort?: Maybe<Scalars['String']['output']>
  openRegion?: Maybe<Scalars['String']['output']>
  openWorldArea?: Maybe<Scalars['String']['output']>
  pool?: Maybe<Scalars['String']['output']>
  vesselId: Scalars['String']['output']
  vesselName?: Maybe<Scalars['String']['output']>
  voyageNumber?: Maybe<Scalars['String']['output']>
}

export type Query = {
  __typename?: 'Query'
  getMTOpenVessels: MtOpenVesselsResponse
  getTotalExposure: TotalExposureWithFiltering
}

export type QueryGetTotalExposureArgs = {
  cargoGrade?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  pool?: InputMaybe<Scalars['String']['input']>
}

export type TotalExposure = {
  __typename?: 'TotalExposure'
  exposureArea?: Maybe<Scalars['String']['output']>
  vessels: Array<OpenVesselData>
}

export type TotalExposureWithFiltering = {
  __typename?: 'TotalExposureWithFiltering'
  latestCreatedDate: Scalars['DateTime']['output']
  poolsInResponse: Array<Scalars['String']['output']>
  totalCount: Scalars['Int']['output']
  totalExposure: Array<TotalExposure>
}

export type GetMtOpenVesselsQueryVariables = Exact<{ [key: string]: never }>

export type GetMtOpenVesselsQuery = {
  __typename?: 'Query'
  getMTOpenVessels: {
    __typename?: 'MTOpenVesselsResponse'
    totalCount: number
    latestCreatedDate: any
    openVessels: Array<{
      __typename?: 'OpenMTVessels'
      exposureArea: string
      fromDate: any
      Maersk: Array<{
        __typename?: 'OpenVesselData'
        cargoGrade?: string | null
        ID: string
        loadingAreaETA?: any | null
        localLoadingAreaETA?: any | null
        localOpenDate?: any | null
        openDate?: any | null
        openPort?: string | null
        openRegion?: string | null
        openWorldArea?: string | null
        pool?: string | null
        vesselId: string
        vesselName?: string | null
      }>
    }>
    totalExposure: Array<{
      __typename?: 'TotalExposure'
      exposureArea?: string | null
      vessels: Array<{
        __typename?: 'OpenVesselData'
        cargoGrade?: string | null
        ID: string
        loadingAreaETA?: any | null
        localLoadingAreaETA?: any | null
        localOpenDate?: any | null
        openDate?: any | null
        openPort?: string | null
        openRegion?: string | null
        openWorldArea?: string | null
        pool?: string | null
        vesselId: string
        vesselName?: string | null
      }>
    }>
  }
}

export type GetMtOpenVessels2QueryVariables = Exact<{
  pool: Scalars['String']['input']
  cargoGrade?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >
}>

export type GetMtOpenVessels2Query = {
  __typename?: 'Query'
  getTotalExposure: {
    __typename?: 'TotalExposureWithFiltering'
    totalExposure: Array<{
      __typename?: 'TotalExposure'
      exposureArea?: string | null
      vessels: Array<{
        __typename?: 'OpenVesselData'
        cargoGrade?: string | null
        ID: string
        loadingAreaETA?: any | null
        localLoadingAreaETA?: any | null
        localOpenDate?: any | null
        openDate?: any | null
        openPort?: string | null
        openRegion?: string | null
        openWorldArea?: string | null
        pool?: string | null
        vesselId: string
        vesselName?: string | null
        voyageNumber?: string | null
      }>
    }>
  }
}

export const GetMtOpenVesselsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMTOpenVessels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMTOpenVessels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openVessels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exposureArea' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fromDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Maersk' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cargoGrade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loadingAreaETA' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'localLoadingAreaETA',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'localOpenDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openPort' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openRegion' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openWorldArea' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pool' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vesselId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vesselName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestCreatedDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalExposure' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exposureArea' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vessels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cargoGrade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loadingAreaETA' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'localLoadingAreaETA',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'localOpenDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openPort' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openRegion' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openWorldArea' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pool' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vesselId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vesselName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMtOpenVesselsQuery,
  GetMtOpenVesselsQueryVariables
>
export const GetMtOpenVessels2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMTOpenVessels2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pool' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cargoGrade' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTotalExposure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pool' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pool' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cargoGrade' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cargoGrade' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalExposure' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exposureArea' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vessels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cargoGrade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loadingAreaETA' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'localLoadingAreaETA',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'localOpenDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openPort' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openRegion' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openWorldArea' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pool' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vesselId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vesselName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'voyageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMtOpenVessels2Query,
  GetMtOpenVessels2QueryVariables
>
