import { FC } from 'react'

import { IQuestionnaireField, IVesselFile } from '~api/vessels/read'
import { Box } from '~components/atoms/box'
import { Text } from '~components/atoms/text'
import { CheckboxGroupField } from '~components/forms/checkbox-group-field/checkbox-group-field'
import { NumberInputField } from '~components/forms/number-input-field/number-input-field'
import { RadiobuttonGroupField } from '~components/forms/radiobutton-group-field/radiobutton-group-field'
import { TextareaField } from '~components/forms/textarea-field/textarea-field'
import { VesselFileField } from '~components/forms/vessel-file-field/vessel-file-field'
import { IQuestionnaireListSection } from '~components/organisms/lists/vessel-questionnaires'
import { VesselQuestionaireField } from '~components/organisms/modals/earnings-simulator/questionnaire-field'

interface IVesselQuestionaireFieldTypeResolver {
  field: IQuestionnaireField
  section: IQuestionnaireListSection
}

export const VesselQuestionaireFieldTypeResolver: FC<
  IVesselQuestionaireFieldTypeResolver
> = ({ field, section }) => {
  switch (field.type) {
    case 'date': {
      return (
        <VesselQuestionaireField
          field={field}
          section={section}
          render={() => {
            return (
              <Text
                text="[date] Not yet implemented"
                color="reds.base"
                weight="bold"
              />
            )
          }}
        />
      )
    }
    case 'file_list': {
      return (
        <VesselQuestionaireField
          field={field}
          section={section}
          render={() => {
            return (
              <Text
                text="[file_list] Not yet implemented"
                color="reds.base"
                weight="bold"
              />
            )
          }}
        />
      )
    }
    case 'downl': {
      return (
        <VesselQuestionaireField
          field={field}
          section={section}
          hideButtons
          hideStatus
          render={() => {
            return <></>
          }}
        />
      )
    }
    case 'file': {
      return (
        <VesselQuestionaireField
          field={field}
          section={section}
          render={() => {
            return (
              <Text
                text="[file] Not yet implemented"
                color="reds.base"
                weight="bold"
              />
            )
          }}
        />
      )
    }
    case 'multiple_choice': {
      return (
        <VesselQuestionaireField
          field={field}
          section={section}
          render={({ isEditing }) => {
            return (
              <CheckboxGroupField
                name={`${field.id}_value`}
                defaultValue={field.value as string[] | null}
                isDisabled={!isEditing}
                options={field.possibleAnswers.map((possibleAnswerOption) => {
                  return {
                    label: possibleAnswerOption,
                    value: possibleAnswerOption,
                  }
                })}
              />
            )
          }}
        />
      )
    }
    case 'upl_com': {
      return (
        <VesselQuestionaireField
          field={field}
          section={section}
          render={() => {
            return (
              <Text
                text="[upl_com] Not yet implemented"
                color="reds.base"
                weight="bold"
              />
            )
          }}
        />
      )
    }
    case 'single_choice': {
      return (
        <VesselQuestionaireField
          field={field}
          section={section}
          render={({ isEditing }) => {
            return (
              <Box mb={1}>
                <RadiobuttonGroupField
                  name={`${field.id}_value`}
                  defaultValue={field.value as string | null}
                  options={field.possibleAnswers.map((answer) => {
                    return { value: answer, label: answer }
                  })}
                  isDisabled={!isEditing}
                />
              </Box>
            )
          }}
        />
      )
    }
    case 'downl_upl': {
      return (
        <VesselQuestionaireField
          field={field}
          section={section}
          hideStatus
          hideButtons
          render={({ isEditing, setIsCompleted }) => {
            return (
              <VesselFileField
                section={section}
                name={`${field.id}`}
                files={field.files as IVesselFile[]}
                isEditing={isEditing}
                setIsCompleted={setIsCompleted}
              />
            )
          }}
        />
      )
    }
    case 'number': {
      return (
        <VesselQuestionaireField
          field={field}
          section={section}
          render={({ isEditing }) => {
            return (
              <NumberInputField
                name={`${field.id}_value`}
                placeholder="Add a number"
                defaultValue={(field.value as string | null) || ''}
                isLocked={!isEditing}
              />
            )
          }}
        />
      )
    }
    default: {
      return (
        <VesselQuestionaireField
          field={field}
          section={section}
          render={({ isEditing }) => {
            return (
              <TextareaField
                name={`${field.id}_value`}
                placeholder={`${field.description}`}
                defaultValue={(field.value as string | null) || ''}
                readOnly={!isEditing}
              />
            )
          }}
        />
      )
    }
  }
}
