import { Button } from '@maersktankersdigital/web-components'
import { Box, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { Modal } from '~components/molecules/modal/modal'
import { localStorageItemKeys } from '~constants/local-storage-items'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useGetWelcomePopup } from '~hooks/queries/welcome-popup/use-get-welcome-popup'
import {
  getFeatureToggle,
  welcomeOnlyPoolPartnersEnabled,
} from '~utils/feature-toggles'
import { Image } from './sub-components/image'
import { Video } from './sub-components/video'

export function WelcomePopup() {
  const isPoolPartner = useHasScope(ExactScopeName.poolPartner)
  const poolPartnerCheckEnabled = getFeatureToggle(
    welcomeOnlyPoolPartnersEnabled,
  )

  if (poolPartnerCheckEnabled && !isPoolPartner) {
    return null
  }

  return <WelcomePopupRenderer />
}

function WelcomePopupRenderer() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data } = useGetWelcomePopup()

  useEffect(() => {
    const isClosed =
      localStorage.getItem(localStorageItemKeys.welcomePopupClosed) === 'true'
    if (isClosed) return

    if (data?.isEnabled) {
      setIsModalOpen(true)
      trackModalDisplayed()
    }
  }, [data])

  function onCloseHandler() {
    setIsModalOpen(false)
    localStorage.setItem(localStorageItemKeys.welcomePopupClosed, 'true')
  }

  if (!data) {
    return null
  }

  const { title, description, releaseDate, imageUrl, imageName, videoLink } =
    data

  const processedDescription = description.replace(/<p><\/p>/g, '<br/>')

  return (
    <Modal
      open={isModalOpen}
      title={title}
      onClose={onCloseHandler}
      modalCaption={
        !releaseDate
          ? undefined
          : `Updated: ${format(parseISO(releaseDate), "do 'of' MMMM yyyy")}`
      }
    >
      <>
        <Box>
          {imageUrl && !videoLink && <Image src={imageUrl} alt={imageName} />}
          {imageUrl && videoLink && <Video />}
          <Typography
            sx={{ mt: imageUrl ? 3 : 0, mb: 4 }}
            component="article"
            dangerouslySetInnerHTML={{
              __html: processedDescription,
            }}
          />
        </Box>
        <Button onClick={onCloseHandler}>Close</Button>
      </>
    </Modal>
  )
}

function trackModalDisplayed() {
  const tagManagerArgs = {
    dataLayer: {
      event: 'welcome_modal_displayed',
    },
  }
  TagManager.dataLayer(tagManagerArgs)
}
