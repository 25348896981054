import * as React from 'react'
import { ReactNode } from 'react'
import styled, { CSSObject } from 'styled-components/macro'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export const textColorMap = {
  'blues.mid': Colors.blues.mid,
}

type StyledLinkProps = {
  color?: 'blues.mid'
  fontSize?: number
  noUnderline?: boolean
  style?: CSSObject // To be removed later, added for existing inline styling support
}

type LinkProps<Elem, Event> = {
  children: ReactNode
  onClick?: (e: React.MouseEvent<Elem, Event>) => void
} & StyledLinkProps

export const StyledLink = styled.span<LinkProps<Element, MouseEvent>>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;
  font-size: ${({ fontSize }) => {
    return fontSize ? `${fontSize}px` : undefined
  }};
  color: ${({ color }) => {
    return color ? textColorMap[color] : undefined
  }};

  &:hover {
    text-decoration: ${({ noUnderline }) => {
      return noUnderline ? 'none' : 'underline'
    }};
  }

  ${(p) => p.style ?? {}}
`

export const Link: React.FC<LinkProps<Element, MouseEvent>> = ({
  children,
  onClick,
  ...props
}) => (
  <StyledLink {...props} onClick={(e) => onClick?.(e)}>
    {children}
  </StyledLink>
)
