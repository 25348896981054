import { StyledButton, theme } from '@maersktankersdigital/web-components'
import { selectClasses } from '@mui/base'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

import { TableCell } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table.styles'
import { statusOptions } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/status-select/status-select'
import { StatusValues } from '~pages/pages-behind-login/position-list/types/position-list-types'

const findGroupLabelByValue = (value: StatusValues): string => {
  for (const group of statusOptions) {
    if (group.options.some((option) => option.value === value)) {
      return group.label
    }
  }
  return ''
}

const setBackgroundColor = (value?: StatusValues) => {
  const group = value ? findGroupLabelByValue(value) : ''
  switch (group) {
    case 'Work':
      return {
        primary: COLORS.citrus.tint1,
        hover: COLORS.citrus.tint3,
      }
    case 'Tentative':
      if (value === StatusValues.ON_SUBS || value === StatusValues.FIXED) {
        return {
          primary: COLORS.orange.tint1,
          hover: COLORS.orange.tint5,
        }
      }
      return {
        primary: COLORS.orange.tint3,
        hover: COLORS.orange.tint4,
      }
    case 'Do Not Work':
      return {
        primary: COLORS.red.light,
        hover: COLORS.red.tint1,
      }
    default:
      return {
        primary: COLORS.citrus.tint1,
        hover: COLORS.citrus.tint3,
      }
  }
}

const StatusTableCellWidth = '136px'

export const StatusButton = styled(StyledButton)<{
  value?: StatusValues
}>`
  height: 56px;
  background-color: transparent;
  color: ${COLORS.secondary.darkBlue};
  min-width: 100%;
  max-width: ${StatusTableCellWidth};
  border-radius: 0;
  &.${selectClasses.expanded} {
    background-color: ${(p) => setBackgroundColor(p.value).primary};
  }

  &:hover {
    background-color: ${(p) => setBackgroundColor(p.value).hover};
  }
`

export const StatusTableCell = styled(TableCell)<{
  isUser?: boolean
  value?: StatusValues
}>`
  padding: 0;
  height: 100%;
  width: ${StatusTableCellWidth};
  max-width: ${StatusTableCellWidth};
  min-width: ${StatusTableCellWidth};
  overflow: hidden;
  background-color: ${(p) => setBackgroundColor(p.value).primary};

  &:hover {
    &:hover {
      background-color: ${(p) =>
        p.isUser ? setBackgroundColor(p.value).hover : 'none'};
    }
  }

  & + ${TableCell} {
    padding-left: ${theme.SPACINGS[2]};
  }

  & ${StatusButton} {
    pointer-events: ${(p) => (p.isUser ? 'auto' : 'none')};
  }
`
