import styled from 'styled-components/macro'

import { ColorVariants } from '~components/atoms/color-label-short/color-label-short'
import { StyledParagraph } from '~components/atoms/typography/paragraph/paragraph.styles'

import { CII } from '~hooks/queries/vessels/data/types/vessel-dashboard'
import { COLORS } from '~theme/colors'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { PortFunction } from '~types/itinerary.types'

const colors: Record<ColorVariants, Record<PortFunction & CII, string>> = {
  portFunction: {
    [PortFunction.COMMENCING]: COLORS.greys.ultraDark,
    [PortFunction.FUELING]: COLORS.green.mid,
    [PortFunction.LOADING]: COLORS.citrus.dark,
    [PortFunction.DISCHARGING]: COLORS.red.light,
    [PortFunction.OTHER]: COLORS.greys.mid,
    [PortFunction.WAITING]: '#004D40',
    [PortFunction.CANAL_TRANSIT]: '#599890',
    [PortFunction.REPAIR]: COLORS.feedback.red.error,
    [PortFunction.CHARTERERS_ORDERS]: COLORS.green.ash,
    [PortFunction.TERMINATING]: COLORS.red.dark,
    [PortFunction.DELIVERY]: COLORS.orange.mid,
    [PortFunction.FOR_ORDERS]: '#4D7970',
    [PortFunction.MULTI_PURPOSE]: Colors.greys.base,
    [PortFunction.REDELIVERY]: COLORS.orange.dark,
    [PortFunction.PASSING]: COLORS.greys.dark,
  },
  CII: {
    [CII.A]: COLORS.citrus.dark,
    [CII.B]: COLORS.citrus.light,
    [CII.C]: COLORS.orange.light,
    [CII.D]: COLORS.orange.mid,
    [CII.E]: COLORS.feedback.red.error,
  },
}

type CommonProps = {
  size: 'small' | 'medium'
}

export const StyledColorLabelShort = styled.div<
  CommonProps &
    (
      | {
          variant: ColorVariants
          variantType: string
        }
      | {
          color: string
        }
    )
>`
  --size: ${(p) => (p.size === 'small' ? '16px' : '30px')};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) =>
    'variant' in p && 'variantType' in p
      ? colors[p.variant as ColorVariants][p.variantType as PortFunction & CII]
      : 'color' in p
        ? p.color
        : 'inherit'};
  width: var(--size);
  height: var(--size);
  border-radius: ${({ size }) => (size === 'small' ? '2px' : '6px')};
  flex-shrink: 0;

  ${StyledParagraph} {
    color: white;
  }
`
