import { EditIcon } from '@maersktankersdigital/web-components'
import * as React from 'react'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'

import {
  InternalCargoesPopupContentWrapper,
  UserNameWrapper,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/l3ccontent/l3ccontent.styles'
import {
  StyledEditButton,
  UserDateWrapper,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/next-dry-dock-field/next-dry-dock-field.styles'
import { formatDate } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-content/position-list-table-content'
import { LastThreeCargoes } from '~pages/pages-behind-login/position-list/types/position-list-types'

interface Props {
  editModalOpen: boolean
  internalLastThreeCargoes?: LastThreeCargoes
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function InternalCargoesHoverContent({
  internalLastThreeCargoes,
  setEditModalOpen,
}: Props) {
  const isPositionListUser = useHasScope(ExactScopeName.positionListUpdate)

  return (
    <InternalCargoesPopupContentWrapper>
      <UserDateWrapper>
        <>
          <UserNameWrapper>
            {internalLastThreeCargoes?.responsibleUser?.name}
          </UserNameWrapper>
          {formatDate(internalLastThreeCargoes?.createdAt)}
        </>
      </UserDateWrapper>
      <div>{internalLastThreeCargoes?.comment}</div>
      {isPositionListUser && (
        <StyledEditButton
          variant="primary"
          onClick={() => setEditModalOpen(true)}
          icon={<EditIcon size={16} />}
        >
          Edit L3C
        </StyledEditButton>
      )}
    </InternalCargoesPopupContentWrapper>
  )
}

export default InternalCargoesHoverContent
