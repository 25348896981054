import { FC, useEffect, useRef } from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'

import { TextInput } from '~components/atoms/text-input'
import { FieldTemplate } from '~components/templates/field/field-template'
import { assembleValidations } from '~utils/form-validations'

interface INumberInputField {
  defaultValue?: string | null
  focus?: boolean
  isLocked?: boolean
  label?: string
  name: string
  placeholder?: string
  required?: boolean
  type?: 'text'
  validations?: RegisterOptions
}

export const NumberInputField: FC<INumberInputField> = ({
  name,
  defaultValue,
  validations,
  required,
  placeholder,
  label,
  isLocked,
  focus,
  type = 'text',
}) => {
  const { control } = useFormContext()
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focus) {
      ref?.current?.focus()
    }
  }, [ref, focus])

  return (
    <FieldTemplate name={name} label={label}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={assembleValidations(required, validations)}
        render={({ field: { onChange, value } }) => {
          return (
            <TextInput
              name={name}
              type={type}
              value={value}
              innerRef={ref}
              placeholder={placeholder}
              isClearable={!!value}
              onChange={onChange}
              isLocked={isLocked}
              onClear={() => {
                return onChange('')
              }}
            />
          )
        }}
      />
    </FieldTemplate>
  )
}
