import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { apiUpdateAction } from '~api/actions'
import { Scope } from './roles/scope-types'

export interface IUserTermsUpdateResponse {
  ToSAcceptedDate: string
  email: string
  name?: string
  phoneNumber?: string
  secondaryPhoneNumber?: string
  statusCode?: string
  sub: string
  scope: Scope
  isInternal: boolean
  poolPartnerKeys: string[]
}

export interface IUserTermsUpdatePayload {
  ToSAcceptedDate: string
}

export function userTermsUpdateAction(
  payload: IUserTermsUpdatePayload,
  urlParams?: Params,
): Promise<IUserTermsUpdateResponse> {
  return apiUpdateAction<IUserTermsUpdatePayload, IUserTermsUpdateResponse>(
    ApiRoutes.User.update,
    payload,
    urlParams,
  )
}
