import { Link } from '@maersktankersdigital/web-components'
import { Box, Typography } from '@mui/material'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { PoolPointsResult } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-result/pool-points-result'
import { COLORS } from '~theme/colors'
import { getCopy } from '~utils/get-copy'
import { useVesselPageState } from '../vessel-page-provider'

export function PoolPointsSumUp() {
  const state = useVesselPageState()
  const isAccountManager = useHasScope(ExactScopeName.accountManager)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
      }}
    >
      <Box
        sx={{
          backgroundColor: COLORS.secondary.blueTint,
          p: 5,
          width: '460px',
        }}
      >
        <Typography variant="h3" component="p" sx={{ pb: 3 }}>
          {getCopy('Pool Points for {$vesselName}', {
            vesselName: state.vessel?.vesselName || '',
          })}
        </Typography>
        <PoolPointsResult />
      </Box>

      {isAccountManager && (
        <Box sx={{ mt: 2 }}>
          <Link
            link={state.poolPointsFlowUrl}
            label="Go to Pool Point Questionnaire"
          />
        </Box>
      )}
    </Box>
  )
}
