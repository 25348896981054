import { Params } from 'react-router-dom'
import { ApiRoutes } from '~api'
import { apiGetAction } from '~api/actions'
import { IRowComment } from './restrictions/all'

interface IVersionHistoryFile {
  etag: string
  filename: string
  name: string
}

export interface IVersionHistoryReadResponse {
  comments: IRowComment[]
  files: IVersionHistoryFile[]
  updatedAt: string
  updatedBy: string
  updatedByUser: {
    name: string
    sub: string
  }
  value: string
}

export interface IVettingVersionHistoryReadResponse {
  vetting: {
    comment: string
    filename: string
    hash: string
    updatedAt: string
    updatedBy: string
    updatedByUser?: {
      name: string
      sub: string
    }
  }
}

export function versionHistoryReadAction(
  urlParams?: Params,
): Promise<IVersionHistoryReadResponse[]> {
  return apiGetAction<IVersionHistoryReadResponse[]>(
    ApiRoutes.Vessels.Tabs.versionHistoryRead,
    urlParams,
  )
}

export function vettingVersionHistoryReadAction(
  urlParams?: Params,
): Promise<IVettingVersionHistoryReadResponse[]> {
  return apiGetAction<IVettingVersionHistoryReadResponse[]>(
    ApiRoutes.Vessels.Tabs.vettingVersionHistoryRead,
    urlParams,
  )
}
