import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'

export interface MuiDrawerData {
  defaultProps?: ComponentsProps['MuiDrawer']
  styleOverrides?: ComponentsOverrides['MuiDrawer']
  variants?: ComponentsVariants['MuiDrawer']
}

export const MuiDrawer: MuiDrawerData = {
  styleOverrides: {
    root: {
      '& .MuiDrawer-paper': {
        backgroundColor: COLORS.secondary.darkBlue,
      },
    },
  },
}
