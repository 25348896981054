import styled from 'styled-components/macro'

import { shadowBoxStyles } from '~components/atoms/shadow-box'

export const StyledNavButtonle = styled.table<{ compact?: boolean }>`
  width: 100%;
  table-layout: fixed;
  border-collapse: separate !important;
  border-spacing: ${(props) => {
    return props.compact ? '0' : '0 12px'
  }};
`

export const StyledCompactTr = styled.tr``
export const StyledCompactTd = styled.td`
  ${StyledCompactTr}:first-child & {
    padding-top: 20px;
  }
  ${StyledCompactTr}:last-child & {
    padding-bottom: 20px;
  }
`

export const StyledCompactSpacing = styled.tbody`
  &:before {
    height: 20px;
    display: table-row;
    content: '';
  }
`

export const StyledTbody = styled.tbody<{
  compact?: boolean
}>`
  &:before {
    display: table-row;
    content: '';
  }
  ${(props) => {
    return props.compact && shadowBoxStyles.join('')
  }}
`

export const TableOverflowWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`
