import { flattenDeep, uniqBy } from 'lodash'

import { apiGetAction } from '~api/actions'
import {
  TableConfig,
  TableRowConfig,
} from '~components/organisms/table/table.types'
import {
  DataTransformerTableConfig,
  TableDataTransformerTable,
} from '~components/organisms/table/table-data-transformer/table-data-transformer.types'
import {
  getExpandableRowsRecursively,
  getRowCellsByConfig,
  searchRecursively,
} from '~components/organisms/table/table-data-transformer/table-data-transformer.utils'

export const tableDataTransformer = async <AD>(
  tableConfig: DataTransformerTableConfig<AD>,
  apiData: AD[],
): Promise<[TableConfig, TableDataTransformerTable[]]> => {
  const tableCellConfigs = tableConfig.rowConfig.cellConfig
  const expandableCellConfigs = searchRecursively(
    tableCellConfigs,
    'expandedCellConfig',
  )

  const tableHeadConfig = {
    rows: [
      {
        cells: tableCellConfigs.map(({ heading, colSpan }) => ({
          colSpan,
          data: heading,
          textTransform: 'uppercase',
          fontWeight: 'bold',
        })),
      },
    ] as TableRowConfig[],
  }

  const apiConfigList = flattenDeep(
    tableCellConfigs.map((config) =>
      searchRecursively(config, 'dataSourceConfig'),
    ),
  ).map((config) => config.apiConfig)
  const apiSourceData = uniqBy(apiConfigList, (data) => data?.type)

  // Fetch all unique api sources mentioned in config before building the table rows
  const apiSourcedRowData = await Promise.all(
    apiSourceData.map(async (config) => ({
      [config.mergerKey]: await apiGetAction(config.url, {
        ...config.params,
      }).then((response) =>
        // @ts-ignore
        response && 'statusCode' in response
          ? []
          : (config.sortFn?.(response ?? []) ?? response),
      ),
    })),
  )

  const apiDataConfig = apiData.map((tableData) => {
    if (!tableData) {
      return null
    }

    // Use custom row data or fall back to the data passed initially
    const tableRowData =
      tableConfig.rowConfig.dataSourceConfig?.dataFn?.(
        tableData,
        apiSourcedRowData,
      ) ?? apiData

    if (tableRowData.length === 0) {
      return null
    }

    return {
      // @ts-ignore
      tableName: 'name' in tableData ? tableData.name : 'Unknown',
      // @ts-ignore
      tableId: 'id' in tableData ? tableData.id : 'Unknown',
      rows: tableRowData.flatMap(
        (tableRowItem: AD, tableRowItemIndex: number) => {
          const expandableTableRows = getExpandableRowsRecursively(
            expandableCellConfigs,
            // @ts-ignore
            apiSourcedRowData,
            tableData,
            tableRowItem,
          )

          return [
            {
              cells: tableCellConfigs.map((config) =>
                getRowCellsByConfig(
                  config,
                  tableData,
                  // @ts-ignore
                  tableRowItem.rowData,
                  tableRowItem,
                  tableRowItemIndex,
                ),
              ),
              expandableRows: expandableTableRows,
            },
          ]
        },
      ),
    }
  }) as TableDataTransformerTable[]

  return [tableHeadConfig, apiDataConfig]
}
