import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import AccordionProvider from '~components/molecules/accordion/accordion-provider'
import { TableDataTransformerTable } from '~components/organisms/table/table-data-transformer/table-data-transformer.types'
import TableElement from '~components/organisms/table/table-element'
import {
  StyledTable,
  StyledTableBody,
} from '~components/organisms/table/table.styles'
import { TableConfig } from '~components/organisms/table/table.types'
import { COLORS } from '~theme/colors'
import { getCustomPoolPointsTableResultsRow } from './pool-points-table.config'
import {
  StyledPoolPointsTable,
  StyledPoolPointsTableHeader,
} from './pool-points-table.styles'

type PoolPointsTableProps = {
  isCustomTableConfig?: boolean
  tableDataConfig: TableDataTransformerTable
  tableHeadConfig?: TableConfig
  tableId: string
  store?: any
}

export const PoolPointsTable = ({
  tableDataConfig,
  tableHeadConfig,
  tableId,
  store,
}: PoolPointsTableProps) => {
  const resultsRow = !store
    ? { cells: [] }
    : getCustomPoolPointsTableResultsRow(store, tableDataConfig)

  const lastRowIndex = tableDataConfig.rows
    ? tableDataConfig.rows.length - 1
    : 0

  return (
    <StyledPoolPointsTable>
      <StyledPoolPointsTableHeader>
        <Paragraph size="medium" color={COLORS.greys.ultraDark}>
          {tableDataConfig.tableName}
        </Paragraph>
      </StyledPoolPointsTableHeader>

      <StyledTable useAccordion>
        <AccordionProvider>
          {tableHeadConfig && (
            <TableElement.Head tableHeadConfig={tableHeadConfig} />
          )}

          <StyledTableBody>
            {tableDataConfig.rows?.map(
              ({ expandableRows, cells }, rowIndex) => {
                return (
                  <>
                    {rowIndex !== lastRowIndex && (
                      <TableElement.Row
                        key={`${tableId}-row-${rowIndex}`}
                        tableId={tableId}
                        cells={cells}
                        rowIndex={rowIndex}
                        expandableRows={expandableRows}
                      />
                    )}
                  </>
                )
              },
            )}
            {resultsRow.cells.length > 0 && (
              <TableElement.Row
                rowIndex={0}
                tableId={tableId}
                cells={resultsRow.cells}
              />
            )}
          </StyledTableBody>
        </AccordionProvider>
      </StyledTable>
    </StyledPoolPointsTable>
  )
}
