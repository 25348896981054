import {
  Checkbox,
  CloseIcon,
  ExportIcon,
  IconWrapper,
  Modal,
  VesselFrontIcon,
} from '@maersktankersdigital/web-components'
import * as React from 'react'
import { forwardRef, useEffect, useState } from 'react'
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard'

import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { useFilteredData } from '~pages/pages-behind-login/position-list/contexts/filtered-data-provider'
import { usePositionListView } from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import { useAreaFilteredVessels } from '~pages/pages-behind-login/position-list/hooks/use-area-filtered-vessels'
import {
  CheckboxWrapper,
  ExportButton,
  ExportModalContent,
  ExportVesselsWrapper,
  InnerMultiWrapper,
  MultiSelectWrapper,
  OverflowWrapper,
  PlusNText,
  StyledClickPoint,
  StyledMultiButton,
  StyledMultiSelect,
} from '~pages/pages-behind-login/position-list/position-list-page/modals/export-modal/export-modal.styles'
import { generateHTMLTable } from '~pages/pages-behind-login/position-list/position-list-page/modals/export-modal/export-modal.utils'
import {
  loadAreaOptions,
  vesselSpecsOptions,
  worldAreaOptions,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-controls/position-list-table-controls'

interface Props {
  setShowExportModal: (open: boolean) => void
  showExportModal: boolean
}
export interface MultiSelectValues {
  eta: string[]
  selectedVessels: string[]
  vesselSpecs: string[]
  worldArea: string[]
}

export interface CheckboxValues {
  lastThreeCargos: boolean
  openDate: boolean
  openPort: boolean
}

const defaultMultiSelectValues: MultiSelectValues = {
  vesselSpecs: [],
  eta: [],
  selectedVessels: [],
  worldArea: [],
}

const defaultCheckboxValues: CheckboxValues = {
  openDate: true,
  openPort: true,
  lastThreeCargos: true,
}

function ExportListModal({ setShowExportModal, showExportModal }: Props) {
  const handleCloseModal = () => setShowExportModal(false)
  const { settings, activeView, saveSettings } = usePositionListView()
  const { filteredData } = useFilteredData()
  const { areaFilteredVessels } = useAreaFilteredVessels(
    filteredData,
    activeView?.filterSettings,
  )
  const [, copy] = useCopyToClipboard()
  const filteredExportedVessels = filteredData?.filter((vessel) =>
    settings?.exportedVessels?.includes(vessel.vesselId),
  )

  const { setStatus } = useAnimatedAlert()

  const [multiSelectValues, setMultiSelectValues] = useState<MultiSelectValues>(
    defaultMultiSelectValues,
  )

  const [checkboxValues, setCheckboxValues] = useState<CheckboxValues>(
    defaultCheckboxValues,
  )

  useEffect(() => {
    setMultiSelectValues((prevValues) => ({
      ...prevValues,
      vesselSpecs: vesselSpecsOptions
        .filter((spec) => activeView?.columns?.includes(spec.value))
        .map((spec) => spec.value),
    }))
    setMultiSelectValues((prevValues) => ({
      ...prevValues,
      eta: loadAreaOptions
        .filter((spec) => activeView?.columns?.includes(spec.value))
        .map((spec) => spec.value),
    }))
    setMultiSelectValues((prevValues) => ({
      ...prevValues,
      worldArea: worldAreaOptions
        .filter((spec) => activeView?.columns?.includes(spec.value))
        .map((spec) => spec.value),
    }))
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      lastThreeCargos:
        activeView?.columns?.includes('lastThreeCargos') || false,
      openDate: activeView?.columns?.includes('openDate') || false,
      openPort: activeView?.columns?.includes('openPort') || false,
    }))
  }, [activeView?.columns])

  useEffect(() => {
    setMultiSelectValues((prevValues) => ({
      ...prevValues,
      selectedVessels:
        filteredData
          ?.filter((vessel) =>
            settings?.exportedVessels?.includes(vessel.vesselId),
          )
          ?.map((vessel) => vessel.vessel) || [],
    }))
  }, [filteredData, settings?.exportedVessels])

  if (!showExportModal) return null

  const handleExport = () => {
    const html =
      activeView?.showHeaders &&
      !activeView?.filterSettings?.worldArea?.includes('ALL')
        ? generateHTMLTable(
            areaFilteredVessels,
            multiSelectValues,
            checkboxValues,
            activeView?.columns || [],
          )
        : generateHTMLTable(
            [{ vessels: filteredData || [], name: '', children: [] }],
            multiSelectValues,
            checkboxValues,
            activeView?.columns || [],
          )

    saveSettings({ isExporting: false, exportedVessels: [] })
    setMultiSelectValues(defaultMultiSelectValues)
    setCheckboxValues(defaultCheckboxValues)
    setShowExportModal(false)
    copy(html, true, () => setStatus('Copied to clipboard!'))
  }

  const vesselNameOptions =
    filteredExportedVessels?.map((vessel) => ({
      label: vessel.vessel,
      value: vessel.vessel,
    })) || []

  const handleMultiSelectChange = (
    event:
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
      | React.FocusEvent<Element>
      | null,
    selectedOptions: string[] | null,
    name: string,
  ) => {
    if (!selectedOptions) return
    let newFilterValue: string[] = []
    const allIndex = selectedOptions.indexOf('ALL')
    if (selectedOptions.length === 0) {
      newFilterValue = []
    } else if (allIndex > -1 && allIndex === selectedOptions.length - 1) {
      newFilterValue = ['ALL']
    } else {
      newFilterValue = selectedOptions.filter((val) => val !== 'ALL')
    }

    setMultiSelectValues((prevValues) => ({
      ...prevValues,
      [name]: newFilterValue,
    }))
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.checked,
    }))
  }

  return (
    <>
      <Modal open={showExportModal} handleClose={handleCloseModal}>
        <>
          <StyledClickPoint ariaLabel="close modal" onClick={handleCloseModal}>
            <CloseIcon size={20} />
          </StyledClickPoint>
          <ExportModalContent>
            <ExportVesselsWrapper>
              <StyledMultiSelect
                label="Vessels"
                name="selectedVessels"
                value={multiSelectValues.selectedVessels}
                options={vesselNameOptions}
                onChange={handleMultiSelectChange}
                slotComponents={{ root: MultiButton }}
                slotProps={{
                  root: {
                    selectedOptions: multiSelectValues.selectedVessels,
                    style: { width: '400px' },
                  },
                }}
              />
            </ExportVesselsWrapper>
            <CheckboxWrapper>
              <Checkbox
                name="openDate"
                label="Open date"
                checked={checkboxValues.openDate}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                name="openPort"
                label="Open port"
                checked={checkboxValues.openPort}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                name="lastThreeCargos"
                label="Last three cargos"
                checked={checkboxValues.lastThreeCargos}
                onChange={handleCheckboxChange}
              />
            </CheckboxWrapper>
            <MultiSelectWrapper>
              <StyledMultiSelect
                label="Vessel specs"
                name="vesselSpecs"
                options={vesselSpecsOptions}
                value={multiSelectValues.vesselSpecs}
                onChange={handleMultiSelectChange}
              />
              <StyledMultiSelect
                label="ETA"
                name="eta"
                options={loadAreaOptions}
                value={multiSelectValues.eta}
                onChange={handleMultiSelectChange}
              />
            </MultiSelectWrapper>
            <MultiSelectWrapper>
              <StyledMultiSelect
                label="World Area"
                name="worldArea"
                options={worldAreaOptions}
                value={multiSelectValues.worldArea}
                onChange={handleMultiSelectChange}
              />
            </MultiSelectWrapper>
            <ExportButton
              variant="primary"
              icon={<ExportIcon />}
              onClick={handleExport}
            >
              Export
            </ExportButton>
          </ExportModalContent>
        </>
      </Modal>
    </>
  )
}
const MultiButton = forwardRef(function MultiButton(
  {
    selectedOptions,
    ...props
  }: {
    selectedOptions: string[]
  },
  ref,
) {
  return (
    <StyledMultiButton {...props} ref={ref}>
      <InnerMultiWrapper>
        <IconWrapper>
          <VesselFrontIcon size={16} />
        </IconWrapper>
        <OverflowWrapper>
          {selectedOptions.slice(0, 3).join(', ')}
          {selectedOptions.length > 3 && (
            <PlusNText>{` + ${selectedOptions.length - 3}`}</PlusNText>
          )}
        </OverflowWrapper>
      </InnerMultiWrapper>
    </StyledMultiButton>
  )
})

export default ExportListModal
