import { FC, memo, MouseEvent, ReactNode } from 'react'

import { iconsMap } from '~assets/svgs'
import { Icon } from '~components/atoms/icon'
import { Spinner } from '~components/atoms/spinner'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

import { Box } from '../box'
import { Text } from '../text'
import {
  StyledButton,
  StyledCloseButton,
  StyledFloatingButton,
  StyledNavButton,
  StyledNewSolidSecondary,
  StyledOutlineButton,
  StyledOutlineDangerButton,
  StyledPrimaryButton,
  StyledSolidSecondaryButton,
  StyledSpinnerWrapper,
  StyledTextWrapper,
  StyledWhiteButton,
} from './styles'

export enum Variants {
  solid = 'solid',
  solidSecondary = 'solidSecondary',
  outline = 'outline',
  outlineDanger = 'outlineDanger',
  white = 'white',
  nav = 'nav',
  floating = 'floating',
  close = 'close',
  primary = 'primary',
  newSolidSecondary = 'newSolidSecondary',
}

export interface IButton {
  active?: boolean
  children?: ReactNode
  icon?: keyof typeof iconsMap
  iconMargin?: number
  isDisabled?: boolean
  isLoading?: boolean
  isSmall?: boolean
  onClick?: (event: MouseEvent) => void
  text: string
  type?: 'button' | 'submit' | 'reset'
  variant?: keyof typeof Variants
}

const buttonComponentVariants = {
  outline: StyledOutlineButton,
  outlineDanger: StyledOutlineDangerButton,
  solidSecondary: StyledSolidSecondaryButton,
  white: StyledWhiteButton,
  solid: StyledButton,
  nav: StyledNavButton,
  floating: StyledFloatingButton,
  close: StyledCloseButton,
  primary: StyledPrimaryButton,
  newSolidSecondary: StyledNewSolidSecondary,
}

export const Button: FC<IButton> = memo(
  ({
    active,
    isDisabled,
    isLoading,
    isSmall,
    onClick,
    text,
    type = 'button',
    variant = Variants.solid,
    icon,
    iconMargin,
    children,
  }) => {
    const StyledVariantButton = buttonComponentVariants[variant]
    const isSolid =
      variant === 'solid' ||
      variant === 'solidSecondary' ||
      variant === 'newSolidSecondary' ||
      variant === 'floating' ||
      (variant === 'primary' && !isDisabled)
    const isDanger = variant === 'outlineDanger'

    return (
      <StyledVariantButton
        isDisabled={!!isDisabled}
        isLoading={!!isLoading}
        isSmall={!!isSmall}
        onClick={onClick}
        type={type}
        active={active}
      >
        {variant === 'close' && children}
        {isLoading && (
          <StyledSpinnerWrapper>
            <Spinner
              color={
                isSolid ? 'white' : isDanger ? Colors.reds.base : Colors.body
              }
            />
          </StyledSpinnerWrapper>
        )}
        <StyledTextWrapper isLoading={!!isLoading}>
          {icon && (
            <Icon
              name={icon}
              size={isSmall ? 18 : 24}
              fill={
                isSolid ? 'white' : isDanger ? Colors.reds.base : Colors.body
              }
            />
          )}
          {icon && <Box mr={iconMargin || 0.8} />}
          <Text
            text={text}
            size={isSmall ? 'small' : 'body'}
            color={
              isSolid
                ? 'white'
                : isDanger
                  ? 'reds.base'
                  : active
                    ? 'white'
                    : 'body'
            }
            whiteSpace="nowrap"
          />
        </StyledTextWrapper>
      </StyledVariantButton>
    )
  },
)
