import styled, { css } from 'styled-components/macro'

import { Z_INDEX } from '~types/z-index'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export const StyledAutoCompleteInputResults = styled.ul`
  top: 100%;
  overflow-y: auto;
  background: ${Colors.white};
  position: absolute;
  width: 100%;
  max-height: 300px;
  margin-top: 4px;
  padding: 7px 0;
  border-radius: 4px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  z-index: ${Z_INDEX.InputList};
`

export const StyledAutoCompleteInputResult = styled.li<{
  isSelected?: boolean
}>`
  list-style-type: none;
  padding: 1px 20px 6px;
  text-transform: uppercase;
  cursor: pointer;

  &:has(a) {
    padding: 0;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background: ${Colors.blues.secondaryLight};
  }

  ${(p) =>
    p.isSelected &&
    css`
      background: ${Colors.blues.secondaryLight};
    `}

  a {
    display: block;
    width: 100%;
    padding: 1px 20px 6px;
  }
`

export const StyledAutoCompleteInputOptionValue = styled.div<{
  color?: string
}>`
  display: inline;
  margin-right: 7px;

  &:last-of-type {
    margin: 0;
  }

  span,
  p {
    color: ${(p) => p.color ?? 'inherit'};
  }
`
