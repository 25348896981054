import { useEffect, useState } from 'react'

import { FilterTypes } from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import { PositionVesselData } from '~pages/pages-behind-login/position-list/types/position-list-types'

export const worldAreaHierarchy = {
  level1: {
    Americas: {
      level2: {
        'Atlantic Basin': {
          level3: ['EC North America', 'EC SAM', 'USG-Caribs'],
        },
        'Pacific Basin': {
          level3: ['WC Latin America'],
        },
      },
    },
    'East of Suez': {
      level2: {
        AG: {
          level3: [
            'East- & South Africa',
            'Middle East',
            'Red Sea',
            'WC India',
          ],
        },
        ASIA: {
          level3: [
            'EC Australia',
            'EC India',
            'N Asia',
            'SE Asia',
            'USWC',
            'WC Australia',
          ],
        },
      },
    },
    Europe: {
      level2: {
        MED: {
          level3: ['Black Sea', 'Center Med', 'East Med', 'West Med'],
        },
        NWE: {
          level3: ['Atlantic', 'Baltic', 'UKC'],
        },
        WAF: {
          level3: ['WAF'],
        },
      },
    },
  },
}

export interface AreaFilteredVessels {
  children: AreaFilteredVessels[]
  isOpen?: boolean
  name: string
  ref?: React.RefObject<HTMLTableRowElement>
  vessels?: PositionVesselData[]
}

export function useAreaFilteredVessels(
  vessels?: PositionVesselData[],
  filters?: FilterTypes,
) {
  const [areaFilteredVessels, setAreaFilteredVessels] = useState<
    AreaFilteredVessels[]
  >([])

  useEffect(() => {
    const newAreaFilteredVessels: AreaFilteredVessels[] = []
    const worldAreaFilters = filters?.worldArea || []
    const vesselsCopy = [...(vessels || [])]

    const noWorldAreaVessels = vessels?.filter((vessel) => {
      if (!vessel.worldArea) return true
      return !vessel.worldArea.level1
    })

    if (noWorldAreaVessels?.length) {
      newAreaFilteredVessels.push({
        name: 'No World Area',
        children: [],
        vessels: noWorldAreaVessels,
        isOpen: true,
      })
    }

    for (const area in worldAreaHierarchy.level1) {
      const areaKey = area as keyof typeof worldAreaHierarchy.level1
      const areaObj: AreaFilteredVessels = {
        name: getName('level1', area),
        children: [],
        vessels: vesselsCopy?.filter((vessel) => {
          return (
            vessel.worldArea &&
            vessel.worldArea?.level1 === area &&
            filters?.worldArea?.includes(`level1.${vessel.worldArea.level1}`) &&
            !filters?.worldArea?.includes(
              `level2.${vessel.worldArea.level2}`,
            ) &&
            !filters?.worldArea?.includes(`level3.${vessel.worldArea.level3}`)
          )
        }),
        isOpen: true,
      }

      for (const region in worldAreaHierarchy.level1[areaKey].level2) {
        const regionObj: AreaFilteredVessels = {
          name: getName('level2', region),
          children: [],
          vessels: vesselsCopy?.filter(
            (vessel) =>
              vessel.worldArea &&
              vessel.worldArea?.level2 === region &&
              filters?.worldArea?.includes(
                `level2.${vessel.worldArea.level2}`,
              ) &&
              !filters?.worldArea?.includes(
                `level3.${vessel.worldArea.level3}`,
              ),
          ),
          isOpen: true,
        }

        // @ts-ignore
        for (const subregion of worldAreaHierarchy.level1[areaKey].level2[
          region
        ].level3) {
          if (worldAreaFilters.includes(`level3.${subregion}`)) {
            regionObj.children.push({
              name: subregion,
              children: [],
              vessels: vesselsCopy?.filter(
                (vessel) =>
                  vessel.worldArea &&
                  vessel.worldArea.level3 === subregion &&
                  filters?.worldArea?.includes(
                    `level3.${vessel.worldArea.level3}`,
                  ),
              ),
              isOpen: true,
            })
          }
        }

        if (regionObj.name !== '' || regionObj.children.length > 0) {
          areaObj.children.push(regionObj)
        }
      }

      if (areaObj.name !== '' || areaObj.children.length > 0) {
        newAreaFilteredVessels.push(areaObj)
      }
    }
    setAreaFilteredVessels(newAreaFilteredVessels)
  }, [filters?.worldArea, vessels])

  function getName(level: string, name: string) {
    return filters?.worldArea?.includes(`${level}.${name}`) ? name : ''
  }

  return { areaFilteredVessels }
}
