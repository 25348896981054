export const COLORS = {
  primary: {
    maerskBlue: '#42b0d5ff',
    white: '#ffffffff',
  },
  secondary: {
    darkBlue: '#00243dff',
    blue: '#193850ff',
    midBlue: '#0073abff',
    barBlue: '#0fa0d1ff',
    hoverBlue: '#57b9daff',
    actionBlue: '#61c8ebff',
    lightBlue: '#b5e0f4ff',
    notificationBlue: '#deedf4ff',
    blueTint: '#f0f9fdff',
  },
  greys: {
    ultraDark: '#405a6eff',
    dark: '#6c7f8dff',
    mid: '#b3bdc5ff',
    light: '#e3e8ecff',
    ultraLight: '#f7f8f9ff',
  },
  orange: {
    dark: '#a76d46ff',
    mid: '#f07508ff',
    light: '#ffa201ff',
    tint1: '#e69c56ff',
    tint2: '#eebd8bff',
    tint3: '#f9d28bff',
    tint4: '#fbe7c3ff',
    tint5: '#f6dec4ff',
  },
  red: {
    dark: '#6b0d05ff',
    mid: '#fa381cff',
    light: '#ee9f92ff',
    tint1: '#f9d8d3ff',
    tint2: '#f9dcd4ff',
    tint3: '#fcefebff',
  },
  citrus: {
    dark: '#7c9d00ff',
    mid: '#a3bf00ff',
    light: '#d6e000ff',
    tint1: '#c0cd89ff',
    tint2: '#dfe6c3ff',
    tint3: '#d5de8cff',
    tint4: '#e8efc2ff',
  },
  green: {
    dark: '#00786bff',
    mid: '#4db5abff',
    light: '#e8f4f2ff',
    ash: '#88a59fff',
  },
  feedback: {
    // not in design system
    green: {
      success: '#00cc00',
      successLight: '#ccffcc',
    },
    yellow: {
      warning: '#ffcc00',
      warningLight: '#ffffcc',
    },
    red: {
      error: '#B80012',
      errorLight: '#ffcccc',
    },
    blue: {
      notification: '#4da6ff',
      notificationLight: '#cce6ff',
    },
  },
}
