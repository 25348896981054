import { RadioButton } from '@maersktankersdigital/web-components'
import { FC } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { IOption } from '~types/components'

export interface IRadiobuttonGroup {
  defaultValue?: string | null
  isDisabled?: boolean
  name: string
  options: IOption[]
  required?: boolean
}

export const RadiobuttonGroup: FC<IRadiobuttonGroup> = ({
  options,
  defaultValue,
  isDisabled,
  required,
  name,
}) => {
  const { control } = useFormContext()
  const { field } = useController({
    control,
    name,
    defaultValue,
    rules: { required: required },
  })

  return (
    <>
      {options.map((option) => {
        return (
          <div key={option.value}>
            <RadioButton
              name={name}
              label={option.label}
              value={option.value}
              checked={field.value === option.value}
              disabled={isDisabled}
              onClick={(e) => {
                // @ts-ignore
                field.onChange(e.target.value)
              }}
            />
          </div>
        )
      })}
    </>
  )
}
