import styled from 'styled-components/macro'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export const StyledTh = styled.th<{ width?: string }>`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${Colors.blues.deep};
  vertical-align: top;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  min-width: ${({ width }) => (width ? `${width}px` : 'auto')};

  &:first-child {
    padding-left: 0;
  }
`
