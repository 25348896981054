import { theme } from '@maersktankersdigital/web-components'
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'

import { typography } from '../global/typography'

export interface MuiTooltipData {
  defaultProps?: ComponentsProps['MuiTooltip']
  styleOverrides?: ComponentsOverrides['MuiTooltip']
  variants?: ComponentsVariants['MuiTooltip']
}

export const MuiTooltip: MuiTooltipData = {
  styleOverrides: {
    popper: {
      '& .MuiTooltip-tooltip': {
        marginTop: '4px',
        backgroundColor: COLORS.primary.white,
        padding: '16px',
        boxShadow: theme.SHADOWS.cardShadow,
        ...typography.paragraph2,
      },
    },
  },
}
