import { TransformedPoolPointsTableData } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-table/pool-points-table.config'

export const getPoolPointsTableGroupedRowData = (
  tableData: TransformedPoolPointsTableData,
) => {
  const maxPossiblePoints = Object.keys(tableData.fields).reduce(
    // @ts-ignore
    (prevVal, key) => prevVal + (tableData.fields[key]?.range?.max || 0),
    0,
  )

  const rows = [
    ...Object.keys(tableData.fields)
      .filter((key) => key !== 'results' && key !== 'summerDraft')
      .map((key) => {
        // @ts-ignore
        const historyData = tableData.fields[key].history || []

        return {
          // @ts-ignore
          ...tableData.fields[key],
          ...(historyData?.[0] || {}),
          key,
          rowData:
            (historyData.length > 0 &&
              historyData.slice(1, historyData.length)) ||
            [],
        }
      }),
    {
      label: `Total Pool Points for ${tableData.name}`,
      range: {
        // @ts-ignore "results" key was added manually and does not come from the API
        min: tableData.fields.results?.result ?? 'Unknown',
        max: maxPossiblePoints,
      },
    },
  ]

  const capNoRowIndex = rows.findIndex((obj) => obj.key === 'capNo')
  const yearBuildRowIndex = rows.findIndex((obj) => obj.key === 'yearBuild')

  if (yearBuildRowIndex > -1 && capNoRowIndex > -1) {
    // Move CapNo after YearBuild
    rows.splice(yearBuildRowIndex, 0, rows.splice(capNoRowIndex, 1)[0])
  }

  return rows
}
