import { apiGetAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'
import { IComment } from '~pages/pages-behind-login/position-list/types/position-list-types'

export interface IPartnerManager {
  name: string
}

export interface IPoolPartnerResponse {
  _id: string
  name: string
  partnerId: string
  partnerManager: string
  partnerManagerUser: IPartnerManager
  poolPartnerKey: string
  pools: string[]
  vesselCount: number
}

export interface IPoolPartnerCommentsReponse {
  _id: string
  comments: IComment
  name: string
  poolPartnerKey: string
}

export function poolPartnersAllAction(): Promise<IPoolPartnerResponse[]> {
  return apiGetAction<IPoolPartnerResponse[]>(ApiRoutes.POOL_PARTNERS.ALL)
}
