import { RegisterOptions } from 'react-hook-form'

const ValidationMessages = {
  required: 'Field is required',
  email: 'Not a valid email format',
  number: 'Not a valid number format',
  minLength: (length: number): string => {
    return `Needs to be at least ${length} characters long`
  },
  maxLength: (length: number): string => {
    return `Needs to be less than ${length + 1} characters long`
  },
  min: (length: number): string => {
    return `Needs to be at least ${length}`
  },
  max: (length: number): string => {
    return `Needs to be less than ${length + 1}`
  },
}

export const assembleValidations = (
  required: boolean | undefined,
  validations: RegisterOptions | undefined,
): RegisterOptions | undefined => {
  return required
    ? { required: ValidationMessages.required, ...validations }
    : validations
}
