import {
  Context,
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react'

import { IVesselsReadResponse } from '~api/vessels/read'
import {
  PoolPoints,
  PoolPointsCorrectionInput,
  PoolPointsVersion,
} from '~types/pool-points.types'
import createContextStateHook from '~utils/create-hook-for-context-state'

export type PoolPointsPageState = {
  correctionData: PoolPointsCorrectionInput | null
  datepickerDate: Record<string, Date>
  isPopupOpen: boolean
  poolPoints: PoolPoints[] | null
  setCorrectionData: Dispatch<SetStateAction<PoolPointsCorrectionInput | null>>
  setDatepickerDate: Dispatch<SetStateAction<Record<string, Date>>>
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>
  setPoolPoints: Dispatch<SetStateAction<PoolPoints[] | null>>
  setVersions: Dispatch<SetStateAction<PoolPointsVersion[]>>
  setVessel: Dispatch<SetStateAction<IVesselsReadResponse | null>>
  versions: PoolPointsVersion[]
  vessel: IVesselsReadResponse | null
}

export const PoolPointsPageContext = createContext<PoolPointsPageState | null>(
  null,
) as Context<PoolPointsPageState>

export default function PoolPointsPageProvider({
  ...props
}: PropsWithChildren) {
  const [vessel, setVessel] = useState<IVesselsReadResponse | null>(null)
  const [poolPoints, setPoolPoints] = useState<PoolPoints[] | null>(null)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

  const [datepickerDate, setDatepickerDate] = useState<Record<string, Date>>({})
  const [versions, setVersions] = useState<PoolPointsVersion[]>([])
  const [correctionData, setCorrectionData] =
    useState<PoolPointsCorrectionInput | null>(null)

  return (
    <PoolPointsPageContext.Provider
      value={{
        vessel,
        setVessel,
        poolPoints,
        setPoolPoints,
        isPopupOpen,
        setIsPopupOpen,
        datepickerDate,
        setDatepickerDate,
        versions,
        setVersions,
        correctionData,
        setCorrectionData,
      }}
      {...props}
    />
  )
}

export const usePoolPointsPageState = createContextStateHook(
  PoolPointsPageContext,
  'PoolPointsPageContext',
)
