import { FC } from 'react'

import { TableHeaderCell } from '~components/atoms/table-header-cell'

import {
  StyledCompactSpacing,
  StyledNavButtonle,
  StyledTbody,
} from './table.styles'

export interface IHeader {
  text: string
  width?: string
}

interface ITable {
  compact?: boolean
  headers: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderRow: (row: any, index: number) => any // need to be typed, a lot of changes in surrounding components
  rows: unknown[]
}

const headersConfig: IHeader[] = [
  { text: 'Vessel', width: '385' },
  { text: 'Pool partner', width: '325' },
  { text: 'Target date', width: '230' },
  { text: 'Pool', width: '205' },
  { text: 'Status/Progress', width: '385' },
  { text: 'Favorite', width: '275' },
  { text: 'VesselPage', width: '385' },
  { text: 'Date left', width: '170' },
  { text: 'Reason for leaving', width: '220' },
]

function getHeadersConfig(headerNames: string[]) {
  return headerNames.map((name) => {
    const headerConfig = headersConfig.find((header) => header.text === name)
    if (!headerConfig) {
      return { text: name }
    }
    return headerConfig
  })
}

export const Table: FC<ITable> = ({ rows, renderRow, headers, compact }) => {
  const headersToRender = getHeadersConfig(headers)

  return (
    <StyledNavButtonle compact={compact}>
      <thead>
        <tr>
          {headersToRender.map(({ text, width }: IHeader) => {
            return <TableHeaderCell key={text} text={text} width={width} />
          })}
        </tr>
      </thead>
      {compact && <StyledCompactSpacing />}
      <StyledTbody compact={compact}>
        {rows.map((row: unknown, index: number) => {
          return renderRow(row, index)
        })}
      </StyledTbody>
    </StyledNavButtonle>
  )
}
