import { FC } from 'react'

import { StyledSpinner } from '~components/atoms/spinner/style'

export interface ISpinner {
  color?: string
}

export const Spinner: FC<ISpinner> = ({ color = 'black' }) => {
  return (
    <StyledSpinner
      className="spinner"
      width="32"
      height="32"
      viewBox="0 0 64 64"
    >
      <g>
        <circle
          strokeWidth="4"
          strokeDasharray="128"
          strokeDashoffset="82"
          r="24"
          cx="32"
          cy="32"
          stroke={color}
          fill="none"
        />
      </g>
    </StyledSpinner>
  )
}
