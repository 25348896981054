import { theme } from '@maersktankersdigital/web-components'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'
import { breakpoints } from '~theme/global'

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const SwitchWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
`

export const Wrapper = styled.div`
  margin: 0 56px ${theme.SPACINGS[4]};
  @media only screen and (min-width: ${breakpoints.values.xl}px) {
    margin: 0 150px ${theme.SPACINGS[4]};
  }
`

export const ButtonAndSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${theme.SPACINGS[4]};
  margin-bottom: ${theme.SPACINGS[4]};
`

export const NavButtonsWrapper = styled.div`
  display: flex;
  gap: ${theme.SPACINGS[1]};
  align-items: center;
  margin-bottom: ${theme.SPACINGS[5]};
`

export const NavButtonDivider = styled.div`
  height: 20px;
  border-left: 1px solid ${COLORS.greys.mid};
`

export const Divider = styled.div`
  ::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${COLORS.greys.light};
    display: flex;
    margin: ${theme.SPACINGS[4]} 0 ${theme.SPACINGS[2]} 0;
  }
`

export const PopupContentWrapper = styled.div`
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  background-color: ${COLORS.primary.white};
  max-width: 800px;
  padding: ${theme.SPACINGS[2]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: ${theme.SPACINGS[1]};
  align-items: space-between;
`

export const ExportButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 2;
`

export const StyledRouterLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${theme.SPACINGS[0]};
  padding: ${theme.SPACINGS[1]} 22px;

  &:focus-visible {
    outline: 2px solid ${COLORS.secondary.lightBlue};
    color: ${COLORS.secondary.midBlue};
  }

  &:hover {
    color: ${COLORS.secondary.hoverBlue};
  }
`
