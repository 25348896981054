import styled from 'styled-components/macro'

import { StyledParagraph } from '~components/atoms/typography/paragraph/paragraph.styles'

const PORT_LABEL_WIDTH = 30
const TEXT_WIDTH = 160
const SHIP_ICON_WIDTH = 94

export const StyledVoyageTracker = styled.div`
  width: 100%;
  margin-bottom: 30px;
`

export const StyledVoyageTrackerInner = styled.div<{ offsetRight: number }>`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  margin-bottom: 30px;
  margin-right: -${(p) => p.offsetRight - TEXT_WIDTH}px;
  transition: 0.1s;
`

export const StyledVoyageTrackerItemInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${PORT_LABEL_WIDTH}px;

  &:first-child {
    display: flex;

    ${StyledParagraph} {
      align-self: center;
      text-align: center;

      &:first-of-type {
        margin-bottom: 2px;
      }
    }
  }

  &:last-child {
    ${StyledParagraph} {
      &:first-of-type {
        margin: 5px 0;
      }
    }
  }

  // Hacky SVG styling, necessary with current SVG setup
  > span {
    align-self: center;
    position: absolute;
    background: transparent;
    width: ${SHIP_ICON_WIDTH}px;
    top: -73px;
    transition: 0.05s;

    svg {
      margin-left: -13px;
    }
  }

  ${StyledParagraph} {
    width: ${TEXT_WIDTH}px;
  }
`

export const StyledVoyageTrackerTrack = styled.div<{ hasDashedTrack: boolean }>`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - ${PORT_LABEL_WIDTH * 2}px);
    height: 0;
    top: 50%;
    right: ${PORT_LABEL_WIDTH / 2}px;
    transform: translateY(-50%);
    border-bottom: 2px ${(p) => (p.hasDashedTrack ? 'dashed' : 'solid')} black;
  }
`

export const StyledVoyageTrackerItem = styled.div<{
  hasDashedTrack: boolean
  pctItemWidth: number
  rowTrackHeight: number
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(p) => p.pctItemWidth}%;
  transition: 0.1s;

  &:first-child,
  &:last-child {
    ${StyledVoyageTrackerItemInner} {
      &:first-child {
        ${StyledParagraph} {
          align-self: unset;
          text-align: left;
        }
      }
    }
  }

  &:last-child {
    ${StyledVoyageTrackerItemInner} {
      &:last-child {
        &:after {
          content: '';
          position: absolute;
          width: 2px;
          height: ${(p) => p.rowTrackHeight}px;
          margin: 0 auto;
          left: 0;
          right: 0;
          top: calc(100% + 14px);
          border-right: 2px ${(p) => (p.hasDashedTrack ? 'dashed' : 'solid')}
            black;
          transition: 0.1s;
        }
      }
    }
  }
`

export const StyledVoyageTrackerRow = styled.div<{
  hasItemWithIcon: boolean
  hasNextItem: boolean
  pxItemWidth: number
}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-top: ${(p) => (p.hasItemWithIcon ? 45 : 20)}px;
  width: 100%;

  &:last-child {
    ${StyledVoyageTrackerItem} {
      ${StyledVoyageTrackerItemInner} {
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    ${StyledVoyageTrackerItemInner} {
      > span {
        transform: rotateY(180deg);
        margin-left: ${(p) => (p.hasNextItem ? p.pxItemWidth : 0)}px;
      }
    }

    ${StyledVoyageTrackerItem} {
      &:first-child {
        ${StyledVoyageTrackerTrack} {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &:nth-child(odd) {
    ${StyledVoyageTrackerItemInner} {
      > span {
        margin-left: -${(p) => (p.hasNextItem ? p.pxItemWidth : 0)}px;
      }
    }

    ${StyledVoyageTrackerItem} {
      &:last-child {
        ${StyledVoyageTrackerTrack} {
          &:after {
            display: none;
          }
        }
      }
    }
  }
`
