import { useEventListener } from '@maersktankersdigital/web-components'
import type { ComponentType, FC, ReactNode } from 'react'
import { useCallback } from 'react'

import { useAccordion } from '~components/molecules/accordion/accordion-provider'
import { useMount } from '~hooks/use-mount'
import useRefClientBounds from '~hooks/use-ref-client-bounds'
import useScrollTo from '~hooks/use-scroll-to'
import { isChildrenRenderFunction } from '~utils/type-utils/react'

import {
  StyledAccordionItemBody,
  StyledAccordionItemHeader,
} from './accordion.styles'

const ANIMATION_DURATION = 200

/**
 * AccordionItem.Header
 */
type AccordionItemHeaderChildrenFn = (params: {
  isOpen: boolean
  toggle: () => void
}) => ReactNode

export type AccordionItemHeaderProps = {
  as?: ComponentType<any> | string | undefined
  children?: AccordionItemHeaderChildrenFn | ReactNode
  clickAfterCollapse?: boolean
  disableClick?: boolean
  enableScrollTo?: boolean
  id: string
  onClick?: (params: { isOpen: boolean }) => void
  scrollToOffset?: number
}

const AccordionItemHeader: FC<AccordionItemHeaderProps> = ({
  id,
  onClick,
  children,
  disableClick,
  enableScrollTo,
  scrollToOffset,
  clickAfterCollapse,
  as,
  ...props
}) => {
  const state = useAccordion()

  const toggle = useCallback(() => state.toggle(id), [id])
  const isOpen = state.isOpen(id)

  const [scroll, setScrollToElement, elem] = useScrollTo({
    offset: scrollToOffset,
  })

  useMount(() => {
    requestAnimationFrame(() => {
      // have to access a fresh `isOpen` state in case it updates
      // between the execution of the render function and the evaluation of `requestAnimationFrame`
      if (enableScrollTo && state.isOpen(id)) {
        scroll()
      }
    })
  })

  const onWrappedClick = useCallback(() => {
    const actualIsOpen = toggle()

    if (enableScrollTo && actualIsOpen) {
      scroll()
    }

    if (onClick) {
      onClick({ isOpen: actualIsOpen })
    }
  }, [onClick, toggle, scroll])

  const onWrappedDeferredClick = useCallback(() => {
    // is active only when there are open items
    // and none of those items are the current header
    if (clickAfterCollapse && state.openItems.length > 0 && !isOpen) {
      // this will collapse all items
      state.reset()
      // this will defer the click by the same amount as the collapse animation
      setTimeout(onWrappedClick, ANIMATION_DURATION)
    } else {
      onWrappedClick()
    }
  }, [onWrappedClick, state.openItems, isOpen])

  // We aren't setting the event on the element itself, because we can't
  // propagate or prevent the event when it's set directly on the element
  useEventListener(
    'click',
    () => (disableClick ? undefined : onWrappedDeferredClick()),
    { current: elem },
  )

  return (
    <StyledAccordionItemHeader
      isOpen={isOpen}
      ref={setScrollToElement}
      as={as}
      id={id}
      {...props}
    >
      {isChildrenRenderFunction(children as ReactNode)
        ? (children as AccordionItemHeaderChildrenFn)({
            isOpen,
            toggle,
          })
        : children}
    </StyledAccordionItemHeader>
  )
}

/**
 * AccordionItem.Body
 */
type AccordionItemBodyChildrenFn = (params: { isOpen: boolean }) => ReactNode

export type AccordionItemBodyProps = {
  as?: ComponentType<any> | string | undefined
  children?: ((params: { isOpen: boolean }) => ReactNode) | ReactNode
  disableAnimation?: boolean
  id?: string
  ids?: Array<string>
}

const AccordionItemBody: FC<AccordionItemBodyProps> = ({
  id,
  ids = [],
  children,
  as,
  ...props
}) => {
  const state = useAccordion()

  const isOpen = id
    ? state.isOpen(id)
    : ids.length > 0 && ids.some(state.isOpen)

  const [bounds, setBounds] = useRefClientBounds([isOpen])

  const child = isChildrenRenderFunction(children as ReactNode)
    ? (children as AccordionItemBodyChildrenFn)({
        isOpen,
      })
    : children

  return (
    <StyledAccordionItemBody
      ref={Boolean(as && !child) ? setBounds : undefined}
      style={{ maxHeight: isOpen ? bounds.height : 0 }}
      $animationDuration={ANIMATION_DURATION}
      isOpen={isOpen}
      as={as}
      {...props}
    >
      <div ref={setBounds}>{child as ReactNode}</div>
    </StyledAccordionItemBody>
  )
}

export default {
  Header: AccordionItemHeader,
  Body: AccordionItemBody,
}
