import { Typography } from '@mui/material'

export const content = {
  title: 'Terms of Use for Insite',
  article: (
    <>
      <Typography variant="h4">1. USE OF THE INSITE</Typography>{' '}
      <p>
        1.1. This document (together with the documents referred to in it) sets
        out the terms of use on which you may use the website “Insite” (the
        “Website”). Please read these Terms of Use carefully before you
        download, install or use the Website. By using the Website, you indicate
        that you accept these Terms of Use and that you agree to abide by them.
        Your use of the Website constitutes your acceptance of these Terms of
        Use which takes effect on the date on which you use the Website. If you
        do not agree with these Terms of Use, you should using the Website,
        Content or Services, and uninstall immediately.
      </p>
      <p>
        1.2. This Website is made available by Maersk Tankers A/S (“Maersk
        Tankers”). This Website is for use only by Maersk Tankers customers or
        prospective customers. Any party approved to access this Website may use
        the information, data, text, images, video or audio or any other
        materials available from Maersk Tankers via or generated on, posted or
        uploaded to the Website (“Content”) or otherwise request, use or receive
        services or facilities (“Services”) via the Website (“User” or “Users”)
        only subject to:
      </p>
      <p>(1) the terms and conditions set out below;</p>
      <p>
        (2) any additional instructions, terms or conditions on the Website that
        apply to particular Content or Services which the User uses (such
        additional instructions, terms and conditions to prevail over the terms
        and conditions below to the extent of any inconsistency);
      </p>
      <p>
        (3) the privacy and cookie policy available electronically at{' '}
        <a
          href="https://maersktankers.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://maersktankers.com/privacy-policy
        </a>{' '}
        together referred to as the “Privacy and Cookie Policy” (altogether,
        “Terms of Use”).
      </p>
      <p>
        1.3. Access to or use of this Website or any Content or Services
        available via it shall be deemed to constitute acceptance by the User
        of, and agreement by the User to, the Privacy and Cookie Policy.
      </p>
      <br />
      <Typography variant="h4">2. REGISTRATION AND USE </Typography>
      <p>
        2.1. Registration will occur through requesting access and registering
        to the Website within the Website’s sign-on screen. As part of
        registration process, the User will be requested to create a user
        account and provide certain information, such as first name, last name,
        email address, and mobile telephone number.
      </p>
      <p>
        2.2. Only valid company email accounts will be accepted for successful
        registration (i.e. Gmail, Hotmail, etc. email accounts are not accepted)
        and Maersk Tankers reserves a right, at its discretion, not to grant
        access to the Website without further notice if valid company email
        account is not provided.
      </p>
      <p>
        2.3. The User agrees to access and use the Website, Content or Services
        only using its login details
      </p>
      <p>
        2.4. The User shall: (i) only use login details to access and use the
        Website; and (ii) abide to any term, instruction or agreement with
        Maersk Tankers in connection with the Website, Content or Services, and
        only access and use the Website, Content and Services, in accordance
        with the restrictions and conditions set out in the Terms of Use and
        with any procedures from time to time in force on the Website.
      </p>
      <p>
        2.5. Maersk Tankers is not liable for any delay or loss caused by the
        misuse or misapplication of login details.
      </p>
      <p>
        2.6. The User agrees that Maersk Tankers may use User’s contact
        information provided as part pf registration to contact the User for the
        purposes of the Website or Content (e.g. following up on calculations to
        explain the methodology used)
      </p>
      <p>
        2.7. The User can decide to allow Maersk Tankers to show his/her contact
        details for other members of the pool. This approval can be changed in
        user settings on the website.
      </p>
      <br />
      <Typography variant="h4">3. CONFIDENTIALITY</Typography>
      <p>
        3.1. Content provided via the Website is confidential information of
        Maersk Tankers (“MT Confidential Information”)
      </p>
      <p>
        3.2. Maersk Tankers allows the User to access and use the MT
        Confidential Information solely for purpose of understanding the Maersk
        Tankers Pool concept at large, performing a simple analysis of what the
        User’s tanker vessels pool points would be within the Maersk Tankers
        pools, understanding key clauses within the Maersk Tankers pool
        agreement, and receiving latest pool news, videos, market outlook, as
        well as other information to aid the User in understanding the benefits
        of the Maersk Tankers pools (“Purpose”) in accordance with the
        confidentiality undertaking herein. The User may not access the MT
        Confidential Information for the purpose of using the MT Confidential
        Information or any information derived therefrom for the purpose of
        competing with Maersk Tankers.
      </p>
      <p>
        3.3. The User is restricted to using the Website, Content and Services,
        solely for the Purpose and thus screen shots, copying and pasting text,
        and downloading of videos for further dissemination is strictly
        forbidden.
      </p>
      <p>
        3.4. The User agrees to use the MT Confidential information solely for
        the Purpose and agrees to keep the MT Confidential information
        confidential and, subject to clauses 3.5, 3.6 and 3.7 below, not to
        disclose the same to any person in any manner whatsoever without the
        prior written consent of Maersk Tankers.
      </p>
      <p>
        3.5. The User may disclose the MT Confidential Information without the
        prior written consent of Maersk Tankers to the extent that such MT
        Confidential Information:
      </p>
      <p>
        a) is already in the public domain or comes into the public domain
        otherwise than by a breach of any obligation of confidentiality; or
        which
      </p>
      <p>
        b) was already lawfully known to the User, it represented company
        (“Company”) or its Affiliates as of the date of disclosure; or which
      </p>
      <p>
        3.6. The User may also disclose the MT Confidential Information without
        the prior written consent of Maersk Tankers to those of Company’s
        Representatives who need to know such MT Confidential information for
        the Purpose, provided that:
      </p>
      <p>
        a. it informs such Representatives of the confidential nature of the MT
        Confidential Information prior to disclose; and
      </p>
      <p>
        b. at all times, it is responsible for any breach of the terms of this
        agreement by any of Company’s representatives.
      </p>
      <p>
        3.7. The User may disclose MT Confidential Information to the extent
        required by law, by any governmental or other regulatory authority
        (including, without limitation, any relevant stock exchange) or by a
        court or other authority of competent jurisdiction provided that, to the
        extent it is permitted to do so, it gives Maersk Tankers as much notice
        of such disclosure as possible and, where notice of disclosure is not
        prohibited and is given in accordance with this clause 3.7, it takes
        into account the reasonable requests of Maersk Tankers in relation to
        the content of such disclosure.
      </p>
      <p>
        3.8. Maersk Tankers reserves all rights in MT Confidential Information.
        No rights in respect of MT Confidential Information are granted to the
        User, or are to be implied from this agreement.
      </p>
      <p>3.9. Upon written request from Maersk Tankers the User shall:</p>
      <p>
        a) return to Maersk Tankers all documents and materials (and any copies)
        containing, reflecting, incorporating or based on MT Confidential
        Information;
      </p>
      <p>
        b) erase all MT Confidential Information from its computer systems,
        phone or any other device (to the extent possible and without deletion
        of back-ups made in the ordinary course provided, however, they are not
        used for the purpose of retrieving the MT Confidential Information); and
      </p>
      <p>
        c) Certify in writing to Maersk Tankers that it has complied with the
        requirements of this clause, provided that the User may retain documents
        and materials containing, reflecting, incorporating or based on MT
        Confidential Information to the extent required by law or any applicable
        governmental or regulatory authority and shall be allowed to retain one
        copy of the MT Confidential Information to be used solely to monitor
        compliance with this agreement or in the event of a dispute. The
        provisions of this clause shall continue to apply to any such documents
        and materials retained by the User.
      </p>
      <br />
      <Typography variant="h4">4. DATA PRIVACY</Typography>
      <p>
        4.1. The EU General Data Protection Regulation (GDPR) requires that MT
        obtain your consent to process your personal data in the form of the
        information you provide us when using the Website. By logging in to the
        Website, you are providing us with your consent. You can withdraw your
        consent at any time.
      </p>
      <p>
        4.2. From time to time, MT may use your personal data to reach out to
        you with business propositions, to get ideas or feedback, to provide
        strategic information or to participate in surveys or analysis of our
        business. Sometimes we may do so via a third-party service provider. We
        will not transfer your personal data outside the EU without requiring
        that GDPR is complied with by the party to whom we send your data for
        processing. Further, we will not permit any third parties to process
        your personal data for their own purposes. We will delete your personal
        data, either when it becomes outdated or upon your request. You also
        have the right to request that your data be updated or that we not use
        your personal data, and to lodge a complaint with the Danish Data Agency
        if you believe we have not complied with GDPR with respect to your
        personal data. If you have any questions or requests, please write
        dataprivacy@maersktankers.com
      </p>
      <br />
      <Typography variant="h4">5. INTELLECTUAL PROPERTY RIGHTS</Typography>
      <p>
        5.1. Ownership of all copyrights, database rights, patents, trade or
        service marks, product names or design rights (whether registered or
        unregistered), trade secrets and confidential information and any
        similar rights existing in any territory now or in future (“Intellectual
        Property Rights”) and similar rights and interests in all domain names,
        trademarks, logos, branding appearing on the Website and all Content, or
        otherwise relating to the structure of the Website or Services offered
        by Maersk Tankers via the Website, vests in Maersk Tankers or its
        licensors.
      </p>
      <p>
        5.2. The User may use this Website, Content and Services, available via
        the Website solely for the Purpose and in accordance with Terms of Use
        and any procedures from time to time in force on the Website. User may
        not:
      </p>
      <p>
        (1) use or permit any other party to use all or any part of the Website,
        Content or Services in connection with activities that breach any
        relevant laws, infringe any third party’s rights, or breach any
        applicable standards, content requirements or codes.
      </p>
      <p>
        (2) post to, upload to, temporarily store on (if such facility is
        provided) or transmit through, the Website any information, materials or
        content that might be or might encourage conduct that might be unlawful,
        threatening, abusive, defamatory, obscene, vulgar, discriminatory,
        pornographic, profane or indecent.
      </p>
      <p>
        5.3. The User shall procure the waiver of any moral rights in any
        information, data or other content or materials posted or uploaded by
        the User to the Website (“User Materials”). The User hereby irrevocably
        authorizes Maersk Tankers and its licensees to use any User Materials
        for all reasonable business purposes, including without limitation
        copying, amending, incorporating in other materials, publishing or
        otherwise providing to third parties (and permitting such third parties
        to use and sublicense the User Materials) anywhere in the world any such
        User Materials. The User agrees to take any steps (including completing
        any further document) that may be required in any jurisdiction to give
        effect to this clause.
      </p>
      <p>
        5.4. Maersk Tankers does not warrant or represent that the User’s or any
        other party’s use of the Website, Content or the Services available via
        the Website will not infringe rights of third parties.
      </p>
      <br />
      <Typography variant="h4">6. ERROR</Typography>
      <p>
        6.1. Maersk Tankers makes no warranty that access to the Website will be
        uninterrupted, timely or error-free. Access to the Website may be
        suspended or withdrawn to or from individual Users or all Users
        temporarily or permanently at any time and without notice. Maersk
        Tankers may also impose restrictions on the length and manner of usage
        of any part of the Website for any reason.
      </p>
      <p>
        6.2. Maersk Tankers does not warrant that the Website will be compatible
        with all hardware and software which the Users may use. Maersk Tankers
        shall not be liable for damage to, or viruses or other code that may
        affect, any equipment (including but not limited to your mobile device),
        software, data or other property as a result of User’s download,
        installation, access to or use of the Website or User obtaining any
        material from, or as a result of using, the Website. Maersk Tankers will
        further not be liable for the actions of third parties.
      </p>
      <p>
        6.3. Maersk Tankers may change or update the Website, Content or
        Services and without notice to the Users.
      </p>
      <p>
        6.4. Maersk Tankers will use reasonable endeavors to ensure that the
        Content accurately reflects either:
      </p>
      <p>
        (1) the relevant part of Maersk Tankers records held on Maersk Tankers’
        computer systems; or
      </p>
      <p>
        (2) information received from a party other than Maersk Tankers. Maersk
        Tankers does not warrant that the Content is accurate, sufficient, error
        free, complete or up to date at the time it is accessed; or
      </p>
      <p>
        (3) views of Maersk Tankers. However, the views and opinions expressed
        in this article are those of Maersk Tankers and do not necessarily
        reflect the market situation or views or any third party views.
      </p>
      <p>
        6.5. The User should make further enquiries to satisfy himself of the
        accuracy or completeness of any Content before relying on it. Maersk
        Tankers makes no warranties at all in relation to its quality, accuracy,
        completeness or timeliness of the Content.
      </p>
      <p>
        6.6. Except as set out in the Terms of Use, Maersk Tankers shall have no
        liability whatsoever for breach of any implied warranty, term or
        condition that might otherwise apply including (without limitation) in
        relation to the operation, quality or fitness for purpose of the Website
        or any Content, User Materials or Service, or the use of reasonable
        skill and care.
      </p>
      <p>
        6.7. The Website is independent of any platform on which it is located
        or any website that could be accessed via the Website. The Website is
        not associated, affiliated, sponsored, endorsed or in any way linked to
        any website or platform operator, including, without limitation, Apple,
        Google, Android or RIM Blackberry (each being an “Operator”).
      </p>
      <p>
        6.8. Your download, installation, access to or use of the Website is
        also bound by the terms and conditions of the Operator.
      </p>
      <p>
        6.9. The User is responsible for the accuracy and completeness of any
        User Materials. The User shall ensure the User Materials do not infringe
        any Intellectual Property Rights or other right of any third party and
        are not defamatory, unlawful, immoral or otherwise likely to breach or
        infringe any right or requirement or to give rise to any claim for loss
        or damage by any third party. The User shall indemnify and hold harmless
        Maersk Tankers and its affiliates, associates and agents against any
        claims, losses, actions, proceedings, damage or other liabilities
        whatsoever (including damages or compensation paid by Maersk Tankers to
        compromise or settle a claim), and all legal costs or other expenses,
        suffered by Maersk Tankers or its affiliates and associates as a result
        of any actual or potential breach by the User of its obligations under
        this clause 4.8.
      </p>
      <br />
      <Typography variant="h4">7. SECURITY</Typography>
      <p>
        7.1. The User agrees to comply with any reasonable instructions Maersk
        Tankers may issue regarding the Website’s security.
      </p>
      <p>
        7.2. The User must ensure that he does not do anything during or after
        any access to or use of the Website, Content or Services which might
        result in the security of the Website, or the systems or security of
        Maersk Tankers or any other users of the Website, or any Maersk Tankers
        customers or associated or affiliated companies, being compromised.
      </p>
      <br />
      <Typography variant="h4">8. LIABILITY</Typography>
      <p>
        8.1. Maersk Tankers, its affiliates, associates and agents shall have no
        liability whatsoever in respect of the use of the Content, Services or
        Website, howsoever arising.
      </p>
      <br />
      <Typography variant="h4">9. MISCELLANEOUS</Typography>
      <p>
        9.1. Use of this Website or of the Content or Services may be subject to
        certain legal or regulatory requirements in particular jurisdictions.
        The User may only access or use the Website, Content or Services to the
        extent such access or use is permitted in the jurisdiction in which he
        accesses or uses the Website, Content or Services.
      </p>
      <p>
        9.2. Maersk Tankers will not be liable for any loss (including without
        limitation loss of profit), damage, delay or failure in performing any
        of its duties relating to the Terms of Use caused in whole or in part by
        the action of any government or governmental agency, natural occurrence,
        law or regulation (or any change in the interpretation thereof),
        injunction, currency restriction, sanction, exchange control, industrial
        action (whether involving its staff or not), war, terrorist action,
        equipment failure, interruption to power supplies or anything whatsoever
        beyond its reasonable control.
      </p>
      <p>
        9.3. The Terms of Use supersede all previous agreements, communications,
        representations and discussions between the parties relating to the
        Website. No party will have a right of action against Maersk Tankers
        arising from any previous agreement, communication, representation and
        discussion in respect of the Website (except in the case of fraudulent
        misrepresentation), and neither party has relied on any terms,
        warranties, representations or conditions other than those expressly
        stated in the Terms of Use. No modification or waiver of the Terms of
        Use shall be binding on Maersk Tankers unless it is in writing and
        agreed by an authorized representative of Maersk Tankers.
      </p>
      <p>
        9.4. Each of the provisions of the Terms of Use is severable from the
        others and if one or more of them becomes void, illegal or
        unenforceable, the remainder will not be affected in any way.
      </p>
      <p>
        9.5. The rights of Maersk Tankers under the Terms of Use may be
        exercised as often as necessary and are cumulative and not exclusive of
        its rights under any applicable law. Any delay in the exercise or
        non-exercise of any such right is not a waiver of that right.
      </p>
      <p>
        9.6. Maersk Tankers may at any time and without notice or liability
        change, improve or remove any Content or any Services available via the
        Website, or the Terms of Use.
      </p>
      <p>
        9.7. The Terms of Use shall be subject to Danish law and any dispute,
        claim, matter of construction or interpretation arising out of or
        relating to the Website, including the Terms of Use, shall be subject to
        the exclusive jurisdiction of the Danish courts.
      </p>
    </>
  ),
}
