import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'
import { typography } from '~theme/global'

export interface MuiTextFieldData {
  defaultProps?: ComponentsProps['MuiTextField']
  styleOverrides?: ComponentsOverrides['MuiTextField']
  variants?: ComponentsVariants['MuiTextField']
}

export const MuiTextField: MuiTextFieldData = {
  styleOverrides: {
    root: {
      marginTop: '32px',
      '& .MuiInputBase-input': {
        padding: '10px 16px !important',
        fontSize: '14px',
        lineHeight: '14px',
        fontFamily: 'Maersk Text',
      },
      '& .MuiInputLabel-root': {
        transform: 'translate(0, -38px) scale(1)',
        padding: '8px 0',
        transition: 'none',
        ...typography.label,
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: COLORS.secondary.darkBlue,
      },
      '& .MuiInputLabel-root.Mui-disabled': {
        color: COLORS.greys.mid,
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, -38px) scale(1)',
      },
      '& .MuiOutlinedInput-root': {
        padding: '0',

        fieldset: {
          borderWidth: '1px',
          borderColor: COLORS.secondary.darkBlue,
          transition: 'border-color 0.1s',
          top: '0',
          legend: {
            display: 'none',
          },
        },
        '&:not(.Mui-disabled):hover': {
          backgroundColor: COLORS.secondary.blueTint,
        },
        '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.secondary.blueTint,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.primary.maerskBlue,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.feedback.red.error,
        },
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: COLORS.feedback.red.error,
      },
      '& .MuiFormHelperText-root': {
        ...typography.graphCaption,
      },
    },
  },
}
