import { ChevronDownIcon } from '@maersktankersdigital/web-components'
import * as React from 'react'
import { createRef, useEffect, useLayoutEffect, useState } from 'react'
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react'

import { Rotate } from '~components/atoms/rotate'
import { useFilteredData } from '~pages/pages-behind-login/position-list/contexts/filtered-data-provider'
import { usePositionListView } from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import {
  AreaFilteredVessels,
  useAreaFilteredVessels,
} from '~pages/pages-behind-login/position-list/hooks/use-area-filtered-vessels'
import VesselListContent from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-content/position-list-table-content'
import PositionListTableControls from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-controls/position-list-table-controls'
import PositionListTableHeader from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-header/position-list-table-header'
import {
  AreaHeader,
  AreaTitle,
  AreaTitleWrapper,
  AreaWrapper,
  CheckboxAreaWrapper,
  StickyHeaderWrapper,
  StyledCheckbox,
  StyledDivider,
  StyledTable,
  TableWrapper,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table.styles'
import { PositionVesselData } from '~pages/pages-behind-login/position-list/types/position-list-types'

function PositionListTable() {
  const { filteredData } = useFilteredData()
  const { activeView, settings, saveSettings } = usePositionListView()
  const [headerWidths, setHeaderWidths] = useState<number[][]>([[]])
  const [openVoyageRowIndex, setOpenVoyageRowIndex] = useState<number | null>(
    null,
  )
  const filters = activeView?.filterSettings
  const { areaFilteredVessels } = useAreaFilteredVessels(filteredData, filters)
  const [areaFilteredVesselsWithRefs, setAreaFilteredVesselsWithRefs] =
    useState<AreaFilteredVessels[]>([])
  const singleRowRef = createRef<HTMLTableRowElement>()

  // Recursive function to create refs
  function createRefs(hierarchy: AreaFilteredVessels[]): AreaFilteredVessels[] {
    return hierarchy.map((node) => {
      node.ref = createRef<HTMLTableRowElement>()
      if (node.children) {
        node.children = createRefs(node.children) // Recursively create refs for children
      }
      return node
    })
  }

  useEffect(() => {
    setAreaFilteredVesselsWithRefs(createRefs(areaFilteredVessels))
  }, [areaFilteredVessels])

  useLayoutEffect(() => {
    // Recursive function to get maximum width from refs in AreaFilteredVessels structure
    function getMaxRefWidth(
      node: AreaFilteredVessels,
      maxColumnWidths: number[],
    ): number[] {
      let maxWidths = [...maxColumnWidths]
      if (node?.ref?.current) {
        const cells = Array.from(node.ref.current.children)
        const widths = cells.map((cell) => cell.getBoundingClientRect().width)

        // Update maximum width for each column at current level
        maxWidths = widths.map((width, index) => {
          return Math.max(width, maxColumnWidths[index] || 0)
        })
      }

      if (node.children) {
        node.children.forEach((child) => {
          // Update maxColumnWidths with the result of the recursive call
          maxWidths = getMaxRefWidth(child, maxWidths)
        })
      }

      return maxWidths
    }

    const getMaxRefWidthOneRow = () => {
      // Find the width for each column in the first row
      window.requestAnimationFrame(() => {
        if (!singleRowRef?.current) return null
        const cells = Array.from(singleRowRef.current?.children)
        const allWidths = cells.map(
          (cell) => cell.getBoundingClientRect().width,
        )

        setHeaderWidths([allWidths])
        return null
      })
    }

    const updateHeaderWidths = () => {
      // Find the width for each column of each table
      window.requestAnimationFrame(() => {
        if (activeView?.showHeaders && areaFilteredVesselsWithRefs?.length) {
          const maxLevelWidths = areaFilteredVesselsWithRefs.map(
            (node: AreaFilteredVessels) => getMaxRefWidth(node, []),
          )

          setHeaderWidths(maxLevelWidths)
        } else {
          getMaxRefWidthOneRow()
        }
      })
    }

    updateHeaderWidths()

    const handleResize = () => {
      updateHeaderWidths()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [
    activeView?.columns,
    areaFilteredVesselsWithRefs,
    activeView?.showHeaders,
  ])

  const renderHeaders =
    activeView?.showHeaders && !filters?.worldArea?.includes('ALL')

  const handleOpenSection = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    areaIndex: number,
    regionIndex: number,
    subregionIndex?: number,
  ) => {
    const isCheckbox =
      (event.target as HTMLElement).nodeName.toLowerCase() === 'input'
    if (isCheckbox) return
    setAreaFilteredVesselsWithRefs((prevHierarchy) => {
      const newHierarchy = [...prevHierarchy]
      if (areaIndex !== undefined) {
        newHierarchy[areaIndex].isOpen = !newHierarchy[areaIndex].isOpen
      }
      if (regionIndex !== undefined) {
        newHierarchy[areaIndex].children[regionIndex].isOpen =
          !newHierarchy[areaIndex].children[regionIndex].isOpen
      }
      if (subregionIndex !== undefined) {
        newHierarchy[areaIndex].children[regionIndex].children[
          subregionIndex
        ].isOpen =
          !newHierarchy[areaIndex].children[regionIndex].children[
            subregionIndex
          ].isOpen
      }

      return newHierarchy
    })
    return
  }

  const handleExportVessels = (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedVessels?: PositionVesselData[],
  ) => {
    const { name, checked } = e.target
    if (!selectedVessels) return null
    const vesselIds = selectedVessels?.map((vessel) => vessel.vesselId)
    const exportedVessels = settings?.exportedVessels || []
    if (checked) {
      saveSettings({ exportedVessels: [...exportedVessels, ...vesselIds] })
    } else {
      saveSettings({
        exportedVessels: exportedVessels.filter(
          (id) => !vesselIds?.includes(id),
        ),
      })
    }
    return
  }

  if (!filteredData) return null

  return (
    <>
      <PositionListTableControls />
      {renderHeaders ? (
        areaFilteredVesselsWithRefs.map((area, areaIndex) => {
          return (
            <AreaWrapper key={area.name}>
              <ScrollSync>
                <>
                  <AreaHeader level="level1">
                    <AreaTitleWrapper>
                      {settings?.isExporting && area.name && (
                        <CheckboxAreaWrapper>
                          <StyledCheckbox
                            name={area.name}
                            onChange={(e) =>
                              handleExportVessels(e, area.vessels)
                            }
                          />
                        </CheckboxAreaWrapper>
                      )}
                      <AreaTitle key={areaIndex}>
                        {`${area.name} ${
                          area.name ? `(${area.vessels?.length})` : ''
                        }`}
                      </AreaTitle>
                    </AreaTitleWrapper>
                  </AreaHeader>
                  <StyledDivider />
                  <StickyHeaderWrapper>
                    <ScrollSyncNode group={area.name}>
                      <div style={{ overflow: 'hidden' }}>
                        <PositionListTableHeader
                          headerWidths={headerWidths[areaIndex]}
                        />
                      </div>
                    </ScrollSyncNode>
                  </StickyHeaderWrapper>
                  <ScrollSyncNode group={area.name} key={areaIndex}>
                    <TableWrapper>
                      <StyledTable>
                        <tbody>
                          {area?.vessels?.map((vessel, vesselIndex) => {
                            return (
                              <VesselListContent
                                key={vesselIndex}
                                vessel={vessel}
                                vesselIndex={vesselIndex}
                                openVoyageRowIndex={openVoyageRowIndex}
                                setOpenVoyageRowIndex={setOpenVoyageRowIndex}
                                ref={area.ref}
                              />
                            )
                          })}
                        </tbody>
                      </StyledTable>
                    </TableWrapper>
                  </ScrollSyncNode>
                  {area.children.map((region, regionIndex) => {
                    return (
                      <>
                        {region.name && (
                          <AreaHeader
                            key={regionIndex}
                            level="level2"
                            onClick={(e) => {
                              handleOpenSection(e, areaIndex, regionIndex)
                            }}
                          >
                            <AreaTitleWrapper>
                              {settings?.isExporting && (
                                <CheckboxAreaWrapper>
                                  <StyledCheckbox
                                    name={region.name}
                                    onChange={(e) =>
                                      handleExportVessels(e, region.vessels)
                                    }
                                  />
                                </CheckboxAreaWrapper>
                              )}
                              <AreaTitle>{`${region.name} (${region.vessels?.length})`}</AreaTitle>
                            </AreaTitleWrapper>
                            <Rotate isRotated={region.isOpen === true}>
                              <ChevronDownIcon />
                            </Rotate>
                          </AreaHeader>
                        )}
                        {region.isOpen && (
                          <ScrollSyncNode group={area.name}>
                            <TableWrapper>
                              <StyledTable>
                                <tbody>
                                  {region.vessels?.map(
                                    (vessel, vesselIndex) => {
                                      return (
                                        <VesselListContent
                                          key={vesselIndex}
                                          vessel={vessel}
                                          vesselIndex={vesselIndex}
                                          openVoyageRowIndex={
                                            openVoyageRowIndex
                                          }
                                          setOpenVoyageRowIndex={
                                            setOpenVoyageRowIndex
                                          }
                                          ref={region.ref}
                                        />
                                      )
                                    },
                                  )}
                                </tbody>
                              </StyledTable>
                            </TableWrapper>
                          </ScrollSyncNode>
                        )}
                        {region.children.map((subregion, subregionIndex) => {
                          return (
                            <>
                              <AreaHeader
                                key={subregionIndex}
                                level="level3"
                                onClick={(e) =>
                                  handleOpenSection(
                                    e,
                                    areaIndex,
                                    regionIndex,
                                    subregionIndex,
                                  )
                                }
                              >
                                <AreaTitleWrapper>
                                  {settings?.isExporting && (
                                    <CheckboxAreaWrapper>
                                      <StyledCheckbox
                                        name={subregion.name}
                                        onChange={(e) =>
                                          handleExportVessels(
                                            e,
                                            subregion.vessels,
                                          )
                                        }
                                      />
                                    </CheckboxAreaWrapper>
                                  )}
                                  <AreaTitle>{`${subregion.name} (${subregion.vessels?.length})`}</AreaTitle>
                                </AreaTitleWrapper>
                                <Rotate isRotated={subregion.isOpen === true}>
                                  <ChevronDownIcon />
                                </Rotate>
                              </AreaHeader>
                              <ScrollSyncNode group={area.name}>
                                <TableWrapper>
                                  {subregion.isOpen && (
                                    <StyledTable>
                                      <tbody>
                                        {subregion.vessels?.map(
                                          (vessel, vesselIndex) => (
                                            <VesselListContent
                                              key={vesselIndex}
                                              vessel={vessel}
                                              vesselIndex={vesselIndex}
                                              openVoyageRowIndex={
                                                openVoyageRowIndex
                                              }
                                              setOpenVoyageRowIndex={
                                                setOpenVoyageRowIndex
                                              }
                                              ref={subregion.ref}
                                            />
                                          ),
                                        )}
                                      </tbody>
                                    </StyledTable>
                                  )}
                                </TableWrapper>
                              </ScrollSyncNode>
                            </>
                          )
                        })}
                      </>
                    )
                  })}
                </>
              </ScrollSync>
            </AreaWrapper>
          )
        })
      ) : (
        <ScrollSync>
          <>
            <StickyHeaderWrapper>
              <ScrollSyncNode group="all">
                <div style={{ overflow: 'hidden' }}>
                  <PositionListTableHeader headerWidths={headerWidths?.[0]} />
                </div>
              </ScrollSyncNode>
            </StickyHeaderWrapper>
            <ScrollSyncNode group="all">
              <TableWrapper>
                <StyledTable>
                  <tbody>
                    {filteredData?.map(
                      (vessel: PositionVesselData, vesselIndex: number) => {
                        return (
                          <VesselListContent
                            key={vesselIndex}
                            vessel={vessel}
                            vesselIndex={vesselIndex}
                            openVoyageRowIndex={openVoyageRowIndex}
                            setOpenVoyageRowIndex={setOpenVoyageRowIndex}
                            ref={singleRowRef}
                          />
                        )
                      },
                    )}
                  </tbody>
                </StyledTable>
              </TableWrapper>
            </ScrollSyncNode>
          </>
        </ScrollSync>
      )}
    </>
  )
}

export default PositionListTable
