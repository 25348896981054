export const mockEarningsYearly = {
  averagePoolEarnings: {
    names: ['MR'],
    poolPoints: 0.0,
    bunkerAdjustmentPerDay: null,
    earnings: 26557.7622,
    bunkerAdjustment: 0.057,
    scrubberBonus: 403.0313,
    specialCompensations: -13.0868,
    wafDta: 48.3045,
    tce: 26996.0683,
  },
  averageFilteredEarnings: null,
  vesselsEarnings: [
    {
      id: 180475,
      vesselCode: 'KR4',
      partner: {
        id: 124,
        name: 'Atlantic Asiatic Lloyd',
        axCode: 'ATL',
      },
      name: 'Falcon Bay',
      hasScrubber: false,
      poolPoints: 115.7,
      bunkerAdjustmentPerDay: 1.2142,
      earnings: 27082.3061,
      bunkerAdjustment: -852.3573,
      scrubberBonus: 0.0,
      specialCompensations: -4762.3512,
      tce: 21720.0808,
      wafDta: 252.4832,
    },
    {
      id: 181049,
      vesselCode: 'LW9',
      partner: {
        id: 173,
        name: 'Beks Shipping',
        axCode: 'BEK',
      },
      name: 'BEKS Ebru',
      hasScrubber: false,
      poolPoints: 30.7936,
      bunkerAdjustmentPerDay: null,
      earnings: 11455.5716,
      bunkerAdjustment: -1638.609,
      scrubberBonus: 0.0,
      specialCompensations: -19.468,
      tce: 10200.7204,
      wafDta: 403.2258,
    },
    {
      id: 180979,
      vesselCode: 'LZ7',
      partner: {
        id: 173,
        name: 'Beks Shipping',
        axCode: 'BEK',
      },
      name: 'Beks Fenix',
      hasScrubber: false,
      poolPoints: 40.5455,
      bunkerAdjustmentPerDay: null,
      earnings: 15149.4632,
      bunkerAdjustment: -1120.4205,
      scrubberBonus: 0.0,
      specialCompensations: 452.2972,
      tce: 14481.3399,
      wafDta: 0.0,
    },
    {
      id: 180468,
      vesselCode: 'KQ6',
      partner: {
        id: 123,
        name: 'Bahri Chemicals',
        axCode: 'BHC',
      },
      name: 'Bahri Tulip',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: 0.2333,
      earnings: 25974.9288,
      bunkerAdjustment: -899.457,
      scrubberBonus: 0.0,
      specialCompensations: 205.8523,
      tce: 25341.9302,
      wafDta: 60.6061,
    },
    {
      id: 180449,
      vesselCode: 'KQ7',
      partner: {
        id: 123,
        name: 'Bahri Chemicals',
        axCode: 'BHC',
      },
      name: 'Bahri Jasmine',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: 1.7518,
      earnings: 25975.1106,
      bunkerAdjustment: -2015.5034,
      scrubberBonus: 0.0,
      specialCompensations: -44.8798,
      tce: 23944.0528,
      wafDta: 29.3255,
    },
    {
      id: 180457,
      vesselCode: 'KQ8',
      partner: {
        id: 123,
        name: 'Bahri Chemicals',
        axCode: 'BHC',
      },
      name: 'Bahri Rose',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: 0.2016,
      earnings: 25974.8239,
      bunkerAdjustment: -469.0109,
      scrubberBonus: 0.0,
      specialCompensations: -270.023,
      tce: 25235.7901,
      wafDta: 0.0,
    },
    {
      id: 180445,
      vesselCode: 'KQ9',
      partner: {
        id: 123,
        name: 'Bahri Chemicals',
        axCode: 'BHC',
      },
      name: 'Bahri Iris',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: 0.5812,
      earnings: 25974.74,
      bunkerAdjustment: -1172.7315,
      scrubberBonus: 0.0,
      specialCompensations: 21.287,
      tce: 24823.2955,
      wafDta: 0.0,
    },
    {
      id: 180563,
      vesselCode: 'MA4',
      partner: {
        id: 107,
        name: 'Blystad',
        axCode: 'BLY',
      },
      name: 'Challenge Passage',
      hasScrubber: false,
      poolPoints: 80.48,
      bunkerAdjustmentPerDay: -1.0711,
      earnings: 23199.9852,
      bunkerAdjustment: 848.8693,
      scrubberBonus: 0.0,
      specialCompensations: -169.3062,
      tce: 23879.5483,
      wafDta: 0.0,
    },
    {
      id: 180850,
      vesselCode: 'KI8',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Proteus',
      hasScrubber: true,
      poolPoints: 74.7727,
      bunkerAdjustmentPerDay: -0.0634,
      earnings: 23071.2423,
      bunkerAdjustment: 513.3205,
      scrubberBonus: 1795.2972,
      specialCompensations: -432.3292,
      tce: 24947.5308,
      wafDta: 0.0,
    },
    {
      id: 180849,
      vesselCode: 'KJ2',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Pro Onyx',
      hasScrubber: true,
      poolPoints: 118.0,
      bunkerAdjustmentPerDay: 1.1773,
      earnings: 27630.2182,
      bunkerAdjustment: -179.0388,
      scrubberBonus: 2888.7008,
      specialCompensations: -186.5997,
      tce: 30167.9433,
      wafDta: 14.6628,
    },
    {
      id: 180443,
      vesselCode: 'KK1',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Lian Xi Hu',
      hasScrubber: false,
      poolPoints: 118.0,
      bunkerAdjustmentPerDay: 0.3896,
      earnings: 27628.7116,
      bunkerAdjustment: 49.0431,
      scrubberBonus: 0.0,
      specialCompensations: -119.3263,
      tce: 27652.6889,
      wafDta: 94.2606,
    },
    {
      id: 180643,
      vesselCode: 'KK5',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Ion M',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.0288,
      earnings: 27511.6664,
      bunkerAdjustment: 393.5877,
      scrubberBonus: 2304.1162,
      specialCompensations: 89.6911,
      tce: 30359.6675,
      wafDta: 60.6061,
    },
    {
      id: 180666,
      vesselCode: 'KK6',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Largo Mariner',
      hasScrubber: false,
      poolPoints: 42.3636,
      bunkerAdjustmentPerDay: null,
      earnings: 15840.2639,
      bunkerAdjustment: 1125.9071,
      scrubberBonus: 0.0,
      specialCompensations: -96.3976,
      tce: 16869.7734,
      wafDta: 0.0,
    },
    {
      id: 180650,
      vesselCode: 'KL8',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Jason',
      hasScrubber: true,
      poolPoints: 96.5455,
      bunkerAdjustmentPerDay: -0.5404,
      earnings: 26072.8379,
      bunkerAdjustment: 999.7213,
      scrubberBonus: 1513.7179,
      specialCompensations: -155.9934,
      tce: 28430.2837,
      wafDta: 0.0,
    },
    {
      id: 180931,
      vesselCode: 'KL9',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Orpheus',
      hasScrubber: true,
      poolPoints: 75.0909,
      bunkerAdjustmentPerDay: 0.049,
      earnings: 23170.8079,
      bunkerAdjustment: -12.6647,
      scrubberBonus: 1845.5637,
      specialCompensations: 162.0268,
      tce: 25165.7337,
      wafDta: 0.0,
    },
    {
      id: 180462,
      vesselCode: 'KM2',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Nikos M',
      hasScrubber: true,
      poolPoints: 96.1364,
      bunkerAdjustmentPerDay: -0.3955,
      earnings: 25963.2813,
      bunkerAdjustment: 776.5971,
      scrubberBonus: 1832.0521,
      specialCompensations: -228.9748,
      tce: 28417.0297,
      wafDta: 74.0741,
    },
    {
      id: 180956,
      vesselCode: 'LC6',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Gwn 2',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.1439,
      earnings: 27511.1881,
      bunkerAdjustment: 606.6383,
      scrubberBonus: 2025.85,
      specialCompensations: 251.8153,
      tce: 30395.4918,
      wafDta: 0.0,
    },
    {
      id: 180967,
      vesselCode: 'LF8',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Ayoe',
      hasScrubber: true,
      poolPoints: 64.3636,
      bunkerAdjustmentPerDay: 0.2412,
      earnings: 20916.4152,
      bunkerAdjustment: 515.8162,
      scrubberBonus: 1362.2459,
      specialCompensations: -275.7732,
      tce: 22518.7042,
      wafDta: 0.0,
    },
    {
      id: 180988,
      vesselCode: 'LI4',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Wisco Adventure',
      hasScrubber: true,
      poolPoints: 117.9055,
      bunkerAdjustmentPerDay: -0.6582,
      earnings: 27609.7189,
      bunkerAdjustment: 668.1525,
      scrubberBonus: 2904.3315,
      specialCompensations: 162.4736,
      tce: 31344.6764,
      wafDta: 0.0,
    },
    {
      id: 180989,
      vesselCode: 'LJ3',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Gwn 3',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -1.0975,
      earnings: 27511.7693,
      bunkerAdjustment: 1173.2504,
      scrubberBonus: 2098.7585,
      specialCompensations: 63.0878,
      tce: 30846.866,
      wafDta: 0.0,
    },
    {
      id: 181067,
      vesselCode: 'LX8',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Stamatia',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.5917,
      earnings: 27511.5482,
      bunkerAdjustment: 690.9806,
      scrubberBonus: 3050.8105,
      specialCompensations: -37.0153,
      tce: 31216.324,
      wafDta: 0.0,
    },
    {
      id: 180477,
      vesselCode: 'LX9',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Alexandros',
      hasScrubber: true,
      poolPoints: 10.6818,
      bunkerAdjustmentPerDay: null,
      earnings: 11933.1898,
      bunkerAdjustment: 740.0416,
      scrubberBonus: 1265.3677,
      specialCompensations: 0.1934,
      tce: 13938.7925,
      wafDta: 0.0,
    },
    {
      id: 181081,
      vesselCode: 'NC9',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Dee4 Ilex',
      hasScrubber: false,
      poolPoints: 75.0455,
      bunkerAdjustmentPerDay: -1.1047,
      earnings: 34230.6524,
      bunkerAdjustment: 1121.7212,
      scrubberBonus: 0.0,
      specialCompensations: -193.9317,
      tce: 35158.4419,
      wafDta: 0.0,
    },
    {
      id: 181098,
      vesselCode: 'NH8',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Sandpiper Pacific',
      hasScrubber: false,
      poolPoints: 21.1818,
      bunkerAdjustmentPerDay: 0.1636,
      earnings: 34184.8987,
      bunkerAdjustment: -187.6292,
      scrubberBonus: 0.0,
      specialCompensations: -418.7135,
      tce: 33578.556,
      wafDta: 0.0,
    },
    {
      id: 181104,
      vesselCode: 'NI8',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Largo Eden',
      hasScrubber: false,
      poolPoints: 10.5909,
      bunkerAdjustmentPerDay: 0.0,
      earnings: 32873.8635,
      bunkerAdjustment: 0.0,
      scrubberBonus: 0.0,
      specialCompensations: 0.0,
      tce: 32873.8635,
      wafDta: 0.0,
    },
    {
      id: 180787,
      vesselCode: 'LY3',
      partner: {
        id: 155,
        name: 'CDBL',
        axCode: 'CDB',
      },
      name: 'CL Huaiyang',
      hasScrubber: false,
      poolPoints: 118.69,
      bunkerAdjustmentPerDay: -1.897,
      earnings: 27793.393,
      bunkerAdjustment: 1808.2378,
      scrubberBonus: 0.0,
      specialCompensations: 32.4247,
      tce: 29811.9635,
      wafDta: 177.9081,
    },
    {
      id: 181076,
      vesselCode: 'NA8',
      partner: {
        id: 155,
        name: 'CDBL',
        axCode: 'CDB',
      },
      name: 'CL Fugou',
      hasScrubber: false,
      poolPoints: 107.4455,
      bunkerAdjustmentPerDay: -2.1309,
      earnings: 29249.1216,
      bunkerAdjustment: 1716.1472,
      scrubberBonus: 0.0,
      specialCompensations: 159.2018,
      tce: 31124.4706,
      wafDta: 0.0,
    },
    {
      id: 180784,
      vesselCode: 'TA2',
      partner: {
        id: 155,
        name: 'CDBL',
        axCode: 'CDB',
      },
      name: 'CL Zhaoge',
      hasScrubber: false,
      poolPoints: 117.72,
      bunkerAdjustmentPerDay: -1.6165,
      earnings: 27564.0026,
      bunkerAdjustment: 1728.2565,
      scrubberBonus: 0.0,
      specialCompensations: -37.5116,
      tce: 29357.3867,
      wafDta: 102.6393,
    },
    {
      id: 181030,
      vesselCode: 'TA3',
      partner: {
        id: 155,
        name: 'CDBL',
        axCode: 'CDB',
      },
      name: 'CL Yingdu',
      hasScrubber: false,
      poolPoints: 118.22,
      bunkerAdjustmentPerDay: -2.2161,
      earnings: 27682.2449,
      bunkerAdjustment: 2037.8125,
      scrubberBonus: 0.0,
      specialCompensations: 32.6067,
      tce: 29752.664,
      wafDta: 0.0,
    },
    {
      id: 180786,
      vesselCode: 'TA5',
      partner: {
        id: 155,
        name: 'CDBL',
        axCode: 'CDB',
      },
      name: 'CL Xunyang',
      hasScrubber: false,
      poolPoints: 118.23,
      bunkerAdjustmentPerDay: -2.7331,
      earnings: 27684.6098,
      bunkerAdjustment: 2393.2991,
      scrubberBonus: 0.0,
      specialCompensations: -50.2531,
      tce: 30027.6558,
      wafDta: 0.0,
    },
    {
      id: 180557,
      vesselCode: 'KA3',
      partner: {
        id: 117,
        name: 'Celsius Shipping',
        axCode: 'CEL',
      },
      name: 'Celsius Riga',
      hasScrubber: false,
      poolPoints: 114.7691,
      bunkerAdjustmentPerDay: -0.6702,
      earnings: 26840.7785,
      bunkerAdjustment: 782.3084,
      scrubberBonus: 0.0,
      specialCompensations: -360.5942,
      tce: 27292.7958,
      wafDta: 30.303,
    },
    {
      id: 180554,
      vesselCode: 'KA6',
      partner: {
        id: 117,
        name: 'Celsius Shipping',
        axCode: 'CEL',
      },
      name: 'Celsius Randers',
      hasScrubber: false,
      poolPoints: 20.7364,
      bunkerAdjustmentPerDay: null,
      earnings: 11574.337,
      bunkerAdjustment: 374.2124,
      scrubberBonus: 0.0,
      specialCompensations: -11.7316,
      tce: 11936.8178,
      wafDta: 0.0,
    },
    {
      id: 180556,
      vesselCode: 'KB8',
      partner: {
        id: 117,
        name: 'Celsius Shipping',
        axCode: 'CEL',
      },
      name: 'Celsius Richmond',
      hasScrubber: false,
      poolPoints: 84.0,
      bunkerAdjustmentPerDay: 0.1773,
      earnings: 24225.0298,
      bunkerAdjustment: 157.253,
      scrubberBonus: 0.0,
      specialCompensations: 1715.2288,
      tce: 26097.5117,
      wafDta: 0.0,
    },
    {
      id: 180427,
      vesselCode: 'LA6',
      partner: {
        id: 117,
        name: 'Celsius Shipping',
        axCode: 'CEL',
      },
      name: 'Celsius Rimini',
      hasScrubber: false,
      poolPoints: 42.0,
      bunkerAdjustmentPerDay: null,
      earnings: 12968.4053,
      bunkerAdjustment: 256.9204,
      scrubberBonus: 0.0,
      specialCompensations: -429.8425,
      tce: 12795.4832,
      wafDta: 0.0,
    },
    {
      id: 180961,
      vesselCode: 'LF4',
      partner: {
        id: 117,
        name: 'Celsius Shipping',
        axCode: 'CEL',
      },
      name: 'Celsius Palermo',
      hasScrubber: false,
      poolPoints: 21.0,
      bunkerAdjustmentPerDay: null,
      earnings: 11722.8233,
      bunkerAdjustment: 676.1513,
      scrubberBonus: 0.0,
      specialCompensations: 34.2782,
      tce: 12836.4786,
      wafDta: 403.2258,
    },
    {
      id: 180971,
      vesselCode: 'LG4',
      partner: {
        id: 117,
        name: 'Celsius Shipping',
        axCode: 'CEL',
      },
      name: 'Celsius Ravenna',
      hasScrubber: false,
      poolPoints: 112.87,
      bunkerAdjustmentPerDay: 0.775,
      earnings: 26417.0507,
      bunkerAdjustment: -364.5602,
      scrubberBonus: 0.0,
      specialCompensations: 406.6173,
      tce: 26475.3416,
      wafDta: 16.2338,
    },
    {
      id: 180471,
      vesselCode: 'KQ5',
      partner: {
        id: 55,
        name: 'Cido Shipping',
        axCode: 'CID',
      },
      name: 'Atlantic Eagle',
      hasScrubber: false,
      poolPoints: 112.13,
      bunkerAdjustmentPerDay: 1.3573,
      earnings: 26240.8336,
      bunkerAdjustment: -1316.7647,
      scrubberBonus: 0.0,
      specialCompensations: -242.7092,
      tce: 24681.3598,
      wafDta: 0.0,
    },
    {
      id: 180947,
      vesselCode: 'LB4',
      partner: {
        id: 55,
        name: 'Cido Shipping',
        axCode: 'CID',
      },
      name: 'Atlantic Crown',
      hasScrubber: false,
      poolPoints: 112.13,
      bunkerAdjustmentPerDay: 0.7966,
      earnings: 26233.6837,
      bunkerAdjustment: -1656.3432,
      scrubberBonus: 0.0,
      specialCompensations: -127.0782,
      tce: 24450.2623,
      wafDta: 0.0,
    },
    {
      id: 180999,
      vesselCode: 'LK8',
      partner: {
        id: 55,
        name: 'Cido Shipping',
        axCode: 'CID',
      },
      name: 'Gran Couva',
      hasScrubber: false,
      poolPoints: 115.13,
      bunkerAdjustmentPerDay: 2.0409,
      earnings: 26951.5065,
      bunkerAdjustment: -1184.7744,
      scrubberBonus: 0.0,
      specialCompensations: -78.9489,
      tce: 25687.7832,
      wafDta: 0.0,
    },
    {
      id: 181018,
      vesselCode: 'LP9',
      partner: {
        id: 55,
        name: 'Cido Shipping',
        axCode: 'CID',
      },
      name: 'Forres Park',
      hasScrubber: false,
      poolPoints: 83.3673,
      bunkerAdjustmentPerDay: 0.7514,
      earnings: 24039.8994,
      bunkerAdjustment: -877.8842,
      scrubberBonus: 0.0,
      specialCompensations: 831.9242,
      tce: 23993.9394,
      wafDta: 0.0,
    },
    {
      id: 181013,
      vesselCode: 'LN4',
      partner: {
        id: 76,
        name: 'CST',
        axCode: 'CST',
      },
      name: 'Green Point',
      hasScrubber: false,
      poolPoints: 109.51,
      bunkerAdjustmentPerDay: 1.2474,
      earnings: 25622.4614,
      bunkerAdjustment: -1239.7104,
      scrubberBonus: 0.0,
      specialCompensations: -296.4475,
      tce: 24086.3035,
      wafDta: 0.0,
    },
    {
      id: 180900,
      vesselCode: 'KL7',
      partner: {
        id: 102,
        name: 'DSD Shipping',
        axCode: 'DSD',
      },
      name: 'Stavanger Pioneer',
      hasScrubber: true,
      poolPoints: 116.9445,
      bunkerAdjustmentPerDay: -1.126,
      earnings: 27412.1217,
      bunkerAdjustment: 1067.2014,
      scrubberBonus: 1927.3512,
      specialCompensations: 21.9181,
      tce: 30428.5924,
      wafDta: 0.0,
    },
    {
      id: 180953,
      vesselCode: 'LC4',
      partner: {
        id: 102,
        name: 'DSD Shipping',
        axCode: 'DSD',
      },
      name: 'Stavanger Pearl',
      hasScrubber: true,
      poolPoints: 116.9545,
      bunkerAdjustmentPerDay: -0.7379,
      earnings: 27413.9102,
      bunkerAdjustment: 780.1221,
      scrubberBonus: 2855.3277,
      specialCompensations: 60.5337,
      tce: 31212.533,
      wafDta: 102.6393,
    },
    {
      id: 180954,
      vesselCode: 'LC5',
      partner: {
        id: 102,
        name: 'DSD Shipping',
        axCode: 'DSD',
      },
      name: 'Stavanger Poseidon',
      hasScrubber: true,
      poolPoints: 116.9545,
      bunkerAdjustmentPerDay: -1.2472,
      earnings: 27414.0471,
      bunkerAdjustment: 972.8836,
      scrubberBonus: 2694.6487,
      specialCompensations: 44.8281,
      tce: 31187.0135,
      wafDta: 60.6061,
    },
    {
      id: 180974,
      vesselCode: 'LG1',
      partner: {
        id: 102,
        name: 'DSD Shipping',
        axCode: 'DSD',
      },
      name: 'Stavanger Pride',
      hasScrubber: true,
      poolPoints: 116.9545,
      bunkerAdjustmentPerDay: 0.4995,
      earnings: 27414.0249,
      bunkerAdjustment: 225.8303,
      scrubberBonus: 2751.5345,
      specialCompensations: 132.6679,
      tce: 30582.7085,
      wafDta: 58.651,
    },
    {
      id: 181039,
      vesselCode: 'LU7',
      partner: {
        id: 176,
        name: 'Egyptian Tanker Company',
        axCode: 'ETC',
      },
      name: 'ETC Nefertari',
      hasScrubber: false,
      poolPoints: 111.26,
      bunkerAdjustmentPerDay: 1.7339,
      earnings: 26036.3101,
      bunkerAdjustment: -1251.5585,
      scrubberBonus: 0.0,
      specialCompensations: -127.676,
      tce: 25047.5238,
      wafDta: 390.4483,
    },
    {
      id: 181033,
      vesselCode: 'LT9',
      partner: {
        id: 174,
        name: 'Falcon Navigation',
        axCode: 'FAL',
      },
      name: 'Falcon Maryam',
      hasScrubber: false,
      poolPoints: 83.4509,
      bunkerAdjustmentPerDay: 0.3557,
      earnings: 19446.2039,
      bunkerAdjustment: -1324.7124,
      scrubberBonus: 0.0,
      specialCompensations: -57.4578,
      tce: 18759.9476,
      wafDta: 695.9139,
    },
    {
      id: 181070,
      vesselCode: 'LZ4',
      partner: {
        id: 174,
        name: 'Falcon Navigation',
        axCode: 'FAL',
      },
      name: 'Falcon Nostos',
      hasScrubber: false,
      poolPoints: 63.0,
      bunkerAdjustmentPerDay: -0.0339,
      earnings: 20467.2518,
      bunkerAdjustment: -1087.4927,
      scrubberBonus: 0.0,
      specialCompensations: 61.1867,
      tce: 19467.8276,
      wafDta: 26.8817,
    },
    {
      id: 180473,
      vesselCode: 'KR2',
      partner: {
        id: 80,
        name: 'Fuyo Kaiun',
        axCode: 'FUY',
      },
      name: 'Sunshine Express',
      hasScrubber: false,
      poolPoints: 84.0,
      bunkerAdjustmentPerDay: -0.0897,
      earnings: 24223.8609,
      bunkerAdjustment: 548.5193,
      scrubberBonus: 0.0,
      specialCompensations: 12.609,
      tce: 24889.1559,
      wafDta: 104.1667,
    },
    {
      id: 181000,
      vesselCode: 'LI7',
      partner: {
        id: 80,
        name: 'Fuyo Kaiun',
        axCode: 'FUY',
      },
      name: 'Rich Rainbow',
      hasScrubber: false,
      poolPoints: 118.4091,
      bunkerAdjustmentPerDay: -1.2818,
      earnings: 27722.3057,
      bunkerAdjustment: 1332.0652,
      scrubberBonus: 0.0,
      specialCompensations: -118.9096,
      tce: 28935.4613,
      wafDta: 0.0,
    },
    {
      id: 180981,
      vesselCode: 'LI1',
      partner: {
        id: 157,
        name: 'Gotland Tankers',
        axCode: 'GOT',
      },
      name: 'Gotland Carolina',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: 0.6685,
      earnings: 34069.7817,
      bunkerAdjustment: -1145.6487,
      scrubberBonus: 0.0,
      specialCompensations: -33.621,
      tce: 32890.5121,
      wafDta: 0.0,
    },
    {
      id: 181038,
      vesselCode: 'LU5',
      partner: {
        id: 157,
        name: 'Gotland Tankers',
        axCode: 'GOT',
      },
      name: 'Gotland Aliya',
      hasScrubber: false,
      poolPoints: 124.5455,
      bunkerAdjustmentPerDay: 2.1088,
      earnings: 29145.6035,
      bunkerAdjustment: -2879.9816,
      scrubberBonus: 0.0,
      specialCompensations: -486.4449,
      tce: 25854.061,
      wafDta: 74.8839,
    },
    {
      id: 181043,
      vesselCode: 'LU6',
      partner: {
        id: 157,
        name: 'Gotland Tankers',
        axCode: 'GOT',
      },
      name: 'Gotland Marieann',
      hasScrubber: false,
      poolPoints: 124.5455,
      bunkerAdjustmentPerDay: 0.807,
      earnings: 28561.0817,
      bunkerAdjustment: -1897.6829,
      scrubberBonus: 0.0,
      specialCompensations: -50.0858,
      tce: 26775.109,
      wafDta: 161.796,
    },
    {
      id: 180747,
      vesselCode: 'LT6',
      partner: {
        id: 171,
        name: 'Hadley Shipping',
        axCode: 'HAD',
      },
      name: 'Cepolis',
      hasScrubber: false,
      poolPoints: 112.02,
      bunkerAdjustmentPerDay: -0.2882,
      earnings: 26216.4758,
      bunkerAdjustment: -46.163,
      scrubberBonus: 0.0,
      specialCompensations: -172.7581,
      tce: 26056.2057,
      wafDta: 58.651,
    },
    {
      id: 181078,
      vesselCode: 'NB7',
      partner: {
        id: 171,
        name: 'Hadley Shipping',
        axCode: 'HAD',
      },
      name: 'Calandria',
      hasScrubber: false,
      poolPoints: 79.9636,
      bunkerAdjustmentPerDay: -0.1593,
      earnings: 30749.7647,
      bunkerAdjustment: 106.9357,
      scrubberBonus: 0.0,
      specialCompensations: -154.6183,
      tce: 30743.7487,
      wafDta: 41.6667,
    },
    {
      id: 180847,
      vesselCode: 'J8T',
      partner: {
        id: 68,
        name: 'Island Navigation',
        axCode: 'ISL',
      },
      name: 'Pine Express',
      hasScrubber: false,
      poolPoints: 115.21,
      bunkerAdjustmentPerDay: -0.8382,
      earnings: 26970.4254,
      bunkerAdjustment: 475.9127,
      scrubberBonus: 0.0,
      specialCompensations: -95.8487,
      tce: 27350.4894,
      wafDta: 0.0,
    },
    {
      id: 180935,
      vesselCode: 'KO5',
      partner: {
        id: 68,
        name: 'Island Navigation',
        axCode: 'ISL',
      },
      name: 'Challenge Prelude',
      hasScrubber: false,
      poolPoints: 111.56,
      bunkerAdjustmentPerDay: -0.2639,
      earnings: 26107.2555,
      bunkerAdjustment: 195.3024,
      scrubberBonus: 0.0,
      specialCompensations: 95.9764,
      tce: 26503.6174,
      wafDta: 105.0831,
    },
    {
      id: 180986,
      vesselCode: 'LH9',
      partner: {
        id: 68,
        name: 'Island Navigation',
        axCode: 'ISL',
      },
      name: 'Aspen Express',
      hasScrubber: false,
      poolPoints: 43.0036,
      bunkerAdjustmentPerDay: null,
      earnings: 16085.4821,
      bunkerAdjustment: 1795.5093,
      scrubberBonus: 0.0,
      specialCompensations: -165.7697,
      tce: 17715.2217,
      wafDta: 0.0,
    },
    {
      id: 180998,
      vesselCode: 'LL3',
      partner: {
        id: 68,
        name: 'Island Navigation',
        axCode: 'ISL',
      },
      name: 'Magnolia Express',
      hasScrubber: false,
      poolPoints: 43.0036,
      bunkerAdjustmentPerDay: null,
      earnings: 16084.6873,
      bunkerAdjustment: 1807.8233,
      scrubberBonus: 0.0,
      specialCompensations: -34.2468,
      tce: 17858.2638,
      wafDta: 0.0,
    },
    {
      id: 181092,
      vesselCode: 'NG3',
      partner: {
        id: 193,
        name: 'Leonhardt & Blumberg',
        axCode: 'LNB',
      },
      name: 'Hansa Oslo',
      hasScrubber: false,
      poolPoints: 42.0,
      bunkerAdjustmentPerDay: 0.8602,
      earnings: 34679.818,
      bunkerAdjustment: -776.6559,
      scrubberBonus: 0.0,
      specialCompensations: -621.4261,
      tce: 33281.7361,
      wafDta: 0.0,
    },
    {
      id: 181090,
      vesselCode: 'NG6',
      partner: {
        id: 195,
        name: 'Manta Denizcilik',
        axCode: 'MAN',
      },
      name: 'Manta Galatasaray',
      hasScrubber: false,
      poolPoints: 42.0,
      bunkerAdjustmentPerDay: -0.8958,
      earnings: 34677.3389,
      bunkerAdjustment: 893.5484,
      scrubberBonus: 0.0,
      specialCompensations: -350.884,
      tce: 35220.0033,
      wafDta: 0.0,
    },
    {
      id: 180994,
      vesselCode: 'LJ8',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Capri',
      hasScrubber: false,
      poolPoints: 118.92,
      bunkerAdjustmentPerDay: -0.1984,
      earnings: 27847.7844,
      bunkerAdjustment: 813.0884,
      scrubberBonus: 0.0,
      specialCompensations: 73.6462,
      tce: 28734.519,
      wafDta: 0.0,
    },
    {
      id: 181003,
      vesselCode: 'LL5',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Callao',
      hasScrubber: false,
      poolPoints: 118.89,
      bunkerAdjustmentPerDay: -1.2287,
      earnings: 27840.6899,
      bunkerAdjustment: 1297.7698,
      scrubberBonus: 0.0,
      specialCompensations: -40.6234,
      tce: 29159.6293,
      wafDta: 61.793,
    },
    {
      id: 181005,
      vesselCode: 'LL9',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Curacao',
      hasScrubber: false,
      poolPoints: 118.92,
      bunkerAdjustmentPerDay: -2.0943,
      earnings: 27847.7844,
      bunkerAdjustment: 1665.1608,
      scrubberBonus: 0.0,
      specialCompensations: 382.0967,
      tce: 29925.345,
      wafDta: 30.303,
    },
    {
      id: 181006,
      vesselCode: 'LM2',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Corsica',
      hasScrubber: false,
      poolPoints: 118.89,
      bunkerAdjustmentPerDay: -1.5212,
      earnings: 27840.6186,
      bunkerAdjustment: 1408.6716,
      scrubberBonus: 0.0,
      specialCompensations: 208.6143,
      tce: 29561.5213,
      wafDta: 103.6168,
    },
    {
      id: 180775,
      vesselCode: 'MA5',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Tacoma',
      hasScrubber: false,
      poolPoints: 118.83,
      bunkerAdjustmentPerDay: -0.631,
      earnings: 27826.5008,
      bunkerAdjustment: 644.9254,
      scrubberBonus: 0.0,
      specialCompensations: 144.7415,
      tce: 28616.1676,
      wafDta: 0.0,
    },
    {
      id: 180779,
      vesselCode: 'MA6',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Tangier',
      hasScrubber: false,
      poolPoints: 118.4191,
      bunkerAdjustmentPerDay: -1.1655,
      earnings: 35660.8448,
      bunkerAdjustment: 1318.2087,
      scrubberBonus: 0.0,
      specialCompensations: 451.1017,
      tce: 37494.6714,
      wafDta: 64.5161,
    },
    {
      id: 180781,
      vesselCode: 'MA7',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Teesport',
      hasScrubber: false,
      poolPoints: 118.8,
      bunkerAdjustmentPerDay: -1.0648,
      earnings: 27819.8574,
      bunkerAdjustment: 1090.1206,
      scrubberBonus: 0.0,
      specialCompensations: 200.5708,
      tce: 29110.5488,
      wafDta: 0.0,
    },
    {
      id: 180777,
      vesselCode: 'MA8',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Tampa',
      hasScrubber: false,
      poolPoints: 118.83,
      bunkerAdjustmentPerDay: -0.7918,
      earnings: 27826.5008,
      bunkerAdjustment: 752.7101,
      scrubberBonus: 0.0,
      specialCompensations: -7.7295,
      tce: 28965.4207,
      wafDta: 393.9394,
    },
    {
      id: 180731,
      vesselCode: '4R9',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Magellan',
      hasScrubber: false,
      poolPoints: 118.99,
      bunkerAdjustmentPerDay: -0.473,
      earnings: 27864.3383,
      bunkerAdjustment: 547.1635,
      scrubberBonus: 0.0,
      specialCompensations: -62.1243,
      tce: 28468.6346,
      wafDta: 119.2571,
    },
    {
      id: 180735,
      vesselCode: 'KG4',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Maru',
      hasScrubber: false,
      poolPoints: 114.52,
      bunkerAdjustmentPerDay: -0.3111,
      earnings: 26807.2508,
      bunkerAdjustment: 400.0716,
      scrubberBonus: 0.0,
      specialCompensations: -69.3006,
      tce: 27225.9984,
      wafDta: 87.9765,
    },
    {
      id: 180740,
      vesselCode: 'O15',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Messina (SIN)',
      hasScrubber: false,
      poolPoints: 71.6291,
      bunkerAdjustmentPerDay: 0.2667,
      earnings: 22090.1029,
      bunkerAdjustment: 170.7544,
      scrubberBonus: 0.0,
      specialCompensations: -214.7778,
      tce: 22138.2453,
      wafDta: 92.1659,
    },
    {
      id: 180785,
      vesselCode: 'O65',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Tokyo',
      hasScrubber: false,
      poolPoints: 118.69,
      bunkerAdjustmentPerDay: -2.2634,
      earnings: 27793.205,
      bunkerAdjustment: 2033.5535,
      scrubberBonus: 0.0,
      specialCompensations: 38.5371,
      tce: 29865.2956,
      wafDta: 0.0,
    },
    {
      id: 180788,
      vesselCode: 'O69',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Trieste',
      hasScrubber: false,
      poolPoints: 42.9782,
      bunkerAdjustmentPerDay: null,
      earnings: 11999.9674,
      bunkerAdjustment: 0.0,
      scrubberBonus: 0.0,
      specialCompensations: 0.0,
      tce: 11999.9674,
      wafDta: 0.0,
    },
    {
      id: 180745,
      vesselCode: 'U04',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Mississippi',
      hasScrubber: false,
      poolPoints: 112.6364,
      bunkerAdjustmentPerDay: -0.4422,
      earnings: 26370.0812,
      bunkerAdjustment: 755.4384,
      scrubberBonus: 0.0,
      specialCompensations: -299.9589,
      tce: 26825.5607,
      wafDta: 0.0,
    },
    {
      id: 180732,
      vesselCode: 'U93',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Malaga',
      hasScrubber: false,
      poolPoints: 119.0,
      bunkerAdjustmentPerDay: -0.1897,
      earnings: 27865.788,
      bunkerAdjustment: -231.8992,
      scrubberBonus: 0.0,
      specialCompensations: 117.2889,
      tce: 27780.5033,
      wafDta: 29.3255,
    },
    {
      id: 180978,
      vesselCode: 'LE4',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'St. Clemens',
      hasScrubber: false,
      poolPoints: 115.5,
      bunkerAdjustmentPerDay: -2.2125,
      earnings: 27039.1955,
      bunkerAdjustment: 1788.0724,
      scrubberBonus: 0.0,
      specialCompensations: 148.8482,
      tce: 28976.116,
      wafDta: 0.0,
    },
    {
      id: 181053,
      vesselCode: 'LV4',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'St. Petri',
      hasScrubber: false,
      poolPoints: 116.5,
      bunkerAdjustmentPerDay: 0.2794,
      earnings: 27275.2152,
      bunkerAdjustment: 290.5207,
      scrubberBonus: 0.0,
      specialCompensations: -21.2505,
      tce: 27544.4854,
      wafDta: 0.0,
    },
    {
      id: 181061,
      vesselCode: 'LW4',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'St.Michaelis',
      hasScrubber: false,
      poolPoints: 116.5,
      bunkerAdjustmentPerDay: 0.4308,
      earnings: 27275.491,
      bunkerAdjustment: -30.1041,
      scrubberBonus: 0.0,
      specialCompensations: -38.685,
      tce: 27206.702,
      wafDta: 0.0,
    },
    {
      id: 181050,
      vesselCode: 'LW5',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'St. Nikolai',
      hasScrubber: false,
      poolPoints: 115.5,
      bunkerAdjustmentPerDay: 0.4225,
      earnings: 27039.006,
      bunkerAdjustment: 334.8687,
      scrubberBonus: 0.0,
      specialCompensations: -88.4956,
      tce: 27285.3791,
      wafDta: 0.0,
    },
    {
      id: 181059,
      vesselCode: 'LY1',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'Blue Grass Mariner',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -1.7448,
      earnings: 27511.653,
      bunkerAdjustment: 1396.9019,
      scrubberBonus: 2570.8341,
      specialCompensations: -60.2887,
      tce: 31419.1004,
      wafDta: 0.0,
    },
    {
      id: 181057,
      vesselCode: 'LY2',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'Yellow Stars',
      hasScrubber: false,
      poolPoints: 118.0,
      bunkerAdjustmentPerDay: -1.0944,
      earnings: 27630.2182,
      bunkerAdjustment: 1411.8339,
      scrubberBonus: 0.0,
      specialCompensations: -19.7806,
      tce: 29022.2716,
      wafDta: 0.0,
    },
    {
      id: 181065,
      vesselCode: 'LZ5',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'Grand Winner 3',
      hasScrubber: false,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.5839,
      earnings: 27511.9759,
      bunkerAdjustment: 908.8443,
      scrubberBonus: 0.0,
      specialCompensations: 476.0929,
      tce: 28956.5417,
      wafDta: 59.6285,
    },
    {
      id: 181077,
      vesselCode: 'LZ8',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'Alexandros',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.5904,
      earnings: 27512.1926,
      bunkerAdjustment: 705.3731,
      scrubberBonus: 2607.7841,
      specialCompensations: 4.1958,
      tce: 31053.8858,
      wafDta: 224.3402,
    },
    {
      id: 180522,
      vesselCode: 'F2Y',
      partner: {
        id: 72,
        name: 'Nan Fung',
        axCode: 'NAF',
      },
      name: 'Bright Dawn',
      hasScrubber: false,
      poolPoints: 114.0909,
      bunkerAdjustmentPerDay: 0.0759,
      earnings: 27146.0304,
      bunkerAdjustment: -863.9693,
      scrubberBonus: 0.0,
      specialCompensations: -520.2446,
      tce: 25761.8165,
      wafDta: 0.0,
    },
    {
      id: 180806,
      vesselCode: 'F2Z',
      partner: {
        id: 72,
        name: 'Nan Fung',
        axCode: 'NAF',
      },
      name: 'New Dawn',
      hasScrubber: false,
      poolPoints: 114.0,
      bunkerAdjustmentPerDay: 0.326,
      earnings: 25748.4235,
      bunkerAdjustment: -1171.3899,
      scrubberBonus: 0.0,
      specialCompensations: 524.8549,
      tce: 25101.8885,
      wafDta: 0.0,
    },
    {
      id: 181102,
      vesselCode: 'NI9',
      partner: {
        id: 72,
        name: 'Nan Fung',
        axCode: 'NAF',
      },
      name: 'Gemini Pearl',
      hasScrubber: false,
      poolPoints: 20.7273,
      bunkerAdjustmentPerDay: 0.24,
      earnings: 33440.3113,
      bunkerAdjustment: -191.8253,
      scrubberBonus: 0.0,
      specialCompensations: 249.3488,
      tce: 33497.8349,
      wafDta: 0.0,
    },
    {
      id: 181103,
      vesselCode: 'NJ3',
      partner: {
        id: 72,
        name: 'Nan Fung',
        axCode: 'NAF',
      },
      name: 'Aries Pearl',
      hasScrubber: false,
      poolPoints: 10.4545,
      bunkerAdjustmentPerDay: 2.103,
      earnings: 32436.947,
      bunkerAdjustment: -1607.7948,
      scrubberBonus: 0.0,
      specialCompensations: 0.0,
      tce: 30829.1522,
      wafDta: 0.0,
    },
    {
      id: 180435,
      vesselCode: 'KX7',
      partner: {
        id: 129,
        name: 'Swift Tankers',
        axCode: 'NHS',
      },
      name: 'Swift Omaha',
      hasScrubber: false,
      poolPoints: 103.2636,
      bunkerAdjustmentPerDay: -0.455,
      earnings: 26041.7602,
      bunkerAdjustment: -50.7745,
      scrubberBonus: 0.0,
      specialCompensations: 0.0553,
      tce: 25991.041,
      wafDta: 0.0,
    },
    {
      id: 181079,
      vesselCode: 'NB6',
      partner: {
        id: 129,
        name: 'Swift Tankers',
        axCode: 'NHS',
      },
      name: ' Swift Winchester',
      hasScrubber: false,
      poolPoints: 82.6109,
      bunkerAdjustmentPerDay: 0.7409,
      earnings: 32955.6306,
      bunkerAdjustment: -1026.1868,
      scrubberBonus: 0.0,
      specialCompensations: 322.8438,
      tce: 32252.2876,
      wafDta: 0.0,
    },
    {
      id: 180970,
      vesselCode: 'LE9',
      partner: {
        id: 145,
        name: 'Nyhill Shipping',
        axCode: 'NYS',
      },
      name: 'Jo Kari',
      hasScrubber: false,
      poolPoints: 10.2391,
      bunkerAdjustmentPerDay: null,
      earnings: 11429.5682,
      bunkerAdjustment: -1372.8229,
      scrubberBonus: 0.0,
      specialCompensations: -0.0004,
      tce: 10056.7449,
      wafDta: 0.0,
    },
    {
      id: 180965,
      vesselCode: 'LF1',
      partner: {
        id: 145,
        name: 'Nyhill Shipping',
        axCode: 'NYS',
      },
      name: 'Jo Ask',
      hasScrubber: false,
      poolPoints: 40.5927,
      bunkerAdjustmentPerDay: null,
      earnings: 15170.0585,
      bunkerAdjustment: -1386.9953,
      scrubberBonus: 0.0,
      specialCompensations: 381.9862,
      tce: 14165.0493,
      wafDta: 0.0,
    },
    {
      id: 180962,
      vesselCode: 'LD6',
      partner: {
        id: 147,
        name: 'Shenlong',
        axCode: 'OJM',
      },
      name: 'St. Katharinen',
      hasScrubber: false,
      poolPoints: 52.5,
      bunkerAdjustmentPerDay: null,
      earnings: 18400.5697,
      bunkerAdjustment: 214.4814,
      scrubberBonus: 0.0,
      specialCompensations: 107.912,
      tce: 18722.9631,
      wafDta: 0.0,
    },
    {
      id: 181017,
      vesselCode: 'LP6',
      partner: {
        id: 162,
        name: 'Pan Ocean',
        axCode: 'PAN',
      },
      name: 'Grand Ace1',
      hasScrubber: false,
      poolPoints: 112.49,
      bunkerAdjustmentPerDay: 0.3443,
      earnings: 26327.1864,
      bunkerAdjustment: -543.9817,
      scrubberBonus: 0.0,
      specialCompensations: -29.1155,
      tce: 25754.0892,
      wafDta: 0.0,
    },
    {
      id: 181023,
      vesselCode: 'LQ2',
      partner: {
        id: 162,
        name: 'Pan Ocean',
        axCode: 'PAN',
      },
      name: 'Grand Ace10',
      hasScrubber: false,
      poolPoints: 20.8473,
      bunkerAdjustmentPerDay: null,
      earnings: 11646.7402,
      bunkerAdjustment: -650.9785,
      scrubberBonus: 0.0,
      specialCompensations: 2080.4381,
      tce: 13076.1998,
      wafDta: 0.0,
    },
    {
      id: 181064,
      vesselCode: 'LZ1',
      partner: {
        id: 162,
        name: 'Pan Ocean',
        axCode: 'PAN',
      },
      name: 'Grand Ace5',
      hasScrubber: false,
      poolPoints: 111.68,
      bunkerAdjustmentPerDay: 1.1111,
      earnings: 26135.4164,
      bunkerAdjustment: -1157.4757,
      scrubberBonus: 0.0,
      specialCompensations: -225.6125,
      tce: 24752.3281,
      wafDta: 0.0,
    },
    {
      id: 180459,
      vesselCode: 'KU1',
      partner: {
        id: 127,
        name: 'Pacific Carriers Limited',
        axCode: 'PCL',
      },
      name: 'Plover Pacific',
      hasScrubber: false,
      poolPoints: 116.5,
      bunkerAdjustmentPerDay: 1.5881,
      earnings: 27275.139,
      bunkerAdjustment: -1680.8186,
      scrubberBonus: 0.0,
      specialCompensations: 66.8267,
      tce: 25824.3925,
      wafDta: 163.2454,
    },
    {
      id: 180895,
      vesselCode: 'D7X',
      partner: {
        id: 146,
        name: 'RAO Tanker',
        axCode: 'RAO',
      },
      name: 'St Pauli',
      hasScrubber: false,
      poolPoints: 64.0909,
      bunkerAdjustmentPerDay: null,
      earnings: 13196.6621,
      bunkerAdjustment: 0.0,
      scrubberBonus: 0.0,
      specialCompensations: 0.0,
      tce: 13196.6621,
      wafDta: 0.0,
    },
    {
      id: 180964,
      vesselCode: 'LR3',
      partner: {
        id: 169,
        name: 'Shandong Weihe Tanker',
        axCode: 'SHA',
      },
      name: 'Shandong Weihe',
      hasScrubber: false,
      poolPoints: 105.8091,
      bunkerAdjustmentPerDay: 0.7258,
      earnings: 24854.6989,
      bunkerAdjustment: -535.469,
      scrubberBonus: 0.0,
      specialCompensations: -264.4697,
      tce: 24054.7602,
      wafDta: 0.0,
    },
    {
      id: 181088,
      vesselCode: 'NF1',
      partner: {
        id: 196,
        name: 'SK Shipping',
        axCode: 'SKS',
      },
      name: 'Grand Ace10',
      hasScrubber: false,
      poolPoints: 52.1182,
      bunkerAdjustmentPerDay: 0.8522,
      earnings: 34674.5643,
      bunkerAdjustment: -790.1386,
      scrubberBonus: 0.0,
      specialCompensations: 293.4193,
      tce: 34177.8451,
      wafDta: 0.0,
    },
    {
      id: 181012,
      vesselCode: 'LO8',
      partner: {
        id: 167,
        name: 'Silk Searoad',
        axCode: 'SSR',
      },
      name: 'Riviera II',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: 1.6938,
      earnings: 25974.8239,
      bunkerAdjustment: -1052.1158,
      scrubberBonus: 0.0,
      specialCompensations: -154.983,
      tce: 24797.0506,
      wafDta: 29.3255,
    },
    {
      id: 181047,
      vesselCode: 'LW1',
      partner: {
        id: 167,
        name: 'Silk Searoad',
        axCode: 'SSR',
      },
      name: 'Evian',
      hasScrubber: false,
      poolPoints: 112.0436,
      bunkerAdjustmentPerDay: 0.4119,
      earnings: 31432.4113,
      bunkerAdjustment: -526.7247,
      scrubberBonus: 0.0,
      specialCompensations: 65.8663,
      tce: 30971.5529,
      wafDta: 0.0,
    },
    {
      id: 180990,
      vesselCode: 'LK4',
      partner: {
        id: 154,
        name: 'Sea World Management',
        axCode: 'SWM',
      },
      name: 'Sw Southport I',
      hasScrubber: false,
      poolPoints: 9.9991,
      bunkerAdjustmentPerDay: null,
      earnings: 11174.1346,
      bunkerAdjustment: -2093.3269,
      scrubberBonus: 0.0,
      specialCompensations: 0.0,
      tce: 9080.8077,
      wafDta: 0.0,
    },
    {
      id: 180498,
      vesselCode: 'J7J',
      partner: {
        id: 110,
        name: 'Transocean Maritime',
        axCode: 'TRO',
      },
      name: 'Atalanta T',
      hasScrubber: false,
      poolPoints: 111.47,
      bunkerAdjustmentPerDay: -0.3013,
      earnings: 26085.9719,
      bunkerAdjustment: 290.7583,
      scrubberBonus: 0.0,
      specialCompensations: -1.4262,
      tce: 26375.304,
      wafDta: 0.0,
    },
    {
      id: 180486,
      vesselCode: 'J8S',
      partner: {
        id: 110,
        name: 'Transocean Maritime',
        axCode: 'TRO',
      },
      name: 'Alcyone T',
      hasScrubber: false,
      poolPoints: 111.45,
      bunkerAdjustmentPerDay: -0.4419,
      earnings: 26081.4404,
      bunkerAdjustment: 49.229,
      scrubberBonus: 0.0,
      specialCompensations: -198.0177,
      tce: 25932.6518,
      wafDta: 0.0,
    },
    {
      id: 180942,
      vesselCode: 'LA5',
      partner: {
        id: 110,
        name: 'Transocean Maritime',
        axCode: 'TRO',
      },
      name: 'Aquarius T',
      hasScrubber: false,
      poolPoints: 41.1455,
      bunkerAdjustmentPerDay: null,
      earnings: 15376.5574,
      bunkerAdjustment: -1383.7725,
      scrubberBonus: 0.0,
      specialCompensations: -407.7804,
      tce: 13585.0045,
      wafDta: 0.0,
    },
    {
      id: 181022,
      vesselCode: 'LQ1',
      partner: {
        id: 110,
        name: 'Transocean Maritime',
        axCode: 'TRO',
      },
      name: 'Atlas T',
      hasScrubber: false,
      poolPoints: 92.7645,
      bunkerAdjustmentPerDay: 0.0201,
      earnings: 25031.5074,
      bunkerAdjustment: -193.7034,
      scrubberBonus: 0.0,
      specialCompensations: -193.0878,
      tce: 24770.1642,
      wafDta: 125.448,
    },
    {
      id: 180510,
      vesselCode: 'MA3',
      partner: {
        id: 110,
        name: 'Transocean Maritime',
        axCode: 'TRO',
      },
      name: 'Atlantic T',
      hasScrubber: false,
      poolPoints: 91.1282,
      bunkerAdjustmentPerDay: 0.0463,
      earnings: 24611.1446,
      bunkerAdjustment: -30.2969,
      scrubberBonus: 0.0,
      specialCompensations: -428.1546,
      tce: 24152.6932,
      wafDta: 0.0,
    },
    {
      id: 181011,
      vesselCode: 'LN7',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Artemis',
      hasScrubber: false,
      poolPoints: 121.5,
      bunkerAdjustmentPerDay: 2.7581,
      earnings: 28457.9154,
      bunkerAdjustment: -2346.4883,
      scrubberBonus: 0.0,
      specialCompensations: -11.4059,
      tce: 26337.0692,
      wafDta: 237.0479,
    },
    {
      id: 181007,
      vesselCode: 'LN8',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Aris',
      hasScrubber: false,
      poolPoints: 122.5,
      bunkerAdjustmentPerDay: 2.9326,
      earnings: 28694.4003,
      bunkerAdjustment: -3107.18,
      scrubberBonus: 0.0,
      specialCompensations: -323.5875,
      tce: 25263.6328,
      wafDta: 0.0,
    },
    {
      id: 181008,
      vesselCode: 'LN9',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Apollon',
      hasScrubber: false,
      poolPoints: 121.5,
      bunkerAdjustmentPerDay: 1.2458,
      earnings: 28457.9154,
      bunkerAdjustment: -1779.164,
      scrubberBonus: 0.0,
      specialCompensations: -46.0953,
      tce: 26632.6561,
      wafDta: 0.0,
    },
    {
      id: 181044,
      vesselCode: 'LW2',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Ajax',
      hasScrubber: false,
      poolPoints: 122.5,
      bunkerAdjustmentPerDay: 4.2147,
      earnings: 28694.4003,
      bunkerAdjustment: -3310.4161,
      scrubberBonus: 0.0,
      specialCompensations: -322.6201,
      tce: 25304.2073,
      wafDta: 242.8432,
    },
    {
      id: 181055,
      vesselCode: 'LX3',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Ariadne',
      hasScrubber: false,
      poolPoints: 121.5,
      bunkerAdjustmentPerDay: 1.2873,
      earnings: 28457.9154,
      bunkerAdjustment: -1681.8247,
      scrubberBonus: 0.0,
      specialCompensations: 225.6182,
      tce: 27001.7089,
      wafDta: 0.0,
    },
    {
      id: 181054,
      vesselCode: 'LX4',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Afrodite',
      hasScrubber: false,
      poolPoints: 121.5,
      bunkerAdjustmentPerDay: 0.779,
      earnings: 28457.9154,
      bunkerAdjustment: -1276.8657,
      scrubberBonus: 0.0,
      specialCompensations: -27.0259,
      tce: 27154.0238,
      wafDta: 0.0,
    },
    {
      id: 180429,
      vesselCode: 'KV5',
      partner: {
        id: 128,
        name: 'Team Tankers',
        axCode: 'TTI',
      },
      name: 'Endo Breeze',
      hasScrubber: false,
      poolPoints: 50.1182,
      bunkerAdjustmentPerDay: null,
      earnings: 17545.7008,
      bunkerAdjustment: -1175.6128,
      scrubberBonus: 0.0,
      specialCompensations: 2910.5731,
      tce: 19345.1772,
      wafDta: 64.5161,
    },
    {
      id: 181089,
      vesselCode: 'NF6',
      partner: {
        id: 190,
        name: 'Tradewind Tankers',
        axCode: 'TWT',
      },
      name: 'Tradewind Energy',
      hasScrubber: false,
      poolPoints: 42.0473,
      bunkerAdjustmentPerDay: 0.4164,
      earnings: 34718.0185,
      bunkerAdjustment: -414.1862,
      scrubberBonus: 0.0,
      specialCompensations: -129.8977,
      tce: 34173.9345,
      wafDta: 0.0,
    },
    {
      id: 181052,
      vesselCode: 'LW8',
      partner: {
        id: 179,
        name: 'Velos Tankers',
        axCode: 'VEL',
      },
      name: 'VELOS FORTUNA',
      hasScrubber: false,
      poolPoints: 40.3018,
      bunkerAdjustmentPerDay: null,
      earnings: 15057.4838,
      bunkerAdjustment: -309.3516,
      scrubberBonus: 0.0,
      specialCompensations: 12.4147,
      tce: 14760.547,
      wafDta: 0.0,
    },
    {
      id: 180948,
      vesselCode: 'LC3',
      partner: {
        id: 144,
        name: 'Wayfare PTI ONE INC.',
        axCode: 'WPO',
      },
      name: 'Cygnus',
      hasScrubber: false,
      poolPoints: 84.0,
      bunkerAdjustmentPerDay: 0.997,
      earnings: 24224.9304,
      bunkerAdjustment: -1601.691,
      scrubberBonus: 0.0,
      specialCompensations: 51.1114,
      tce: 22718.9936,
      wafDta: 44.6429,
    },
    {
      id: 180960,
      vesselCode: 'LE5',
      partner: {
        id: 143,
        name: 'Wayfare PTI TWO INC.',
        axCode: 'WPT',
      },
      name: 'Sextans',
      hasScrubber: false,
      poolPoints: 82.1818,
      bunkerAdjustmentPerDay: 0.1282,
      earnings: 21815.0161,
      bunkerAdjustment: -1793.0978,
      scrubberBonus: 0.0,
      specialCompensations: 25.0658,
      tce: 20046.9842,
      wafDta: 0.0,
    },
    {
      id: 181016,
      vesselCode: 'LP7',
      partner: {
        id: 164,
        name: 'Zodiac Maritime',
        axCode: 'ZMA',
      },
      name: 'Vendome Street',
      hasScrubber: false,
      poolPoints: 110.88,
      bunkerAdjustmentPerDay: 0.2468,
      earnings: 25946.8725,
      bunkerAdjustment: -142.285,
      scrubberBonus: 0.0,
      specialCompensations: 85.5098,
      tce: 25890.0973,
      wafDta: 0.0,
    },
    {
      id: 181051,
      vesselCode: 'LX2',
      partner: {
        id: 164,
        name: 'Zodiac Maritime',
        axCode: 'ZMA',
      },
      name: 'Canal Street',
      hasScrubber: false,
      poolPoints: 112.99,
      bunkerAdjustmentPerDay: 1.0745,
      earnings: 26445.31,
      bunkerAdjustment: -645.4795,
      scrubberBonus: 0.0,
      specialCompensations: 68.027,
      tce: 25867.8575,
      wafDta: 0.0,
    },
  ],
}
