import { dummyVessel } from '../vessels'

export const vesselsList = [
  {
    IMONumber: '9212589',
    VesselName: 'Griya Jawa',
  },
  {
    IMONumber: '9212694',
    VesselName: 'East Ace',
  },
  {
    IMONumber: '9212709',
    VesselName: 'Tan Xu Lun',
  },
  {
    IMONumber: dummyVessel.imoNumber,
    VesselName: dummyVessel.vesselName,
  },
]
