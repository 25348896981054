import {
  CargoGroupEnum,
  VesselSpecsEnum,
} from '~pages/pages-behind-login/position-list/types/position-list-types'

export const vesselSpecsOptions = [
  { label: 'All', value: VesselSpecsEnum.ALL },
  { label: 'Stern Line', value: VesselSpecsEnum.STERN_LINE },
  { label: 'BWTS', value: VesselSpecsEnum.BWTS },
  { label: 'Ice Class', value: VesselSpecsEnum.ICE_CLASS },
  { label: 'IGS', value: VesselSpecsEnum.IGS },
  { label: 'IMO2/3', value: VesselSpecsEnum.IMO_CLASS },
  { label: 'Scrubber', value: VesselSpecsEnum.SCRUBBER },
]

export const cargoGroupOptions = [
  { label: 'All', value: CargoGroupEnum.ALL },
  { label: 'CPP', value: CargoGroupEnum.CPP },
  { label: 'DPP', value: CargoGroupEnum.DPP },
]

export const worldAreaOptions = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Level 1',
    canToggle: true,
    options: [
      {
        label: 'Americas',
        value: 'level1.Americas',
      },
      { label: 'East of Suez', value: 'level1.East of Suez' },
      { label: 'Europe', value: 'level1.Europe' },
    ],
  },
  {
    label: 'Level 2',
    canToggle: true,
    options: [
      { label: 'Americas', options: [], canToggle: false },
      {
        label: 'Atlantic Basin',
        value: 'level2.Atlantic Basin',
      },
      {
        label: 'Pacific Basin',
        value: 'level2.Pacific Basin',
      },
      { label: 'East of Suez', options: [], canToggle: false },

      { label: 'AG', value: 'level2.AG' },
      { label: 'Asia', value: 'level2.ASIA' },
      { label: 'Europe', options: [], canToggle: false },
      {
        label: 'MED',
        value: 'level2.MED',
      },
      {
        label: 'NWE',
        value: 'level2.NWE',
      },
      { label: 'WAF', value: 'level2.WAF' },
    ],
  },
  {
    label: 'Level 3',
    canToggle: true,
    options: [
      { label: 'Americas / Atlantic Basin', options: [], canToggle: false },
      { label: 'EC North America', value: 'level3.EC North America' },
      { label: 'EC SAM', value: 'level3.EC SAM' },
      { label: 'USG-Caribs', value: 'level3.USG-Caribs' },
      { label: 'Americas / Pacific Basin', options: [], canToggle: false },
      { label: 'WC Latin America', value: 'level3.WC Latin America' },
      { label: 'East of Suez / AG', options: [], canToggle: false },
      { label: 'East & South Africa', value: 'level3.East- & South Africa' },
      { label: 'Middle East', value: 'level3.Middle East' },
      { label: 'Red Sea', value: 'level3.Red Sea' },
      { label: 'WC India', value: 'level3.WC India' },
      { label: 'East of Suez / Asia', options: [], canToggle: false },
      { label: 'EC Australia', value: 'level3.EC Australia' },
      { label: 'EC India', value: 'level3.EC India' },
      { label: 'N Asia', value: 'level3.N Asia' },
      { label: 'SE Asia', value: 'level3.SE Asia' },
      { label: 'USWC', value: 'level3.USWC' },
      { label: 'WC Australia', value: 'level3.WC Australia' },
      { label: 'Europe / MED', options: [], canToggle: false },
      { label: 'Black Sea', value: 'level3.Black Sea' },
      { label: 'Center Med', value: 'level3.Center Med' },
      { label: 'East Med', value: 'level3.East Med' },
      { label: 'West Med', value: 'level3.West Med' },
      { label: 'Europe / NWE', options: [], canToggle: false },
      { label: 'Atlantic', value: 'level3.Atlantic' },
      { label: 'Baltic', value: 'level3.Baltic' },
      { label: 'UKC', value: 'level3.UKC' },
      { label: 'Europe / WAF', options: [], canToggle: false },
      { label: 'WAF', value: 'level3.WAF' },
    ],
  },
]
