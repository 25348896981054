import { FC, ReactElement, ReactNode } from 'react'
import ReactTooltip, { TooltipProps } from 'react-tooltip'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

export const StyledTooltip = styled.div``

export const StyledTooltipTrigger = styled.div``

export const StyledTooltipBox = styled(ReactTooltip)<TooltipProps>`
  max-width: 500px;

  &.place-top,
  &.place-bottom,
  &.place-right,
  &.place-left {
    opacity: 1 !important;
    padding: 0;
  }
`
export interface ITooltip {
  backgroundColor?: string
  borderColor?: string
  content: ReactNode
  effect?: 'solid' | 'float'
  id: string
  multiline?: boolean
  offset?: string
  place?: 'top' | 'bottom' | 'right' | 'left'
  showDelay?: number
  trigger: ReactElement
  triggerHeight?: string
  triggerWidth?: string
}

export const Tooltip: FC<ITooltip> = ({
  content,
  trigger,
  backgroundColor = '#ffffff',
  borderColor = COLORS.greys.mid,
  effect = 'solid',
  id,
  multiline,
  place = 'top',
  offset,
  showDelay,
}) => {
  return (
    <StyledTooltip>
      <StyledTooltipTrigger data-offset={offset} data-tip data-for={id}>
        {trigger}
      </StyledTooltipTrigger>

      <StyledTooltipBox
        id={id}
        effect={effect}
        place={place}
        multiline={multiline}
        border
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        delayShow={showDelay}
      >
        {content}
      </StyledTooltipBox>
    </StyledTooltip>
  )
}
