import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { apiUpdateAction } from '~api/actions'

export interface ITabParameter {
  comment?: string
  q88data: string
  value?: string
}

export type ITabUpdateResponse = {
  fields: ITabParameter[]
  id: string
  name: string
}

export interface ITabFieldsPayload {
  comment?: string
  id: string
  value?: string
}

export type ITabUpdatePayload = {
  fields: ITabFieldsPayload[]
  id?: string
  name?: string
}

export function vesselTabsUpdateAction(
  payload: ITabUpdatePayload,
  urlParams?: Params,
): Promise<ITabUpdateResponse> {
  return apiUpdateAction<ITabUpdatePayload, ITabUpdateResponse>(
    ApiRoutes.Vessels.Tabs.update,
    payload,
    urlParams,
  )
}
