import { uniqBy } from 'lodash'
import { SWRResponse } from 'swr'

import { IError } from '~api/types'
import { IDocumentsRowData } from '~api/vessels/vessel-tabs/documents/all'
import { TableStatus } from '~components/organisms/table/table-component-renderer'
import {
  DataTransformerConfigTagFilterConfig,
  TableDataTransformerTable,
} from '~components/organisms/table/table-data-transformer/table-data-transformer.types'

export function getAllTagFilters(
  tagFilterConfig:
    | DataTransformerConfigTagFilterConfig<IDocumentsRowData>
    | undefined,
  apiData: SWRResponse<IDocumentsRowData[], IError>,
  isDataLoaded: boolean,
) {
  if (!tagFilterConfig) {
    return []
  }

  const tagFilterData = apiData.data?.flat().filter(
    (data) =>
      // @ts-ignore
      data[tagFilterConfig.filterKey] && data[tagFilterConfig.nameKey],
  )

  if (isDataLoaded && tagFilterData?.length === 0) {
    console.log(
      `MainTableWrapper: Can't create tag filters, no ${tagFilterConfig.filterKey} and ${tagFilterConfig.nameKey} found.`,
    )

    return []
  }

  return uniqBy(
    tagFilterData!.map((data) => ({
      id: data[tagFilterConfig.filterKey as keyof IDocumentsRowData],
      name: data[tagFilterConfig.nameKey as keyof IDocumentsRowData],
    })),
    (data) => data.id,
  )
}

export function getTagFilteredTables(
  tagFilterConfig:
    | DataTransformerConfigTagFilterConfig<IDocumentsRowData>
    | undefined,
  apiData: SWRResponse<IDocumentsRowData[], IError>,
  tagFilters: string[],
): any[] {
  if (!apiData.data) {
    return []
  }

  if (tagFilters.length === 0 || !tagFilterConfig) {
    return apiData.data
  }

  if (tagFilterConfig.dataFilterer) {
    return tagFilterConfig.dataFilterer(
      apiData.data,
      tagFilterConfig,
      tagFilters,
    )
  }

  return (apiData.data ?? [])?.filter((data) =>
    // @ts-ignore
    tagFilters.includes(data[tagFilterConfig.filterKey]),
  )
}

export function getMainTableWrapperStatus(
  apiData: SWRResponse<any, IError>,
  tableDataConfig: TableDataTransformerTable[],
  isDataLoaded: boolean,
  hasActiveFilters: boolean,
  hasFilteredTableResults: boolean,
) {
  if (apiData.error) {
    return TableStatus.ERROR
  }

  if (apiData.isValidating || apiData.isLoading) {
    return TableStatus.PREPARING
  }
  if (isDataLoaded && apiData.data && apiData.data.length === 0) {
    return TableStatus.NO_DATA
  }

  if (hasActiveFilters && !hasFilteredTableResults) {
    return TableStatus.NO_RESULTS
  }

  if (tableDataConfig.length > 0) {
    return TableStatus.DONE
  }

  return TableStatus.PREPARING
}
