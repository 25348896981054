import styled from 'styled-components/macro'

import { StyledBox } from '~components/atoms/box/styles'
import { StyledLink } from '~components/atoms/link'

export const StyledFavoritesToggler = styled.div`
  position: relative;
  height: 100%;
  user-select: none;

  ${StyledBox} {
    display: flex;
    height: 100%;

    ${StyledLink} {
      flex-grow: 1;
      align-items: flex-start;
      line-height: 1;
    }
  }
`
