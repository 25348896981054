import { Box, Divider, Typography } from '@mui/material'

export function SubHeaderText({
  vesselName,
  subHeaderText,
}: {
  vesselName?: string
  subHeaderText?: string
}) {
  if (!subHeaderText) return null

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        variant="h2"
        data-cy="vessel-dashboard-subpage-header"
        sx={{ mt: 3 }}
      >
        {`${subHeaderText} for ${vesselName || 'Unknown'}`}
      </Typography>
      <Divider sx={{ mt: 3, mb: 5 }} />
    </Box>
  )
}
