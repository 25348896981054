/*
 * Example usage
 *
 * ```typescript
 * export const Foo = styled.div`
 *   transition: transform ${durationFast} ${easeStandard};
 * `;
 * ```
 */

// These are the pre-defined eases that should be used in the project.
export const easeStandard = 'cubic-bezier(0.4, 0.0, 0.2, 1)'
export const easeAccelerate = 'cubic-bezier(0.4, 0.0, 1, 1)'
export const easeDecelerate = 'cubic-bezier(0.0, 0.0, 0.2, 1)'

// These are the pre-defined animation duration values that should be used in the project.
export const durationInstant = '0s'
export const durationFast = '0.1s'
export const durationModerate = '0.2s'
export const durationSlow = '0.4s'
