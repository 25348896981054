import { Alert } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { chunk } from 'lodash'
import ColorLabelShort from '~components/atoms/color-label-short'
import { Icon, IIconNames } from '~components/atoms/icon'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import {
  getActiveItineraryItem,
  getNextItineraryItem,
  getUpcomingItineraryItems,
} from '~components/molecules/voyage-tracker/voyage-tracker.utils'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import {
  PORT_FUNCTION_LABEL,
  PortFunction,
  Voyage,
  VoyageItinerary,
} from '~types/itinerary.types'
import { capitalizeStringWords } from '~utils/capitalize-string-words'
import { toDateTimeFormat } from '~utils/dates'
import useRefClientBounds from '../../../hooks/use-ref-client-bounds'
import { useRefMap } from '../../../hooks/use-ref-map'
import ErrorBoundary from '../../layout/error/error-boundary/error-boundary'
import ErrorDisplay from '../../layout/error/error-display/error-display'
import {
  StyledVoyageTracker,
  StyledVoyageTrackerInner,
  StyledVoyageTrackerItem,
  StyledVoyageTrackerItemInner,
  StyledVoyageTrackerRow,
  StyledVoyageTrackerTrack,
} from './voyage-tracker.styles'

type VoyageTrackerProps = {
  voyageData: Voyage
}

function VoyageTracker({ voyageData }: VoyageTrackerProps) {
  const [wrapperElBounds, setWrapperElBounds] = useRefClientBounds()
  const [itemBounds, setItemBounds] = useRefClientBounds()
  const [rowRefMap, setRowRefMap] = useRefMap()

  const itineraryData = voyageData.itineraries

  if (itineraryData.length === 0) {
    return (
      <Box sx={{ py: 3 }}>
        <Alert variant="info" text="Theres no voyage itinerary available." />
      </Box>
    )
  }

  const wrapperElWidth = wrapperElBounds.width

  const minItemWidth = 160
  const chunkSize = Math.floor(wrapperElWidth / minItemWidth)
  const templateDataChunks: Array<VoyageItinerary>[] = chunk(
    itineraryData,
    chunkSize,
  )
  const [firstRowItems] = templateDataChunks
  const pxItemWidth = itemBounds?.width
  const pctItemWidth = 100 / firstRowItems?.length || 0

  const activeItem = getActiveItineraryItem(itineraryData)
  const nextItineraryItem = getNextItineraryItem(itineraryData)
  const upcomingItineraryItems = getUpcomingItineraryItems(itineraryData)

  // Used for adding extra padding to the row holding the ship icon
  const iconHoldingRowIndex = templateDataChunks.findIndex((row) =>
    row.find((item) => item === activeItem || item === nextItineraryItem),
  )

  const rowElems = Object.values(rowRefMap.current).filter(Boolean)

  // The items connected by a vertical track inbetween rows
  const verticallyConnectedItems =
    rowElems.length > 1
      ? chunk(
          rowElems.flatMap((rowElem, rowIndex) => {
            const rowChildrenElems = Object.values(rowElem.children)

            return rowChildrenElems.filter((_, index) => {
              const isFirstRow = rowIndex === 0
              const isLastRow = rowIndex === rowElems.length - 1

              const isFirstItemInRow = index === 0
              const isLastItemInRow = index === rowChildrenElems.length - 1

              return (
                (!isFirstRow && isFirstItemInRow) ||
                (!isLastRow && isLastItemInRow)
              )
            })
          }),
          2,
        )
      : []

  const verticalDistancesBetweenRowItems = verticallyConnectedItems.map(
    (sumItems) =>
      sumItems.map((item, itemIndex) => {
        const { height, y } = item.getBoundingClientRect()

        // The calculation of the track height between rows =
        // a.y + a.height - b.y
        return itemIndex === 0 ? y + height : y
      }),
  )

  const verticalTrackGutter = 30
  // Calculate track height
  const voyageItemRowTrackHeightList = verticalDistancesBetweenRowItems.map(
    (rowYPositions) =>
      rowYPositions.reduce((a, b) => b - a - verticalTrackGutter),
  )

  return (
    <StyledVoyageTracker ref={setWrapperElBounds}>
      <ErrorBoundary
        errorComponent={<ErrorDisplay text="An error has occurred." />}
      >
        <StyledVoyageTrackerInner offsetRight={Math.floor(pxItemWidth)}>
          {templateDataChunks.map((row, rowIndex) => (
            <StyledVoyageTrackerRow
              key={`voyage-row-${rowIndex}`}
              ref={setRowRefMap(rowIndex)}
              hasItemWithIcon={iconHoldingRowIndex === rowIndex}
              pxItemWidth={pxItemWidth}
              hasNextItem={Boolean(nextItineraryItem)}
            >
              {row.map((item, itemIndex) => {
                const { portName, portFunction, arrivalLocal, departureLocal } =
                  item
                // The index of the total amount of items, not the index of the item in the row
                const index = chunkSize * rowIndex + itemIndex
                const isActive = item === activeItem
                const isNext = item === nextItineraryItem
                const isUpcoming = upcomingItineraryItems.indexOf(item) > -1
                const hasDashedTrack = isUpcoming || isActive
                const isFirstItem = index === 0

                const capitalizedPortName = capitalizeStringWords(portName)
                const capitalizedPortFunction =
                  capitalizeStringWords(portFunction)

                return (
                  <StyledVoyageTrackerItem
                    key={`voyage-item-${portName}-${index}`}
                    ref={setItemBounds}
                    pctItemWidth={pctItemWidth}
                    rowTrackHeight={voyageItemRowTrackHeightList[rowIndex]}
                    hasDashedTrack={hasDashedTrack}
                  >
                    {(isActive || isNext) && (
                      <StyledVoyageTrackerItemInner>
                        <Icon
                          name={
                            isNext
                              ? 'shipOther'
                              : // Raw PortFunction looks like this: LOADING/WAITING/CANAL TRANSIT
                                // `capitalizeStringWords` changes the strings to: Loading/Waiting/Canal Transit
                                // `.replace` removes the non-aplhanumeric vals, so we can use the string for icon name
                                (`ship${capitalizedPortFunction.replace(
                                  /\W/g,
                                  '',
                                )}` as IIconNames)
                          }
                          width={120}
                          height={70}
                        />
                      </StyledVoyageTrackerItemInner>
                    )}

                    <StyledVoyageTrackerTrack hasDashedTrack={hasDashedTrack}>
                      <ColorLabelShort
                        variant="portFunction"
                        variantType={capitalizedPortFunction}
                        label={
                          PORT_FUNCTION_LABEL[
                            capitalizedPortFunction as PortFunction
                          ]
                        }
                      />
                    </StyledVoyageTrackerTrack>

                    <StyledVoyageTrackerItemInner>
                      <Paragraph
                        size="medium"
                        weight="normal"
                        color={Colors.blues.base}
                      >
                        {capitalizedPortName}
                      </Paragraph>

                      {!isFirstItem && (
                        <Paragraph size="xSmall" color={Colors.greys.ultraDark}>
                          {`${isUpcoming ? 'ETA' : 'ATA'}: ${
                            arrivalLocal
                              ? toDateTimeFormat(arrivalLocal)
                              : 'Unknown'
                          }`}
                        </Paragraph>
                      )}

                      <Paragraph size="xSmall" color={Colors.greys.ultraDark}>
                        {`${isUpcoming ? 'ETS' : 'ATD'}: ${
                          departureLocal
                            ? toDateTimeFormat(departureLocal)
                            : 'Unknown'
                        }`}
                      </Paragraph>
                    </StyledVoyageTrackerItemInner>
                  </StyledVoyageTrackerItem>
                )
              })}
            </StyledVoyageTrackerRow>
          ))}
        </StyledVoyageTrackerInner>
      </ErrorBoundary>
    </StyledVoyageTracker>
  )
}

export default VoyageTracker
