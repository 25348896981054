export function capitalizeStringWords(string: string) {
  if (!string) return ''
  const romanNumerals = [
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
    'X',
    'XI',
    'XII',
    'XIII',
    'XIV',
    'XV',
    'XVI',
    'XVII',
    'XVIII',
    'XIX',
    'XX',
  ]
  return string
    .split(/([ ()])/) // Split by spaces and parentheses
    .map((splitVal) => {
      if (romanNumerals.includes(splitVal.toUpperCase())) {
        return splitVal.toUpperCase()
      } else {
        return `${splitVal.charAt(0).toUpperCase()}${splitVal
          .slice(1)
          .toLowerCase()}`
      }
    })
    .join('')
}
