import { ReactElement } from 'react'
import registerToast from '~components/templates/toasts/toast-register'

export const copyToClipboard = (
  content: string,
  toastMessage?: string | ReactElement,
): void => {
  const listener = (e: ClipboardEvent) => {
    if (e.clipboardData) {
      e.clipboardData.setData('text/html', content)
      e.clipboardData.setData('text/plain', content)
    }

    if (toastMessage) {
      registerToast({
        subject: toastMessage,
        type: 'success',
      })
    }

    e.preventDefault()
  }
  document.addEventListener('copy', listener)
  document.execCommand('copy')
  document.removeEventListener('copy', listener)
}
