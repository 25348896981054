import { useAuth0 } from '@auth0/auth0-react'

export function useFetch<T = unknown>() {
  const { getAccessTokenSilently } = useAuth0()

  async function fetchRequest(
    requestUrl: string,
    options?: RequestInit,
    payload?: unknown,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
    formData?: FormData,
  ): Promise<T> {
    const token = await getAccessTokenSilently()

    const headers: HeadersInit = {
      Authorization: `Bearer ${token}`,
    }

    if (!formData) {
      headers['Content-Type'] = 'application/json'
    }

    const response = await fetch(requestUrl, {
      method,
      headers,
      ...options,
      body: formData || JSON.stringify(payload),
    })
    if (!response.ok) {
      throw new Error('something went wrong')
    }
    const contentType = response.headers.get('content-type')
    if (contentType?.includes('application/json')) {
      return response.json()
    } else {
      return response.text() as unknown as T
    }
  }

  return fetchRequest
}
