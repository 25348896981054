import { cloneElement, FC, ReactElement } from 'react'
import Popup from 'reactjs-popup'
import { PopupProps } from 'reactjs-popup/dist/types'

import { ModalToolbar } from '~components/molecules/modal-toolbar/modal-toolbar'

export type IModal = Partial<PopupProps> & {
  content: ReactElement
  handleAfterClose?: () => void
  initialIsOpen?: boolean
  title?: string
  unsetSize?: boolean
}

export const Modal: FC<IModal> = ({
  title,
  trigger,
  position,
  content,
  initialIsOpen,
  unsetSize,
  handleAfterClose,
}) => {
  const contentStyle = unsetSize
    ? { width: 'unset' }
    : { width: '90vw', height: '90vh' }

  const overlayStyle = {
    background: 'hsla(201, 100%, 19%, 0.75)',
    zIndex: 9999,
  }
  return (
    <Popup
      trigger={trigger}
      position={position || 'center center'}
      modal
      open={initialIsOpen}
      lockScroll={false}
      closeOnDocumentClick={false}
      closeOnEscape
      onClose={handleAfterClose}
      {...{ contentStyle, overlayStyle }}
    >
      {/* @ts-ignore */}
      {(handleClose: () => never) => {
        return (
          <div className="popup-content-root">
            {title && (
              <ModalToolbar
                title={title}
                close={() => {
                  handleClose()
                }}
              />
            )}
            {cloneElement(content, { close: handleClose })}
          </div>
        )
      }}
    </Popup>
  )
}
