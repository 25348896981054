export enum Z_INDEX {
  Background = 1,
  VesselNav,
  InputList,
  PageHeader,
  MainNav,
  MainNavUserMenu,
  Modal = 9999,
  Popup,
}
