import { FC } from 'react'

import { iconsMap } from '~assets/svgs'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export type IIconNames = keyof typeof iconsMap

export interface IIcon {
  className?: string
  fill?: string
  height?: number
  name: IIconNames
  onClick?: (e: MouseEvent) => void
  size?: number
  width?: number
}

export const Icon: FC<IIcon> = ({
  fill,
  name,
  size,
  height = 16,
  width = 16,
  className,
  onClick,
}) => {
  const Svg = iconsMap[name]

  return (
    <span
      className={className}
      onClick={(e) => {
        if (onClick) return onClick(e as unknown as MouseEvent)

        e.preventDefault()
      }}
    >
      <Svg
        fill={fill || Colors.body}
        width={size || width}
        height={size || height}
        className={className}
      />
    </span>
  )
}
