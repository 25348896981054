// src/mocks/handlers.js
import { HttpResponse, delay, graphql, http } from 'msw'
import { apiBase, apiBaseV1, apiBaseV2 } from '../utils/base-url'
import { mockComments } from './mock-data/comments'
import { dashboardSummaryMockData } from './mock-data/dashboard/summary'
import { mockExposure, mockTotalExposure } from './mock-data/exposure'
import { forecastComments } from './mock-data/forecast-comments'
import { poolPartnerList } from './mock-data/list/pool-partner-list'
import { vesselsList } from './mock-data/list/vessels-list'
import { mockMarket } from './mock-data/market'
import { dummyUserId } from './mock-data/me/me-id'
import { dummyUserVt } from './mock-data/me/me-vt'
import { poolsMock } from './mock-data/pools'
import { reportTypeMock, reportWithIdMock } from './mock-data/reports'
import { dummyVessel } from './mock-data/vessels'
import {
  mockEarningsMonthly,
  mockEarningsQuarterly,
  mockEarningsYearly,
} from './mock-data/vessels-earnings'
import { mockGeneralInfo } from './mock-data/vessels/general-info'
import { patchVesselsStatusResMock } from './mock-data/vessels/patch-vessels-res'
import { postVesselsResMock } from './mock-data/vessels/post-vessels-res'

export const handlers = [
  http.get(`${apiBaseV2()}/vessels/:vessel/general-info`, () => {
    return HttpResponse.json(mockGeneralInfo, { status: 200 })
  }),
  http.get(`${apiBaseV2()}/reports`, ({ request }) => {
    const url = new URL(request.url)
    const type = url.searchParams.get('type')
    const reportId = url.searchParams.get('reportId')
    const includeEmbedToken = url.searchParams.get('includeEmbedToken')

    if (type === 'euets-report') {
      return HttpResponse.json(reportTypeMock, { status: 200 })
    }

    if (reportId && includeEmbedToken === 'true') {
      return HttpResponse.json(reportWithIdMock, { status: 200 })
    }
  }),
  http.get(`${apiBaseV2()}/me`, () => {
    return HttpResponse.json(dummyUserId, { status: 200 })
  }),
  http.get(`${apiBase()}/me`, () => {
    return HttpResponse.json(dummyUserVt, { status: 200 })
  }),
  http.get(`${apiBase()}/vessels`, () => {
    return HttpResponse.json([dummyVessel], { status: 200 })
  }),
  http.get(`${apiBase()}/posts`, () => {
    return HttpResponse.json(forecastComments, { status: 200 })
  }),
  http.get(`${apiBaseV2()}/pools`, () => {
    return HttpResponse.json(poolsMock, { status: 200 })
  }),
  // http.get('https://maersktankers-stage.digital/api/marketing/signups', () => {
  //   return HttpResponse.json(testResponse, { status: 200 })
  // }),
  http.get(`${apiBaseV2()}/dashboard/summary`, () => {
    return HttpResponse.json(dashboardSummaryMockData, { status: 200 })
  }),
  http.get(`${apiBaseV2()}/pools/:poolId/comments`, () => {
    return HttpResponse.json(mockComments, { status: 200 })
  }),
  http.get(`${apiBaseV1()}/pool/:poolId/market`, () => {
    return HttpResponse.json(mockMarket, { status: 200 })
  }),
  // http.get(`${apiBaseV2()}/forecast/calculated?poolId=:poolId`, () => {
  //   return HttpResponse.json(mockForecast, { status: 200 })
  // }),
  http.get(
    `${apiBaseV2()}/pools/:poolId/vessels-earnings`,
    async ({ request }) => {
      const url = new URL(request.url)
      const aggregate = url.searchParams.get('aggregate')
      if (aggregate === 'quarterly') {
        return HttpResponse.json(mockEarningsQuarterly, { status: 200 })
      }
      if (aggregate === 'yearly') {
        return HttpResponse.json(mockEarningsYearly, { status: 200 })
      }
      await delay(2000)
      return HttpResponse.json(mockEarningsMonthly, { status: 200 })
    },
  ),
  graphql.query('getMTOpenVessels', () => {
    return HttpResponse.json(mockExposure)
  }),
  graphql.query('GetMTOpenVessels2', () => {
    return HttpResponse.json(mockTotalExposure)
  }),
  http.post(`${apiBase()}/vessels`, () => {
    return HttpResponse.json(postVesselsResMock, { status: 200 })
  }),
  http.patch(`${apiBase()}/vessels/:vesselId/status`, () => {
    return HttpResponse.json(patchVesselsStatusResMock, { status: 200 })
  }),
  http.get(`${apiBase()}/q88/list`, () => {
    return HttpResponse.json(vesselsList, { status: 200 })
  }),
  http.get(`${apiBase()}/poolpartners/list`, () => {
    return HttpResponse.json(poolPartnerList, { status: 200 })
  }),
]
