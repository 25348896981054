import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { StyledDatePicker } from '~components/molecules/modal/modal.styles'
import { assembleValidations } from '~utils/form-validations'

interface Props {
  className?: string
  isDisabled?: boolean
  label: string
  name: string
  required?: boolean
  selected?: Date
  validations?: RegisterOptions
}

function DatePickerField({
  name,
  validations,
  label,
  selected,
  required,
  isDisabled,
  className,
}: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={selected}
      rules={assembleValidations(required, validations)}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <StyledDatePicker
          onChange={onChange}
          onBlur={onBlur}
          selected={value}
          className={className}
          name={name}
          disabled={isDisabled}
          required={required}
          ref={ref}
          label={label}
          error={errors[name] ? (errors[name]?.message as string) : undefined}
        />
      )}
    />
  )
}

export default DatePickerField
