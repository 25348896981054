import { toast } from 'react-toastify'

import { ToastProps, ToastTemplate } from './toast-template'

interface IToastRegistration extends ToastProps {
  closeTimeout?: number
}

const registerToast = ({
  type,
  content,
  subject,
  closeTimeout = 1750,
}: IToastRegistration): void => {
  toast[type](
    <ToastTemplate content={content} subject={subject} type={type} />,
    {
      autoClose: closeTimeout,
    },
  )
}

export default registerToast
