import { Sire } from '~components/organisms/dashboard/dashboard.types'
import { FALLBACK_STRING_DASH } from '~constants/global-strings'
import { ITableRowData } from '~hooks/queries/vessels/data/types/tabs-read'
import { VesselDashboardTab } from '~hooks/queries/vessels/data/types/vessel-dashboard'

export function getUpcomingCdiDate(cdis: ITableRowData[] | undefined) {
  if (!cdis || cdis.length === 0) return

  return cdis.find((cdi) => !cdi.inspectionCompleted)?.inspectionDate
}

type CommonType = {
  productCodes: string
  cargoGrades: string
}

export function getLastThreeVoyages<T extends CommonType>(
  voyages: T[] | undefined,
): T[] | undefined {
  if (!voyages || voyages.length === 0) return

  return voyages
    .filter(
      ({ productCodes, cargoGrades }) =>
        productCodes.length > 0 || cargoGrades.length > 0,
    )
    .slice(0, 3)
}

export function getLastSire(sires: Sire[] | undefined) {
  if (!sires || sires.length === 0) return

  return sires.find((sire) => sire.inspectionCompleted)
}

export function getUpcomingSireDate(sires: Sire[] | undefined) {
  if (!sires || sires.length === 0) return

  return sires?.find((sire) => !sire.inspectionCompleted)?.inspectionDate
}

export function evaluateData(data: string[] | undefined | null): string {
  if (!data || data.length === 0) return FALLBACK_STRING_DASH
  if (data.some((tank) => tank === null)) return FALLBACK_STRING_DASH
  if (data.every((tank) => tank.toLowerCase() === 'yes')) return 'Yes'

  return 'No'
}
