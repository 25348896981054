import { Params } from 'react-router-dom'

export function generatePathName(url: string, params?: Params): string {
  let newUrl = url

  // Replace path parameters
  for (const key in params) {
    const value = params[key]
    if (value !== undefined) {
      newUrl = newUrl.replace(`:${key}`, value)
    }
  }

  // Replace query parameters
  for (const key in params) {
    const value = params[key]
    if (value !== undefined) {
      newUrl = newUrl.replace(`${key}=:${key}`, `${key}=${value}`)
    }
  }

  return removeTrailingSlashStar(newUrl)
}

function removeTrailingSlashStar(string: string) {
  return string.endsWith('/*') ? string.slice(0, -2) : string
}
