import { useEffect, useRef } from 'react'

declare type ResizeObserverCallback = (
  entries: Array<any>,
  observer: ResizeObserver,
) => void

declare class ResizeObserver {
  public constructor(callback: ResizeObserverCallback)
  public observe(target: Element, options?: any): void
  public disconnect(): void
}

const useResizeObserver = (
  element: HTMLElement | null,
  callback: ResizeObserverCallback,
) => {
  const resizeObserver = useRef<ResizeObserver | null>(null)

  // @ts-ignore not all code paths return a value
  useEffect(() => {
    if (element) {
      ;(async () => {
        resizeObserver.current = await createResizeObserver(callback)
        resizeObserver.current!.observe(element)
      })()

      return () => {
        if (resizeObserver.current) {
          resizeObserver.current.disconnect()
          resizeObserver.current = null
        }
      }
    }
  }, [element]) // eslint-disable-line react-hooks/exhaustive-deps
}

const createResizeObserver = async (callback: ResizeObserverCallback) => {
  if ('ResizeObserver' in window) {
    return new ResizeObserver(callback)
  }

  const module = await import('@juggle/resize-observer')
  return new module.ResizeObserver(callback)
}

export default useResizeObserver
