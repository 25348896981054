import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from 'src/constants/constants'
import { ApiConfig } from '~api/config'
import { ApiRoutes } from '~api/routes'
import { Voyage } from '~types/itinerary.types'
import { useFetch } from '~utils/fetch-request'

function getVoyagesUrl(vesselId: string) {
  return ApiRoutes.Voyages.all_ten.replace(':vesselId', vesselId)
}

export function useGetAllTenVoyages(vesselId: string | undefined) {
  const fetchRequest = useFetch<Voyage[]>()
  const url = !vesselId ? null : getVoyagesUrl(vesselId)
  return useQuery({
    queryKey: [url],
    queryFn: () => fetchRequest(`${ApiConfig.domain}${url}`),
    staleTime: tenMinutes,
    enabled: !!vesselId,
  })
}
