import * as React from 'react'
import { FC } from 'react'
import styled from 'styled-components/macro'
import { Box } from '~components/atoms/box'
import { Icon } from '~components/atoms/icon'
import { Text } from '~components/atoms/text'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

type ToastType = 'success' | 'error' | 'info'

export interface ToastProps {
  content?: string | React.ReactElement
  subject?: string | React.ReactElement
  type: ToastType
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const mapIcon = (type?: ToastType) => {
  switch (type) {
    case 'success':
      return 'largeCheckmark'
    case 'error':
      return 'warning'
    default:
      return 'confirm'
  }
}

const getIconColor = (type?: ToastType) => {
  switch (type) {
    case 'success':
      return Colors.blues.maersk
    case 'error':
      return Colors.reds.base
    default:
      return Colors.greens.lighter
  }
}

const InfoTemplateContent: FC<{
  innerContent?: React.ReactElement | string
  subject?: React.ReactElement | string
}> = ({ innerContent, subject }) => {
  return (
    <Container>
      <Box mt={2} mb={4}>
        {typeof subject === 'string' ? (
          <Text text={subject ?? ''} size="h6" headline />
        ) : (
          subject
        )}
      </Box>
      <Box mb={6}>{innerContent}</Box>
    </Container>
  )
}

export const ToastTemplate: FC<ToastProps> = ({
  content,
  subject,
  type = 'success',
}) => {
  let innerContent = content
  if (typeof content === 'string') {
    innerContent = <Text text={content ?? ''} size="h3" headline />
  }

  return type === 'info' ? (
    <InfoTemplateContent innerContent={innerContent} subject={subject} />
  ) : (
    <Container>
      {innerContent ? <Box mb={1}>{innerContent}</Box> : <Box mb={4.6} />}
      <Box mb={2}>
        <Icon
          name={mapIcon(type)}
          size={type === 'success' ? 90 : 50}
          fill={getIconColor(type)}
        />
      </Box>
      {typeof subject === 'string' ? (
        <Box dataTest="string-headline-pop-up">
          <Text text={subject ?? ''} size="h6" headline />
        </Box>
      ) : (
        subject
      )}
    </Container>
  )
}
