import { gtm } from '~utils/gtm'

import { statusOptions } from '../position-list-page/position-list-table/status-select/status-select'

export function trackNewStatusSelected(value: string) {
  const flatStatusOptions = statusOptions.flatMap((group) => group.options)
  const statusItem = flatStatusOptions.find((option) => option.value === value)

  if (!statusItem) return

  gtm.pushEvent('internal_click', {
    click_type: 'New status selected',
    click_subtype: statusItem.label,
  })
}
