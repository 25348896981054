import { COLORS } from '~theme/colors'

export const Fonts = {
  // TODO: Add fallbacks here?
  families: {
    light: 'MaerskTextLight',
    regular: 'MaerskTextRegular',
    bold: 'MaerskTextBold',
    'headline-light': 'MaerskHeadlineLight',
    headline: 'MaerskHeadlineRegular',
    'headline-bold': 'MaerskHeadlineBold',
    headlines: {
      light: 'MaerskHeadlineLight',
      regular: 'MaerskHeadlineRegular',
      bold: 'MaerskHeadlineBold',
    },
  },
  sizes: {
    caption: '12px',
    small: '14px',
    body: '16px',
    large: '20px',
    xLarge: '26px',
    h6: '20px',
    h5: '26px',
    h4: '34px',
    h3: '40px',
    h2: '50px',
    h1: '60px',
  },
  lineHeights: {
    caption: '20px',
    small: '24px',
    body: '24px',
    h6: '26px',
    h5: '32px',
    h4: '38px',
    h3: '46px',
    h2: '56px',
    h1: '66px',
  },
}

export const Colors = {
  black: COLORS.secondary.darkBlue,
  blues: {
    base: COLORS.secondary.darkBlue,
    dark: COLORS.secondary.darkBlue,
    deep: COLORS.secondary.darkBlue,
    mid: COLORS.secondary.midBlue,
    maersk: COLORS.primary.maerskBlue,
    light: COLORS.secondary.lightBlue,
    secondaryLight: COLORS.secondary.blueTint,
    lighter: '#e9f6fc',
    tableSubsection: '#b5e0f433',
    tableExpanded: '#b5e0f41a',
    secondaryDark: COLORS.greys.ultraDark,
  },
  body: COLORS.secondary.darkBlue,
  greys: {
    darker: COLORS.greys.ultraDark,
    dark: COLORS.greys.dark,
    table: COLORS.greys.light,
    base: COLORS.greys.mid,
    light: '#d5d5d5',
    lighter: COLORS.greys.light,
    lightest: COLORS.greys.ultraLight,
    ultraLight: '#f7f8f980',
    ultraDark: COLORS.greys.ultraDark,
    sidebar: '#fafafa',
  },
  greens: {
    lighter: COLORS.citrus.mid,
    light: '#8ea532',
    base: COLORS.citrus.dark,
    teal: COLORS.green.mid,
  },
  oranges: {
    light: '#FBE7C3',
    base: COLORS.orange.light,
    dark: COLORS.orange.mid,
    brick: '#e9735d',
  },
  reds: {
    base: COLORS.red.mid,
    error: '#b80012',
    light: COLORS.red.tint3,
  },
  yellows: {
    base: COLORS.citrus.light,
    light: '#dce5bc',
    lighter: '#f8faf2',
  },
  white: COLORS.primary.white,
  tints: {
    black10: 'rgba(0, 0, 0, 0.1)',
    black16: 'rgba(0, 0, 0, 0.16)',
  },
  transparent: 'transparent',
}

export const Shadows = {
  light: `0px 1px 6px ${Colors.tints.black10}`,
  normal: `0px 1px 2px ${Colors.tints.black16}`,
}
