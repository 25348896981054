import { FC, ReactNode } from 'react'

import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'

interface IPartnerGuard {
  alternateContent?: ReactNode
  children?: ReactNode
}

// Partner Manager allowed only
export const PartnerManagerGuard: FC<IPartnerGuard> = ({
  children,
  alternateContent,
}) => {
  const isPartnerManager = useHasScope(ExactScopeName.accountManager)
  return <>{isPartnerManager ? children : alternateContent}</>
}
