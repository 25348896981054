import {
  AutoCompleteInputChunkConfig,
  AutoCompleteInputOptions,
  AutoCompleteInputOptionValue,
} from '~components/molecules/auto-complete-input/auto-complete.types'

export const getFilteredAutoCompleteOptions = (
  options: AutoCompleteInputOptions[],
  inputValue: string,
  maxLength?: number,
) =>
  // @ts-ignore
  options.reduce(
    // @ts-ignore
    (
      prevValue: AutoCompleteInputOptions[],
      optionConfig: AutoCompleteInputOptions,
    ) => {
      const inputValueSplitBySpace = inputValue
        .split(/\s/)
        .filter((str) => str.length > 0)

      if (inputValueSplitBySpace.length === 0) return

      const chunkConfigList = optionConfig.searchableItems.reduce(
        (chunkConfigList, searchableItem, valueIndex) => {
          if (searchableItem.isHidden) return chunkConfigList

          const { value } = searchableItem

          if (!value) {
            return chunkConfigList
          }

          // Make sure the loop doesn't break when a special character is used
          const escapedSplitInputValue = inputValueSplitBySpace.flatMap(
            (inputValue) =>
              inputValue.replaceAll(/[.*+?^${}()|[\]\\]/g, '\\$&'),
          )

          const splitMatchWithDelimiterRegex = new RegExp(
            escapedSplitInputValue
              .map((inputValue) => `(${inputValue})`)
              .join('|'),
            'gi',
          )

          const splitMatchesWithDelimiter = value
            .split(splitMatchWithDelimiterRegex)
            .filter((val) => val && val.length > 0)

          const MATCH_REPLACEMENT = '{replace}'

          // Use {replace} to find matches
          const splitMatchesWithReplacedDelimiter = value
            .split(splitMatchWithDelimiterRegex)
            .filter((val) => val && val.length > 0)
            .map((value) =>
              value.replaceAll(splitMatchWithDelimiterRegex, MATCH_REPLACEMENT),
            )

          const hasMatch = splitMatchesWithReplacedDelimiter.some(
            (value) => value === MATCH_REPLACEMENT,
          )

          // We need to stop the loop when no match has been found
          if (!hasMatch) {
            return chunkConfigList
          }

          // Assign chunkConfigList to index of the value it belongs to
          chunkConfigList[valueIndex] = splitMatchesWithDelimiter.map(
            (splitMatchValue, index) => ({
              value: splitMatchValue,
              fontWeight:
                splitMatchesWithReplacedDelimiter[index] === MATCH_REPLACEMENT
                  ? 'bold'
                  : 'normal',
            }),
          )

          return chunkConfigList as AutoCompleteInputChunkConfig[][]
        },
        [] as AutoCompleteInputChunkConfig[][],
      )

      const hasMatches = chunkConfigList.some(
        (chunk) => chunk && chunk.length > 0,
      )

      if (hasMatches) {
        if (maxLength && prevValue.length > maxLength) return prevValue

        prevValue.push({
          ...optionConfig,
          searchableItems: optionConfig.searchableItems.map((value, index) => ({
            ...value,
            chunkConfigList: chunkConfigList[index],
          })),
        })

        // Sort by most matches
        // eslint-disable-next-line no-param-reassign
        prevValue = prevValue.sort((a, b) => {
          const getMatchLength = (val: AutoCompleteInputOptionValue[]) =>
            val
              .filter(
                (config) =>
                  config.chunkConfigList && config.chunkConfigList.length > 0,
              )
              .flatMap(({ chunkConfigList }) =>
                chunkConfigList!
                  .filter(({ fontWeight }) => fontWeight === 'bold')
                  .map(({ value }) => value),
              )
              .join('').length

          const matchLengthA = getMatchLength(a.searchableItems)
          const matchLengthB = getMatchLength(b.searchableItems)

          return matchLengthB - matchLengthA
        })
      }

      return prevValue
    },
    [],
  )
