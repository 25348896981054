import { useAuth0 } from '@auth0/auth0-react'
import { Alert } from '@maersktankersdigital/web-components'
import { useEffect, useState } from 'react'
import { localStorageItemKeys } from 'src/constants/local-storage-items'
import LoadComponent from '~components/molecules/load-component/load-component'
import { useGetMeID } from '~hooks/queries/me/use-get-me-id'
import { useGetMeVT } from '~hooks/queries/me/use-get-me-vt'
import { useGetUsers } from '~hooks/queries/users/use-get-users'
import { setLocalStorageItem } from './utils/set-local-storage-item'

export function HelperForIDv1({ children }: { children: any }) {
  // The insite digital v1 stores user data and access token in local storage (local storage key - 'persist:root')
  // If the data is present in local storage, the user is treated as logged in
  // To ensure that the user is treated as loged in on insite digital v1, despite logging in on portal, the data is stored in local storage
  // This is a temporary solution until the insite digital v1 is decommissioned

  // the useGetMeID and useGetUsers will return an error if the user does not have access to the insite digital
  // if that is the case, the error msg should not be rendered
  const { data: meID, isLoading: isLoadingCreateUser } = useGetMeID()
  const { data: users, isLoading: isLoadingUsers } = useGetUsers(!!meID)

  const {
    data: meVT,
    isLoading: isLoadingMe,
    isError: isErrorMe,
  } = useGetMeVT()

  const { getAccessTokenSilently } = useAuth0()
  const [waitingForLsItemToBeSet, setWaitingForLsItemToBeSet] = useState(true)

  const isLoading = isLoadingCreateUser || isLoadingUsers || isLoadingMe

  useEffect(() => {
    if (!meID) return
    localStorage.setItem(localStorageItemKeys.me, btoa(JSON.stringify(meVT)))
  }, [meID])

  useEffect(() => {
    if (isLoading) return
    if (!users || !meVT) {
      setWaitingForLsItemToBeSet(false)
      return
    }

    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently()
        setLocalStorageItem(token, users, meVT?.scope)
        setWaitingForLsItemToBeSet(false)
      } catch (e) {
        console.error(e)
      }
    }
    getAccessToken()
  }, [getAccessTokenSilently, users, meVT, isLoading])

  if (!waitingForLsItemToBeSet) {
    return <>{children}</>
  }

  if (isLoading || waitingForLsItemToBeSet) {
    return <LoadComponent />
  }

  if (isErrorMe) {
    return (
      <Alert
        variant="error"
        text="Oops! There was an error getting data about your user. Please reload the page or try again later."
      />
    )
  }

  return <>{children}</>
}
