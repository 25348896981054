import styled from 'styled-components/macro'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export const StyledHtmlMarkup = styled.span`
  color: ${Colors.body};

  a {
    color: ${Colors.blues.mid};
  }
`
