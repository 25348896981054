import { ApiRoutes } from '~api'
import { IFileBox } from '~components/molecules/file-box/file-box'

import { apiGetAction } from '../actions'

type IQuestionTypes =
  | 'date'
  | 'downl_upl'
  | 'downl'
  | 'file_list'
  | 'file'
  | 'free_text'
  | 'multiple_choice'
  | 'single_choice'
  | 'upl_com'
  | 'number'

interface IFileMetadata {
  id: string
  imo: string
  user: string
  vesselId: string
}

export interface IVesselFile {
  approvedAt?: string
  approvedBy?: string
  crc32c: string
  filename: string
  generation: string
  md5Hash: string
  metadata: IFileMetadata
  name: string
  status: IFileBox['status']
}

export interface IComment {
  authorName: string
  createdAt: string
  createdBy: string
  value: string | null
}

export interface IDownloadLink {
  _id: string
}

export type QuestionnaireFieldId = string
export type QuestionnaireFieldValue = string | null

export interface IQuestionnaireField {
  allowComments?: boolean
  allowFiles?: boolean
  allowNotApplicable?: boolean
  approvalRequired?: boolean
  approved?: string
  comments?: IComment[] | undefined
  complete: boolean
  dependsOn?: Pick<IQuestionnaireField, 'id' | 'value'>
  description: string
  details: string
  downloadLink: IDownloadLink
  files: IVesselFile[] | undefined
  id: string
  missing?: boolean
  name: string
  notApplicable?: boolean
  parentQuestionId: number | null
  possibleAnswers: string[]
  tooltip?: string
  type: IQuestionTypes
  value: string | null
  valueToShowSubFields: string
  waitingForApproval?: boolean
  readOnly?: boolean
}

export interface IQuestionnaireSection {
  canEdit: boolean
  deadline: string
  dependsOn?: Pick<IQuestionnaireField, 'id' | 'value'>
  fields: IQuestionnaireField[]
  fieldsApproved: number
  fieldsMissing: number
  fieldsWaitingForApproval: number
  id: string
  name: string
  progress: number
  urgent: boolean
}

export interface IQuestionnaireCategory {
  name: string
  questionnaireOverallProgress: number
  rolesForCategory?: string[]
  scope?: string[] // Migrating from rolesForCategory => scope.
  sections: IQuestionnaireSection[]
}

export interface IVesselManager {
  name: string
  sub: string
}

export type IQuestionnairesResponse = IQuestionnaireCategory[]

export interface IVesselsReadResponse {
  _id: number
  bunkerconsumption: {
    ladenTonsPerDay: number
    ladenTonsPerDayPoolAvg: number
    ladenTonsPerDayPoolRating?: 'low' | 'medium' | 'high'
  }
  createdAt: string
  euaInfo?: {
    euaAccountName?: string
    euaAccountNumber?: string
  }
  imoNumber: string
  onboardedDate: string
  opsCoordinatorName: string
  partnerManager: string
  partnerManagerUser: IVesselManager
  pool: string
  poolPartner: string
  progress: number
  questionnaire: IQuestionnairesResponse
  deliveryDate?: string
  redeliveryDate?: string
  status: string
  targetDate: string
  tcin?: {
    deliveryDateLocal?: string
    redeliveryDateLocal?: string
    status?: 'Delivered' | 'Redelivered' | 'Fixed'
  }[]
  track: string
  vesselId: string
  vesselName: string
  voyageManager: string
  voyageManagerUser: IVesselManager
}

export function QuestionnaireReadFieldsAction(
  filtersQuery = '',
): Promise<IQuestionnaireField> {
  return apiGetAction<IQuestionnaireField>(
    ApiRoutes.Vessels.Questionnaire.readFields + filtersQuery,
  )
}
