import { useParams } from 'react-router-dom'
import { useGetPools } from './queries/pools/use-get-pools'

export function usePoolId() {
  const { pool } = useParams()
  const { data: pools } = useGetPools()
  return pools?.find((item) => item.name.toLocaleLowerCase() === pool)?.id
}

export function usePoolName() {
  const { pool } = useParams()
  const { data: pools } = useGetPools()
  return pools?.find((item) => item.name.toLocaleLowerCase() === pool)?.name
}
