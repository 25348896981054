import styled, { css } from 'styled-components/macro'

import { NavButtonTypes } from '~components/atoms/tab/nav-button'
import { StyledParagraph } from '~components/atoms/typography/paragraph/paragraph.styles'

const BORDER_BOTTOM_WIDTH = 'calc(100% - 35px)'

export const StyledNavButtonBorderBottom = styled.span<{
  color: string
  isSelected: boolean
}>`
  content: '';
  position: absolute;
  background: ${(p) => p.color};
  // Added a negative margin-right to take into account the empty space around SVGs
  margin: 0 -4px 0 auto;
  width: ${(p) => (p.isSelected ? BORDER_BOTTOM_WIDTH : 0)};
  height: 2px;
  bottom: -2px;
  transition: 0.3s;
`

export const StyledNavButton = styled.div<{
  bgColor: string
  type: NavButtonTypes
}>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  justify-content: center;

  ${StyledParagraph} {
    white-space: nowrap;
    text-transform: uppercase;
  }

  svg {
    margin-right: 7px;
  }

  ${(p) =>
    p.type === 'with-background' &&
    css`
      background: ${p.bgColor};
    `}

  ${(p) =>
    p.type === 'with-border-bottom' &&
    css`
      &:hover {
        ${StyledNavButtonBorderBottom} {
          width: ${BORDER_BOTTOM_WIDTH};
          transition: 0.3s;
        }
      }
    `}
`
