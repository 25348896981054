import styled from 'styled-components/macro'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export const StyledModalTemplate = styled.div`
  display: flex;
  background-color: ${Colors.greys.sidebar};
  height: calc(90vh - 75px);
`

export const StyledModalContent = styled.div`
  width: 60%;
  overflow-y: auto;
  background-color: ${Colors.white};
  padding: 42px 48px;
`

export const StyledModalSidebar = styled.div`
  width: 40%;
  overflow-y: auto;
`
