import { getWeek } from 'date-fns'
import {
  ExposureAreaHeader,
  ExposureDivider,
  VesselsNumber,
} from '~pages/pages-behind-login/position-list/exposure-page/exposure-list/exposure-list.styles'
import {
  AreaTitle,
  AreaTitleWrapper,
  StyledTable,
  StyledTableHead,
  TableCell,
  TableHeaderCell,
  TableRow,
  TableRowHeader,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table.styles'
import { formatDate } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-content/position-list-table-content'
import { StyledRouterLink } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/vessel-name-content/vessel-name.styles'
import {
  OpenVessels,
  useExposureFilters,
} from '~pages/pages-behind-login/position-list/contexts/exposure-filter-provider'
import { OpenAreaEnum } from '~pages/pages-behind-login/position-list/types/exposure-types'
import { PageRoute } from '~routing/constants/page-route'
import { capitalizeStringWords } from '~utils/capitalize-string-words'

interface Props {
  activeSelection?: OpenVessels
}

const ETAPort = {
  [OpenAreaEnum.ALL]: '',
  [OpenAreaEnum.NWE]: 'AMS',
  [OpenAreaEnum.AG]: 'FUJ',
  [OpenAreaEnum.NORTH_ASIA]: 'YSU',
  [OpenAreaEnum.SOUTH_ASIA]: 'SGP',
  [OpenAreaEnum.MED]: 'MLT',
  [OpenAreaEnum.AMERICAS]: 'HOU',
}

function ExposureList({ activeSelection }: Props) {
  const { filters } = useExposureFilters()

  if (
    !activeSelection ||
    (!activeSelection.fromDate && !filters?.totalExposure)
  )
    return null

  const vessels = activeSelection.Maersk || activeSelection.vessels

  const exposureArea = activeSelection.exposureArea
  const title = filters?.totalExposure
    ? 'Total Exposure'
    : `WEEK ${getWeek(new Date(activeSelection?.fromDate || ''))}`

  return (
    <>
      <VesselsNumber>
        Vessels <span>{vessels?.length}</span>
      </VesselsNumber>
      <ExposureAreaHeader level="level1">
        <AreaTitleWrapper>
          <AreaTitle>{exposureArea}</AreaTitle>
        </AreaTitleWrapper>
        <AreaTitle>{title}</AreaTitle>
      </ExposureAreaHeader>
      <ExposureDivider />
      <StyledTable>
        <StyledTableHead>
          <TableRowHeader>
            <TableHeaderCell>Vessel</TableHeaderCell>
            <TableHeaderCell>Open Port</TableHeaderCell>
            <TableHeaderCell>Open Region</TableHeaderCell>
            <TableHeaderCell>Open Date</TableHeaderCell>
            <TableHeaderCell>Pool</TableHeaderCell>
            <TableHeaderCell>Cargo Grade</TableHeaderCell>
            <TableHeaderCell>{`ETA ${ETAPort[exposureArea as OpenAreaEnum]}`}</TableHeaderCell>
          </TableRowHeader>
        </StyledTableHead>
        <tbody>
          {vessels
            ?.sort(
              (a, b) =>
                new Date(a.localLoadingAreaETA).getTime() -
                new Date(b.localLoadingAreaETA).getTime(),
            )
            .map((vessel) => (
              <TableRow key={vessel.ID}>
                <TableCell>
                  <StyledRouterLink
                    to={PageRoute.VESSEL}
                    params={{ vesselId: vessel.vesselId }}
                    target="_blank"
                  >
                    {vessel.vesselName}
                  </StyledRouterLink>
                </TableCell>
                <TableCell>
                  {capitalizeStringWords(vessel?.openPort || '')}
                </TableCell>
                <TableCell>{vessel?.openRegion || ''}</TableCell>
                <TableCell>
                  {formatDate(vessel.localOpenDate, 'dd-MM')}
                </TableCell>
                <TableCell>{vessel.pool}</TableCell>
                <TableCell>
                  {vessel.cargoGrade ? vessel.cargoGrade : 'Missing'}
                </TableCell>
                <TableCell>
                  {formatDate(vessel.localLoadingAreaETA, 'dd-MM')}
                </TableCell>
              </TableRow>
            ))}
        </tbody>
      </StyledTable>
    </>
  )
}

export default ExposureList
