import { Status } from '@maersktankersdigital/web-components'
import { Box, Divider, Typography } from '@mui/material'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'

export function PoolPointsPageHeader() {
  const today = new Date()
  const dateString = `${today.toLocaleString('default', {
    month: 'long',
  })} ${today.toLocaleString('default', { year: 'numeric' })}`

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ display: 'flex', gap: 5, alignItems: 'baseline' }}>
        <Typography
          variant="h2"
          data-cy="vessel-dashboard-subpage-header"
          sx={{ mt: 3 }}
        >
          Pool Points
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Status variant="secondary" />
          <Paragraph weight="bold">{dateString}</Paragraph>
        </Box>
      </Box>
      <Divider sx={{ mt: 3, mb: 5 }} />
    </Box>
  )
}
