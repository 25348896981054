import type {
  Context,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
} from 'react'
import { createContext, useState } from 'react'

import {
  AutoCompleteInputOptions,
  AutoCompleteInputOptionValue,
  AutoCompleteResults,
} from '~components/molecules/auto-complete-input/auto-complete.types'
import createContextStateHook from '~utils/create-hook-for-context-state'

export type AutoCompleteInputState = {
  activeResult: AutoCompleteInputOptionValue[] | null
  inputValue: string | null
  isLoading: boolean
  isOpen: boolean
  options: AutoCompleteInputOptions[]
  reset: () => void
  results: AutoCompleteResults<string>
  resultsId: string | null
  setActiveResult: Dispatch<
    SetStateAction<AutoCompleteInputOptionValue[] | null>
  >
  setInputValue: Dispatch<SetStateAction<string | null>>
  setIsLoading: Dispatch<SetStateAction<boolean>>
  setIsOpen: Dispatch<SetStateAction<boolean>>
  setOptions: Dispatch<SetStateAction<AutoCompleteInputOptions[]>>
  setResults: Dispatch<SetStateAction<AutoCompleteResults<string>>>
  setResultsById: (id: string, results: AutoCompleteInputOptions[]) => void
  setResultsId: Dispatch<SetStateAction<string | null>>
  setSelectedResult: (index: number) => void
}

export const AutoCompleteInputContext =
  createContext<AutoCompleteInputState | null>(
    null,
  ) as Context<AutoCompleteInputState>

export default function AutoCompleteInputProvider({
  children,
}: PropsWithChildren) {
  const [options, setOptions] = useState<AutoCompleteInputOptions[]>([])
  const [results, setResults] = useState<AutoCompleteResults<string>>({})
  const [activeResult, setActiveResult] = useState<
    AutoCompleteInputOptionValue[] | null
  >(null)
  const [inputValue, setInputValue] = useState<string | null>(null)
  const [resultsId, setResultsId] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  function setSelectedResult(index: number) {
    if (!resultsId) return

    const newResults = results[resultsId].map((result) => ({
      ...result,
      isSelected: false,
    }))

    if (!newResults[index]) return

    newResults[index].isSelected = true

    setResultsById(resultsId, newResults)
  }

  function setResultsById(id: string, newResults: AutoCompleteInputOptions[]) {
    setResultsId(id)
    setResults({ ...results, [id]: newResults })
  }

  const reset = () => {
    if (!resultsId) {
      console.log('No results set yet. Nothing to reset.')

      return
    }

    setActiveResult(null)
    setInputValue(null)
    setResultsById(resultsId, [])
  }

  return (
    <AutoCompleteInputContext.Provider
      value={{
        options,
        setOptions,
        results,
        setResults,
        activeResult,
        setActiveResult,
        inputValue,
        setInputValue,
        resultsId,
        setResultsId,
        isOpen,
        setIsOpen,
        isLoading,
        setIsLoading,
        setSelectedResult,
        setResultsById,
        reset,
      }}
    >
      {children}
    </AutoCompleteInputContext.Provider>
  )
}

export const useAutoCompleteInputState = createContextStateHook(
  AutoCompleteInputContext,
  'AutoCompleteInputContext',
)
