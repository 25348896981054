import { ToastContainer } from 'react-toastify'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export const StyledToastContainer = styled(ToastContainer)`
  &.Toastify__toast-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.secondary.darkBlue};
    top: 0;
    color: ${Colors.body};
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &.Toastify__toast-container--top-center {
    padding-top: 20vh;
  }

  & .Toastify__toast {
    background-color: ${Colors.white};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.41);
    padding: 3em 2em 4.5em 2em;
    width: 37.25rem;
  }

  & .Toastify__close-button {
    display: none;
  }
`
