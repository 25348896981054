import { ApiRoutes } from '~api'

import { apiGetAction } from '../actions'

export interface IFilesAllResponse {
  _id: string
  field?: string
  imoNumber?: string
  name?: string
  pool?: string
  subtype?: 'general' | 'vessel' | 'pool' | 'placeholder'
  type?: 'onboarding'
  uploadedDate: string
  vesselId?: string
  vesselName?: string
}

export interface IFilesReadResponse {
  signedUrl?: string
}

export const filesReadAction = (id: string): Promise<IFilesReadResponse> => {
  return apiGetAction<IFilesReadResponse>(ApiRoutes.Files.read, { id })
}

export const vettingFilesReadAction = (
  id: string,
): Promise<IFilesReadResponse> => {
  return apiGetAction<IFilesReadResponse>(ApiRoutes.Files.vettingRead, { id })
}

export const filesAllAction = (): Promise<IFilesAllResponse> => {
  return apiGetAction<IFilesAllResponse>(ApiRoutes.Files.all)
}
