import styled from 'styled-components/macro'

interface IRotate {
  isRotated: boolean
}

export const Rotate = styled.span<IRotate>`
  display: inline-block;
  transition: transform 200ms ease;
  transform: rotate(
    ${(props) => {
      return props.isRotated ? 180 : 0
    }}deg
  );
  transform-origin: center center;
  cursor: pointer;
`
