import { FC } from 'react'

import { Box } from '~components/atoms/box'
import { Button } from '~components/atoms/button'
import { Icon } from '~components/atoms/icon'
import { Text } from '~components/atoms/text'

import { styles } from './styles'

export const ModalToolbar: FC<{
  close?: () => void
  title?: string
}> = ({ title, close }) => {
  return (
    <div style={styles.toolbar}>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        pl={4}
      >
        <Text text={title ?? ''} size="h5" color="white" headline />
        <Box flex={0} mr={2}>
          <Button
            text=""
            variant="close"
            onClick={() => {
              if (close) {
                close()
              }
            }}
          >
            <Icon name="close" size={38} fill="white" />
          </Button>
        </Box>
      </Box>
    </div>
  )
}
