import { useEffect, useState } from 'react'
import { useGetWelcomePopup } from '~hooks/queries/welcome-popup/use-get-welcome-popup'
import { VideoOverlay } from './video-overlay'
import { VideoThumbnail } from './video-thumbnail'

export function Video() {
  const { data } = useGetWelcomePopup()

  const [isVideoOpen, setIsVideoOpen] = useState(false)

  useEffect(() => {
    // Disables scrollbar
    if (isVideoOpen) {
      document.body.style.position = 'fixed'
    } else {
      document.body.style.position = 'unset'
    }
  }, [isVideoOpen])

  if (!data) {
    return null
  }

  const { imageUrl, imageName, videoLink } = data

  if (!imageUrl || !imageName || !videoLink) return null

  return isVideoOpen ? (
    <VideoOverlay
      videoLink={videoLink}
      onClickHandler={() => setIsVideoOpen(false)}
    />
  ) : (
    <VideoThumbnail
      imageUrl={imageUrl}
      imageName={imageName}
      onClickHandler={() => setIsVideoOpen(true)}
    />
  )
}
