import {
  FileCopyIcon,
  FunnelIcon,
  MultiSelect,
  ResetIcon,
  Switch,
  TextButton,
} from '@maersktankersdigital/web-components'
import { ClickAwayListener } from '@mui/base'
import * as React from 'react'
import { useRef, useState } from 'react'

import {
  initialColumnOrder,
  initialColumnSort,
  usePositionListView,
} from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import {
  ButtonAndSwitchWrapper,
  ButtonsWrapper,
  SwitchWrapper,
} from '~pages/pages-behind-login/position-list/position-list.styles'
import {
  columnsConfig,
  loadAreaConfig,
  vesselSpecsConfig,
  worldAreaConfig,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-controls/columns-config'
import { trackFilterColumnsClick } from '~pages/pages-behind-login/position-list/tracking/track-filter-columns-click'

import { trackToggleHeaders } from '../../../tracking/track-toggle-headers'

export const columnOptions = [
  ...columnsConfig
    .filter((col) => !col.permanent)
    .map((col) => ({
      label: col.label,
      value: col.value,
    })),
]

export const vesselSpecsOptions = vesselSpecsConfig
  .map((col) => ({
    label: col.label,
    value: col.value,
  }))
  .sort((a, b) => a.label.localeCompare(b.label))

export const loadAreaOptions = loadAreaConfig
  .map((col) => ({
    label: col.label,
    value: col.value,
  }))
  .sort((a, b) => a.label.localeCompare(b.label))

export const worldAreaOptions = worldAreaConfig.map((col) => ({
  label: col.label,
  value: col.value,
}))

const groupedOptions = [
  ...columnOptions,
  {
    label: 'ETA',
    canToggle: true,
    options: loadAreaOptions,
  },
  {
    label: 'Vessel Specs',
    canToggle: true,
    options: vesselSpecsOptions,
  },
  {
    label: 'World Area',
    canToggle: true,
    options: worldAreaOptions,
  },
]

export const groupedColumnsConfig = [
  ...vesselSpecsConfig,
  ...loadAreaConfig,
  ...columnsConfig,
  ...worldAreaConfig,
]

export const constantColumnsStart = ['vessel']
export const constantColumnsEnd = ['status', 'showCurrentVoyage']

export const multiSelectLabelValuePairs = [
  ...columnOptions,
  ...loadAreaOptions,
  ...vesselSpecsOptions,
  ...worldAreaConfig,
]

function PositionListTableControls() {
  const [addColumnsOpen, setAddColumnsOpen] = useState(false)
  const { activeView, saveView, settings, saveSettings } = usePositionListView()
  const initialLoad = useRef(true)

  const handleResetColumns = () => {
    saveView({ sortColumn: initialColumnSort, columns: initialColumnOrder })
  }

  const handleColumnsFiltering = (newValues: string[]) => {
    const filteredNewValue = newValues.filter(
      (value) =>
        !constantColumnsStart.includes(value) &&
        !constantColumnsEnd.includes(value),
    )

    const nextColumns = Array.from(
      new Set([
        ...constantColumnsStart,
        ...filteredNewValue,
        ...constantColumnsEnd,
      ]),
    )

    saveView({ columns: nextColumns })
  }

  const handleToggleHeaders = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newShowHeaders = e.target.checked
    saveView({ showHeaders: newShowHeaders })
    trackToggleHeaders(newShowHeaders)
  }

  const handleToggleDuplicates = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newShowDuplicates = e.target.checked
    saveView({ filterSettings: { showDuplicates: newShowDuplicates } })
  }

  function handleToggleColumns(
    event:
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
      | React.FocusEvent<Element, Element>
      | null,
    newValues: string[] | null,
  ) {
    if (!newValues) return
    if (initialLoad.current) {
      initialLoad.current = false
      return
    }
    handleColumnsFiltering(newValues)

    // only triggered on users click
    if (event) {
      saveSettings({ changesPending: true })
      trackFilterColumnsClick(event, newValues)
    }
  }

  const handleExportVessels = () => {
    saveSettings({ isExporting: !settings?.isExporting, exportedVessels: [] })
  }

  return (
    <ClickAwayListener onClickAway={() => setAddColumnsOpen(false)}>
      <ButtonAndSwitchWrapper>
        <ButtonsWrapper>
          <TextButton
            variant="primary"
            icon={<FileCopyIcon size={16} />}
            onClick={handleExportVessels}
          >
            Export vessels
          </TextButton>
          <TextButton
            variant="primary"
            onClick={handleResetColumns}
            icon={<ResetIcon size={16} />}
          >
            Reset Columns
          </TextButton>
          <MultiSelect
            name="positionListColumns"
            variant="secondary"
            options={groupedOptions}
            icon={<FunnelIcon size={16} />}
            value={activeView?.columns}
            placeholder="Add columns"
            onChange={handleToggleColumns}
            hiddenValue="status"
            listboxOpen={addColumnsOpen}
            onClick={() => setAddColumnsOpen((prev) => !prev)}
          />
        </ButtonsWrapper>
        <SwitchWrapper>
          <Switch
            name="toggle_duplicates"
            checked={activeView?.filterSettings?.showDuplicates}
            onChange={handleToggleDuplicates}
            label="Show ballast duplicates"
          />
          <Switch
            name="toggle_headers"
            checked={activeView?.showHeaders}
            onChange={handleToggleHeaders}
            label="Show headers"
            disabled={settings?.isExporting}
          />
        </SwitchWrapper>
      </ButtonAndSwitchWrapper>
    </ClickAwayListener>
  )
}

export default PositionListTableControls
