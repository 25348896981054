import { Typography } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export const StyledDropzone = styled.div<{ isDragActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 140px;
  border-style: dashed;
  border-color: ${Colors.greys.dark};
  border-width: 1px;
  border-radius: 6px;
  background-color: ${(props) => {
    return props.isDragActive ? Colors.greys.lightest : 'transparent'
  }};
  width: 344px;

  &:focus {
    outline: none;
  }
`

export const FileBoxText = styled(Typography)<{ color?: string }>`
  color: ${({ color }) => (color ? color : COLORS.secondary.midBlue)};
`
