import { Select, theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

import { StyledTagFilter } from '~components/layout/tag-filter/tag-filter.styles'

export const StyledMainTableWrapper = styled.div``

export const StyledMainTableWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledMainTableWrapperFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: ${theme.SPACINGS[2]};
  margin-bottom: ${theme.SPACINGS[5]};

  ${StyledTagFilter} {
    margin-bottom: 0 !important; // temporary
  }
`

export const StyledSelect = styled(Select)`
  min-width: 240px;
`
