import { MouseEvent } from 'react'
import styled from 'styled-components/macro'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

interface IStyledButton {
  isDisabled: boolean
  isLoading: boolean
  isSmall: boolean
  onClick?: (event: MouseEvent) => void
  type: string
}

export const StyledButton = styled.button.attrs<IStyledButton>((props) => {
  return {
    disabled: props.isDisabled || props.isLoading,
  }
})<IStyledButton>`
  position: relative;
  height: ${(props) => {
    return props.isSmall ? '40px' : '50px'
  }};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.blues.base};
  background-color: ${(props) => {
    return props.isDisabled ? `${Colors.greys.base}` : `${Colors.blues.base}`
  }};

  border-color: ${(props) => {
    return props.isDisabled ? `${Colors.greys.base}` : `${Colors.blues.base}`
  }};
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  text-transform: uppercase;
  padding: ${(props) => {
    return props.isSmall ? '0 20px' : '0 24px'
  }};
  cursor: ${(props) => {
    return props.isDisabled
      ? 'default'
      : props.isLoading
        ? 'default'
        : 'pointer'
  }};

  &:focus,
  &:hover {
    /* Special color only used in this button's hover state */
    background-color: ${(props) => {
      return props.isDisabled ? `${Colors.greys.base}` : '#02406e'
    }};
    /* Special color only used in this button's hover state */
    border-color: ${(props) => {
      return props.isDisabled ? `${Colors.greys.base}` : '#02406e'
    }};

  &:focus {
    outline: none;
  }
`

export const StyledSolidSecondaryButton = styled(StyledButton)`
  background-color: ${Colors.blues.maersk};
  border-color: ${Colors.blues.maersk};

  &:focus,
  &:hover {
    /* Special color only used in this button's hover state */
    background-color: #57b9da;
    /* Special color only used in this button's hover state */
    border-color: #57b9da;
  }
`

export const StyledOutlineButton = styled(StyledButton)`
  background-color: ${Colors.white};

  &:focus,
  &:hover {
    background-color: ${Colors.greys.ultraLight};
    border-color: ${Colors.blues.deep};
  }
`

export const StyledOutlineDangerButton = styled(StyledButton)`
  background-color: ${Colors.white};
  border-color: ${Colors.reds.base};

  &:focus,
  &:hover {
    background-color: ${Colors.reds.light};
    border-color: ${Colors.reds.base};
  }
`

export const StyledWhiteButton = styled(StyledButton)`
  background-color: ${Colors.white};
  border-color: ${Colors.white};

  &:focus,
  &:hover {
    background-color: ${Colors.greys.lighter};
    border-color: ${Colors.greys.lighter};
  }
`

export const StyledSpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledTextWrapper = styled.div<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${(props) => {
    return props.isLoading ? 0 : 1
  }};
`

export const StyledNavButton = styled(StyledButton)<{
  active?: boolean
}>`
  background-color: ${(props) => {
    return props.active ? Colors.blues.deep : Colors.greys.ultraLight
  }};
  text-transform: none;
  border: none;
  height: 40px;
  border-radius: 4px;
  padding: 12px 16px;
  &:hover {
    background-color: ${(props) => {
      return props.active ? Colors.blues.deep : Colors.blues.secondaryLight
    }};
  }
  &:focus {
    background-color: ${(props) => {
      return !props.active && Colors.blues.maersk
    }};
  }
`

export const StyledFloatingButton = styled(StyledButton)`
  background-color: ${Colors.oranges.base};
  border: none;
  &:hover {
    background-color: #f59d03;
  }
`
export const StyledCloseButton = styled(StyledButton)`
  background-color: ${Colors.transparent};
  height: 25px;
  width: 25px;
  padding: 0;
  border: none;
  &:hover,
  &:focus {
    background-color: ${Colors.transparent};
  }
`

export const StyledPrimaryButton = styled(StyledButton)`
  background-color: ${(props) => {
    return props.isDisabled ? `${Colors.transparent}` : `${Colors.blues.dark}`
  }};
  border-color: ${(props) => {
    return props.isDisabled ? `${Colors.body}` : `${Colors.blues.dark}`
  }};
  &:focus,
  &:hover {
    background-color: ${(props) => {
      return props.isDisabled ? `${Colors.transparent}` : `${Colors.blues.dark}`
    }};
    border-color: ${Colors.blues.deep};
  }
`

export const StyledNewSolidSecondary = styled(StyledButton)`
  background-color: ${Colors.blues.mid};
  border: 1px solid ${Colors.blues.mid};
  &:focus {
    background-color: ${Colors.blues.mid};
    border-color: ${Colors.blues.mid};
  }
`
