import { localStorageItemKeys } from 'src/constants/local-storage-items'
import { User } from '~hooks/queries/users/use-get-users'
import { findPermissions } from './find-permissions'

export function setLocalStorageItem(
  token: string,
  data: User,
  roles: string[],
) {
  const valueInLS = localStorage.getItem(localStorageItemKeys.persistRoot)
  const userData = {
    user: `{"current":{"accessToken":"${token}"},
        "data": ${JSON.stringify(data)},
        "updating":true,
        "permissions":${JSON.stringify(findPermissions(roles))},
        "authReturnRoute":null}`,
  }

  if (valueInLS === JSON.stringify(userData)) return
  localStorage.setItem(
    localStorageItemKeys.persistRoot,
    JSON.stringify(userData),
  )
}
