import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'

export interface MuiSkeletonData {
  defaultProps?: ComponentsProps['MuiSkeleton']
  styleOverrides?: ComponentsOverrides['MuiSkeleton']
  variants?: ComponentsVariants['MuiSkeleton']
}

export const MuiSkeleton: MuiSkeletonData = {
  styleOverrides: {
    root: {
      backgroundColor: COLORS.greys.light,
    },
  },
}
