import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { apiRemoveFileAction, apiUploadFileAction } from '~api/actions'
import { IDocumentsRowData } from '~api/vessels/vessel-tabs/documents/all'

export interface IDocumentsUpdatePayload {
  documentName: string
  expires?: Date
  file?: File
  issued?: Date
  notApplicable: boolean
  type: string
}

export function documentsUpdateAction(
  payload: IDocumentsUpdatePayload,
  urlParams?: Params,
): Promise<IDocumentsRowData> {
  return apiUploadFileAction<IDocumentsUpdatePayload, IDocumentsRowData>(
    ApiRoutes.Vessels.Documents.update,
    payload,
    urlParams,
  )
}

export function documentsRemoveFileAction(
  urlParams: Params,
): Promise<IDocumentsRowData> {
  return apiRemoveFileAction<IDocumentsRowData>(
    ApiRoutes.Vessels.Documents.delete,
    urlParams,
  )
}
