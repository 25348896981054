import { FC } from 'react'

import { Text } from '~components/atoms/text'

import { StyledTh } from './styles'

interface ITableHeaderCell {
  text: string
  width?: string
}

export const TableHeaderCell: FC<ITableHeaderCell> = ({ text, width }) => {
  return (
    <StyledTh width={width}>
      <Text
        text={text.toUpperCase()}
        weight="bold"
        headline
        color="blues.deep"
        size="body"
      />
    </StyledTh>
  )
}
