export const mockGeneralInfo = {
  vesselId: '66e82c4318595caad118fefd',
  name: 'Eastern Nymphaea',
  vesselName: 'Eastern Nymphaea',
  imo: '9827994',
  imoNumber: '9827994',
  vesselCode: 'VNZ9',
  tramosCode: 'NZ9',
  poolPartner: 'EGPN Bulk Carrier Co. Ltd.',
  poolPartnerCode: 'EBC',
  axCode: 'EBC',
  buildYear: '2019',
  builder: 'Zhejiang Shenzhou Shipbuilding Co., Ltd, China',
  flag: 'Liberia',
  dwt: '13498.50',
  length: '139.98',
  iceClass: 'No',
  imoClass: 'IMO 2',
  dimensionsSummerLoadlineDraft: '8.08',
  hasScrubber: false,
  cbm: '17900',
}
