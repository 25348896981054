import { CSSProperties, FC, memo, ReactNode } from 'react'

import { StyledBox } from './styles'

interface FlexboxStyle {
  alignContent?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'stretch'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
  alignSelf?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'stretch'
  flexWrap?: 'wrap' | 'nowrap'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
}

export type IBox = {
  alignContent?: FlexboxStyle['alignContent']
  alignItems?: FlexboxStyle['alignItems']
  alignSelf?: FlexboxStyle['alignSelf']
  children?: ReactNode
  dataTest?: string
  flex?: number | string
  flexDirection?: CSSProperties['flexDirection']
  flexWrap?: CSSProperties['flexWrap']
  gap?: number
  inline?: boolean
  justifyContent?: FlexboxStyle['justifyContent']
  noStyles?: boolean
  onClick?: () => void
} & IBoxSizeTypes

type IBoxSizeTypes = {
  m?: number
  mb?: number
  mh?: number
  ml?: number
  mr?: number
  mt?: number
  mv?: number
  p?: number
  pb?: number
  ph?: number
  pl?: number
  pr?: number
  pt?: number
  pv?: number
}

const paddingBase = 10

export const getSpacing = (times: number): number => {
  return Math.floor(paddingBase * times)
}

export const Box: FC<IBox> = memo(
  ({
    flex,
    flexWrap,
    flexDirection,
    justifyContent,
    alignItems,
    alignContent,
    alignSelf,
    children,
    dataTest,
    gap,
    noStyles = false,
    inline = false,
    ...props
  }) => {
    const styles: CSSProperties = Object.keys(props).reduce(
      (obj, key) => {
        const currentStyle = key.charAt(0) === 'm' ? 'margin' : 'padding'
        const propValue = props[key as keyof IBoxSizeTypes]
        const spacingValue = propValue !== undefined ? getSpacing(propValue) : 0

        // If key is only 1 character, like in cases of 'm' and 'p', we don't need the calculations below
        if (key.length === 1) {
          return { ...obj, [currentStyle]: spacingValue } as CSSProperties
        }

        const directions = [
          { t: ['top'] },
          { b: ['bottom'] },
          { l: ['left'] },
          { r: ['right'] },
          { v: ['top', 'bottom'] },
          { h: ['left', 'right'] },
        ]

        // Get style direction (see above) based on the string passed
        //
        // ml = margin-left
        // pr = padding-right
        const currentDirections = Object.values(
          directions.find((direction) =>
            Object.keys(direction).find(
              (directionKey) => directionKey === key.charAt(key.length - 1),
            ),
          ) ?? {},
        ).flat()

        // Convert above array with spacing values to styling format
        const spacingStyles = currentDirections.reduce(
          (prevValue, currValue) => ({
            ...prevValue,
            [currentStyle +
            currValue.charAt(0).toUpperCase() +
            currValue.slice(1)]: spacingValue,
          }),
          {},
        )

        return {
          ...obj,
          ...spacingStyles,
        }
      },
      noStyles
        ? {}
        : {
            display: inline ? 'inline-flex' : 'flex',
            flex: flex ?? 1,
            flexWrap,
            flexDirection: flexDirection || 'column',
            gap,
            justifyContent,
            alignItems,
            alignContent,
            alignSelf,
          },
    )

    return (
      <StyledBox data-test={dataTest} {...props} jsStyles={styles}>
        {children}
      </StyledBox>
    )
  },
)
