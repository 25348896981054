import * as React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { useFilteredData } from '~pages/pages-behind-login/position-list/contexts/filtered-data-provider'
import { usePositionListView } from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import { groupedColumnsConfig } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-controls/position-list-table-controls'
import {
  CheckboxHeaderWrapper,
  HeaderContent,
  StyledArrowUpIcon,
  StyledCheckbox,
  StyledMoveColumnIcon,
  StyledTableHead,
  TableHeaderCell,
  TableRowHeader,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table.styles'
import { PositionVesselData } from '~pages/pages-behind-login/position-list/types/position-list-types'

enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface Props {
  headerWidths?: number[]
}

function PositionListTableHeader({ headerWidths }: Props) {
  const { activeView, saveView, settings, saveSettings } = usePositionListView()
  const { filteredData } = useFilteredData()
  const { columns, sortColumn } = activeView || {}
  const showCheckboxInHeader =
    settings?.isExporting &&
    (!activeView?.showHeaders ||
      activeView?.filterSettings?.worldArea?.includes('ALL'))

  if (!columns || !sortColumn) return null
  const onDragEnd = (result: any) => {
    const { destination, source } = result

    // Ignore if no destination is provided or the item is dropped outside the list
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    const newColumns = Array.from(columns)
    const [removed] = newColumns.splice(source.index, 1)

    // Make sure removed is not null before inserting it back
    if (removed) {
      newColumns.splice(destination.index, 0, removed)
      saveView({ columns: newColumns })
    } else {
      console.error(
        'Invalid drag/drop operation: No item found at source index',
      )
    }
  }

  const handleOnSortingChange = (value: string) => {
    const { column, order } = sortColumn
    const newSortOrder =
      value === column && order === SortOrder.ASC
        ? SortOrder.DESC
        : SortOrder.ASC
    saveView({
      sortColumn: {
        column: value as keyof PositionVesselData,
        order: newSortOrder,
      },
    })
  }

  const determineRotation = (active: boolean): string => {
    const { order } = sortColumn
    if (active) {
      if (order === SortOrder.DESC) {
        return 'rotate(180deg)'
      }
      if (order === SortOrder.ASC) {
        return 'rotate(0deg)'
      }
    }
    return 'rotate(180deg)'
  }

  const handleExportAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allVesselIds = filteredData?.map((vessel) => vessel.vesselId)
      saveSettings({ exportedVessels: allVesselIds })
    } else {
      saveSettings({ exportedVessels: [] })
    }
  }

  return (
    <StyledTableHead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="position-list-droppable" direction="horizontal">
          {(provided) => (
            <TableRowHeader
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <>
                {showCheckboxInHeader ? (
                  <CheckboxHeaderWrapper>
                    <StyledCheckbox name="all" onChange={handleExportAll} />
                  </CheckboxHeaderWrapper>
                ) : null}
                {columns.map((columnKey, index) => {
                  const columnConfig = groupedColumnsConfig.find(
                    (col) => col.value === columnKey,
                  )
                  const isActive = columnConfig?.value
                    ? columnKey === sortColumn.column
                    : false

                  const label = columnConfig?.columnLabel || columnConfig?.label
                  return columnConfig?.fixed ? (
                    <TableHeaderCell
                      key={columnKey}
                      onClick={() =>
                        columnConfig?.sortable
                          ? handleOnSortingChange(columnKey)
                          : null
                      }
                      $width={headerWidths?.[index]}
                      sortable={columnConfig?.sortable}
                      $isExporting={showCheckboxInHeader}
                    >
                      <HeaderContent>
                        {label}
                        {columnConfig?.sortable && (
                          <StyledArrowUpIcon
                            size={18}
                            order={sortColumn.order}
                            style={{
                              transform: determineRotation(isActive),
                            }}
                            $isActive={isActive}
                          />
                        )}
                      </HeaderContent>
                    </TableHeaderCell>
                  ) : (
                    <Draggable
                      key={columnKey}
                      draggableId={columnKey}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <>
                            <TableHeaderCell
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isDragging={snapshot.isDragging}
                              onClick={() =>
                                columnConfig?.sortable
                                  ? handleOnSortingChange(columnKey)
                                  : null
                              }
                              $width={headerWidths?.[index]}
                            >
                              <HeaderContent>
                                {columnConfig?.label && (
                                  <StyledMoveColumnIcon size={10} />
                                )}
                                <span style={{ textAlign: 'center' }}>
                                  {label}
                                </span>
                                {columnConfig?.sortable && (
                                  <StyledArrowUpIcon
                                    size={18}
                                    order={sortColumn.order}
                                    style={{
                                      transform: determineRotation(isActive),
                                    }}
                                    $isActive={isActive}
                                  />
                                )}
                              </HeaderContent>
                            </TableHeaderCell>
                          </>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </>
            </TableRowHeader>
          )}
        </Droppable>
      </DragDropContext>
    </StyledTableHead>
  )
}

export default PositionListTableHeader
