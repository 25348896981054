import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

import {
  StyledTable,
  StyledTableCell,
  StyledTableCellIcon,
} from '~components/organisms/table/table.styles'

export const StyledPoolPointsTable = styled.div`
  &:last-child {
    table {
      margin: 0;
    }
  }

  ${StyledTable} {
    margin-bottom: 60px;

    ${StyledTableCell} {
      &:first-child {
        width: 25px;
        padding-left: 12px;

        ${StyledTableCellIcon} {
          vertical-align: top;
        }
      }

      &:nth-child(2) {
        padding-left: 8px;
      }
    }

    table {
      margin: 0;
    }
  }

  button {
    background: none;

    svg {
      fill: none;
    }
  }
`

export const StyledPoolPointsTableHeader = styled.div`
  display: flex;
  align-content: center;
  text-transform: uppercase;
  border-bottom: 1px solid ${COLORS.greys.light};
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  gap: 1rem;

  button {
    padding: 0;
  }
`
