import ErrorDisplay from '../../components/layout/error/error-display/error-display'

export default function Unauthorized() {
  return (
    <ErrorDisplay
      heading="Sorry, we couldn't find this page"
      text="You might not have permission to see this page"
      isPageError
    />
  )
}
