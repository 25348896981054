import { FC, ReactNode } from 'react'

import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'

interface IMTUsersGuard {
  alternateContent?: ReactNode
  children?: ReactNode
}

// MT Users allowed only
export const MTUsersGuard: FC<IMTUsersGuard> = ({
  children,
  alternateContent,
}) => {
  const isSuperUser = useHasScope(ExactScopeName.superUser)
  const isMTUser = useHasScope(ExactScopeName.maerskTankersUser)
  const isSuperReader = useHasScope(ExactScopeName.superReader)

  return (
    <>
      {isSuperUser || isSuperReader || isMTUser ? children : alternateContent}
    </>
  )
}
