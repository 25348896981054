import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import { NavLink } from 'react-router-dom'
import { MenuItemI } from '../links/primary'

export function MenuItem({ link, label }: MenuItemI) {
  return (
    <Box sx={{ '& a': { textDecoration: 'none' } }}>
      <NavLink to={link}>
        {({ isActive }) => {
          return (
            // @ts-ignore
            <ListItem variant={isActive ? 'active' : undefined}>
              <Typography variant="menu" sx={{ mr: 3 }}>
                {label}
              </Typography>
            </ListItem>
          )
        }}
      </NavLink>
    </Box>
  )
}
