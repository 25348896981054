import { COLORS } from '~theme/colors'
import { CookieIcon } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'

export function CookieBannerOpener({
  onClickHandler,
}: {
  onClickHandler: () => void
}) {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1201,
        bottom: 4,
        left: 4,
        overflow: 'hidden',
        color: COLORS.secondary.midBlue,
        transition: 'transform .2s ease',

        bgcolor: 'transparent',
        border: 'none',

        ':hover': {
          cursor: 'pointer',
          transform: 'scale(1.5)',
          transformOrigin: 'bottom left',
        },

        ':not(:hover)': {
          transform: 'scale(1)',
          transformOrigin: 'bottom left',
        },
      }}
      component="button"
      onClick={onClickHandler}
    >
      <CookieIcon height="32px" width="32px" />
    </Box>
  )
}
