import { FC, ReactNode, useState } from 'react'
import { NavLink, Params } from 'react-router-dom'

import { StyledRouterLink } from '~components/atoms/router-link/styles'
import { generatePathName } from '~utils/generate-path-name'

interface IRouterLink {
  children: ReactNode
  className?: string
  externalLink?: boolean
  noUnderline?: boolean
  params?: Params
  replace?: boolean
  state?: Record<string, string | number | { name: string; url?: string }>
  target?: string
  to: string
}

export const RouterLink: FC<IRouterLink> = ({
  children,
  to,
  params,
  state,
  replace = false,
  noUnderline = false,
  className,
  target,
  externalLink,
}) => {
  const [newState, setNewState] = useState(state)
  if (
    state?.prevPage &&
    // @ts-ignore
    !state.prevPage.url
  ) {
    setNewState({
      ...newState,
      prevPage: Object.assign(state.prevPage, { url: location.pathname }),
    })
  }

  return (
    <StyledRouterLink noUnderline={noUnderline} to={to} className={className}>
      {!externalLink ? (
        <NavLink
          to={generatePathName(to, params)}
          state={newState}
          replace={replace}
          target={target}
        >
          {children}
        </NavLink>
      ) : (
        <a href={to} target={target}>
          {children}
        </a>
      )}
    </StyledRouterLink>
  )
}
