import type { PropsWithChildren } from 'react'
import { createContext, useEffect, useState } from 'react'

import createContextStateHook from '~utils/create-hook-for-context-state'

export type AccordionProps = {
  initialItems?: Array<string>
  isExclusive?: boolean
}

export type AccordionState = {
  collapse: (item: string) => void
  collapseMultiple: (items: Array<string>) => void
  isOpen: (id: string) => boolean
  open: (item: string) => void
  openItems: Array<string>
  openMultiple: (items: Array<string>) => void
  reset: () => void
  toggle: (item: string) => boolean
}

type State = {
  activeItemMap: Map<string, true>
}

export const AccordionContext = createContext<AccordionState | undefined>(
  undefined,
)

export default function AccordionProvider({
  initialItems = [],
  isExclusive = true,
  children,
}: PropsWithChildren<AccordionProps>) {
  const [state, setState] = useState<State>({
    activeItemMap: new Map(),
  })

  useEffect(() => {
    openMultiple(initialItems!)
  }, [initialItems]) // eslint-disable-line react-hooks/exhaustive-deps

  const isOpen = (id: string) => state.activeItemMap.has(id)

  const open = (item: string) => {
    if (isExclusive) {
      reset()
    }

    const activeItemMap = state.activeItemMap

    activeItemMap.set(item, true)

    setState({
      ...state,
      activeItemMap,
    })
  }

  const openMultiple = (items: Array<string>) => {
    for (const item of items) {
      open(item)
    }
  }

  const reset = () => {
    collapseMultiple(Array.from(state.activeItemMap.keys()))
  }

  const collapse = (item: string) => {
    const activeItemMap = state.activeItemMap

    activeItemMap.delete(item)

    setState({ ...state, activeItemMap })
  }

  const collapseMultiple = (items: Array<string>) => {
    for (const item of items) {
      collapse(item)
    }
  }

  const toggle = (item: string) => {
    if (isOpen(item)) {
      collapse(item)
    } else {
      open(item)
    }

    return !isOpen(item)
  }

  return (
    <AccordionContext.Provider
      value={{
        openItems: Array.from(state.activeItemMap.keys()),
        isOpen,
        toggle,
        collapseMultiple,
        collapse,
        reset,
        openMultiple,
        open,
      }}
    >
      {children}
    </AccordionContext.Provider>
  )
}

export const useAccordion = createContextStateHook(
  AccordionContext,
  'AccordionContext',
)
