import { InfoCircleIcon } from '@maersktankersdigital/web-components'
import { Popup } from '@mui/base/Unstable_Popup/Popup'
import * as React from 'react'
import { useState } from 'react'

import {
  NoWorldAreaPopupContent,
  NoWorldAreaWrapper,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/vessel-name-content/vessel-name.styles'

function NoWorldAreaContent() {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const open = Boolean(anchor)

  const handleMouseLeave = () => {
    setAnchor(null)
  }

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  return (
    <>
      <NoWorldAreaWrapper
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <InfoCircleIcon size={16} />
        <Popup open={open} anchor={anchor} style={{ zIndex: 2 }}>
          <NoWorldAreaPopupContent>
            This Vessel has No World Area
          </NoWorldAreaPopupContent>
        </Popup>
      </NoWorldAreaWrapper>
    </>
  )
}

export default NoWorldAreaContent
