import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'
import { ITableRowData } from '../vessels/data/types/tabs-read'

export function useGetVettings(
  type: 'rejection' | 'SIRE' | 'CDI',
  vesselId?: string,
) {
  const fetchRequest = useFetch<ITableRowData[]>()
  const url = `/vettings?vesselId=${vesselId}&type=${type}`

  return useQuery({
    queryKey: [url],
    queryFn: () => fetchRequest(`${apiBase()}${url}`),
    staleTime: tenMinutes,
    enabled: !!vesselId,
  })
}
