import { Button, ExportIcon } from '@maersktankersdigital/web-components'

import { usePositionListView } from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import { ExportButtonsWrapper } from '~pages/pages-behind-login/position-list/position-list.styles'

type Props = {
  setShowExportModal: (open: boolean) => void
}

function ExportButtons({ setShowExportModal }: Props) {
  const { settings, saveSettings } = usePositionListView()

  const handleCancelExport = () => {
    saveSettings({
      isExporting: false,
      exportedVessels: [],
    })
  }

  const handleOpenExportModal = () => {
    setShowExportModal(true)
  }

  const disabledExport = settings?.exportedVessels?.length === 0
  return settings?.isExporting ? (
    <ExportButtonsWrapper>
      <Button
        icon={<ExportIcon />}
        onClick={handleOpenExportModal}
        disabled={disabledExport}
      >
        Export
      </Button>
      <Button variant="secondary" onClick={handleCancelExport}>
        Cancel
      </Button>
    </ExportButtonsWrapper>
  ) : null
}

export default ExportButtons
