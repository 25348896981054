import { ApiRoutes } from '~api'
import { apiGetCsvAction } from '~api/actions'

export interface IVettingReadResponseTableSubsectionField {
  canImpairEarnings: boolean
  closedBy?: string
  closingDate?: string
  comment: string
  currentVoyage?: any
  differenceInDays: string
  filename?: string
  hash: string
  isOngoing: boolean
  oilMajor: string
  pool: string
  poolPartner: string
  poolPartnerKey: string
  rejectionCause: string
  rejectionDate: string
  responsibleUser?: {
    name: string
    sub: string
  }
  updatedAt: string
  updatedBy: string
  vesselId: string
  vesselName: string
}

export interface IVettingReadResponseTableSubsection {
  fields: IVettingReadResponseTableSubsectionField[]
  id: string
  name: string
}

export interface IVettingReadResponseTable {
  id: string
  name: string
  subsections: IVettingReadResponseTableSubsection[]
}

export interface IVettingReadResponse {
  id: string
  name: string
  tables: IVettingReadResponseTable[]
}

export const vettingCSVFileReadAction = (
  filtersQuery = '',
): Promise<string> => {
  return apiGetCsvAction(ApiRoutes.Vettings.Overview.allCSV + filtersQuery)
}

export interface IVettingInspectionActiveRejection {
  oilMajor?: string
  rejectionCause?: string
  rejectionDate?: string
}

export interface IVettingInspectionLastInspection {
  approval?: string
  basis?: string
  comment?: string
  comments?: string
  expireDate?: string
  id: string
  inspectionDate?: string
  inspector?: string
  issueDate?: string
  operation?: string
  plan?: string
  port?: string
  remote?: boolean
  type?: string
}

export interface IVettingInspectionOverviewReadResponse {
  activeRejections?: IVettingInspectionActiveRejection[] | null
  dueInspectionDate: string
  dueInspectionInDays: number
  lastInspectionDate: string
  lastInspections: IVettingInspectionLastInspection[] | null
  pool?: string
  poolPartnerKey?: string
  poolPartnerName?: string
  technicalManagement?: string
  vesselId: string
  vesselName?: string
}
