import { ClickPoint, CloseIcon } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'

export function VideoOverlay({
  videoLink,
  onClickHandler,
}: {
  videoLink: string
  onClickHandler: () => void
}) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#000',
        zIndex: 1202,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          top: { xs: '2.5vh', md: '5.5vh' },
          left: { xs: '2.5vw', md: '12.5vw' },
          width: { xs: '95vw', md: '75vw' },
          height: { xs: '95vh', md: '75vh' },
        }}
      >
        <Box
          component={ClickPoint}
          onClick={onClickHandler}
          variant="dark"
          ariaLabel="close video"
          sx={{ float: 'right' }}
        >
          <CloseIcon />
        </Box>
        <Box
          component="iframe"
          src={videoLink}
          allowFullScreen
          allow="autoplay; encrypted-media"
          sx={{ width: '100%', height: '90vh' }}
        />
      </Box>
    </Box>
  )
}
