const vesselPageRoute = '/api/vessels/:vesselId'

export const ApiRoutes = {
  ApplicationInfo: {
    BE: { read: '/api/info' },
    FE: { read: '/info.json' },
  },
  Files: {
    all: '/api/files',
    onboardingProcessFiles: '/api/files?type=onboarding',
    add: '/api/files',
    read: '/api/files/:id',
    update: '/api/files/:id',
    delete: '/api/files/:id',
    vettingRead: '/api/files/:id??type=vetting',
  },
  POOL_PARTNERS: {
    ALL: '/api/poolpartners',
    LIST: '/api/poolpartners/list',
    READ: '/api/poolpartners/:poolPartnerKey',
    UPDATE: '/api/poolpartners/:poolPartnerKey',
    COMMENTS: {
      READ: '/api/poolpartners/comments',
      UPDATE: '/api/poolpartners/:partnerId/comments',
    },
  },
  Vessels: {
    all: '/api/vessels',
    flow: `${vesselPageRoute}/flow?id=:flowId`,
    imos: '/api/ipaas?list=true',
    incomingVessel:
      '/api/vessels?status=incoming&vesselName=:vesselName&poolPartner=:poolPartner',
    managers: '/api/users?mt=true',
    read: vesselPageRoute,
    readByImo: '/api/vessels/:imoNumber',
    status: `${vesselPageRoute}/status`,
    update: vesselPageRoute,
    Comments: {
      update: `${vesselPageRoute}/comments`,
      get: `${vesselPageRoute}/comments?tab=:id`,
    },
    // dbPath can be poolpartners, q88 or vessels e.g. q88.Builder or
    // poolpartners.poolPartnerKey:MPT
    vesselByDbPath: '/api/vessels?match-regex=:dbPath',
    Questionnaire: {
      read: `${vesselPageRoute}/questionnaire`,
      approve: `${vesselPageRoute}/approve`,
      downloadFile: `${vesselPageRoute}/:fileId/:fileName`,
      reject: `${vesselPageRoute}/reject`,
      update: `${vesselPageRoute}/section`,
      uploadFile: `${vesselPageRoute}/file`,
      removeFile: `${vesselPageRoute}/:id/:fileName`,
      readFields: '/api/vessels/questionnaire/fields',
      readSection: `${vesselPageRoute}/questionnaire/section/:sectionId`,
    },
    Tabs: {
      update: `${vesselPageRoute}/data?tab=:id`,
      changeRequest: '/api/tabs/:id/changerequest',
      all: '/api/tabs/:vesselId',
      versionHistoryRead: `${vesselPageRoute}/history?field_id=:id`,
      customTabRead: `${vesselPageRoute}/:tabId`,
      vettingVersionHistoryRead: `${vesselPageRoute}/history?vetting_hash=:hash&type=vetting`,
    },
    Documents: {
      all: `${vesselPageRoute}/documents`,
      update: `${vesselPageRoute}/documents`,
      add: `${vesselPageRoute}/documents`,
      delete: `${vesselPageRoute}/documents?documentName=:documentName`,
    },
    Certificates: {
      requestDownloadingFile: `${vesselPageRoute}/certificates/requestdownload`,
      downloadFile: `${vesselPageRoute}/file?name=:filename`,
      downloadBulkFile: `${vesselPageRoute}/certificates/archive?:params`,
    },
  },
  User: {
    Roles: {
      add: '/api/roles',
      delete: '/api/roles',
      read: '/api/roles',
    },
    Info: {
      read: '/api/me',
      update: '/api/userinfo',
    },
    Settings: {
      update: '/api/me',
      delete: '/api/me',
    },
    create: '/api/users/create',
    invite: '/api/users/invite',
    readInvite: '/api/users/invite/:invite',
    read: '/api/users/:id',
    update: '/api/users/:id',
    deactivate: '/api/users/:id',
  },
  Users: {
    all: '/api/users',
    Managers: {
      all: '/api/users?mt=true',
      partnerManager: '/api/users?role=accountmanager',
    },
    readByVesselId: '/api/users?vesselId=:vesselId',
    readByPoolPartner: '/api/users?poolPartnerKey=:poolPartnerKey',
  },
  Vettings: {
    Overview: {
      all: '/api/vettings/overview',
      allCSV: '/api/vettings/overview.csv',
      filters: '/api/vettings/overviewfilters',
    },
    add: '/api/vettings/rejection?vesselId=:vesselId',
    addSireCdi: '/api/vettings/inspection?vesselId=:vesselId',
    update: '/api/vettings/rejection?vesselId=:vesselId&hash=:hash',
    updateSireCdi: '/api/vettings/inspection?vesselId=:vesselId',
  },
  Voyages: {
    all: '/api/voyages?vesselId=:vesselId',
    all_ten: '/api/voyages?vesselId=:vesselId&limit=10',
    allCsv: '/api/voyages.csv?vesselId=:vesselId',
    filters: '/api/voyages/filters?vesselId=:vesselId',
  },
  POOL_POINTS: {
    ALL: '/api/poolpoints',
    BY_ID: '/api/poolpoints/:vesselId',
    BY_ID_AND_DATE: '/api/poolpoints/:vesselId?version=:version',
    BY_POOL_AND_DATE: '/api/poolpoints?version=:version&pool=:pool',
    PATCH: '/api/poolpoints/:vesselId/correction',
    VERSIONS: '/api/poolpoints/:vesselId/versions',
  },
  POSITION_LIST: {
    ALL: '/api/positionlist/vessels',
    COMMERCIAL_MANAGEMENT: '/api/positionlist/commercialmanagementvessels',
    PATCH: '/api/positionlist/:vesselId/positionlist',
    STATUS: '/api/positionlist/status',
  },
}
