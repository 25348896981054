import {
  BurgerMenuIcon,
  GlobeIcon,
  TextButton,
  Typography,
} from '@maersktankersdigital/web-components'
import { PageHeader } from '~components/page-header'
import { StyledRouterLink } from '~pages/pages-behind-login/position-list/position-list.styles'
import { PageRoute } from '~routing/constants/page-route'

interface Props {
  myListsButtonRef?: React.RefObject<HTMLButtonElement>
  setShowMyLists?: React.Dispatch<React.SetStateAction<boolean>>
}

function PositionListHeader({ setShowMyLists, myListsButtonRef }: Props) {
  const handleToggleMyLists = () => {
    setShowMyLists?.((prev) => !prev)
  }

  const pageHeaderButtons = myListsButtonRef
    ? [
        <TextButton
          key="My Lists"
          ref={myListsButtonRef}
          onClick={handleToggleMyLists}
          icon={<BurgerMenuIcon />}
          data-cy="my-lists-button"
        >
          My Lists
        </TextButton>,
      ]
    : []

  pageHeaderButtons.unshift(
    <Typography key="Map" variant="button" as="p">
      <StyledRouterLink to={PageRoute.MAP} target="_blank" rel="noopener">
        <GlobeIcon />
        Map
      </StyledRouterLink>
    </Typography>,
  )

  return <PageHeader buttons={pageHeaderButtons} pageName="Position List" />
}

export default PositionListHeader
