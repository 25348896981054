import { Params } from 'react-router-dom'
import { getTokenSilently } from '~utils/auth'
import { generatePathName } from '~utils/generate-path-name'
import { ApiConfig } from './config'

interface IApiAction<R> {
  auth?: boolean
  isBlob?: boolean
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE'
  payload?: R
  url: string
  urlParams?: Params
}

async function authApiAction<Request>({
  method,
  url,
  payload,
  urlParams,
  auth = true,
}: IApiAction<Request>) {
  const constructedUrl = generatePathName(url, urlParams)
  let headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  if (auth) {
    const token: string = await getTokenSilently()
    headers = {
      ...headers,
      Authorization: `${token} Bearer`,
    }
  }

  const response = await fetch(ApiConfig.domain + constructedUrl, {
    method,
    body: JSON.stringify(payload),
    headers,
    cache: 'no-cache',
  })

  return response.json()
}

export async function apiGetAction<Response>(
  url: string,
  urlParams?: Params,
  auth?: boolean,
): Promise<Response> {
  return await authApiAction({ method: 'GET', url, urlParams, auth })
}

export async function apiGetCsvAction(
  url: string,
  urlParams?: Params,
  auth = true,
): Promise<string> {
  const constructedUrl = generatePathName(url, urlParams)
  let headers: Record<string, string> = {
    'Content-Type': 'text/csv',
  }

  if (auth) {
    const token: string = await getTokenSilently()
    headers = {
      ...headers,
      Authorization: `${token} Bearer`,
    }
  }

  const response = await fetch(ApiConfig.domain + constructedUrl, {
    method: 'GET',
    headers,
  })

  return response.text()
}

export async function apiGetFileAction<Response>(
  url: string,
  urlParams?: Params,
  auth?: boolean,
): Promise<Response> {
  return await authApiAction({ method: 'GET', url, urlParams, auth })
}

export async function apiRemoveFileAction<Response>(
  url: string,
  urlParams?: Params,
  auth?: boolean,
): Promise<Response> {
  return await authApiAction({ method: 'DELETE', url, urlParams, auth })
}

export async function apiPostAction<Request, Response>(
  url: string,
  payload: Request,
  urlParams?: Params,
  auth?: boolean,
): Promise<Response> {
  return await authApiAction({ method: 'POST', url, payload, urlParams, auth })
}

export async function apiUpdateAction<Request, Response>(
  url: string,
  payload: Request,
  urlParams?: Params,
  auth?: boolean,
): Promise<Response> {
  return await authApiAction({ method: 'PATCH', url, payload, urlParams, auth })
}

export async function apiDeleteAction<Request, Response>(
  url: string,
  payload: Request,
  urlParams?: Params,
  auth?: boolean,
): Promise<Response> {
  return await authApiAction({
    method: 'DELETE',
    url,
    payload,
    urlParams,
    auth,
  })
}

export async function apiUploadFileAction<Request extends object, Response>(
  url: string,
  payload: Request,
  urlParams?: Params,
  update?: boolean,
): Promise<Response> {
  const formData = new FormData()
  // @ts-ignore
  Object.keys(payload).forEach((key) => {
    // @ts-ignore
    formData.append(key, payload[key])
  })
  const token: string = await getTokenSilently()
  const constructedUrl = generatePathName(url, urlParams)
  const response = await fetch(ApiConfig.domain + constructedUrl, {
    method: update ? 'PATCH' : 'POST',
    body: formData,
    headers: {
      Authorization: `${token} Bearer`,
    },
  })
  return response.json()
}

export const handleMutation = async <T>(
  mutationFn: () => Promise<T>,
  successCallback: (response: T) => void,
  errorCallback: (error: any) => void,
): Promise<void> => {
  try {
    const response = await mutationFn()
    successCallback(response)
  } catch (error) {
    errorCallback(error)
    console.error('Mutation error:', error)
  }
}
