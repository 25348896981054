import { theme, typographyStyles } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { TableCell } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table.styles'
import { PopupContentWrapper } from '~pages/pages-behind-login/position-list/position-list.styles'
import { COLORS } from '~theme/colors'

export const CommentCell = styled(TableCell)`
  white-space: normal;
  padding: ${theme.SPACINGS[1]};
`

export const CommentFieldWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: ${theme.SPACINGS[0]};
`

export const ResponsibleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.SPACINGS[0]};
  padding-bottom: 3px;
`

export const StyledName = styled.span`
  ${typographyStyles.label}
  font-size: 0.5rem;
`

export const StyledDate = styled.span`
  font-size: 0.5rem;
`

export const CommentPopupContentWrapper = styled(PopupContentWrapper)`
  white-space: pre-line;
  font-family: ${theme.FONTS.families.regular};
  font-size: ${theme.FONTS.sizes.xs};
`

export const CommentInput = styled.textarea`
  ${typographyStyles.caption}
  background-color: ${COLORS.greys.ultraLight};
  margin: ${theme.SPACINGS[0]} 0;
  resize: none;
  outline: none;
  cursor: text;

  :active,
  :focus-visible {
    border: 1px solid ${COLORS.primary.maerskBlue};
  }
`

export const CommentText = styled.div`
  ${typographyStyles.caption}
  white-space: pre-line;
`
