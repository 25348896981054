import { useCallback, useState } from 'react'

type UseScrollToOptions = {
  container?: Window | HTMLElement
  offset?: number
}

const DEFAULT_OPTIONS: Required<UseScrollToOptions> = {
  offset: 0,
  container: window,
}

/**
 * useScrollTo scrolls to the element set using `setElement`
 *
 * @returns [the scroll function, the setter for use in a ref prop (<... ref={setter}/>)
 * and the actual element received from the setter]
 *
 * @example ```jsx
 * const Component = () => {
 *   const [scroll, setElement] = useScrollTo({offset: 0, container: window});
 *
 *   return (
 *     <div onClick={() => scroll()} ref={setElement}>
 *       <p>Click, to scroll to me!</p>
 *     </div>
 *   );
 * };
 *```
 */
export default function useScrollTo<E extends HTMLElement>(
  options: UseScrollToOptions = {},
): [() => void, (instance: E | null) => void, E | null] {
  const { offset, container } = { ...DEFAULT_OPTIONS, ...options }
  const [element, setElement] = useState<E | null>(null)

  const scroll = useCallback(
    () => scrollToElement(element, container, offset),
    [container, offset],
  )

  return [scroll, setElement, element]
}

export function scrollToElement(
  element: HTMLElement | null,
  container: Window | HTMLElement,
  offset = 0,
) {
  if (element && 'scrollTo' in container) {
    requestAnimationFrame(() => {
      const scrollAmount = element.getBoundingClientRect()?.top ?? 0

      const scrollTop =
        (container as Window)?.scrollY ??
        (container as HTMLElement)?.scrollTop ??
        0

      const y = scrollTop + scrollAmount - offset

      'scrollBehavior' in document.documentElement.style // check if the 'smooth' behavior is supported
        ? container.scrollTo({ top: y, behavior: 'smooth' })
        : container.scrollTo(0, y)
    })
  }
}
