import noop from 'lodash/noop'
import pick from 'lodash/pick'
import type { DependencyList } from 'react'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'

import useEventListener from './use-event-listener'
import useResizeObserver from './use-resize-observer'

const BoundsKeys = [
  'width',
  'height',
  'left',
  'right',
  'top',
  'bottom',
  'x',
  'y',
] as const

export type Bounds = {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
  x: number
  y: number
}

export const useClientBounds = (
  element: HTMLElement | null,
  updateOnScroll?: boolean,
  deps: DependencyList = [],
) => {
  const [bounds, setBounds] = useState<Bounds>({
    width: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    x: 0,
    y: 0,
  })

  const boundsRef = useRef<Bounds>()

  const resizeHandler = useCallback(() => {
    if (element) {
      const clientRect = element.getBoundingClientRect() as Bounds

      if (
        !boundsRef.current ||
        !areBoundsEqual(clientRect, boundsRef.current)
      ) {
        // to have a clean bounds object, we just pick out the keys
        // of the properties we need
        boundsRef.current = pick(clientRect, BoundsKeys)
        setBounds(boundsRef.current)
      }
    }
  }, [element])

  useResizeObserver(element, resizeHandler)

  useEventListener('scroll', window, updateOnScroll ? resizeHandler : noop)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(resizeHandler, [resizeHandler, ...deps])

  return bounds
}

const areBoundsEqual = (a: Bounds, b: Bounds): boolean =>
  BoundsKeys.every((key) => a[key] === b[key])
