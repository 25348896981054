import { CloseIcon, TextButton } from '@maersktankersdigital/web-components'
import { Box as MuiBox, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { IComment } from '~api/vessels/read'
import { Box } from '~components/atoms/box'
import { Link } from '~components/atoms/link'
import { RouterLink } from '~components/atoms/router-link'
import { FALLBACK_STRING_DASH } from '~constants/global-strings'
import { PageRoute } from '../../../routes/routes-behind-login/vt-routing/constants/page-route'
import { Group } from '../group/group'

export interface ICommentObject {
  canRemove?: boolean
  comment: IComment
  name: string
  save: () => void
}

export const Comment: FC<ICommentObject> = ({
  name,
  comment,
  save,
  canRemove = true,
}) => {
  const { value, createdAt, createdBy, authorName } = comment
  const { control, setValue } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={
        <Typography variant="paragraph2">
          {value || FALLBACK_STRING_DASH}
        </Typography>
      }
      render={({ field: { value } }) => {
        return value ? (
          <Group spacing={1} flexDirection="column">
            <Box alignItems="flex-start">
              <MuiBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  whiteSpace: 'nowrap',
                }}
              >
                <RouterLink
                  to={PageRoute.USER}
                  params={{ id: createdBy }}
                  noUnderline
                >
                  <Link>
                    <Typography variant="label">
                      {authorName || FALLBACK_STRING_DASH}
                    </Typography>
                  </Link>
                </RouterLink>
                <Typography variant="graphCaption">
                  {createdAt
                    ? format(parseISO(createdAt), 'dd-MM-yyyy')
                    : FALLBACK_STRING_DASH}
                </Typography>
              </MuiBox>
            </Box>
            <MuiBox sx={{ display: 'flex' }}>
              <Typography variant="paragraph2" sx={{ flexGrow: 1 }}>
                {value || FALLBACK_STRING_DASH}
              </Typography>
              {canRemove && (
                <MuiBox sx={{ flexBasis: '120px', flexShrink: 0 }}>
                  <TextButton
                    icon={<CloseIcon />}
                    onClick={() => {
                      setValue(name, null, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                      save()
                    }}
                  >
                    Remove
                  </TextButton>
                </MuiBox>
              )}
            </MuiBox>
          </Group>
        ) : (
          <></>
        )
      }}
    />
  )
}
