import { Button } from '@maersktankersdigital/web-components'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Modal } from '~components/molecules/modal/modal'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useGetMeVT } from '~hooks/queries/me/use-get-me-vt'
import { usePatchUsersVt } from '~hooks/queries/users/use-patch-users-vt'
import { content } from '~pages/open-pages/terms-and-conditions/content'

export function TermsOfUseModal() {
  const isMtUser = useHasScope(ExactScopeName.maerskTankersUser)

  // isMtUser is undefined while loading, true if the user has the scope, false if the user does not have the scope
  if (isMtUser === undefined || isMtUser) return null

  return <TermsOfUseModalRenderer />
}

function TermsOfUseModalRenderer() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data } = useGetMeVT()
  const { mutate: patchUser } = usePatchUsersVt(data?.sub || undefined)

  useEffect(() => {
    if (data && !data?.ToSAcceptedDate) {
      setIsModalOpen(true)
      return
    }

    setIsModalOpen(false)
  }, [data])

  function onClickHandler() {
    patchUser({
      ToSAcceptedDate: new Date().toISOString(),
    })
    setIsModalOpen(false)
  }

  const { title, article } = content
  return (
    <Box
      sx={{ zIndex: 10000 }}
      component={Modal}
      open={isModalOpen}
      title={title}
    >
      <>
        <Typography component="article" sx={{ mb: 5 }}>
          {article}
        </Typography>
        <Button onClick={onClickHandler}>Accept</Button>
      </>
    </Box>
  )
}
