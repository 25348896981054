import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

import { StyledWrapper } from '~components/layout/wrapper/wrapper.styles'

export const StyledUserPage = styled.div``

export const StyledUserPageContentHeader = styled.div`
  ${StyledWrapper} {
    &:first-child {
      display: flex;
      justify-content: space-between;
      margin-bottom: ${theme.SPACINGS[5]};
    }
  }
`
export const ModalWrapper = styled.div`
  width: 400px;
  min-height: 134px;
  padding: ${theme.SPACINGS[4]};

  button {
    margin-top: ${theme.SPACINGS[2]};
    margin-right: ${theme.SPACINGS[1]};
  }
`
