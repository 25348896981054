import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import LoadComponent from '~components/molecules/load-component/load-component'

export function AuthenticationGuard({ children }: { children: any }) {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isAuthenticated) {
    return <>{children}</>
  }

  if (isLoading) {
    return <LoadComponent />
  }

  const PrivateRoute = () => children
  const Component = withAuthenticationRequired(PrivateRoute, {
    onRedirecting: () => <LoadComponent />,
  })

  return <Component />
}
