import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { removeLocalStorageItems } from '~utils/remove-local-storage-items'

const StyledLogoutButton = styled(Button)<{ wide: boolean }>`
  ${(props) => props.wide && 'width: 200px'}
`
const LogoutButton = ({ wide }: { wide?: boolean }) => {
  const { logout } = useAuth0()

  return (
    <StyledLogoutButton
      wide={!!wide}
      variant="primary"
      onClick={() => {
        removeLocalStorageItems()
        logout({ logoutParams: { returnTo: window.location.origin } })
      }}
    >
      Log Out
    </StyledLogoutButton>
  )
}

export default LogoutButton
