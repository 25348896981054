import { useQuery } from '@tanstack/react-query'

import { tenMinutes } from '~constants/constants'
import { apiBaseV1 } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export function useGetUsers(disabled?: boolean) {
  const fetchRequest = useFetch<User>()
  return useQuery({
    queryKey: ['v1/users'],
    queryFn: () => fetchRequest(`${apiBaseV1()}/users`),
    staleTime: tenMinutes,
    enabled: !disabled,
  })
}

export interface User {
  publicId: string
  name: string
  email: string
  phoneNumber: string
  title: null
  countryCode: string
  partners: any[]
  termsNeedingAccept: null
  allowDataPublish: boolean
  avatar: string
  pools: number[]
  IsStaff: boolean
  companyRole: string
  alias: string
}
