import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from 'src/constants/constants'
import { apiBaseV2 } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

interface MeIDRes {
  sub: string
  user: string
  app: string
  scope: string[]
  profitCenterPools: string[]
  isInternal: boolean
  legacy_authorization: object
  id: string
  Name: string
  Email: string
  PhoneNumber: null
  Title: null
  CountryCode: null
  PartnerId: null
  avatar: null
  CompanyRole: string
  alias: string
  AxCode: null
  PartnerName: null
  IsStaff: number
}

export function useGetMeID() {
  const fetchRequest = useFetch<MeIDRes>()

  // if the user does not exist the in the insite digital db
  // this get request will create the user in the insite digital db
  return useQuery({
    queryKey: ['/v2/me'],
    queryFn: () => fetchRequest(`${apiBaseV2()}/me`),
    staleTime: tenMinutes,
  })
}
