import { useEffect, useState } from 'react'
import { MissingPoolNotification } from '~components/notifications/missing-pool-notification'
import { localStorageItemKeys } from '~constants/local-storage-items'
import { useNotifications } from '~hooks/queries/use-notification-banner'
import { Notification } from './notification'

export function NotificationsBanner() {
  const [hiddenNotifications, setHiddenNotifications] = useState<string[]>([])
  const { data: notifications } = useNotifications()

  useEffect(() => {
    setHiddenNotifications(getNotificationsFromLocalStorage())
  }, [])

  return (
    <>
      <MissingPoolNotification />
      {notifications?.map((notification) => {
        return hiddenNotifications.includes(
          notification.id.toString(),
        ) ? null : (
          <Notification key={notification.id} {...notification} />
        )
      })}
    </>
  )
}

export function getNotificationsFromLocalStorage() {
  const lsItem = localStorage?.getItem(localStorageItemKeys.notificationBanners)
  if (!lsItem) return []

  const parsedLsItem = JSON.parse(lsItem)
  const notificationIds = Array.isArray(parsedLsItem)
    ? parsedLsItem.map((item: any) => item.toString())
    : []
  return notificationIds
}
