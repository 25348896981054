import { FC } from 'react'

import { StyledDivider } from '~components/atoms/divider/styles'

interface IDivider {
  index: number
}

export const Divider: FC<IDivider> = ({ index }) => {
  return <StyledDivider className="divider" index={index} />
}
