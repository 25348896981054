import { Box, Grow, Slide } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { localStorageItemKeys } from '~constants/local-storage-items'
import { ToggleButton } from '../toggle-button'
import { ToggleContent } from './toggle-content'

export const useMock =
  localStorage.getItem(localStorageItemKeys.retainedPostLogout.useMockData) ===
  'true'

export function MswToggle() {
  const [isOpen, setIsOpen] = useState(false)
  const [isMocking, setIsMocking] = useState(false)

  useEffect(() => {
    setIsMocking(useMock)
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    localStorage.setItem(
      localStorageItemKeys.retainedPostLogout.useMockData,
      isChecked ? 'true' : 'false',
    )
    setIsMocking(isChecked)
    window.location.reload()
  }

  if (!import.meta.env.DEV) return null

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1201,
        bottom: 12,
        right: 70,
      }}
    >
      <Slide in={!isOpen} direction="up">
        <ToggleButton
          isMocking={isMocking}
          onClick={() => setIsOpen(!isOpen)}
        />
      </Slide>
      {isOpen && (
        <Grow in>
          <ToggleContent
            isMocking={isMocking}
            handleChange={handleChange}
            onClose={() => setIsOpen(!isOpen)}
          />
        </Grow>
      )}
    </Box>
  )
}
