import { FunctionComponent } from 'react'

import Paragraph from '~components/atoms/typography/paragraph/paragraph'

import { StyledColorLabelShort } from './color-label-short.styles'

export type ColorVariants = 'portFunction' | 'CII'

type ColorLabelShortProps = {
  color?: string
  label: string
  size?: 'small' | 'medium'
  variant?: ColorVariants
  variantType?: string
  weight?: 'bold' | 'normal'
}

export const ColorLabelShort: FunctionComponent<ColorLabelShortProps> = ({
  variant,
  label,
  variantType,
  weight,
  color,
  size = 'medium',
}) => (
  // For styling, we will only allow either both `variant` and `variantType` to be given, or `color`
  // @ts-ignore
  <StyledColorLabelShort
    {...(variant && variantType
      ? { variant, variantType: variantType || label }
      : color
        ? { color }
        : {})}
    size={size}
  >
    <Paragraph size={size} as="span" weight={weight}>
      {label.charAt(0)}
    </Paragraph>
  </StyledColorLabelShort>
)
