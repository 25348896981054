import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'

export interface MuiAppBarData {
  defaultProps?: ComponentsProps['MuiAppBar']
  styleOverrides?: ComponentsOverrides['MuiAppBar']
  variants?: ComponentsVariants['MuiAppBar']
}

export const MuiAppBar: MuiAppBarData = {
  styleOverrides: {
    root: {
      backgroundColor: COLORS.primary.white,
      boxShadow: 'none',
      color: COLORS.secondary.darkBlue,
    },
  },
}
