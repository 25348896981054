import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

export const StyledWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 56px;

  @media only screen and (${theme.BREAKPOINTS.xxl}) {
    padding: 0 152px;
  }
`
