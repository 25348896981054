import { TextButton, theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

export const StyledTextButton = styled(TextButton)`
  margin-bottom: ${theme.SPACINGS[3]};
  transform: translateY(50%);
`

export const LegendText = styled.div`
  font-family: ${theme.FONTS.families.bold};
  font-size: ${theme.FONTS.sizes.xs};
  color: ${COLORS.secondary.darkBlue};
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: ${theme.SPACINGS[4]};
`

export const barStyles = {
  radius: [7.5, 7.5, 7.5, 7.5] as [number, number, number, number],
  barSize: 8,
}
