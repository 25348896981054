import { Button } from '@maersktankersdigital/web-components'
import { Cookie } from '../cookie-banner-v2'
import { saveCookieSettings } from '../utils/save-cookie-settings'

export function AcceptAllButton({ closeModal }: { closeModal: () => void }) {
  const optionalCookies = [Cookie['statistical']]

  return (
    <Button
      variant="primary"
      onClick={() => {
        saveCookieSettings(new Set(optionalCookies))
        closeModal()
      }}
    >
      Accept all
    </Button>
  )
}
