import { apiUploadFileAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'

export interface IDocumentAddPayload {
  documentName: string
  expires?: Date
  file: File
  issued?: Date
  type: string
}

export function documentAddAction(
  payload: IDocumentAddPayload,
  vesselId: string,
): Promise<IDocumentAddResponse> {
  return apiUploadFileAction<IDocumentAddPayload, IDocumentAddResponse>(
    ApiRoutes.Vessels.Documents.add,
    payload,
    { vesselId },
  )
}
export interface IDocumentAddResponse {
  documentName: string
  error?: string
  message?: string
  statusCode?: number
  type: string
  updatedAt: string
  updatedBy: string
}
