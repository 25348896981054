import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'

export interface MuiTooltipData {
  defaultProps?: ComponentsProps['MuiTab']
  styleOverrides?: ComponentsOverrides['MuiTab']
  variants?: ComponentsVariants['MuiTab']
}

export const MuiTab: MuiTooltipData = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      fontFamily: 'MaerskTextRegular',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      color: COLORS.greys.dark,
    },
  },
}
