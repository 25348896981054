// https://github.com/typescript-eslint/typescript-eslint/issues/1856
import type { Context } from 'react'
import { useContext } from 'react'

/**
 * The createContextStateHook function creates a custom hook
 * for the specified React Context.
 * It's a simple Wrapper around the `useContext` hook, which will
 * also throw an error of the accessed context state is invalid (!state)
 *
 * @param Context React Context created using React.createContext();
 * @param name optional second parameter that is used in the error display
 *
 * @returns a react hook
 *
 * @example ```typescript
 *
 * const CounterContext = createContext<{counter: number}|null>(null);
 *
 * const useCounterState = createContextStateHook(CounterContext, 'CounterContext');
 *
 * const ChildComponent = () => {
 *   const state = useCounterState();
 *   return <p>Counter: {state.counter}</p>
 * }
 *
 * const WrapperComponent = () => {
 *   const state = {counter: 0};
 *   return <CounterContext.Provider value={state}><ChildComponent/></CounterContext.Provider>
 * }
 *```
 */
function createContextStateHook<T>(Context: Context<T>, name?: string) {
  return () => {
    const state = useContext(Context)

    if (!state) {
      throw new Error(
        `Can't access ${name || 'unnamed '} context as it is undefined`,
      )
    }

    return state!
  }
}

export default createContextStateHook
