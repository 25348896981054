import { Typography, useBreakpoint } from '@maersktankersdigital/web-components'
import { FC, ReactNode } from 'react'

import { IVesselsReadResponse } from '~api/vessels/read'
import { RouterLink } from '~components/atoms/router-link'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'

import { getHeaderDetailsContent } from './header-details.data'
import {
  HeaderDetailsWrapper,
  StyledHeaderDetailsWrapper,
  StyledLabel,
  StyledLabelValueRenderer,
} from './header-details.styles'

export interface HeaderDetail {
  guard?: {
    component: FC
  }
  label: string
  routerLink?: string
  value: string | ReactNode
}

export function HeaderDetails({ vessel }: { vessel: IVesselsReadResponse }) {
  const { breakpoint } = useBreakpoint()

  if (!vessel) return null

  const columns = getHeaderDetailsContent({
    vesselData: vessel,
    isXlBreakpoint: breakpoint === 'xxl',
  })

  return (
    <StyledHeaderDetailsWrapper>
      {columns.map((column, index) => (
        <HeaderDetailsColumn key={index} details={column} />
      ))}
    </StyledHeaderDetailsWrapper>
  )
}

function HeaderDetailsColumn({ details }: { details: HeaderDetail[] }) {
  return (
    <HeaderDetailsWrapper>
      {details.map(({ label, value, guard, routerLink }) => {
        const GuardComponent = guard?.component
        const dataComponent =
          typeof value === 'string' ? <Paragraph>{value}</Paragraph> : null

        if (!guard) {
          return (
            <LabelValueRenderer key={`${label}-${value}`} label={label}>
              <Typography variant="caption">{value}</Typography>
            </LabelValueRenderer>
          )
        }

        return (
          <LabelValueRenderer key={`${label}-${value}`} label={label}>
            {/* @ts-ignore JSX element does not have any construct or call signatures */}
            <GuardComponent alternateContent={dataComponent}>
              {routerLink ? (
                <Typography variant="caption">
                  <RouterLink to={routerLink}>{value}</RouterLink>
                </Typography>
              ) : (
                dataComponent
              )}
            </GuardComponent>
          </LabelValueRenderer>
        )
      })}
    </HeaderDetailsWrapper>
  )
}

function LabelValueRenderer({
  label,
  children,
}: {
  children: ReactNode
  label: string
}) {
  return (
    <StyledLabelValueRenderer>
      <StyledLabel variant="label">{label}</StyledLabel>
      {children}
    </StyledLabelValueRenderer>
  )
}
