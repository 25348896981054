import { COLORS } from '~theme/colors'
import { MuiAccordion } from './accordion'
import { MuiAppBar } from './app-bar'
import { MuiAutocomplete } from './autocomplete'
import { MuiBackdrop } from './backdrop'
import { MuiCheckbox } from './checkbox'
import { MuiDrawer } from './drawer'
import { MuiFormControlLabel } from './form-control-label'
import { MuiListItem } from './list-item'
import { MuiListItemIcon } from './list-item-icon'
import { MuiSkeleton } from './skeleton'
import { MuiTab } from './tab'
import { MuiTableRow } from './table-row'
import { MuiTextField } from './text-field'
import { MuiTooltip } from './tool-tip'

export const createComponents = () => ({
  MuiAutocomplete,
  MuiTextField,
  MuiAccordion,
  MuiAppBar,
  MuiBackdrop,
  MuiCheckbox,
  MuiDrawer,
  MuiFormControlLabel,
  MuiListItem,
  MuiListItemIcon,
  MuiTab,
  MuiTooltip,
  MuiSkeleton,
  MuiTableRow,
  MuiTypography: {
    styleOverrides: {
      root: {
        color: COLORS.secondary.darkBlue,
      },
    },
  },
})
