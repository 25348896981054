import { Checkbox, CheckboxProps } from '@maersktankersdigital/web-components'
import { useEffect, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { Group } from '~components/molecules/group/group'
import { IOption } from '~types/components'

export interface ICheckboxGroup {
  defaultValue?: string[] | null
  isDisabled?: boolean
  name: string
  options: IOption[]
  required?: boolean
  withWrapper?: boolean
}

type CheckboxGroupProps = ICheckboxGroup & CheckboxProps

export const CheckboxGroup = ({
  defaultValue,
  isDisabled,
  name,
  options,
  required,
  withWrapper = true,
}: CheckboxGroupProps) => {
  const { control } = useFormContext()
  const { field } = useController({
    control,
    name,
    rules: { required: required },
  })
  const [value, setValue] = useState(field.value || [])

  useEffect(() => {
    if (defaultValue && defaultValue !== value) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const checkboxes = options.map((option) => {
    return (
      <Checkbox
        key={option.value}
        name={option.label}
        value={option.value}
        disabled={isDisabled}
        label={option.label}
        checked={value?.includes?.(option.value)}
        onChange={(e) => {
          let valueCopy = [...value]
          if (e.target.checked) {
            valueCopy.push(e.target.value)
          } else {
            valueCopy = valueCopy.filter((el: string) => el !== e.target.value)
          }
          field.onChange(valueCopy)
          setValue(valueCopy)
        }}
      />
    )
  })

  return withWrapper ? (
    <Group spacing={1}>{checkboxes}</Group>
  ) : (
    <>{checkboxes}</>
  )
}
