// THE ORDER OF PERMISSIONS IS IMPORTANT
// DO NOT CHANGE IT

export const technicalManagerPermissions = [
  'avatar.edit',
  'contact.show',
  'news.show',
  'faq.show',
  'userPanel.show',
  'technical.show',
  'vesselsEarnings.show',
  'vps.show',
  'userPassword.change',
  'userDetails.edit',
]

export const poolPartnerPermissions = [
  'avatar.edit',
  'calendar.show',
  'contact.show',
  'documents.show',
  'reports.show',
  'earnings.show',
  'forecast.show',
  'emissionUniverse.show',
  'map.show',
  'news.show',
  'faq.show',
  'userPanel.show',
  'vesselDetails.show',
  'vesselsEarnings.show',
  'vps.show',
  'userPassword.change',
  'userDetails.edit',
  'poolPartners.show',
  'welcome_popup.show',
  'dashboard.show',
]
