import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

export const LastSirePopupContent = styled.div`
  background-color: ${COLORS.primary.white};
  padding: 1rem;
  box-shadow: ${theme.SHADOWS.cardShadow};
  border-radius: 2px;
`
