import { GoToIcon } from '@maersktankersdigital/web-components'
import { Divider, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useSecondaryNavLinks } from '~components/navigation-and-footer/navigation/left-navigation/links/secondary'
import { COLORS } from '~theme/colors'
import { MenuItem } from './components/menu-item'
import { usePrimaryNavLinks } from './links/primary'

export function LeftNavigation() {
  const primaryNavLinks = usePrimaryNavLinks()
  const secondaryNavLinks = useSecondaryNavLinks()

  return (
    <Box sx={{ overflow: 'auto' }}>
      <List>
        {primaryNavLinks?.map((item) => {
          return <MenuItem key={item.label} {...item} />
        })}
        {secondaryNavLinks.length > 0 && (
          <Divider sx={{ my: 4, borderColor: COLORS.secondary.darkBlue }} />
        )}
        {secondaryNavLinks.map((item) => {
          return (
            <ListItem key={item.label}>
              <a href={item.link} rel="noopener" target="blank">
                <Typography variant="menu" sx={{ mr: 1 }}>
                  {item.label}
                </Typography>
              </a>
              <GoToIcon />
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}
