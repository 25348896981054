import { Params } from 'react-router-dom'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'
import { IError } from '~api/types'
import { generatePathName } from '~utils/generate-path-name'

export const useApi = <T>(
  url: string,
  params?: Params,
  options?: SWRConfiguration,
): SWRResponse<T, IError> => {
  const requestUrl = url ? generatePathName(url, params) : null
  const { data, error, isValidating, isLoading, mutate } = useSWR<T, IError>(
    requestUrl,
    {
      revalidateOnMount: true,
      ...options,
    },
  )

  return { data, error, isValidating, isLoading, mutate }
}
