import { Label } from '@maersktankersdigital/web-components'
import { FC } from 'react'

import { Box } from '~components/atoms/box'
import { FormError } from '~components/forms/form-error/form-error'
import { RadiobuttonGroup } from '~components/molecules/radiobutton-group/radiobutton-group'
import { IOption } from '~types/components'

interface IRadiobuttonGroupField {
  defaultValue?: string | null
  isDisabled?: boolean
  label?: string
  name: string
  options: IOption[]
  required?: boolean
}

export const RadiobuttonGroupField: FC<IRadiobuttonGroupField> = ({
  name,
  required,
  label,
  options,
  isDisabled,
  defaultValue,
}) => {
  return (
    <>
      {label && <Label label={label} />}
      <RadiobuttonGroup
        name={name}
        required={required}
        options={options}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
      />
      <Box>
        <FormError name={name} />
      </Box>
    </>
  )
}
