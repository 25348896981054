import { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useStore } from '~context/store-provider'
import { TagFilterRenderer } from '../deault/tag-filter-renderer'
import { filtersParamName, TagFilterWrapperProps } from '../tag-filter'

export const DeprecatedTagFilterWrapper: FC<TagFilterWrapperProps> = ({
  filters,
}) => {
  const store = useStore()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const searchParamTagFilters = searchParams.get(filtersParamName)
    store.setTagFilters(searchParamTagFilters?.split(',') || [])
  }, [searchParams])

  useEffect(() => {
    const searchParamTagFilters = searchParams.get(filtersParamName)
    const newSearchParams = store.tagFilters.join(',') || null

    if (newSearchParams !== null && newSearchParams !== searchParamTagFilters) {
      searchParams.set(filtersParamName, newSearchParams)
    }

    setSearchParams(searchParams)
  }, [store.tagFilters])

  function onClearClick() {
    store.setTagFilters([])
  }

  function onTagClick({ id }: { id: string }) {
    store.setTagFilters([
      ...(store.tagFilters.includes(id)
        ? store.tagFilters.filter((filter: string) => filter !== id)
        : [...store.tagFilters, id]),
    ])
  }

  function isTagActive({ id }: { id: string }) {
    return Boolean(store.tagFilters?.includes(id))
  }
  return (
    <TagFilterRenderer
      filters={filters}
      onClearClick={onClearClick}
      onTagClick={onTagClick}
      isTagActive={isTagActive}
    />
  )
}
