import { forwardRef, Ref } from 'react'

import { StyledParagraph } from '~components/atoms/typography/paragraph/paragraph.styles'

import { PropsFromStyledComponent } from '../../../../utils/type-utils/styled'

type ParagraphType = 'span' | 'p'

export type ParagraphProps = PropsFromStyledComponent<
  typeof StyledParagraph
> & {
  type?: ParagraphType
}

const Paragraph = (
  { type, ...props }: ParagraphProps,
  ref: Ref<HTMLParagraphElement>,
) => <StyledParagraph ref={ref} as={type} {...props} />

export default forwardRef(Paragraph)
