import { createTheme } from '@mui/material/styles'

import { createComponents } from './components'
import { breakpoints, palette, spacing, typography } from './global'

const themeOptions = createTheme({
  breakpoints,
  palette,
  spacing,
  // @ts-ignore
  typography,
})
themeOptions.components = createComponents()

export default themeOptions
