import { ChevronDownIcon } from '@maersktankersdigital/web-components'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { forwardRef } from 'react'
import { COLORS } from '~theme/colors'

export const ToggleContent = forwardRef<
  HTMLDivElement,
  {
    isMocking: boolean
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onClose: () => void
  }
>(({ isMocking, handleChange, onClose }, ref) => {
  return (
    <Box ref={ref}>
      <Box
        sx={{
          border: `solid 4px ${COLORS.primary.maerskBlue}`,
          borderBottom: 'none',
          backgroundColor: 'white',
          width: 'fit-content',
          borderRadius: '8px 8px 0 0',
          ml: 'auto',
          mr: 4,
          px: 1,
          mb: -1,
          zIndex: 1202,
          position: 'relative',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: COLORS.greys.light,
          },
        }}
        onClick={onClose}
      >
        <ChevronDownIcon />
      </Box>
      <Box
        sx={{
          border: `solid 4px ${COLORS.primary.maerskBlue}`,
          backgroundColor: 'white',
          borderRadius: '16px',
          p: 3,
          pb: 0,
        }}
      >
        <Typography variant="graphCaption" component="p">
          {isMocking
            ? 'Mocking is currently enabled'
            : 'Mocking is currently disabled'}
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={isMocking} onChange={handleChange} />}
          label="mocking"
        />
      </Box>
    </Box>
  )
})
