import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'
import { typography } from '~theme/global'

export interface MuiAutocompleteData {
  defaultProps?: ComponentsProps['MuiAutocomplete']
  styleOverrides?: ComponentsOverrides['MuiAutocomplete']
  variants?: ComponentsVariants['MuiAutocomplete']
}

export const MuiAutocomplete: MuiAutocompleteData = {
  styleOverrides: {
    root: {
      '& .MuiInputLabel-root': {
        transform: 'translate(0, -38px) scale(1)',
        padding: '8px 0',
        transition: 'none',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, -38px) scale(1)',
      },
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          top: '0',
          legend: {
            display: 'none',
          },
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.primary.maerskBlue,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.feedback.red.error,
        },
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: COLORS.feedback.red.error,
      },
    },
    popper: {
      ul: {
        maxHeight: '300px',
        ...typography.paragraph2,
      },
    },
  },
}
