import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { IUsersResponse } from '~api/users/all'

import { apiGetAction } from '../actions'

// TODO: Add correct response
export type IUsersReadResponse = IUsersResponse

export function usersReadByVesselIdAction(
  urlParams?: Params,
): Promise<IUsersResponse[]> {
  return apiGetAction<IUsersResponse[]>(
    ApiRoutes.Users.readByVesselId,
    urlParams,
  )
}

export function usersReadByPoolPartnerAction(
  urlParams?: Params,
): Promise<IUsersResponse[]> {
  return apiGetAction<IUsersResponse[]>(
    ApiRoutes.Users.readByPoolPartner,
    urlParams,
  )
}
