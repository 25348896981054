const retainedPostLogout = {
  showHorizontalScrollMessage: 'show_horizontal_scroll_message',
  allowedCookies: 'allowed_cookies',
  useMockData: 'use_mock_data',
}

export const localStorageItemKeys = {
  cookieAccepted: 'cookie_accepted',
  notificationBanners: 'HideNotificationBanners',
  persistRoot: 'persist:root',
  me: 'me',
  welcomePopupClosed: 'welcome_popup_closed',
  emailSubscriptions: 'email_subscriptions',
  retainedPostLogout,
}
