import { theme, Typography } from '@maersktankersdigital/web-components'
import { FC } from 'react'
import styled from 'styled-components/macro'
import { Box } from '~components/atoms/box'
import { PopupContentWrapper } from '~pages/pages-behind-login/position-list/position-list.styles'

interface ITooltipContent {
  lastSireWithin4Months: boolean
  rejections: any[]
}

export const StyledPopupContent = styled(PopupContentWrapper)`
  width: fit-content;
  max-width: 180px;
  display: flex;
  flex-direction: column;
`

export const StyledHeadline = styled(Typography).attrs({
  variant: 'headline3',
})`
  margin-bottom: ${theme.SPACINGS[1]};
`

export const PositionListRejectionsTooltipContent: FC<ITooltipContent> = ({
  rejections,
  lastSireWithin4Months,
}) => {
  return (
    <StyledPopupContent>
      <StyledHeadline>REJECTIONS</StyledHeadline>
      {rejections.map((rejection, i) => (
        <Typography variant="paragraph2" key={i}>
          {rejection}
        </Typography>
      ))}
      {!lastSireWithin4Months && (
        <Box mt={1.2}>
          <Typography variant="caption">SIRE</Typography>
          <Typography variant="paragraph2">SIRE ABOVE 4 MONTHS</Typography>
        </Box>
      )}
    </StyledPopupContent>
  )
}
