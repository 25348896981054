export const mockEarningsMonthly = {
  averagePoolEarnings: {
    poolId: 2,
    poolPoints: 112.23639,
    bunkerAdjustmentPerDay: null,
    earnings: 22428.353578,
    bunkerAdjustment: -0.00019,
    scrubberBonus: 109.887321,
    specialCompensations: 0.748713,
    wafDta: 28.548858,
    tce: 22567.538212,
    names: ['Handy'],
  },
  averageFilteredEarnings: {
    poolId: 2,
    poolPoints: 112.23639,
    bunkerAdjustmentPerDay: null,
    earnings: 22428.353578,
    bunkerAdjustment: -0.00019,
    scrubberBonus: 109.887321,
    specialCompensations: 0.748713,
    wafDta: 28.548858,
    tce: 22567.538212,
    names: ['test'],
  },
  vesselsEarnings: [
    {
      id: 181137,
      name: 'Advantage Party',
      vesselCode: 'NR7',
      imo: 9327360,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 116.03,
      earnings: 23168.62,
      bunkerAdjustment: -1554.08,
      bunkerAdjustmentPerDay: 2.31,
      scrubberBonus: 0,
      specialCompensations: -321.30851,
      wafDta: 0,
      tce: 21293.23,
      partner: {
        id: 199,
        name: 'Advantage Tankers',
        axCode: 'APS',
      },
    },
    {
      id: 181019,
      name: 'Baltic',
      vesselCode: 'LQ4',
      imo: 9253258,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 111.29,
      earnings: 22230.29,
      bunkerAdjustment: -221.61,
      bunkerAdjustmentPerDay: 0.33,
      scrubberBonus: 0,
      specialCompensations: 364.465665,
      wafDta: 0,
      tce: 22373.14,
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
    },
    {
      id: 181025,
      name: 'Bassilevousa',
      vesselCode: 'LQ8',
      imo: 9295335,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 112.73,
      earnings: 22528.35,
      bunkerAdjustment: -62.74,
      bunkerAdjustmentPerDay: 0.09,
      scrubberBonus: 0,
      specialCompensations: 29.912903,
      wafDta: 0,
      tce: 22495.53,
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
    },
    {
      id: 180521,
      name: 'Bloom',
      vesselCode: 'U87',
      imo: 9365283,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 112,
      earnings: 22380.68,
      bunkerAdjustment: -797.8,
      bunkerAdjustmentPerDay: 1.19,
      scrubberBonus: 0,
      specialCompensations: -43.009354,
      wafDta: 0,
      tce: 21539.87,
      partner: {
        id: 86,
        name: 'Marwave',
        axCode: 'MAR',
      },
    },
    {
      id: 181099,
      name: 'Cb Baltic',
      vesselCode: 'NI6',
      imo: 9851701,
      hasScrubber: true,
      isMyVessel: true,
      poolPoints: 120.44,
      earnings: 24091.05,
      bunkerAdjustment: -244.06,
      bunkerAdjustmentPerDay: 0.36,
      scrubberBonus: 3092.98,
      specialCompensations: -18.212807,
      wafDta: 0,
      tce: 26921.76,
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
    },
    {
      id: 181028,
      name: 'Cb Pacific',
      vesselCode: 'LS3',
      imo: 9851725,
      hasScrubber: true,
      isMyVessel: true,
      poolPoints: 120.39,
      earnings: 24077.84,
      bunkerAdjustment: 394.02,
      bunkerAdjustmentPerDay: -0.59,
      scrubberBonus: 502.01,
      specialCompensations: 259.622902,
      wafDta: 0,
      tce: 25233.49,
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
    },
    {
      id: 181141,
      name: 'Eastern Quince',
      vesselCode: 'NS9',
      imo: 9379040,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 118.5,
      earnings: 23695.52,
      bunkerAdjustment: -1513.43,
      bunkerAdjustmentPerDay: 2.25,
      scrubberBonus: 0,
      specialCompensations: -193.570967,
      wafDta: 0,
      tce: 21988.53,
      partner: {
        id: 199,
        name: 'Advantage Tankers',
        axCode: 'APS',
      },
    },
    {
      id: 180628,
      name: 'Hans Maersk',
      vesselCode: 'KG9',
      imo: 9389497,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 115.48,
      earnings: 23084.63,
      bunkerAdjustment: -760.92,
      bunkerAdjustmentPerDay: 1.13,
      scrubberBonus: 0,
      specialCompensations: -15.905806,
      wafDta: 0,
      tce: 22307.8,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180631,
      name: 'Helene Maersk',
      vesselCode: 'KH2',
      imo: 9389514,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 116.51,
      earnings: 23292.98,
      bunkerAdjustment: -877.3,
      bunkerAdjustmentPerDay: 1.31,
      scrubberBonus: 0,
      specialCompensations: -13.036774,
      wafDta: 0,
      tce: 22402.64,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180467,
      name: 'Henning Maersk',
      vesselCode: 'KH3',
      imo: 9389526,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 114.5,
      earnings: 22886.39,
      bunkerAdjustment: -618.13,
      bunkerAdjustmentPerDay: 0.92,
      scrubberBonus: 0,
      specialCompensations: 12.558709,
      wafDta: 0,
      tce: 22280.82,
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
    },
    {
      id: 180932,
      name: 'Henriette Maersk',
      vesselCode: 'KH4',
      imo: 9399349,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 115.49,
      earnings: 23086.65,
      bunkerAdjustment: -320.4,
      bunkerAdjustmentPerDay: 0.48,
      scrubberBonus: 0,
      specialCompensations: 264.356451,
      wafDta: 0,
      tce: 23030.61,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180456,
      name: 'Henry Maersk',
      vesselCode: 'KH5',
      imo: 9399351,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 116.47,
      earnings: 23284.89,
      bunkerAdjustment: -169.06,
      bunkerAdjustmentPerDay: 0.25,
      scrubberBonus: 0,
      specialCompensations: -69.616153,
      wafDta: 0,
      tce: 23046.21,
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
    },
    {
      id: 180933,
      name: 'Hulda Maersk',
      vesselCode: 'KH6',
      imo: 9399363,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 116.48,
      earnings: 23286.91,
      bunkerAdjustment: -261.01,
      bunkerAdjustmentPerDay: 0.39,
      scrubberBonus: 0,
      specialCompensations: -32.107096,
      wafDta: 0,
      tce: 22993.79,
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
    },
    {
      id: 180659,
      name: 'Kirsten Maersk',
      vesselCode: 'J2L',
      imo: 9431264,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 116.86,
      earnings: 23363.78,
      bunkerAdjustment: 216.47,
      bunkerAdjustmentPerDay: -0.32,
      scrubberBonus: 0,
      specialCompensations: -32.642258,
      wafDta: 0,
      tce: 23547.61,
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
    },
    {
      id: 181109,
      name: 'Lady Rina',
      vesselCode: 'NK3',
      imo: 9631383,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 111.16,
      earnings: 22210.76,
      bunkerAdjustment: 174.15,
      bunkerAdjustmentPerDay: -0.26,
      scrubberBonus: 0,
      specialCompensations: -18.249354,
      wafDta: 0,
      tce: 22366.67,
      partner: {
        id: 211,
        name: 'Mercuria Shipping',
        axCode: 'MSH',
      },
    },
    {
      id: 180678,
      name: 'Maersk Adriatic',
      vesselCode: 'U00',
      imo: 9636632,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 113.77,
      earnings: 22738.72,
      bunkerAdjustment: 623.25,
      bunkerAdjustmentPerDay: -0.93,
      scrubberBonus: 0,
      specialCompensations: -37.968387,
      wafDta: 0,
      tce: 23324,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180679,
      name: 'Maersk Aegean',
      vesselCode: 'U19',
      imo: 9636644,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 113.77,
      earnings: 22738.72,
      bunkerAdjustment: 186.6,
      bunkerAdjustmentPerDay: -0.28,
      scrubberBonus: 0,
      specialCompensations: -31.984193,
      wafDta: 806.45,
      tce: 23699.79,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180682,
      name: 'Maersk Barry',
      vesselCode: 'J2M',
      imo: 9299458,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 105.52,
      earnings: 21069.89,
      bunkerAdjustment: 1816.84,
      bunkerAdjustmentPerDay: -2.7,
      scrubberBonus: 0,
      specialCompensations: 44.217741,
      wafDta: 0,
      tce: 22930.95,
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
    },
    {
      id: 180683,
      name: 'Maersk Beaufort',
      vesselCode: '2Q9',
      imo: 9340594,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 106.51,
      earnings: 21270.15,
      bunkerAdjustment: 374.22,
      bunkerAdjustmentPerDay: -0.56,
      scrubberBonus: 0,
      specialCompensations: -10.178387,
      wafDta: 0,
      tce: 21634.19,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180684,
      name: 'Maersk Belfast',
      vesselCode: '2Q6',
      imo: 9299446,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 104.02,
      earnings: 20766.47,
      bunkerAdjustment: -17.29,
      bunkerAdjustmentPerDay: 0.03,
      scrubberBonus: 0,
      specialCompensations: 6.849333,
      wafDta: 0,
      tce: 20756.02,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180685,
      name: 'Maersk Bering',
      vesselCode: '046',
      imo: 9299422,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 103.03,
      earnings: 20560.39,
      bunkerAdjustment: 208.06,
      bunkerAdjustmentPerDay: -0.31,
      scrubberBonus: 0,
      specialCompensations: -55.46958,
      wafDta: 0,
      tce: 20712.98,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180689,
      name: 'Maersk Bristol',
      vesselCode: 'J2N',
      imo: 9299434,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 104.03,
      earnings: 20768.49,
      bunkerAdjustment: 795.42,
      bunkerAdjustmentPerDay: -1.18,
      scrubberBonus: 0,
      specialCompensations: 6.99387,
      wafDta: 0,
      tce: 21570.9,
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
    },
    {
      id: 181140,
      name: 'Maersk Kalea',
      vesselCode: 'J6C',
      imo: 9256298,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 112.44,
      earnings: 22559.92,
      bunkerAdjustment: -172.2,
      bunkerAdjustmentPerDay: 0.25,
      scrubberBonus: 0,
      specialCompensations: -461.108433,
      wafDta: 0,
      tce: 21926.6,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180725,
      name: 'Maersk Kara',
      vesselCode: 'U65',
      imo: 9374428,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 112.7,
      earnings: 22522.28,
      bunkerAdjustment: 348.88,
      bunkerAdjustmentPerDay: -0.52,
      scrubberBonus: 0,
      specialCompensations: -10.524838,
      wafDta: 0,
      tce: 22860.64,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180727,
      name: 'Maersk Katarina',
      vesselCode: '200',
      imo: 9431290,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 116.86,
      earnings: 23363.78,
      bunkerAdjustment: -254.53,
      bunkerAdjustmentPerDay: 0.38,
      scrubberBonus: 0,
      specialCompensations: -23.30129,
      wafDta: 0,
      tce: 23085.95,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180728,
      name: 'Maersk Kate',
      vesselCode: '198',
      imo: 9431276,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 116.88,
      earnings: 23367.82,
      bunkerAdjustment: 236.6,
      bunkerAdjustmentPerDay: -0.35,
      scrubberBonus: 0,
      specialCompensations: -22.258709,
      wafDta: 0,
      tce: 23582.16,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 180729,
      name: 'Maersk Kaya',
      vesselCode: 'KG8',
      imo: 9431288,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 116.37,
      earnings: 23264.66,
      bunkerAdjustment: -459.09,
      bunkerAdjustmentPerDay: 0.68,
      scrubberBonus: 0,
      specialCompensations: -44.695161,
      wafDta: 0,
      tce: 22760.87,
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
    },
    {
      id: 181151,
      name: 'Magic Star',
      vesselCode: 'NW8',
      imo: 9420253,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 116.21,
      earnings: 23228.49,
      bunkerAdjustment: 0,
      bunkerAdjustmentPerDay: 0,
      scrubberBonus: 0,
      specialCompensations: 0,
      wafDta: 0,
      tce: 23228.49,
      partner: {
        id: 211,
        name: 'Mercuria Shipping',
        axCode: 'MSH',
      },
    },
    {
      id: 181094,
      name: 'OM Borneo',
      vesselCode: 'NG7',
      imo: 9341445,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 106.51,
      earnings: 21270.15,
      bunkerAdjustment: 1167.07,
      bunkerAdjustmentPerDay: -1.74,
      scrubberBonus: 0,
      specialCompensations: -64.693548,
      wafDta: 0,
      tce: 22372.53,
      partner: {
        id: 178,
        name: 'OM Maritime',
        axCode: 'OMO',
      },
    },
    {
      id: 181123,
      name: 'OM Singapore',
      vesselCode: 'NM8',
      imo: 9341433,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 106.51,
      earnings: 21270.15,
      bunkerAdjustment: 1242.63,
      bunkerAdjustmentPerDay: -1.85,
      scrubberBonus: 0,
      specialCompensations: -31.227419,
      wafDta: 0,
      tce: 22481.55,
      partner: {
        id: 178,
        name: 'OM Maritime',
        axCode: 'OMO',
      },
    },
    {
      id: 181149,
      name: 'Robert Maersk',
      vesselCode: 'J6I',
      imo: 9237008,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 100.9,
      earnings: 20137.11,
      bunkerAdjustment: -1947.42,
      bunkerAdjustmentPerDay: 2.9,
      scrubberBonus: 0,
      specialCompensations: 0,
      wafDta: 0,
      tce: 18189.69,
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
    },
    {
      id: 180884,
      name: 'Sloman Themis',
      vesselCode: 'J7T',
      imo: 9306677,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 105.31,
      earnings: 21027.41,
      bunkerAdjustment: 473.23,
      bunkerAdjustmentPerDay: -0.7,
      scrubberBonus: 0,
      specialCompensations: -4.297096,
      wafDta: 0,
      tce: 21496.34,
      partner: {
        id: 63,
        name: 'Sloman-Neptun',
        axCode: 'SLO',
      },
    },
    {
      id: 180885,
      name: 'Sloman Thetis',
      vesselCode: 'J7U',
      imo: 9306653,
      hasScrubber: false,
      isMyVessel: false,
      poolPoints: 105.33,
      earnings: 21030.88,
      bunkerAdjustment: 114.79,
      bunkerAdjustmentPerDay: -0.17,
      scrubberBonus: 0,
      specialCompensations: -28.036745,
      wafDta: 0,
      tce: 21117.62,
      partner: {
        id: 63,
        name: 'Sloman-Neptun',
        axCode: 'SLO',
      },
    },
  ],
}
export const mockEarningsMonthlyXs = {
  averagePoolEarnings: {
    names: ['City'],
    poolPoints: 115.045,
    bunkerAdjustmentPerDay: null,
    earnings: 9882.99,
    bunkerAdjustment: 0.0,
    scrubberBonus: 0.0,
    specialCompensations: 0.0,
    wafDta: 0.0,
    tce: 9882.99,
  },
  averageFilteredEarnings: null,
  // {
  //   'names': [
  //     'Asia Pacific Shipping Company Ltd',
  //   ],
  //   'poolPoints': 109.6226,
  //   'bunkerAdjustmentPerDay': null,
  //   'earnings': 14920.6083,
  //   'bunkerAdjustment': 331.4816,
  //   'scrubberBonus': 0.0,
  //   'specialCompensations': -400.9317,
  //   'wafDta': 0.0,
  //   'tce': 14851.1581,
  // },
  vesselsEarnings: [
    {
      id: 181258,
      vesselCode: 'NC6',
      partner: {
        id: 1121,
        name: 'EGPN Bulk Carrier Co.,LTD',
        axCode: 'EBC',
      },
      name: 'Eastern Petunia',
      hasScrubber: false,
      poolPoints: 117.09,
      bunkerAdjustmentPerDay: 0.0,
      earnings: 10058.6664,
      bunkerAdjustment: 0.0,
      scrubberBonus: 0.0,
      specialCompensations: 28.1064,
      tce: 10086.7729,
      wafDta: 0.0,
    },
    {
      id: 181289,
      vesselCode: 'NK2',
      partner: {
        id: 1138,
        name: 'Petro-Nav Inc.',
        axCode: 'PNA',
      },
      name: 'Mia Desgagnes',
      hasScrubber: false,
      poolPoints: 113.0,
      bunkerAdjustmentPerDay: 0.0,
      earnings: 9707.3136,
      bunkerAdjustment: 0.0,
      scrubberBonus: 0.0,
      specialCompensations: 0.0,
      tce: 9707.3136,
      wafDta: 0.0,
    },
  ],
}
