import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { apiGetAction, apiGetFileAction, apiPostAction } from '~api/actions'

export interface IRequestDownloadingCertificatePayload {
  Certificate: string
  Issued: string
}
export type IRequestDownloadingCertificateResponse = string

export interface IDownloadCertificateResponse {
  signedUrl: string
}

export const downloadCertificateAction = (
  urlParams: Params,
): Promise<IDownloadCertificateResponse> => {
  return apiGetAction<IDownloadCertificateResponse>(
    ApiRoutes.Vessels.Certificates.downloadFile,
    urlParams,
  )
}

export const downloadBulkCertificatesAction = (
  urlParams?: Params,
): Promise<IDownloadCertificateResponse> => {
  return apiGetFileAction<IDownloadCertificateResponse>(
    ApiRoutes.Vessels.Certificates.downloadBulkFile,
    urlParams,
  )
}

export const requestDownloadingCertificateAction = (
  payload: IRequestDownloadingCertificatePayload,
): Promise<IRequestDownloadingCertificateResponse> => {
  return apiPostAction<
    IRequestDownloadingCertificatePayload,
    IRequestDownloadingCertificateResponse
  >(ApiRoutes.Vessels.Certificates.requestDownloadingFile, payload)
}
