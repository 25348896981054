import {
  ClickPoint,
  Modal,
  theme,
  typographyStyles,
} from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

export const StyledModal = styled(Modal)``

export const ModalButtonWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  gap: ${theme.SPACINGS[5]}};
  margin-top: ${theme.SPACINGS[5]};
`

export const DeleteListModalContent = styled.div`
  width: 328px;
  height: 212px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledClickPoint = styled(ClickPoint)`
  position: absolute;
  top: -8px;
  right: -8px;
`

export const ModalTitle = styled.div`
  ${typographyStyles.paragraphBoldLarge};
  margin-bottom: ${theme.SPACINGS[3]};
`
