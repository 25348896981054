export const mockExposure = {
  data: {
    getMTOpenVessels: {
      openVessels: [
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-09-09T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-09-16T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9389526',
              loadingAreaETA: '2024-09-22 08:29:07',
              localLoadingAreaETA: '2024-09-22 12:29:07',
              localOpenDate: '2024-09-15T23:59:00',
              openDate: '2024-09-15T21:59:00',
              openPort: 'PORT SUDAN',
              openRegion: 'Red Sea',
              openWorldArea: 'AG (Fuj)',
              pool: 'Handy',
              vesselId: '6228a5de322650694d94697a',
              vesselName: 'Henning Maersk',
            },
          ],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-09-23T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9341433',
              loadingAreaETA: '2024-09-28 12:10:45',
              localLoadingAreaETA: '2024-09-28 16:10:45',
              localOpenDate: '2024-09-26T23:21:30',
              openDate: '2024-09-26T18:21:30',
              openPort: 'PORT QASIM',
              openRegion: 'WC India',
              openWorldArea: 'AG (Fuj)',
              pool: 'Handy',
              vesselId: '6050c2af5673da0654aba95c',
              vesselName: 'OM Singapore',
            },
          ],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-09-30T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'DPP',
              ID: '9726451',
              loadingAreaETA: '2024-10-04 23:32:11',
              localLoadingAreaETA: '2024-10-05 03:32:11',
              localOpenDate: '2024-09-22T06:51:00',
              openDate: '2024-09-22T04:51:00',
              openPort: 'DURBAN',
              openRegion: 'East- & South Africa',
              openWorldArea: 'AG (Fuj)',
              pool: 'MR',
              vesselId: '60c02db2a8cd8426cfa21d82',
              vesselName: 'Maersk Tangier',
            },
            {
              cargoGrade: 'CPP',
              ID: '9332169',
              loadingAreaETA: '2024-10-05 03:41:11',
              localLoadingAreaETA: '2024-10-05 07:41:11',
              localOpenDate: '2024-09-22T11:00:00',
              openDate: '2024-09-22T09:00:00',
              openPort: 'DURBAN',
              openRegion: 'East- & South Africa',
              openWorldArea: 'AG (Fuj)',
              pool: 'MR',
              vesselId: '6050ca6e5673da38e1abaa88',
              vesselName: 'Atlantic Crown',
            },
          ],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-10-07T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9788497',
              loadingAreaETA: '2024-10-09 06:26:47',
              localLoadingAreaETA: '2024-10-09 10:26:47',
              localOpenDate: '2024-09-23T16:00:00',
              openDate: '2024-09-23T14:00:00',
              openPort: 'GIBRALTAR',
              openRegion: 'Red Sea',
              openWorldArea: 'AG (Fuj)',
              pool: 'MR',
              vesselId: '6050c80f5673da9536aba9ff',
              vesselName: 'St. Petri',
            },
            {
              cargoGrade: 'CPP',
              ID: '9732929',
              loadingAreaETA: '2024-10-10 07:37:29',
              localLoadingAreaETA: '2024-10-10 11:37:29',
              localOpenDate: '2024-10-08T18:48:14',
              openDate: '2024-10-08T13:48:14',
              openPort: 'PORT QASIM',
              openRegion: 'WC India',
              openWorldArea: 'AG (Fuj)',
              pool: 'MR',
              vesselId: '6050cc225673daad3cabaafc',
              vesselName: 'Cl Huaiyang',
            },
          ],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-10-14T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'DPP',
              ID: '9389497',
              loadingAreaETA: '2024-10-15 03:11:18',
              localLoadingAreaETA: '2024-10-15 07:11:18',
              localOpenDate: '2024-10-06T00:44:26',
              openDate: '2024-10-05T21:44:26',
              openPort: 'PORT SAID',
              openRegion: 'Red Sea',
              openWorldArea: 'AG (Fuj)',
              pool: 'Handy',
              vesselId: '6050c2c35673da6fdfaba962',
              vesselName: 'Hans Maersk',
            },
            {
              cargoGrade: 'CPP',
              ID: '9845180',
              loadingAreaETA: '2024-10-15 09:43:10',
              localLoadingAreaETA: '2024-10-15 13:43:10',
              localOpenDate: '2024-10-15T13:43:10',
              openDate: '2024-10-15T09:43:10',
              openPort: 'SOHAR',
              openRegion: 'Middle East',
              openWorldArea: 'AG (Fuj)',
              pool: 'MR',
              vesselId: '6620d93d882da4ac679440cb',
              vesselName: 'Fpmc 36',
            },
          ],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-10-21T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9358321',
              loadingAreaETA: '2024-10-21 03:37:27',
              localLoadingAreaETA: '2024-10-21 07:37:27',
              localOpenDate: '2024-10-03T04:37:12',
              openDate: '2024-10-03T02:37:12',
              openPort: 'WALVIS BAY',
              openRegion: 'East- & South Africa',
              openWorldArea: 'AG (Fuj)',
              pool: 'MR',
              vesselId: '62c7db7cfef791cc8a808aee',
              vesselName: 'Hansa Oslo',
            },
          ],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-10-28T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-11-04T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9299458',
              loadingAreaETA: '2024-11-04 05:18:48',
              localLoadingAreaETA: '2024-11-04 09:18:48',
              localOpenDate: '2024-11-02T03:54:14',
              openDate: '2024-11-01T22:24:14',
              openPort: 'KANDLA',
              openRegion: 'WC India',
              openWorldArea: 'AG (Fuj)',
              pool: 'Handy',
              vesselId: '6050c3e05673da7a26aba97f',
              vesselName: 'Maersk Barry',
            },
          ],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-11-11T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9365362',
              loadingAreaETA: '2024-11-11 07:44:50',
              localLoadingAreaETA: '2024-11-11 11:44:50',
              localOpenDate: '2024-11-09T06:20:16',
              openDate: '2024-11-09T00:50:16',
              openPort: 'KANDLA',
              openRegion: 'WC India',
              openWorldArea: 'AG (Fuj)',
              pool: 'MR',
              vesselId: '6050c9575673dad426abaa54',
              vesselName: 'Gemini Pearl',
            },
          ],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-11-18T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'DPP',
              ID: '9379040',
              loadingAreaETA: '2024-11-23 11:00:00',
              localLoadingAreaETA: '2024-11-23 15:00:00',
              localOpenDate: '2024-11-23T15:00:00',
              openDate: '2024-11-23T11:00:00',
              openPort: 'FUJAIRAH',
              openRegion: 'Middle East',
              openWorldArea: 'AG (Fuj)',
              pool: 'Handy',
              vesselId: '64db47c02bb6ec893ef9ffa4',
              vesselName: 'Eastern Quince',
            },
          ],
        },
        {
          exposureArea: 'AG (Fuj)',
          fromDate: '2024-11-25T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-09-09T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9831701',
              loadingAreaETA: '2024-09-12 17:01:00',
              localLoadingAreaETA: '2024-09-12 12:01:00',
              localOpenDate: '2024-09-12T12:01:00',
              openDate: '2024-09-12T17:01:00',
              openPort: 'HOUSTON',
              openRegion: 'USG-Caribs',
              openWorldArea: 'Americas (Houston)',
              pool: 'MR',
              vesselId: '6405ddf9900e9746da219e2f',
              vesselName: 'Fpmc 32',
            },
            {
              cargoGrade: 'CPP',
              ID: '9555319',
              loadingAreaETA: '2024-09-14 06:07:15',
              localLoadingAreaETA: '2024-09-14 01:07:15',
              localOpenDate: '2024-09-13T01:33:36',
              openDate: '2024-09-13T06:33:36',
              openPort: 'USG DEVIATION POINT',
              openRegion: 'EC North America',
              openWorldArea: 'Americas (Houston)',
              pool: 'MR',
              vesselId: '6050cacf5673da0f8babaa9a',
              vesselName: 'Maersk Mississippi',
            },
          ],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-09-16T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9299422',
              loadingAreaETA: '2024-09-21 01:22:55',
              localLoadingAreaETA: '2024-09-20 20:22:55',
              localOpenDate: '2024-09-13T23:27:36',
              openDate: '2024-09-14T03:27:36',
              openPort: 'QUINCY',
              openRegion: 'EC North America',
              openWorldArea: 'Americas (Houston)',
              pool: 'Handy',
              vesselId: '6050c4275673daf36caba98e',
              vesselName: 'Maersk Bering',
            },
            {
              cargoGrade: 'CPP',
              ID: '9786217',
              loadingAreaETA: '2024-09-16 21:52:08',
              localLoadingAreaETA: '2024-09-16 16:52:08',
              localOpenDate: '2024-09-15T17:18:29',
              openDate: '2024-09-15T22:18:29',
              openPort: 'USG DEVIATION POINT',
              openRegion: 'EC North America',
              openWorldArea: 'Americas (Houston)',
              pool: 'MR',
              vesselId: '651d09a2ecd313e4823bfda6',
              vesselName: 'Maersk Cyprus',
            },
          ],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-09-23T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9561930',
              loadingAreaETA: '2024-09-26 11:12:26',
              localLoadingAreaETA: '2024-09-26 06:12:26',
              localOpenDate: '2024-09-13T21:52:30',
              openDate: '2024-09-14T04:52:30',
              openPort: 'LA PAZ',
              openRegion: 'WC Latin America',
              openWorldArea: 'Americas (Houston)',
              pool: 'MR',
              vesselId: '64e5db65dbe3b03bdee46b40',
              vesselName: 'Mont Blanc I',
            },
          ],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-09-30T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9858852',
              loadingAreaETA: '2024-10-05 00:38:28',
              localLoadingAreaETA: '2024-10-04 19:38:28',
              localOpenDate: '2024-09-13T14:07:19',
              openDate: '2024-09-13T17:07:19',
              openPort: 'RECALADA PILOT STATION',
              openRegion: 'EC SAM',
              openWorldArea: 'Americas (Houston)',
              pool: 'MR',
              vesselId: '6050cbe55673dab6f8abaae7',
              vesselName: 'Wisco Adventure',
            },
          ],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-10-07T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9747106',
              loadingAreaETA: '2024-10-13 18:21:03',
              localLoadingAreaETA: '2024-10-13 13:21:03',
              localOpenDate: '2024-09-29T14:54:00',
              openDate: '2024-09-29T21:54:00',
              openPort: 'GUAYMAS',
              openRegion: 'WC Latin America',
              openWorldArea: 'Americas (Houston)',
              pool: 'MR',
              vesselId: '6050cb685673da1b7eabaac5',
              vesselName: 'Lian Xi Hu',
            },
            {
              cargoGrade: 'CPP',
              ID: '9409479',
              loadingAreaETA: '2024-10-13 19:24:43',
              localLoadingAreaETA: '2024-10-13 14:24:43',
              localOpenDate: '2024-10-07T06:30:00',
              openDate: '2024-10-07T10:30:00',
              openPort: 'NEW YORK',
              openRegion: 'EC North America',
              openWorldArea: 'Americas (Houston)',
              pool: 'LR1',
              vesselId: '65169459c37506d92cfaeb6b',
              vesselName: 'Sanmar Sonatina',
            },
          ],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-10-14T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-10-21T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9500144',
              loadingAreaETA: '2024-10-26 15:14:42',
              localLoadingAreaETA: '2024-10-26 10:14:42',
              localOpenDate: '2024-10-08T09:43:48',
              openDate: '2024-10-08T12:43:48',
              openPort: 'RIO DE JANEIRO',
              openRegion: 'EC SAM',
              openWorldArea: 'Americas (Houston)',
              pool: 'Intermediate',
              vesselId: '65f1a78a0224b2890bedb6b3',
              vesselName: 'XT PROGRESS',
            },
            {
              cargoGrade: 'CPP',
              ID: '9542178',
              loadingAreaETA: '2024-10-23 00:49:52',
              localLoadingAreaETA: '2024-10-22 19:49:52',
              localOpenDate: '2024-10-13T18:47:34',
              openDate: '2024-10-13T22:47:34',
              openPort: 'MANAUS',
              openRegion: 'EC SAM',
              openWorldArea: 'Americas (Houston)',
              pool: 'Intermediate',
              vesselId: '65e040c6eb7ef16009a7d30b',
              vesselName: 'Asp Lily',
            },
          ],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-10-28T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9340594',
              loadingAreaETA: '2024-10-29 12:34:26',
              localLoadingAreaETA: '2024-10-29 07:34:26',
              localOpenDate: '2024-10-22T10:39:07',
              openDate: '2024-10-22T14:39:07',
              openPort: 'QUINCY',
              openRegion: 'EC North America',
              openWorldArea: 'Americas (Houston)',
              pool: 'Handy',
              vesselId: '6050c3f65673da4973aba985',
              vesselName: 'Maersk Beaufort',
            },
          ],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-11-04T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-11-11T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-11-18T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'Americas (Houston)',
          fromDate: '2024-11-25T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-09-09T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9636644',
              loadingAreaETA: '2024-09-09 02:04:32',
              localLoadingAreaETA: '2024-09-09 04:04:32',
              localOpenDate: '2024-09-07T09:46:00',
              openDate: '2024-09-07T07:46:00',
              openPort: 'PLOCE',
              openRegion: 'Center Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6050c3725673da5d8eaba977',
              vesselName: 'Maersk Aegean',
            },
            {
              cargoGrade: 'CPP',
              ID: '9636632',
              loadingAreaETA: '2024-09-11 03:22:52',
              localLoadingAreaETA: '2024-09-11 05:22:52',
              localOpenDate: '2024-09-09T15:57:22',
              openDate: '2024-09-09T13:57:22',
              openPort: 'CIVITAVECCHIA',
              openRegion: 'Center Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6050c3045673da7418aba971',
              vesselName: 'Maersk Adriatic',
            },
            {
              cargoGrade: 'CPP',
              ID: '9313436',
              loadingAreaETA: '2024-09-09 14:00:00',
              localLoadingAreaETA: '2024-09-09 16:00:00',
              localOpenDate: '2024-09-09T16:00:00',
              openDate: '2024-09-09T14:00:00',
              openPort: 'MALTA (COUNTRY)',
              openRegion: 'Center Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '667282cd6308ee482fab96e6',
              vesselName: 'Peonia',
            },
            {
              cargoGrade: 'CPP',
              ID: '9341445',
              loadingAreaETA: '2024-09-12 01:22:01',
              localLoadingAreaETA: '2024-09-12 03:22:01',
              localOpenDate: '2024-09-09T22:00:00',
              openDate: '2024-09-09T19:00:00',
              openPort: 'ALIAGA',
              openRegion: 'East Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6050c4325673da65e9aba991',
              vesselName: 'OM Borneo',
            },
            {
              cargoGrade: 'CPP',
              ID: '9389514',
              loadingAreaETA: '2024-09-12 06:18:58',
              localLoadingAreaETA: '2024-09-12 08:18:58',
              localOpenDate: '2024-09-10T02:00:00',
              openDate: '2024-09-10T00:00:00',
              openPort: 'BARCELONA',
              openRegion: 'West Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6050c2d35673da269faba965',
              vesselName: 'Helene Maersk',
            },
            {
              cargoGrade: 'CPP',
              ID: '9399363',
              loadingAreaETA: '2024-09-13 00:36:24',
              localLoadingAreaETA: '2024-09-13 02:36:24',
              localOpenDate: '2024-09-11T06:00:00',
              openDate: '2024-09-11T04:00:00',
              openPort: 'LIVORNO',
              openRegion: 'Center Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6050c1a95673da01d1aba93a',
              vesselName: 'Hulda Maersk',
            },
            {
              cargoGrade: 'CPP',
              ID: '9291597',
              loadingAreaETA: '2024-09-13 09:50:56',
              localLoadingAreaETA: '2024-09-13 11:50:56',
              localOpenDate: '2024-09-12T00:00:00',
              openDate: '2024-09-11T21:00:00',
              openPort: 'SAN NICOLAS (GREECE)',
              openRegion: 'East Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Intermediate',
              vesselId: '63ab173c81ddd2a92c07ddb7',
              vesselName: 'Ym Jupiter',
            },
            {
              cargoGrade: 'CPP',
              ID: '9431264',
              loadingAreaETA: '2024-09-14 07:50:47',
              localLoadingAreaETA: '2024-09-14 09:50:47',
              localOpenDate: '2024-09-12T00:48:41',
              openDate: '2024-09-11T22:48:41',
              openPort: 'SETE',
              openRegion: 'West Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6050c2f35673da4111aba96e',
              vesselName: 'Kirsten Maersk',
            },
            {
              cargoGrade: 'DPP',
              ID: '9374428',
              loadingAreaETA: '2024-09-13 06:18:50',
              localLoadingAreaETA: '2024-09-13 08:18:50',
              localOpenDate: '2024-09-12T04:54:43',
              openDate: '2024-09-12T02:54:43',
              openPort: 'SARROCH',
              openRegion: 'West Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6050c4635673da7088aba99f',
              vesselName: 'Maersk Kara',
            },
            {
              cargoGrade: 'CPP',
              ID: '9724568',
              loadingAreaETA: '2024-09-14 05:05:43',
              localLoadingAreaETA: '2024-09-14 07:05:43',
              localOpenDate: '2024-09-12T22:48:00',
              openDate: '2024-09-12T20:48:00',
              openPort: 'PORTO ROMANO',
              openRegion: 'Center Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6629f99a4eda322509b9d7b8',
              vesselName: 'Sea Cirrus',
            },
          ],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-09-16T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9333242',
              loadingAreaETA: '2024-09-22 15:34:37',
              localLoadingAreaETA: '2024-09-22 17:34:37',
              localOpenDate: '2024-09-14T08:00:00',
              openDate: '2024-09-14T08:00:00',
              openPort: 'DAKAR',
              openRegion: 'WAF',
              openWorldArea: 'MED (Malta)',
              pool: 'MR',
              vesselId: '64fec5c14c9f4c7004453def',
              vesselName: 'Florence',
            },
            {
              cargoGrade: 'CPP',
              ID: '9732931',
              loadingAreaETA: '2024-09-17 22:18:58',
              localLoadingAreaETA: '2024-09-18 00:18:58',
              localOpenDate: '2024-09-15T18:00:00',
              openDate: '2024-09-15T16:00:00',
              openPort: 'BARCELONA',
              openRegion: 'West Med',
              openWorldArea: 'MED (Malta)',
              pool: 'MR',
              vesselId: '6050cc2f5673da7ea0abaaff',
              vesselName: 'Cl Fugou',
            },
            {
              cargoGrade: 'CPP',
              ID: '9809784',
              loadingAreaETA: '2024-09-19 11:33:41',
              localLoadingAreaETA: '2024-09-19 13:33:41',
              localOpenDate: '2024-09-16T07:30:00',
              openDate: '2024-09-16T04:30:00',
              openPort: 'VARNA',
              openRegion: 'Black sea',
              openWorldArea: 'MED (Malta)',
              pool: 'Intermediate',
              vesselId: '6050c8ce5673da799dabaa37',
              vesselName: 'Sloman Hebe',
            },
            {
              cargoGrade: 'CPP',
              ID: '9399351',
              loadingAreaETA: '2024-09-17 22:47:45',
              localLoadingAreaETA: '2024-09-18 00:47:45',
              localOpenDate: '2024-09-16T22:05:04',
              openDate: '2024-09-16T20:05:04',
              openPort: 'NAPLES',
              openRegion: 'Center Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6050c4df5673da03c7aba9bb',
              vesselName: 'Henry Maersk',
            },
            {
              cargoGrade: 'DPP',
              ID: '9431290',
              loadingAreaETA: '2024-09-20 19:30:07',
              localLoadingAreaETA: '2024-09-20 21:30:07',
              localOpenDate: '2024-09-17T12:22:36',
              openDate: '2024-09-17T11:22:36',
              openPort: 'TANGER MED',
              openRegion: 'West Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6050c47e5673dadee5aba9a5',
              vesselName: 'Maersk Katarina',
            },
            {
              cargoGrade: 'CPP',
              ID: '9431288',
              loadingAreaETA: '2024-09-21 15:03:07',
              localLoadingAreaETA: '2024-09-21 17:03:07',
              localOpenDate: '2024-09-18T14:22:34',
              openDate: '2024-09-18T11:22:34',
              openPort: 'VASSILIKO',
              openRegion: 'East Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Handy',
              vesselId: '6050c1515673da1ea4aba931',
              vesselName: 'Maersk Kaya',
            },
          ],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-09-23T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9705914',
              loadingAreaETA: '2024-09-28 22:41:38',
              localLoadingAreaETA: '2024-09-29 00:41:38',
              localOpenDate: '2024-09-15T09:00:00',
              openDate: '2024-09-15T08:00:00',
              openPort: 'LAGOS (NIGERIA)',
              openRegion: 'WAF',
              openWorldArea: 'MED (Malta)',
              pool: 'MR',
              vesselId: '65df2a0d5080afc96b36bb40',
              vesselName: 'Elka Elefsis',
            },
          ],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-09-30T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9542166',
              loadingAreaETA: '2024-09-30 21:27:09',
              localLoadingAreaETA: '2024-09-30 23:27:09',
              localOpenDate: '2024-09-29T01:51:12',
              openDate: '2024-09-28T23:51:12',
              openPort: 'VASTO',
              openRegion: 'Center Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Intermediate',
              vesselId: '647ef991b36dff8049fa0ee1',
              vesselName: 'NV Minerva',
            },
          ],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-10-07T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-10-14T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-10-21T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-10-28T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-11-04T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9708617',
              loadingAreaETA: '2024-11-08 08:38:27',
              localLoadingAreaETA: '2024-11-08 09:38:27',
              localOpenDate: '2024-10-31T01:03:50',
              openDate: '2024-10-31T01:03:50',
              openPort: 'DAKAR',
              openRegion: 'WAF',
              openWorldArea: 'MED (Malta)',
              pool: 'MR',
              vesselId: '6050ca6c5673da7828abaa85',
              vesselName: 'Maersk Tacoma',
            },
          ],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-11-11T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9527075',
              loadingAreaETA: '2024-11-13 00:34:25',
              localLoadingAreaETA: '2024-11-13 01:34:25',
              localOpenDate: '2024-10-30T10:52:47',
              openDate: '2024-10-30T09:52:47',
              openPort: 'LAGOS (NIGERIA)',
              openRegion: 'WAF',
              openWorldArea: 'MED (Malta)',
              pool: 'Intermediate',
              vesselId: '649415bd7a79bcacda1ce00a',
              vesselName: 'NV Athena',
            },
          ],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-11-18T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'MED (Malta)',
          fromDate: '2024-11-25T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-09-09T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9786205',
              loadingAreaETA: '2024-09-15 04:25:05',
              localLoadingAreaETA: '2024-09-15 13:25:05',
              localOpenDate: '2024-09-15T06:01:00',
              openDate: '2024-09-14T21:01:00',
              openPort: 'ONSAN',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '6050cba35673da2213abaad4',
              vesselName: 'Maersk Curacao',
            },
          ],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-09-16T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9380520',
              loadingAreaETA: '2024-09-15 19:28:13',
              localLoadingAreaETA: '2024-09-16 04:28:13',
              localOpenDate: '2024-09-13T18:00:00',
              openDate: '2024-09-13T09:00:00',
              openPort: 'NEGISHI',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '6050ccb65673da3097abab1f',
              vesselName: 'Gran Couva',
            },
            {
              cargoGrade: '',
              ID: '9439838',
              loadingAreaETA: '2024-09-20 21:12:07',
              localLoadingAreaETA: '2024-09-21 06:12:07',
              localOpenDate: '2024-09-16T16:12:00',
              openDate: '2024-09-16T08:12:00',
              openPort: 'BATAAN',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'Intermediate',
              vesselId: '668262bef01b7360a80156b6',
              vesselName: 'EVERHARD SCHULTE',
            },
            {
              cargoGrade: 'CPP',
              ID: '9786152',
              loadingAreaETA: '2024-09-20 07:08:17',
              localLoadingAreaETA: '2024-09-20 16:08:17',
              localOpenDate: '2024-09-19T12:00:00',
              openDate: '2024-09-19T04:00:00',
              openPort: 'ZHENJIANG',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '6050cc375673da8e2eabab02',
              vesselName: 'Maersk Callao',
            },
            {
              cargoGrade: 'CPP',
              ID: '9576818',
              loadingAreaETA: '2024-09-22 11:48:51',
              localLoadingAreaETA: '2024-09-22 20:48:51',
              localOpenDate: '2024-09-21T08:09:08',
              openDate: '2024-09-20T23:09:08',
              openPort: 'SAKAI',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'Intermediate',
              vesselId: '650aa531cba2442c9ba5f117',
              vesselName: 'TTC Vishaka',
            },
          ],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-09-23T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9862413',
              loadingAreaETA: '2024-09-23 03:05:36',
              localLoadingAreaETA: '2024-09-23 12:05:36',
              localOpenDate: '2024-09-20T20:50:43',
              openDate: '2024-09-20T11:50:43',
              openPort: 'TOMAKOMAI',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '66d8459ec7ee987fae5b21a9',
              vesselName: 'Pro Onyx',
            },
            {
              cargoGrade: 'DPP',
              ID: '9786176',
              loadingAreaETA: '2024-09-24 10:54:05',
              localLoadingAreaETA: '2024-09-24 19:54:05',
              localOpenDate: '2024-09-24T12:30:00',
              openDate: '2024-09-24T03:30:00',
              openPort: 'ULSAN',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '651d1f5fecd313e4823bfdab',
              vesselName: 'Maersk Cebu',
            },
          ],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-09-30T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9459266',
              loadingAreaETA: '2024-09-29 15:23:30',
              localLoadingAreaETA: '2024-09-30 00:23:30',
              localOpenDate: '2024-09-30T00:23:30',
              openDate: '2024-09-29T15:23:30',
              openPort: 'YOSU',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '63ef4c1d7936a2109b499833',
              vesselName: 'Leman',
            },
            {
              cargoGrade: 'DPP',
              ID: '9786164',
              loadingAreaETA: '2024-10-03 17:48:16',
              localLoadingAreaETA: '2024-10-04 02:48:16',
              localOpenDate: '2024-10-02T08:51:53',
              openDate: '2024-10-02T00:51:53',
              openPort: 'DONGYING',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '6050cc045673daae9babaaf0',
              vesselName: 'Maersk Cayman',
            },
            {
              cargoGrade: 'DPP',
              ID: '9306653',
              loadingAreaETA: '2024-10-06 01:13:01',
              localLoadingAreaETA: '2024-10-06 10:13:01',
              localOpenDate: '2024-10-05T00:01:00',
              openDate: '2024-10-04T16:01:00',
              openPort: 'ZHOUSHAN',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'Handy',
              vesselId: '6050c5fd5673da22f8aba9d0',
              vesselName: 'Sloman Thetis',
            },
          ],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-10-07T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9581447',
              loadingAreaETA: '2024-10-07 14:07:23',
              localLoadingAreaETA: '2024-10-07 23:07:23',
              localOpenDate: '2024-09-19T20:31:40',
              openDate: '2024-09-20T03:31:40',
              openPort: 'SAN FRANCISCO',
              openRegion: 'USWC',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '6050cb215673da5c30abaaad',
              vesselName: 'Maersk Maru',
            },
            {
              cargoGrade: 'CPP',
              ID: '9718076',
              loadingAreaETA: '2024-10-10 08:39:08',
              localLoadingAreaETA: '2024-10-10 17:39:08',
              localOpenDate: '2024-10-09T07:27:07',
              openDate: '2024-10-08T23:27:07',
              openPort: 'NINGBO',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '63f487ae71978b5c6b088bab',
              vesselName: 'Maersk Tokyo',
            },
          ],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-10-14T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9539561',
              loadingAreaETA: '2024-10-15 04:06:52',
              localLoadingAreaETA: '2024-10-15 13:06:52',
              localOpenDate: '2024-10-02T22:29:31',
              openDate: '2024-10-02T12:29:31',
              openPort: 'TOWNSVILLE',
              openRegion: 'EC Australia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '6177bbefc1877f12b7cb6be7',
              vesselName: 'Canal Street',
            },
          ],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-10-21T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9366299',
              loadingAreaETA: '2024-10-22 13:38:16',
              localLoadingAreaETA: '2024-10-22 22:38:16',
              localOpenDate: '2024-10-06T22:01:26',
              openDate: '2024-10-07T06:01:26',
              openPort: 'KOTZEBUE',
              openRegion: 'USWC',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'MR',
              vesselId: '6594f62dd20bce1a136d0e9a',
              vesselName: 'Capital',
            },
          ],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-10-28T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-11-04T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-11-11T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-11-18T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          fromDate: '2024-11-25T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-09-02T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'DPP',
              ID: '9399349',
              loadingAreaETA: '2024-09-06 13:20:20',
              localLoadingAreaETA: '2024-09-06 15:20:20',
              localOpenDate: '2024-09-06T03:24:00',
              openDate: '2024-09-06T01:24:00',
              openPort: 'ROTTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Handy',
              vesselId: '6050c4bd5673da775faba9b1',
              vesselName: 'Henriette Maersk',
            },
          ],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-09-09T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'DPP',
              ID: '9439814',
              loadingAreaETA: '2024-09-12 00:48:41',
              localLoadingAreaETA: '2024-09-12 02:48:41',
              localOpenDate: '2024-09-07T23:00:00',
              openDate: '2024-09-07T22:00:00',
              openPort: 'SINES',
              openRegion: 'Atlantic',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6152eae6ce7efb4e5b40b0fd',
              vesselName: 'Erin Schulte',
            },
            {
              cargoGrade: 'CPP',
              ID: '9786140',
              loadingAreaETA: '2024-09-10 05:32:51',
              localLoadingAreaETA: '2024-09-10 07:32:51',
              localOpenDate: '2024-09-08T12:00:00',
              openDate: '2024-09-08T10:00:00',
              openPort: 'KALUNDBORG',
              openRegion: 'Baltic',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'MR',
              vesselId: '6050cc1a5673daaf51abaaf9',
              vesselName: 'Maersk Capri',
            },
            {
              cargoGrade: 'DPP',
              ID: '9439826',
              loadingAreaETA: '2024-09-10 11:41:43',
              localLoadingAreaETA: '2024-09-10 13:41:43',
              localOpenDate: '2024-09-09T18:00:00',
              openDate: '2024-09-09T17:00:00',
              openPort: 'IMMINGHAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c7dd5673dafdc2aba9f6',
              vesselName: 'Eva Schulte',
            },
            {
              cargoGrade: 'CPP',
              ID: '9439864',
              loadingAreaETA: '2024-09-10 21:30:00',
              localLoadingAreaETA: '2024-09-10 23:30:00',
              localOpenDate: '2024-09-10T23:30:00',
              openDate: '2024-09-10T21:30:00',
              openPort: 'AMSTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '60c358bba8cd845ef6a21d88',
              vesselName: 'Erika Schulte',
            },
            {
              cargoGrade: 'CPP',
              ID: '9466714',
              loadingAreaETA: '2024-09-12 10:36:15',
              localLoadingAreaETA: '2024-09-12 12:36:15',
              localOpenDate: '2024-09-12T12:36:15',
              openDate: '2024-09-12T10:36:15',
              openPort: 'AMSTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c8e55673da6a0babaa3d',
              vesselName: 'Sloman Hera',
            },
            {
              cargoGrade: 'CPP',
              ID: '9340623',
              loadingAreaETA: '2024-09-15 21:24:01',
              localLoadingAreaETA: '2024-09-15 23:24:01',
              localOpenDate: '2024-09-13T10:51:15',
              openDate: '2024-09-13T09:51:15',
              openPort: 'DUBLIN',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c8a45673daf3a0abaa2b',
              vesselName: 'Bro Nissum',
            },
            {
              cargoGrade: 'CPP',
              ID: '9466738',
              loadingAreaETA: '2024-09-14 05:56:20',
              localLoadingAreaETA: '2024-09-14 07:56:20',
              localOpenDate: '2024-09-13T20:00:00',
              openDate: '2024-09-13T18:00:00',
              openPort: 'ROTTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c8f05673dab1c0abaa40',
              vesselName: 'Sloman Hermes',
            },
            {
              cargoGrade: 'CPP',
              ID: '9786190',
              loadingAreaETA: '2024-09-15 00:50:38',
              localLoadingAreaETA: '2024-09-15 02:50:38',
              localOpenDate: '2024-09-14T08:30:00',
              openDate: '2024-09-14T07:30:00',
              openPort: 'THAMESHAVEN',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'MR',
              vesselId: '651d1c32ecd313e4823bfda9',
              vesselName: 'Maersk Crete',
            },
            {
              cargoGrade: 'DPP',
              ID: '9344435',
              loadingAreaETA: '2024-09-15 11:57:20',
              localLoadingAreaETA: '2024-09-15 13:57:20',
              localOpenDate: '2024-09-15T02:01:00',
              openDate: '2024-09-15T00:01:00',
              openPort: 'ROTTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c8635673da73c5abaa1a',
              vesselName: 'Bro Anna',
            },
          ],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-09-16T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'DPP',
              ID: '9466726',
              loadingAreaETA: '2024-09-16 02:00:06',
              localLoadingAreaETA: '2024-09-16 04:00:06',
              localOpenDate: '2024-09-14T20:23:05',
              openDate: '2024-09-14T19:23:05',
              openPort: 'PORTLAND (UNITED KINGDOM)',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c9355673dab62dabaa4c',
              vesselName: 'Sloman Herakles',
            },
            {
              cargoGrade: 'CPP',
              ID: '9323819',
              loadingAreaETA: '2024-09-16 16:42:53',
              localLoadingAreaETA: '2024-09-16 18:42:53',
              localOpenDate: '2024-09-16T00:22:15',
              openDate: '2024-09-15T23:22:15',
              openPort: 'THAMESHAVEN',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c8b85673dac0b2abaa31',
              vesselName: 'Bro Nuuk',
            },
            {
              cargoGrade: 'CPP',
              ID: '9827994',
              loadingAreaETA: '2024-09-18 08:00:05',
              localLoadingAreaETA: '2024-09-18 10:00:05',
              localOpenDate: '2024-09-17T22:03:45',
              openDate: '2024-09-17T20:03:45',
              openPort: 'ROTTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'City',
              vesselId: '656ee268be8e36ee5f9700bb',
              vesselName: 'Eastern Nymphaea',
            },
            {
              cargoGrade: 'CPP',
              ID: '9439840',
              loadingAreaETA: '2024-09-18 10:19:08',
              localLoadingAreaETA: '2024-09-18 12:19:08',
              localOpenDate: '2024-09-18T00:22:48',
              openDate: '2024-09-17T22:22:48',
              openPort: 'ROTTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '615dd767ce7efb6cda40b21d',
              vesselName: 'Elisabeth Schulte',
            },
            {
              cargoGrade: 'CPP',
              ID: '9399612',
              loadingAreaETA: '2024-09-22 04:14:01',
              localLoadingAreaETA: '2024-09-22 06:14:01',
              localOpenDate: '2024-09-21T02:00:00',
              openDate: '2024-09-21T01:00:00',
              openPort: 'FAWLEY',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'MR',
              vesselId: '61482ebd5458764de44a4644',
              vesselName: 'Etc Nefertari',
            },
          ],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-09-23T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9499486',
              loadingAreaETA: '2024-09-23 02:18:45',
              localLoadingAreaETA: '2024-09-23 04:18:45',
              localOpenDate: '2024-09-18T10:16:30',
              openDate: '2024-09-18T08:16:30',
              openPort: 'HUELVA',
              openRegion: 'Atlantic',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '65e041aebefb47da1b9c80c5',
              vesselName: 'Opec Victory',
            },
            {
              cargoGrade: 'CPP',
              ID: '9864344',
              loadingAreaETA: '2024-09-24 23:48:01',
              localLoadingAreaETA: '2024-09-25 01:48:01',
              localOpenDate: '2024-09-24T13:51:41',
              openDate: '2024-09-24T11:51:41',
              openPort: 'ROTTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'MR',
              vesselId: '6050cb435673daa23cabaab9',
              vesselName: 'Ion M',
            },
            {
              cargoGrade: 'CPP',
              ID: '9894739',
              loadingAreaETA: '2024-09-25 17:02:01',
              localLoadingAreaETA: '2024-09-25 19:02:01',
              localOpenDate: '2024-09-24T14:48:00',
              openDate: '2024-09-24T13:48:00',
              openPort: 'FAWLEY',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'MR',
              vesselId: '66c7433562fce44df6da1db7',
              vesselName: 'NORD MIYABI',
            },
          ],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-09-30T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9726463',
              loadingAreaETA: '2024-10-02 03:35:50',
              localLoadingAreaETA: '2024-10-02 05:35:50',
              localOpenDate: '2024-09-30T01:46:48',
              openDate: '2024-09-30T00:46:48',
              openPort: 'MILFORD HAVEN',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'MR',
              vesselId: '6050ca115673da5657abaa74',
              vesselName: 'Maersk Teesport',
            },
          ],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-10-07T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9786188',
              loadingAreaETA: '2024-10-10 09:42:24',
              localLoadingAreaETA: '2024-10-10 11:42:24',
              localOpenDate: '2024-10-07T15:37:36',
              openDate: '2024-10-07T14:37:36',
              openPort: 'AUGHINISH',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'MR',
              vesselId: '6050cbce5673da5eedabaae0',
              vesselName: 'Maersk Corsica',
            },
            {
              cargoGrade: 'CPP',
              ID: '9362138',
              loadingAreaETA: '2024-10-13 02:02:35',
              localLoadingAreaETA: '2024-10-13 04:02:35',
              localOpenDate: '2024-10-09T06:04:48',
              openDate: '2024-10-09T03:04:48',
              openPort: 'KOTKA',
              openRegion: 'Baltic',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '63ab15d581ddd2a92c07ddb2',
              vesselName: 'Ym Saturn',
            },
          ],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-10-14T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-10-21T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-10-28T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-11-04T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-11-11T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9870707',
              loadingAreaETA: '2024-11-12 05:15:22',
              localLoadingAreaETA: '2024-11-12 06:15:22',
              localOpenDate: '2024-11-12T06:15:22',
              openDate: '2024-11-12T05:15:22',
              openPort: 'AMSTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'MR',
              vesselId: '6641ea897834886545bc8691',
              vesselName: 'Nord Elegance',
            },
          ],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-11-18T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-11-25T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          fromDate: '2024-12-02T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: '',
              ID: '9542130',
              loadingAreaETA: '2024-12-08 14:22:22',
              localLoadingAreaETA: '2024-12-08 15:22:22',
              localOpenDate: '2024-12-06T04:33:13',
              openDate: '2024-12-06T03:33:13',
              openPort: 'KARLSHAMN',
              openRegion: 'Baltic',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '66b2106e3bf57db48f8da59e',
              vesselName: 'Windsor',
            },
          ],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-09-09T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'DPP',
              ID: '9306677',
              loadingAreaETA: '2024-09-09 01:24:00',
              localLoadingAreaETA: '2024-09-09 09:24:00',
              localOpenDate: '2024-09-09T09:24:00',
              openDate: '2024-09-09T01:24:00',
              openPort: 'SINGAPORE',
              openRegion: 'SE Asia',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'Handy',
              vesselId: '6050c5ee5673da747eaba9cd',
              vesselName: 'Sloman Themis',
            },
            {
              cargoGrade: 'DPP',
              ID: '9365283',
              loadingAreaETA: '2024-09-11 00:00:00',
              localLoadingAreaETA: '2024-09-11 08:00:00',
              localOpenDate: '2024-09-11T08:00:00',
              openDate: '2024-09-11T00:00:00',
              openPort: 'SINGAPORE',
              openRegion: 'SE Asia',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'Handy',
              vesselId: '6050c5005673dad3e9aba9c4',
              vesselName: 'Bloom',
            },
            {
              cargoGrade: 'DPP',
              ID: '9253258',
              loadingAreaETA: '2024-09-14 20:00:00',
              localLoadingAreaETA: '2024-09-15 04:00:00',
              localOpenDate: '2024-09-15T04:00:00',
              openDate: '2024-09-14T20:00:00',
              openPort: 'SINGAPORE',
              openRegion: 'SE Asia',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'Handy',
              vesselId: '60811d4a8ba1ea6df051114c',
              vesselName: 'Baltic',
            },
          ],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-09-16T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9689043',
              loadingAreaETA: '2024-09-21 20:09:08',
              localLoadingAreaETA: '2024-09-22 04:09:08',
              localOpenDate: '2024-09-17T03:26:39',
              openDate: '2024-09-16T21:26:39',
              openPort: 'CHITTAGONG',
              openRegion: 'EC India',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'Intermediate',
              vesselId: '624a4e59bed572c5534ab3be',
              vesselName: 'Golden Violet',
            },
          ],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-09-23T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9786138',
              loadingAreaETA: '2024-09-23 15:15:41',
              localLoadingAreaETA: '2024-09-23 23:15:41',
              localOpenDate: '2024-09-18T18:00:00',
              openDate: '2024-09-18T12:30:00',
              openPort: 'HALDIA',
              openRegion: 'SE Asia',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'MR',
              vesselId: '64e7680fe32f9a5ebb5928be',
              vesselName: 'Maersk Cancun',
            },
            {
              cargoGrade: 'DPP',
              ID: '9333254',
              loadingAreaETA: '2024-09-28 08:10:51',
              localLoadingAreaETA: '2024-09-28 16:10:51',
              localOpenDate: '2024-09-23T11:27:07',
              openDate: '2024-09-23T05:57:07',
              openPort: 'TRINCOMALEE',
              openRegion: 'EC India',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'MR',
              vesselId: '66875b8e5660f38931a3266d',
              vesselName: 'Culture',
            },
            {
              cargoGrade: 'DPP',
              ID: '9346067',
              loadingAreaETA: '2024-09-24 12:36:28',
              localLoadingAreaETA: '2024-09-24 20:36:28',
              localOpenDate: '2024-09-24T20:36:28',
              openDate: '2024-09-24T12:36:28',
              openPort: 'TANJUNG PELEPAS',
              openRegion: 'SE Asia',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'MR',
              vesselId: '608102f28ba1ea7fd651114a',
              vesselName: 'Grand Ace1',
            },
          ],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-09-30T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9573672',
              loadingAreaETA: '2024-09-30 04:24:25',
              localLoadingAreaETA: '2024-09-30 12:24:25',
              localOpenDate: '2024-09-28T07:19:12',
              openDate: '2024-09-28T00:19:12',
              openPort: 'NHA BE',
              openRegion: 'SE Asia',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'MR',
              vesselId: '607400d173a879ac534edd6d',
              vesselName: 'Vendome Street',
            },
            {
              cargoGrade: 'DPP',
              ID: '9295335',
              loadingAreaETA: '2024-10-01 00:55:04',
              localLoadingAreaETA: '2024-10-01 08:55:04',
              localOpenDate: '2024-10-01T08:55:04',
              openDate: '2024-10-01T00:55:04',
              openPort: 'SINGAPORE',
              openRegion: 'SE Asia',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'Handy',
              vesselId: '60e6f7b653e98fbf44818c9e',
              vesselName: 'Bassilevousa',
            },
            {
              cargoGrade: 'DPP',
              ID: '9447732',
              loadingAreaETA: '2024-10-03 23:24:12',
              localLoadingAreaETA: '2024-10-04 07:24:12',
              localOpenDate: '2024-10-04T07:24:12',
              openDate: '2024-10-03T23:24:12',
              openPort: 'TANJUNG PELEPAS',
              openRegion: 'SE Asia',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'MR',
              vesselId: '6050cb745673da40cdabaac8',
              vesselName: 'Maersk Magellan',
            },
          ],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-10-07T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'DPP',
              ID: '9443877',
              loadingAreaETA: '2024-10-09 13:20:54',
              localLoadingAreaETA: '2024-10-09 21:20:54',
              localOpenDate: '2024-10-09T21:20:54',
              openDate: '2024-10-09T13:20:54',
              openPort: 'SINGAPORE',
              openRegion: 'SE Asia',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'MR',
              vesselId: '609108268ca09e41765ca301',
              vesselName: 'Grand Ace10',
            },
          ],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-10-14T00:00:00.000Z',
          Maersk: [
            {
              cargoGrade: 'CPP',
              ID: '9713014',
              loadingAreaETA: '2024-10-14 07:42:15',
              localLoadingAreaETA: '2024-10-14 15:42:15',
              localOpenDate: '2024-10-09T07:02:00',
              openDate: '2024-10-09T01:32:00',
              openPort: 'KRISHNAPATNAM',
              openRegion: 'SE Asia',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'City',
              vesselId: '63034d9ca4e54b6a24b25ab5',
              vesselName: 'Asp Sunrise',
            },
          ],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-10-21T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-10-28T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-11-04T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-11-11T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-11-18T00:00:00.000Z',
          Maersk: [],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          fromDate: '2024-11-25T00:00:00.000Z',
          Maersk: [],
        },
      ],
      totalCount: 93,
      latestCreatedDate: '2024-09-09T03:30:03.923Z',
      // totalExposure: [
      //   {
      //     exposureArea: 'AG (Fuj)',
      //     vessels: [
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9389526',
      //         loadingAreaETA: '2024-09-22 08:29:07',
      //         localLoadingAreaETA: '2024-09-22 12:29:07',
      //         localOpenDate: '2024-09-15T23:59:00',
      //         openDate: '2024-09-15T21:59:00',
      //         openPort: 'PORT SUDAN',
      //         openRegion: 'Red Sea',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'Handy',
      //         vesselId: '6228a5de322650694d94697a',
      //         vesselName: 'Henning Maersk',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9726451',
      //         loadingAreaETA: '2024-10-04 23:32:11',
      //         localLoadingAreaETA: '2024-10-05 03:32:11',
      //         localOpenDate: '2024-09-22T06:51:00',
      //         openDate: '2024-09-22T04:51:00',
      //         openPort: 'DURBAN',
      //         openRegion: 'East- & South Africa',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'MR',
      //         vesselId: '60c02db2a8cd8426cfa21d82',
      //         vesselName: 'Maersk Tangier',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9332169',
      //         loadingAreaETA: '2024-10-05 03:41:11',
      //         localLoadingAreaETA: '2024-10-05 07:41:11',
      //         localOpenDate: '2024-09-22T11:00:00',
      //         openDate: '2024-09-22T09:00:00',
      //         openPort: 'DURBAN',
      //         openRegion: 'East- & South Africa',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'MR',
      //         vesselId: '6050ca6e5673da38e1abaa88',
      //         vesselName: 'Atlantic Crown',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9788497',
      //         loadingAreaETA: '2024-10-09 06:26:47',
      //         localLoadingAreaETA: '2024-10-09 10:26:47',
      //         localOpenDate: '2024-09-23T16:00:00',
      //         openDate: '2024-09-23T14:00:00',
      //         openPort: 'GIBRALTAR',
      //         openRegion: 'Red Sea',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'MR',
      //         vesselId: '6050c80f5673da9536aba9ff',
      //         vesselName: 'St. Petri',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9341433',
      //         loadingAreaETA: '2024-09-28 12:10:45',
      //         localLoadingAreaETA: '2024-09-28 16:10:45',
      //         localOpenDate: '2024-09-26T23:21:30',
      //         openDate: '2024-09-26T18:21:30',
      //         openPort: 'PORT QASIM',
      //         openRegion: 'WC India',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'Handy',
      //         vesselId: '6050c2af5673da0654aba95c',
      //         vesselName: 'OM Singapore',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9358321',
      //         loadingAreaETA: '2024-10-21 03:37:27',
      //         localLoadingAreaETA: '2024-10-21 07:37:27',
      //         localOpenDate: '2024-10-03T04:37:12',
      //         openDate: '2024-10-03T02:37:12',
      //         openPort: 'WALVIS BAY',
      //         openRegion: 'East- & South Africa',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'MR',
      //         vesselId: '62c7db7cfef791cc8a808aee',
      //         vesselName: 'Hansa Oslo',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9389497',
      //         loadingAreaETA: '2024-10-15 03:11:18',
      //         localLoadingAreaETA: '2024-10-15 07:11:18',
      //         localOpenDate: '2024-10-06T00:44:26',
      //         openDate: '2024-10-05T21:44:26',
      //         openPort: 'PORT SAID',
      //         openRegion: 'Red Sea',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'Handy',
      //         vesselId: '6050c2c35673da6fdfaba962',
      //         vesselName: 'Hans Maersk',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9732929',
      //         loadingAreaETA: '2024-10-10 07:37:29',
      //         localLoadingAreaETA: '2024-10-10 11:37:29',
      //         localOpenDate: '2024-10-08T18:48:14',
      //         openDate: '2024-10-08T13:48:14',
      //         openPort: 'PORT QASIM',
      //         openRegion: 'WC India',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'MR',
      //         vesselId: '6050cc225673daad3cabaafc',
      //         vesselName: 'Cl Huaiyang',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9845180',
      //         loadingAreaETA: '2024-10-15 09:43:10',
      //         localLoadingAreaETA: '2024-10-15 13:43:10',
      //         localOpenDate: '2024-10-15T13:43:10',
      //         openDate: '2024-10-15T09:43:10',
      //         openPort: 'SOHAR',
      //         openRegion: 'Middle East',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'MR',
      //         vesselId: '6620d93d882da4ac679440cb',
      //         vesselName: 'Fpmc 36',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9299458',
      //         loadingAreaETA: '2024-11-04 05:18:48',
      //         localLoadingAreaETA: '2024-11-04 09:18:48',
      //         localOpenDate: '2024-11-02T03:54:14',
      //         openDate: '2024-11-01T22:24:14',
      //         openPort: 'KANDLA',
      //         openRegion: 'WC India',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'Handy',
      //         vesselId: '6050c3e05673da7a26aba97f',
      //         vesselName: 'Maersk Barry',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9365362',
      //         loadingAreaETA: '2024-11-11 07:44:50',
      //         localLoadingAreaETA: '2024-11-11 11:44:50',
      //         localOpenDate: '2024-11-09T06:20:16',
      //         openDate: '2024-11-09T00:50:16',
      //         openPort: 'KANDLA',
      //         openRegion: 'WC India',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'MR',
      //         vesselId: '6050c9575673dad426abaa54',
      //         vesselName: 'Gemini Pearl',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9379040',
      //         loadingAreaETA: '2024-11-23 11:00:00',
      //         localLoadingAreaETA: '2024-11-23 15:00:00',
      //         localOpenDate: '2024-11-23T15:00:00',
      //         openDate: '2024-11-23T11:00:00',
      //         openPort: 'FUJAIRAH',
      //         openRegion: 'Middle East',
      //         openWorldArea: 'AG (Fuj)',
      //         pool: 'Handy',
      //         vesselId: '64db47c02bb6ec893ef9ffa4',
      //         vesselName: 'Eastern Quince',
      //       },
      //     ],
      //   },
      //   {
      //     exposureArea: 'Americas (Houston)',
      //     vessels: [
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9831701',
      //         loadingAreaETA: '2024-09-12 17:01:00',
      //         localLoadingAreaETA: '2024-09-12 12:01:00',
      //         localOpenDate: '2024-09-12T12:01:00',
      //         openDate: '2024-09-12T17:01:00',
      //         openPort: 'HOUSTON',
      //         openRegion: 'USG-Caribs',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'MR',
      //         vesselId: '6405ddf9900e9746da219e2f',
      //         vesselName: 'Fpmc 32',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9555319',
      //         loadingAreaETA: '2024-09-14 06:07:15',
      //         localLoadingAreaETA: '2024-09-14 01:07:15',
      //         localOpenDate: '2024-09-13T01:33:36',
      //         openDate: '2024-09-13T06:33:36',
      //         openPort: 'USG DEVIATION POINT',
      //         openRegion: 'EC North America',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'MR',
      //         vesselId: '6050cacf5673da0f8babaa9a',
      //         vesselName: 'Maersk Mississippi',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9858852',
      //         loadingAreaETA: '2024-10-05 00:38:28',
      //         localLoadingAreaETA: '2024-10-04 19:38:28',
      //         localOpenDate: '2024-09-13T14:07:19',
      //         openDate: '2024-09-13T17:07:19',
      //         openPort: 'RECALADA PILOT STATION',
      //         openRegion: 'EC SAM',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'MR',
      //         vesselId: '6050cbe55673dab6f8abaae7',
      //         vesselName: 'Wisco Adventure',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9299422',
      //         loadingAreaETA: '2024-09-21 01:22:55',
      //         localLoadingAreaETA: '2024-09-20 20:22:55',
      //         localOpenDate: '2024-09-13T23:27:36',
      //         openDate: '2024-09-14T03:27:36',
      //         openPort: 'QUINCY',
      //         openRegion: 'EC North America',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'Handy',
      //         vesselId: '6050c4275673daf36caba98e',
      //         vesselName: 'Maersk Bering',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9561930',
      //         loadingAreaETA: '2024-09-26 11:12:26',
      //         localLoadingAreaETA: '2024-09-26 06:12:26',
      //         localOpenDate: '2024-09-13T21:52:30',
      //         openDate: '2024-09-14T04:52:30',
      //         openPort: 'LA PAZ',
      //         openRegion: 'WC Latin America',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'MR',
      //         vesselId: '64e5db65dbe3b03bdee46b40',
      //         vesselName: 'Mont Blanc I',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9786217',
      //         loadingAreaETA: '2024-09-16 21:52:08',
      //         localLoadingAreaETA: '2024-09-16 16:52:08',
      //         localOpenDate: '2024-09-15T17:18:29',
      //         openDate: '2024-09-15T22:18:29',
      //         openPort: 'USG DEVIATION POINT',
      //         openRegion: 'EC North America',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'MR',
      //         vesselId: '651d09a2ecd313e4823bfda6',
      //         vesselName: 'Maersk Cyprus',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9747106',
      //         loadingAreaETA: '2024-10-13 18:21:03',
      //         localLoadingAreaETA: '2024-10-13 13:21:03',
      //         localOpenDate: '2024-09-29T14:54:00',
      //         openDate: '2024-09-29T21:54:00',
      //         openPort: 'GUAYMAS',
      //         openRegion: 'WC Latin America',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'MR',
      //         vesselId: '6050cb685673da1b7eabaac5',
      //         vesselName: 'Lian Xi Hu',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9409479',
      //         loadingAreaETA: '2024-10-13 19:24:43',
      //         localLoadingAreaETA: '2024-10-13 14:24:43',
      //         localOpenDate: '2024-10-07T06:30:00',
      //         openDate: '2024-10-07T10:30:00',
      //         openPort: 'NEW YORK',
      //         openRegion: 'EC North America',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'LR1',
      //         vesselId: '65169459c37506d92cfaeb6b',
      //         vesselName: 'Sanmar Sonatina',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9500144',
      //         loadingAreaETA: '2024-10-26 15:14:42',
      //         localLoadingAreaETA: '2024-10-26 10:14:42',
      //         localOpenDate: '2024-10-08T09:43:48',
      //         openDate: '2024-10-08T12:43:48',
      //         openPort: 'RIO DE JANEIRO',
      //         openRegion: 'EC SAM',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'Intermediate',
      //         vesselId: '65f1a78a0224b2890bedb6b3',
      //         vesselName: 'XT PROGRESS',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9542178',
      //         loadingAreaETA: '2024-10-23 00:49:52',
      //         localLoadingAreaETA: '2024-10-22 19:49:52',
      //         localOpenDate: '2024-10-13T18:47:34',
      //         openDate: '2024-10-13T22:47:34',
      //         openPort: 'MANAUS',
      //         openRegion: 'EC SAM',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'Intermediate',
      //         vesselId: '65e040c6eb7ef16009a7d30b',
      //         vesselName: 'Asp Lily',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9340594',
      //         loadingAreaETA: '2024-10-29 12:34:26',
      //         localLoadingAreaETA: '2024-10-29 07:34:26',
      //         localOpenDate: '2024-10-22T10:39:07',
      //         openDate: '2024-10-22T14:39:07',
      //         openPort: 'QUINCY',
      //         openRegion: 'EC North America',
      //         openWorldArea: 'Americas (Houston)',
      //         pool: 'Handy',
      //         vesselId: '6050c3f65673da4973aba985',
      //         vesselName: 'Maersk Beaufort',
      //       },
      //     ],
      //   },
      //   {
      //     exposureArea: 'MED (Malta)',
      //     vessels: [
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9636644',
      //         loadingAreaETA: '2024-09-09 02:04:32',
      //         localLoadingAreaETA: '2024-09-09 04:04:32',
      //         localOpenDate: '2024-09-07T09:46:00',
      //         openDate: '2024-09-07T07:46:00',
      //         openPort: 'PLOCE',
      //         openRegion: 'Center Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6050c3725673da5d8eaba977',
      //         vesselName: 'Maersk Aegean',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9636632',
      //         loadingAreaETA: '2024-09-11 03:22:52',
      //         localLoadingAreaETA: '2024-09-11 05:22:52',
      //         localOpenDate: '2024-09-09T15:57:22',
      //         openDate: '2024-09-09T13:57:22',
      //         openPort: 'CIVITAVECCHIA',
      //         openRegion: 'Center Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6050c3045673da7418aba971',
      //         vesselName: 'Maersk Adriatic',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9313436',
      //         loadingAreaETA: '2024-09-09 14:00:00',
      //         localLoadingAreaETA: '2024-09-09 16:00:00',
      //         localOpenDate: '2024-09-09T16:00:00',
      //         openDate: '2024-09-09T14:00:00',
      //         openPort: 'MALTA (COUNTRY)',
      //         openRegion: 'Center Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '667282cd6308ee482fab96e6',
      //         vesselName: 'Peonia',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9341445',
      //         loadingAreaETA: '2024-09-12 01:22:01',
      //         localLoadingAreaETA: '2024-09-12 03:22:01',
      //         localOpenDate: '2024-09-09T22:00:00',
      //         openDate: '2024-09-09T19:00:00',
      //         openPort: 'ALIAGA',
      //         openRegion: 'East Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6050c4325673da65e9aba991',
      //         vesselName: 'OM Borneo',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9389514',
      //         loadingAreaETA: '2024-09-12 06:18:58',
      //         localLoadingAreaETA: '2024-09-12 08:18:58',
      //         localOpenDate: '2024-09-10T02:00:00',
      //         openDate: '2024-09-10T00:00:00',
      //         openPort: 'BARCELONA',
      //         openRegion: 'West Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6050c2d35673da269faba965',
      //         vesselName: 'Helene Maersk',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9399363',
      //         loadingAreaETA: '2024-09-13 00:36:24',
      //         localLoadingAreaETA: '2024-09-13 02:36:24',
      //         localOpenDate: '2024-09-11T06:00:00',
      //         openDate: '2024-09-11T04:00:00',
      //         openPort: 'LIVORNO',
      //         openRegion: 'Center Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6050c1a95673da01d1aba93a',
      //         vesselName: 'Hulda Maersk',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9291597',
      //         loadingAreaETA: '2024-09-13 09:50:56',
      //         localLoadingAreaETA: '2024-09-13 11:50:56',
      //         localOpenDate: '2024-09-12T00:00:00',
      //         openDate: '2024-09-11T21:00:00',
      //         openPort: 'SAN NICOLAS (GREECE)',
      //         openRegion: 'East Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Intermediate',
      //         vesselId: '63ab173c81ddd2a92c07ddb7',
      //         vesselName: 'Ym Jupiter',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9431264',
      //         loadingAreaETA: '2024-09-14 07:50:47',
      //         localLoadingAreaETA: '2024-09-14 09:50:47',
      //         localOpenDate: '2024-09-12T00:48:41',
      //         openDate: '2024-09-11T22:48:41',
      //         openPort: 'SETE',
      //         openRegion: 'West Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6050c2f35673da4111aba96e',
      //         vesselName: 'Kirsten Maersk',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9374428',
      //         loadingAreaETA: '2024-09-13 06:18:50',
      //         localLoadingAreaETA: '2024-09-13 08:18:50',
      //         localOpenDate: '2024-09-12T04:54:43',
      //         openDate: '2024-09-12T02:54:43',
      //         openPort: 'SARROCH',
      //         openRegion: 'West Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6050c4635673da7088aba99f',
      //         vesselName: 'Maersk Kara',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9724568',
      //         loadingAreaETA: '2024-09-14 05:05:43',
      //         localLoadingAreaETA: '2024-09-14 07:05:43',
      //         localOpenDate: '2024-09-12T22:48:00',
      //         openDate: '2024-09-12T20:48:00',
      //         openPort: 'PORTO ROMANO',
      //         openRegion: 'Center Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6629f99a4eda322509b9d7b8',
      //         vesselName: 'Sea Cirrus',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9333242',
      //         loadingAreaETA: '2024-09-22 15:34:37',
      //         localLoadingAreaETA: '2024-09-22 17:34:37',
      //         localOpenDate: '2024-09-14T08:00:00',
      //         openDate: '2024-09-14T08:00:00',
      //         openPort: 'DAKAR',
      //         openRegion: 'WAF',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'MR',
      //         vesselId: '64fec5c14c9f4c7004453def',
      //         vesselName: 'Florence',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9705914',
      //         loadingAreaETA: '2024-09-28 22:41:38',
      //         localLoadingAreaETA: '2024-09-29 00:41:38',
      //         localOpenDate: '2024-09-15T09:00:00',
      //         openDate: '2024-09-15T08:00:00',
      //         openPort: 'LAGOS (NIGERIA)',
      //         openRegion: 'WAF',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'MR',
      //         vesselId: '65df2a0d5080afc96b36bb40',
      //         vesselName: 'Elka Elefsis',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9732931',
      //         loadingAreaETA: '2024-09-17 22:18:58',
      //         localLoadingAreaETA: '2024-09-18 00:18:58',
      //         localOpenDate: '2024-09-15T18:00:00',
      //         openDate: '2024-09-15T16:00:00',
      //         openPort: 'BARCELONA',
      //         openRegion: 'West Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'MR',
      //         vesselId: '6050cc2f5673da7ea0abaaff',
      //         vesselName: 'Cl Fugou',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9809784',
      //         loadingAreaETA: '2024-09-19 11:33:41',
      //         localLoadingAreaETA: '2024-09-19 13:33:41',
      //         localOpenDate: '2024-09-16T07:30:00',
      //         openDate: '2024-09-16T04:30:00',
      //         openPort: 'VARNA',
      //         openRegion: 'Black sea',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Intermediate',
      //         vesselId: '6050c8ce5673da799dabaa37',
      //         vesselName: 'Sloman Hebe',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9399351',
      //         loadingAreaETA: '2024-09-17 22:47:45',
      //         localLoadingAreaETA: '2024-09-18 00:47:45',
      //         localOpenDate: '2024-09-16T22:05:04',
      //         openDate: '2024-09-16T20:05:04',
      //         openPort: 'NAPLES',
      //         openRegion: 'Center Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6050c4df5673da03c7aba9bb',
      //         vesselName: 'Henry Maersk',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9431290',
      //         loadingAreaETA: '2024-09-20 19:30:07',
      //         localLoadingAreaETA: '2024-09-20 21:30:07',
      //         localOpenDate: '2024-09-17T12:22:36',
      //         openDate: '2024-09-17T11:22:36',
      //         openPort: 'TANGER MED',
      //         openRegion: 'West Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6050c47e5673dadee5aba9a5',
      //         vesselName: 'Maersk Katarina',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9431288',
      //         loadingAreaETA: '2024-09-21 15:03:07',
      //         localLoadingAreaETA: '2024-09-21 17:03:07',
      //         localOpenDate: '2024-09-18T14:22:34',
      //         openDate: '2024-09-18T11:22:34',
      //         openPort: 'VASSILIKO',
      //         openRegion: 'East Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Handy',
      //         vesselId: '6050c1515673da1ea4aba931',
      //         vesselName: 'Maersk Kaya',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9542166',
      //         loadingAreaETA: '2024-09-30 21:27:09',
      //         localLoadingAreaETA: '2024-09-30 23:27:09',
      //         localOpenDate: '2024-09-29T01:51:12',
      //         openDate: '2024-09-28T23:51:12',
      //         openPort: 'VASTO',
      //         openRegion: 'Center Med',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Intermediate',
      //         vesselId: '647ef991b36dff8049fa0ee1',
      //         vesselName: 'NV Minerva',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9527075',
      //         loadingAreaETA: '2024-11-13 00:34:25',
      //         localLoadingAreaETA: '2024-11-13 01:34:25',
      //         localOpenDate: '2024-10-30T10:52:47',
      //         openDate: '2024-10-30T09:52:47',
      //         openPort: 'LAGOS (NIGERIA)',
      //         openRegion: 'WAF',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'Intermediate',
      //         vesselId: '649415bd7a79bcacda1ce00a',
      //         vesselName: 'NV Athena',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9708617',
      //         loadingAreaETA: '2024-11-08 08:38:27',
      //         localLoadingAreaETA: '2024-11-08 09:38:27',
      //         localOpenDate: '2024-10-31T01:03:50',
      //         openDate: '2024-10-31T01:03:50',
      //         openPort: 'DAKAR',
      //         openRegion: 'WAF',
      //         openWorldArea: 'MED (Malta)',
      //         pool: 'MR',
      //         vesselId: '6050ca6c5673da7828abaa85',
      //         vesselName: 'Maersk Tacoma',
      //       },
      //     ],
      //   },
      //   {
      //     exposureArea: 'North Asia (Yosu)',
      //     vessels: [
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9380520',
      //         loadingAreaETA: '2024-09-15 19:28:13',
      //         localLoadingAreaETA: '2024-09-16 04:28:13',
      //         localOpenDate: '2024-09-13T18:00:00',
      //         openDate: '2024-09-13T09:00:00',
      //         openPort: 'NEGISHI',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '6050ccb65673da3097abab1f',
      //         vesselName: 'Gran Couva',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9786205',
      //         loadingAreaETA: '2024-09-15 04:25:05',
      //         localLoadingAreaETA: '2024-09-15 13:25:05',
      //         localOpenDate: '2024-09-15T06:01:00',
      //         openDate: '2024-09-14T21:01:00',
      //         openPort: 'ONSAN',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '6050cba35673da2213abaad4',
      //         vesselName: 'Maersk Curacao',
      //       },
      //       {
      //         cargoGrade: '',
      //         ID: '9439838',
      //         loadingAreaETA: '2024-09-20 21:12:07',
      //         localLoadingAreaETA: '2024-09-21 06:12:07',
      //         localOpenDate: '2024-09-16T16:12:00',
      //         openDate: '2024-09-16T08:12:00',
      //         openPort: 'BATAAN',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'Intermediate',
      //         vesselId: '668262bef01b7360a80156b6',
      //         vesselName: 'EVERHARD SCHULTE',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9786152',
      //         loadingAreaETA: '2024-09-20 07:08:17',
      //         localLoadingAreaETA: '2024-09-20 16:08:17',
      //         localOpenDate: '2024-09-19T12:00:00',
      //         openDate: '2024-09-19T04:00:00',
      //         openPort: 'ZHENJIANG',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '6050cc375673da8e2eabab02',
      //         vesselName: 'Maersk Callao',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9581447',
      //         loadingAreaETA: '2024-10-07 14:07:23',
      //         localLoadingAreaETA: '2024-10-07 23:07:23',
      //         localOpenDate: '2024-09-19T20:31:40',
      //         openDate: '2024-09-20T03:31:40',
      //         openPort: 'SAN FRANCISCO',
      //         openRegion: 'USWC',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '6050cb215673da5c30abaaad',
      //         vesselName: 'Maersk Maru',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9862413',
      //         loadingAreaETA: '2024-09-23 03:05:36',
      //         localLoadingAreaETA: '2024-09-23 12:05:36',
      //         localOpenDate: '2024-09-20T20:50:43',
      //         openDate: '2024-09-20T11:50:43',
      //         openPort: 'TOMAKOMAI',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '66d8459ec7ee987fae5b21a9',
      //         vesselName: 'Pro Onyx',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9576818',
      //         loadingAreaETA: '2024-09-22 11:48:51',
      //         localLoadingAreaETA: '2024-09-22 20:48:51',
      //         localOpenDate: '2024-09-21T08:09:08',
      //         openDate: '2024-09-20T23:09:08',
      //         openPort: 'SAKAI',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'Intermediate',
      //         vesselId: '650aa531cba2442c9ba5f117',
      //         vesselName: 'TTC Vishaka',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9786176',
      //         loadingAreaETA: '2024-09-24 10:54:05',
      //         localLoadingAreaETA: '2024-09-24 19:54:05',
      //         localOpenDate: '2024-09-24T12:30:00',
      //         openDate: '2024-09-24T03:30:00',
      //         openPort: 'ULSAN',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '651d1f5fecd313e4823bfdab',
      //         vesselName: 'Maersk Cebu',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9459266',
      //         loadingAreaETA: '2024-09-29 15:23:30',
      //         localLoadingAreaETA: '2024-09-30 00:23:30',
      //         localOpenDate: '2024-09-30T00:23:30',
      //         openDate: '2024-09-29T15:23:30',
      //         openPort: 'YOSU',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '63ef4c1d7936a2109b499833',
      //         vesselName: 'Leman',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9786164',
      //         loadingAreaETA: '2024-10-03 17:48:16',
      //         localLoadingAreaETA: '2024-10-04 02:48:16',
      //         localOpenDate: '2024-10-02T08:51:53',
      //         openDate: '2024-10-02T00:51:53',
      //         openPort: 'DONGYING',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '6050cc045673daae9babaaf0',
      //         vesselName: 'Maersk Cayman',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9539561',
      //         loadingAreaETA: '2024-10-15 04:06:52',
      //         localLoadingAreaETA: '2024-10-15 13:06:52',
      //         localOpenDate: '2024-10-02T22:29:31',
      //         openDate: '2024-10-02T12:29:31',
      //         openPort: 'TOWNSVILLE',
      //         openRegion: 'EC Australia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '6177bbefc1877f12b7cb6be7',
      //         vesselName: 'Canal Street',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9306653',
      //         loadingAreaETA: '2024-10-06 01:13:01',
      //         localLoadingAreaETA: '2024-10-06 10:13:01',
      //         localOpenDate: '2024-10-05T00:01:00',
      //         openDate: '2024-10-04T16:01:00',
      //         openPort: 'ZHOUSHAN',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'Handy',
      //         vesselId: '6050c5fd5673da22f8aba9d0',
      //         vesselName: 'Sloman Thetis',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9366299',
      //         loadingAreaETA: '2024-10-22 13:38:16',
      //         localLoadingAreaETA: '2024-10-22 22:38:16',
      //         localOpenDate: '2024-10-06T22:01:26',
      //         openDate: '2024-10-07T06:01:26',
      //         openPort: 'KOTZEBUE',
      //         openRegion: 'USWC',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '6594f62dd20bce1a136d0e9a',
      //         vesselName: 'Capital',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9718076',
      //         loadingAreaETA: '2024-10-10 08:39:08',
      //         localLoadingAreaETA: '2024-10-10 17:39:08',
      //         localOpenDate: '2024-10-09T07:27:07',
      //         openDate: '2024-10-08T23:27:07',
      //         openPort: 'NINGBO',
      //         openRegion: 'N Asia',
      //         openWorldArea: 'North Asia (Yosu)',
      //         pool: 'MR',
      //         vesselId: '63f487ae71978b5c6b088bab',
      //         vesselName: 'Maersk Tokyo',
      //       },
      //     ],
      //   },
      //   {
      //     exposureArea: 'NWE (Amsterdam)',
      //     vessels: [
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9399349',
      //         loadingAreaETA: '2024-09-06 13:20:20',
      //         localLoadingAreaETA: '2024-09-06 15:20:20',
      //         localOpenDate: '2024-09-06T03:24:00',
      //         openDate: '2024-09-06T01:24:00',
      //         openPort: 'ROTTERDAM',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Handy',
      //         vesselId: '6050c4bd5673da775faba9b1',
      //         vesselName: 'Henriette Maersk',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9439814',
      //         loadingAreaETA: '2024-09-12 00:48:41',
      //         localLoadingAreaETA: '2024-09-12 02:48:41',
      //         localOpenDate: '2024-09-07T23:00:00',
      //         openDate: '2024-09-07T22:00:00',
      //         openPort: 'SINES',
      //         openRegion: 'Atlantic',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '6152eae6ce7efb4e5b40b0fd',
      //         vesselName: 'Erin Schulte',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9786140',
      //         loadingAreaETA: '2024-09-10 05:32:51',
      //         localLoadingAreaETA: '2024-09-10 07:32:51',
      //         localOpenDate: '2024-09-08T12:00:00',
      //         openDate: '2024-09-08T10:00:00',
      //         openPort: 'KALUNDBORG',
      //         openRegion: 'Baltic',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'MR',
      //         vesselId: '6050cc1a5673daaf51abaaf9',
      //         vesselName: 'Maersk Capri',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9439826',
      //         loadingAreaETA: '2024-09-10 11:41:43',
      //         localLoadingAreaETA: '2024-09-10 13:41:43',
      //         localOpenDate: '2024-09-09T18:00:00',
      //         openDate: '2024-09-09T17:00:00',
      //         openPort: 'IMMINGHAM',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '6050c7dd5673dafdc2aba9f6',
      //         vesselName: 'Eva Schulte',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9439864',
      //         loadingAreaETA: '2024-09-10 21:30:00',
      //         localLoadingAreaETA: '2024-09-10 23:30:00',
      //         localOpenDate: '2024-09-10T23:30:00',
      //         openDate: '2024-09-10T21:30:00',
      //         openPort: 'AMSTERDAM',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '60c358bba8cd845ef6a21d88',
      //         vesselName: 'Erika Schulte',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9466714',
      //         loadingAreaETA: '2024-09-12 10:36:15',
      //         localLoadingAreaETA: '2024-09-12 12:36:15',
      //         localOpenDate: '2024-09-12T12:36:15',
      //         openDate: '2024-09-12T10:36:15',
      //         openPort: 'AMSTERDAM',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '6050c8e55673da6a0babaa3d',
      //         vesselName: 'Sloman Hera',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9340623',
      //         loadingAreaETA: '2024-09-15 21:24:01',
      //         localLoadingAreaETA: '2024-09-15 23:24:01',
      //         localOpenDate: '2024-09-13T10:51:15',
      //         openDate: '2024-09-13T09:51:15',
      //         openPort: 'DUBLIN',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '6050c8a45673daf3a0abaa2b',
      //         vesselName: 'Bro Nissum',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9466738',
      //         loadingAreaETA: '2024-09-14 05:56:20',
      //         localLoadingAreaETA: '2024-09-14 07:56:20',
      //         localOpenDate: '2024-09-13T20:00:00',
      //         openDate: '2024-09-13T18:00:00',
      //         openPort: 'ROTTERDAM',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '6050c8f05673dab1c0abaa40',
      //         vesselName: 'Sloman Hermes',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9786190',
      //         loadingAreaETA: '2024-09-15 00:50:38',
      //         localLoadingAreaETA: '2024-09-15 02:50:38',
      //         localOpenDate: '2024-09-14T08:30:00',
      //         openDate: '2024-09-14T07:30:00',
      //         openPort: 'THAMESHAVEN',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'MR',
      //         vesselId: '651d1c32ecd313e4823bfda9',
      //         vesselName: 'Maersk Crete',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9466726',
      //         loadingAreaETA: '2024-09-16 02:00:06',
      //         localLoadingAreaETA: '2024-09-16 04:00:06',
      //         localOpenDate: '2024-09-14T20:23:05',
      //         openDate: '2024-09-14T19:23:05',
      //         openPort: 'PORTLAND (UNITED KINGDOM)',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '6050c9355673dab62dabaa4c',
      //         vesselName: 'Sloman Herakles',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9344435',
      //         loadingAreaETA: '2024-09-15 11:57:20',
      //         localLoadingAreaETA: '2024-09-15 13:57:20',
      //         localOpenDate: '2024-09-15T02:01:00',
      //         openDate: '2024-09-15T00:01:00',
      //         openPort: 'ROTTERDAM',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '6050c8635673da73c5abaa1a',
      //         vesselName: 'Bro Anna',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9323819',
      //         loadingAreaETA: '2024-09-16 16:42:53',
      //         localLoadingAreaETA: '2024-09-16 18:42:53',
      //         localOpenDate: '2024-09-16T00:22:15',
      //         openDate: '2024-09-15T23:22:15',
      //         openPort: 'THAMESHAVEN',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '6050c8b85673dac0b2abaa31',
      //         vesselName: 'Bro Nuuk',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9827994',
      //         loadingAreaETA: '2024-09-18 08:00:05',
      //         localLoadingAreaETA: '2024-09-18 10:00:05',
      //         localOpenDate: '2024-09-17T22:03:45',
      //         openDate: '2024-09-17T20:03:45',
      //         openPort: 'ROTTERDAM',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'City',
      //         vesselId: '656ee268be8e36ee5f9700bb',
      //         vesselName: 'Eastern Nymphaea',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9439840',
      //         loadingAreaETA: '2024-09-18 10:19:08',
      //         localLoadingAreaETA: '2024-09-18 12:19:08',
      //         localOpenDate: '2024-09-18T00:22:48',
      //         openDate: '2024-09-17T22:22:48',
      //         openPort: 'ROTTERDAM',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '615dd767ce7efb6cda40b21d',
      //         vesselName: 'Elisabeth Schulte',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9499486',
      //         loadingAreaETA: '2024-09-23 02:18:45',
      //         localLoadingAreaETA: '2024-09-23 04:18:45',
      //         localOpenDate: '2024-09-18T10:16:30',
      //         openDate: '2024-09-18T08:16:30',
      //         openPort: 'HUELVA',
      //         openRegion: 'Atlantic',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '65e041aebefb47da1b9c80c5',
      //         vesselName: 'Opec Victory',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9399612',
      //         loadingAreaETA: '2024-09-22 04:14:01',
      //         localLoadingAreaETA: '2024-09-22 06:14:01',
      //         localOpenDate: '2024-09-21T02:00:00',
      //         openDate: '2024-09-21T01:00:00',
      //         openPort: 'FAWLEY',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'MR',
      //         vesselId: '61482ebd5458764de44a4644',
      //         vesselName: 'Etc Nefertari',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9864344',
      //         loadingAreaETA: '2024-09-24 23:48:01',
      //         localLoadingAreaETA: '2024-09-25 01:48:01',
      //         localOpenDate: '2024-09-24T13:51:41',
      //         openDate: '2024-09-24T11:51:41',
      //         openPort: 'ROTTERDAM',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'MR',
      //         vesselId: '6050cb435673daa23cabaab9',
      //         vesselName: 'Ion M',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9894739',
      //         loadingAreaETA: '2024-09-25 17:02:01',
      //         localLoadingAreaETA: '2024-09-25 19:02:01',
      //         localOpenDate: '2024-09-24T14:48:00',
      //         openDate: '2024-09-24T13:48:00',
      //         openPort: 'FAWLEY',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'MR',
      //         vesselId: '66c7433562fce44df6da1db7',
      //         vesselName: 'NORD MIYABI',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9726463',
      //         loadingAreaETA: '2024-10-02 03:35:50',
      //         localLoadingAreaETA: '2024-10-02 05:35:50',
      //         localOpenDate: '2024-09-30T01:46:48',
      //         openDate: '2024-09-30T00:46:48',
      //         openPort: 'MILFORD HAVEN',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'MR',
      //         vesselId: '6050ca115673da5657abaa74',
      //         vesselName: 'Maersk Teesport',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9786188',
      //         loadingAreaETA: '2024-10-10 09:42:24',
      //         localLoadingAreaETA: '2024-10-10 11:42:24',
      //         localOpenDate: '2024-10-07T15:37:36',
      //         openDate: '2024-10-07T14:37:36',
      //         openPort: 'AUGHINISH',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'MR',
      //         vesselId: '6050cbce5673da5eedabaae0',
      //         vesselName: 'Maersk Corsica',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9362138',
      //         loadingAreaETA: '2024-10-13 02:02:35',
      //         localLoadingAreaETA: '2024-10-13 04:02:35',
      //         localOpenDate: '2024-10-09T06:04:48',
      //         openDate: '2024-10-09T03:04:48',
      //         openPort: 'KOTKA',
      //         openRegion: 'Baltic',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '63ab15d581ddd2a92c07ddb2',
      //         vesselName: 'Ym Saturn',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9870707',
      //         loadingAreaETA: '2024-11-12 05:15:22',
      //         localLoadingAreaETA: '2024-11-12 06:15:22',
      //         localOpenDate: '2024-11-12T06:15:22',
      //         openDate: '2024-11-12T05:15:22',
      //         openPort: 'AMSTERDAM',
      //         openRegion: 'UKC',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'MR',
      //         vesselId: '6641ea897834886545bc8691',
      //         vesselName: 'Nord Elegance',
      //       },
      //       {
      //         cargoGrade: '',
      //         ID: '9542130',
      //         loadingAreaETA: '2024-12-08 14:22:22',
      //         localLoadingAreaETA: '2024-12-08 15:22:22',
      //         localOpenDate: '2024-12-06T04:33:13',
      //         openDate: '2024-12-06T03:33:13',
      //         openPort: 'KARLSHAMN',
      //         openRegion: 'Baltic',
      //         openWorldArea: 'NWE (Amsterdam)',
      //         pool: 'Intermediate',
      //         vesselId: '66b2106e3bf57db48f8da59e',
      //         vesselName: 'Windsor',
      //       },
      //     ],
      //   },
      //   {
      //     exposureArea: 'South Asia (Singapore)',
      //     vessels: [
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9306677',
      //         loadingAreaETA: '2024-09-09 01:24:00',
      //         localLoadingAreaETA: '2024-09-09 09:24:00',
      //         localOpenDate: '2024-09-09T09:24:00',
      //         openDate: '2024-09-09T01:24:00',
      //         openPort: 'SINGAPORE',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'Handy',
      //         vesselId: '6050c5ee5673da747eaba9cd',
      //         vesselName: 'Sloman Themis',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9365283',
      //         loadingAreaETA: '2024-09-11 00:00:00',
      //         localLoadingAreaETA: '2024-09-11 08:00:00',
      //         localOpenDate: '2024-09-11T08:00:00',
      //         openDate: '2024-09-11T00:00:00',
      //         openPort: 'SINGAPORE',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'Handy',
      //         vesselId: '6050c5005673dad3e9aba9c4',
      //         vesselName: 'Bloom',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9333278',
      //         loadingAreaETA: '2024-09-04 00:05:42',
      //         localLoadingAreaETA: '2024-09-04 08:05:42',
      //         localOpenDate: '2024-09-12T04:00:00',
      //         openDate: '2024-09-12T04:00:00',
      //         openPort: 'AT SEA',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'MR',
      //         vesselId: '6050ccbc5673da72f3abab22',
      //         vesselName: 'Challenge Prelude',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9253258',
      //         loadingAreaETA: '2024-09-14 20:00:00',
      //         localLoadingAreaETA: '2024-09-15 04:00:00',
      //         localOpenDate: '2024-09-15T04:00:00',
      //         openDate: '2024-09-14T20:00:00',
      //         openPort: 'SINGAPORE',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'Handy',
      //         vesselId: '60811d4a8ba1ea6df051114c',
      //         vesselName: 'Baltic',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9689043',
      //         loadingAreaETA: '2024-09-21 20:09:08',
      //         localLoadingAreaETA: '2024-09-22 04:09:08',
      //         localOpenDate: '2024-09-17T03:26:39',
      //         openDate: '2024-09-16T21:26:39',
      //         openPort: 'CHITTAGONG',
      //         openRegion: 'EC India',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'Intermediate',
      //         vesselId: '624a4e59bed572c5534ab3be',
      //         vesselName: 'Golden Violet',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9786138',
      //         loadingAreaETA: '2024-09-23 15:15:41',
      //         localLoadingAreaETA: '2024-09-23 23:15:41',
      //         localOpenDate: '2024-09-18T18:00:00',
      //         openDate: '2024-09-18T12:30:00',
      //         openPort: 'HALDIA',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'MR',
      //         vesselId: '64e7680fe32f9a5ebb5928be',
      //         vesselName: 'Maersk Cancun',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9333254',
      //         loadingAreaETA: '2024-09-28 08:10:51',
      //         localLoadingAreaETA: '2024-09-28 16:10:51',
      //         localOpenDate: '2024-09-23T11:27:07',
      //         openDate: '2024-09-23T05:57:07',
      //         openPort: 'TRINCOMALEE',
      //         openRegion: 'EC India',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'MR',
      //         vesselId: '66875b8e5660f38931a3266d',
      //         vesselName: 'Culture',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9346067',
      //         loadingAreaETA: '2024-09-24 12:36:28',
      //         localLoadingAreaETA: '2024-09-24 20:36:28',
      //         localOpenDate: '2024-09-24T20:36:28',
      //         openDate: '2024-09-24T12:36:28',
      //         openPort: 'TANJUNG PELEPAS',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'MR',
      //         vesselId: '608102f28ba1ea7fd651114a',
      //         vesselName: 'Grand Ace1',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9573672',
      //         loadingAreaETA: '2024-09-30 04:24:25',
      //         localLoadingAreaETA: '2024-09-30 12:24:25',
      //         localOpenDate: '2024-09-28T07:19:12',
      //         openDate: '2024-09-28T00:19:12',
      //         openPort: 'NHA BE',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'MR',
      //         vesselId: '607400d173a879ac534edd6d',
      //         vesselName: 'Vendome Street',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9295335',
      //         loadingAreaETA: '2024-10-01 00:55:04',
      //         localLoadingAreaETA: '2024-10-01 08:55:04',
      //         localOpenDate: '2024-10-01T08:55:04',
      //         openDate: '2024-10-01T00:55:04',
      //         openPort: 'SINGAPORE',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'Handy',
      //         vesselId: '60e6f7b653e98fbf44818c9e',
      //         vesselName: 'Bassilevousa',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9447732',
      //         loadingAreaETA: '2024-10-03 23:24:12',
      //         localLoadingAreaETA: '2024-10-04 07:24:12',
      //         localOpenDate: '2024-10-04T07:24:12',
      //         openDate: '2024-10-03T23:24:12',
      //         openPort: 'TANJUNG PELEPAS',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'MR',
      //         vesselId: '6050cb745673da40cdabaac8',
      //         vesselName: 'Maersk Magellan',
      //       },
      //       {
      //         cargoGrade: 'CPP',
      //         ID: '9713014',
      //         loadingAreaETA: '2024-10-14 07:42:15',
      //         localLoadingAreaETA: '2024-10-14 15:42:15',
      //         localOpenDate: '2024-10-09T07:02:00',
      //         openDate: '2024-10-09T01:32:00',
      //         openPort: 'KRISHNAPATNAM',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'City',
      //         vesselId: '63034d9ca4e54b6a24b25ab5',
      //         vesselName: 'Asp Sunrise',
      //       },
      //       {
      //         cargoGrade: 'DPP',
      //         ID: '9443877',
      //         loadingAreaETA: '2024-10-09 13:20:54',
      //         localLoadingAreaETA: '2024-10-09 21:20:54',
      //         localOpenDate: '2024-10-09T21:20:54',
      //         openDate: '2024-10-09T13:20:54',
      //         openPort: 'SINGAPORE',
      //         openRegion: 'SE Asia',
      //         openWorldArea: 'South Asia (Singapore)',
      //         pool: 'MR',
      //         vesselId: '609108268ca09e41765ca301',
      //         vesselName: 'Grand Ace10',
      //       },
      //     ],
      //   },
      // ],
    },
  },
}

export const mockTotalExposure = {
  data: {
    getTotalExposure: {
      totalExposure: [
        {
          exposureArea: 'Americas (Houston)',
          vessels: [
            {
              cargoGrade: 'CPP',
              ID: '9500144',
              loadingAreaETA: '2024-10-05 06:15:54',
              localLoadingAreaETA: '2024-10-05 01:15:54',
              localOpenDate: '2024-09-17T00:45:00',
              openDate: '2024-09-17T03:45:00',
              openPort: 'RIO DE JANEIRO',
              openRegion: 'EC SAM',
              openWorldArea: 'Americas (Houston)',
              pool: 'Intermediate',
              vesselId: '65f1a78a0224b2890bedb6b3',
              vesselName: 'XT PROGRESS',
              voyageNumber: '24608',
            },
            {
              cargoGrade: 'CPP',
              ID: '9542178',
              loadingAreaETA: '2024-10-23 20:49:52',
              localLoadingAreaETA: '2024-10-23 15:49:52',
              localOpenDate: '2024-10-14T14:47:34',
              openDate: '2024-10-14T18:47:34',
              openPort: 'MANAUS',
              openRegion: 'EC SAM',
              openWorldArea: 'Americas (Houston)',
              pool: 'Intermediate',
              vesselId: '65e040c6eb7ef16009a7d30b',
              vesselName: 'Asp Lily',
              voyageNumber: '24604',
            },
          ],
        },
        {
          exposureArea: 'MED (Malta)',
          vessels: [
            {
              cargoGrade: 'CPP',
              ID: '9291597',
              loadingAreaETA: '2024-09-13 05:20:56',
              localLoadingAreaETA: '2024-09-13 07:20:56',
              localOpenDate: '2024-09-11T19:30:00',
              openDate: '2024-09-11T16:30:00',
              openPort: 'SAN NICOLAS (GREECE)',
              openRegion: 'East Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Intermediate',
              vesselId: '63ab173c81ddd2a92c07ddb7',
              vesselName: 'Ym Jupiter',
              voyageNumber: '24616',
            },
            {
              cargoGrade: 'CPP',
              ID: '9809784',
              loadingAreaETA: '2024-09-19 11:33:41',
              localLoadingAreaETA: '2024-09-19 13:33:41',
              localOpenDate: '2024-09-16T07:30:00',
              openDate: '2024-09-16T04:30:00',
              openPort: 'VARNA',
              openRegion: 'Black sea',
              openWorldArea: 'MED (Malta)',
              pool: 'Intermediate',
              vesselId: '6050c8ce5673da799dabaa37',
              vesselName: 'Sloman Hebe',
              voyageNumber: '24619',
            },
            {
              cargoGrade: 'CPP',
              ID: '9542166',
              loadingAreaETA: '2024-09-30 22:27:09',
              localLoadingAreaETA: '2024-10-01 00:27:09',
              localOpenDate: '2024-09-29T02:51:12',
              openDate: '2024-09-29T00:51:12',
              openPort: 'VASTO',
              openRegion: 'Center Med',
              openWorldArea: 'MED (Malta)',
              pool: 'Intermediate',
              vesselId: '647ef991b36dff8049fa0ee1',
              vesselName: 'NV Minerva',
              voyageNumber: '24605',
            },
            {
              cargoGrade: 'CPP',
              ID: '9527075',
              loadingAreaETA: '2024-11-13 00:34:25',
              localLoadingAreaETA: '2024-11-13 01:34:25',
              localOpenDate: '2024-10-30T10:52:47',
              openDate: '2024-10-30T09:52:47',
              openPort: 'LAGOS (NIGERIA)',
              openRegion: 'WAF',
              openWorldArea: 'MED (Malta)',
              pool: 'Intermediate',
              vesselId: '649415bd7a79bcacda1ce00a',
              vesselName: 'NV Athena',
              voyageNumber: '24607',
            },
          ],
        },
        {
          exposureArea: 'North Asia (Yosu)',
          vessels: [
            {
              cargoGrade: '',
              ID: '9439838',
              loadingAreaETA: '2024-09-20 14:06:07',
              localLoadingAreaETA: '2024-09-20 23:06:07',
              localOpenDate: '2024-09-16T09:06:00',
              openDate: '2024-09-16T01:06:00',
              openPort: 'BATAAN',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'Intermediate',
              vesselId: '668262bef01b7360a80156b6',
              vesselName: 'EVERHARD SCHULTE',
              voyageNumber: '24600',
            },
            {
              cargoGrade: 'CPP',
              ID: '9576818',
              loadingAreaETA: '2024-09-21 02:48:51',
              localLoadingAreaETA: '2024-09-21 11:48:51',
              localOpenDate: '2024-09-19T23:09:08',
              openDate: '2024-09-19T14:09:08',
              openPort: 'SAKAI',
              openRegion: 'N Asia',
              openWorldArea: 'North Asia (Yosu)',
              pool: 'Intermediate',
              vesselId: '650aa531cba2442c9ba5f117',
              vesselName: 'TTC Vishaka',
              voyageNumber: '24611',
            },
          ],
        },
        {
          exposureArea: 'NWE (Amsterdam)',
          vessels: [
            {
              cargoGrade: 'DPP',
              ID: '9439814',
              loadingAreaETA: '2024-09-14 01:18:41',
              localLoadingAreaETA: '2024-09-14 03:18:41',
              localOpenDate: '2024-09-09T23:30:00',
              openDate: '2024-09-09T22:30:00',
              openPort: 'SINES',
              openRegion: 'Atlantic',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6152eae6ce7efb4e5b40b0fd',
              vesselName: 'Erin Schulte',
              voyageNumber: '24618',
            },
            {
              cargoGrade: 'CPP',
              ID: '9466714',
              loadingAreaETA: '2024-09-13 03:35:15',
              localLoadingAreaETA: '2024-09-13 05:35:15',
              localOpenDate: '2024-09-13T05:35:15',
              openDate: '2024-09-13T03:35:15',
              openPort: 'AMSTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c8e55673da6a0babaa3d',
              vesselName: 'Sloman Hera',
              voyageNumber: '24623',
            },
            {
              cargoGrade: 'CPP',
              ID: '9466738',
              loadingAreaETA: '2024-09-14 05:56:20',
              localLoadingAreaETA: '2024-09-14 07:56:20',
              localOpenDate: '2024-09-13T20:00:00',
              openDate: '2024-09-13T18:00:00',
              openPort: 'ROTTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c8f05673dab1c0abaa40',
              vesselName: 'Sloman Hermes',
              voyageNumber: '24615',
            },
            {
              cargoGrade: 'CPP',
              ID: '9340623',
              loadingAreaETA: '2024-09-16 16:54:01',
              localLoadingAreaETA: '2024-09-16 18:54:01',
              localOpenDate: '2024-09-14T06:21:15',
              openDate: '2024-09-14T05:21:15',
              openPort: 'DUBLIN',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c8a45673daf3a0abaa2b',
              vesselName: 'Bro Nissum',
              voyageNumber: '24611',
            },
            {
              cargoGrade: 'DPP',
              ID: '9466726',
              loadingAreaETA: '2024-09-15 15:05:06',
              localLoadingAreaETA: '2024-09-15 17:05:06',
              localOpenDate: '2024-09-14T09:28:05',
              openDate: '2024-09-14T08:28:05',
              openPort: 'PORTLAND (UNITED KINGDOM)',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c9355673dab62dabaa4c',
              vesselName: 'Sloman Herakles',
              voyageNumber: '24619',
            },
            {
              cargoGrade: 'DPP',
              ID: '9344435',
              loadingAreaETA: '2024-09-16 10:19:20',
              localLoadingAreaETA: '2024-09-16 12:19:20',
              localOpenDate: '2024-09-16T00:23:00',
              openDate: '2024-09-15T22:23:00',
              openPort: 'ROTTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c8635673da73c5abaa1a',
              vesselName: 'Bro Anna',
              voyageNumber: '24619',
            },
            {
              cargoGrade: 'CPP',
              ID: '9323819',
              loadingAreaETA: '2024-09-16 16:42:53',
              localLoadingAreaETA: '2024-09-16 18:42:53',
              localOpenDate: '2024-09-16T00:22:15',
              openDate: '2024-09-15T23:22:15',
              openPort: 'THAMESHAVEN',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c8b85673dac0b2abaa31',
              vesselName: 'Bro Nuuk',
              voyageNumber: '24615',
            },
            {
              cargoGrade: 'CPP',
              ID: '9439840',
              loadingAreaETA: '2024-09-18 18:58:53',
              localLoadingAreaETA: '2024-09-18 20:58:53',
              localOpenDate: '2024-09-18T09:02:33',
              openDate: '2024-09-18T07:02:33',
              openPort: 'ROTTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '615dd767ce7efb6cda40b21d',
              vesselName: 'Elisabeth Schulte',
              voyageNumber: '24611',
            },
            {
              cargoGrade: 'CPP',
              ID: '9499486',
              loadingAreaETA: '2024-09-23 09:52:17',
              localLoadingAreaETA: '2024-09-23 11:52:17',
              localOpenDate: '2024-09-18T17:50:02',
              openDate: '2024-09-18T15:50:02',
              openPort: 'HUELVA',
              openRegion: 'Atlantic',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '65e041aebefb47da1b9c80c5',
              vesselName: 'Opec Victory',
              voyageNumber: '24604',
            },
            {
              cargoGrade: 'CPP',
              ID: '9439864',
              loadingAreaETA: '2024-09-19 00:58:07',
              localLoadingAreaETA: '2024-09-19 02:58:07',
              localOpenDate: '2024-09-19T02:58:07',
              openDate: '2024-09-19T00:58:07',
              openPort: 'AMSTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '60c358bba8cd845ef6a21d88',
              vesselName: 'Erika Schulte',
              voyageNumber: '24616',
            },
            {
              cargoGrade: 'DPP',
              ID: '9439826',
              loadingAreaETA: '2024-09-20 15:56:20',
              localLoadingAreaETA: '2024-09-20 17:56:20',
              localOpenDate: '2024-09-20T06:00:00',
              openDate: '2024-09-20T04:00:00',
              openPort: 'ROTTERDAM',
              openRegion: 'UKC',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '6050c7dd5673dafdc2aba9f6',
              vesselName: 'Eva Schulte',
              voyageNumber: '24615',
            },
            {
              cargoGrade: 'CPP',
              ID: '9362138',
              loadingAreaETA: '2024-10-13 08:07:23',
              localLoadingAreaETA: '2024-10-13 10:07:23',
              localOpenDate: '2024-10-09T12:09:36',
              openDate: '2024-10-09T09:09:36',
              openPort: 'KOTKA',
              openRegion: 'Baltic',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '63ab15d581ddd2a92c07ddb2',
              vesselName: 'Ym Saturn',
              voyageNumber: '24610',
            },
            {
              cargoGrade: '',
              ID: '9542130',
              loadingAreaETA: '2024-12-08 14:22:22',
              localLoadingAreaETA: '2024-12-08 15:22:22',
              localOpenDate: '2024-12-06T04:33:13',
              openDate: '2024-12-06T03:33:13',
              openPort: 'KARLSHAMN',
              openRegion: 'Baltic',
              openWorldArea: 'NWE (Amsterdam)',
              pool: 'Intermediate',
              vesselId: '66b2106e3bf57db48f8da59e',
              vesselName: 'Windsor',
              voyageNumber: '24605',
            },
          ],
        },
        {
          exposureArea: 'South Asia (Singapore)',
          vessels: [
            {
              cargoGrade: 'CPP',
              ID: '9689043',
              loadingAreaETA: '2024-09-22 01:19:29',
              localLoadingAreaETA: '2024-09-22 09:19:29',
              localOpenDate: '2024-09-17T08:37:00',
              openDate: '2024-09-17T02:37:00',
              openPort: 'CHITTAGONG',
              openRegion: 'EC India',
              openWorldArea: 'South Asia (Singapore)',
              pool: 'Intermediate',
              vesselId: '624a4e59bed572c5534ab3be',
              vesselName: 'Golden Violet',
              voyageNumber: '24608',
            },
          ],
        },
      ],
    },
  },
}
