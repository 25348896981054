export const poolsMock = [
  {
    id: 24,
    name: 'City',
  },
  {
    id: 1,
    name: 'Intermediate',
  },
  {
    id: 30,
    name: 'Chemicals 2',
  },
  {
    id: 25,
    name: 'Flexi',
  },
  {
    id: 2,
    name: 'Handy',
  },
  {
    id: 3,
    name: 'MR',
  },
  {
    id: 26,
    name: 'LR1',
  },
  {
    id: 4,
    name: 'LR2',
  },
  {
    id: 5,
    name: 'Aframax',
  },
]
