import { CommentPopupContentWrapper } from '~components/molecules/comment-field/comment-field.styles'

interface Props {
  comment?: string
}

function CommentPopupContent({ comment }: Props) {
  return <CommentPopupContentWrapper>{comment}</CommentPopupContentWrapper>
}

export default CommentPopupContent
