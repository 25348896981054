import { createRef, FC, ReactElement, useEffect } from 'react'

import {
  StyledModalContent,
  StyledModalSidebar,
  StyledModalTemplate,
} from './styles'

interface IModalTemplate {
  className?: string
  content: ReactElement
  sidebar?: ReactElement
}

export const ModalTemplate: FC<IModalTemplate> = ({
  content,
  sidebar,
  className,
}): ReactElement => {
  const contentRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (!contentRef) {
      return
    }
    contentRef.current?.scrollIntoView()
  }, [contentRef])

  return (
    <StyledModalTemplate>
      <StyledModalContent className={className} ref={contentRef}>
        {content}
      </StyledModalContent>
      {sidebar && <StyledModalSidebar>{sidebar}</StyledModalSidebar>}
    </StyledModalTemplate>
  )
}
