import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { apiUpdateAction } from '~api/actions'

interface ResponsibleUser {
  email: string
  name: string
}

interface IUpdateResponse {
  createdAt: string
  responsibleUser: ResponsibleUser
  statusCode?: number
}

export interface IStatusUpdateResponse extends IUpdateResponse {
  positionListStatus: string
}

export interface IDryDockUpdateResponse extends IUpdateResponse {
  nextDryDock: string
}

export interface ILastThreeCargosResponse extends IUpdateResponse {
  lastThreeCargos: string
}

export interface IStatusUpdatePayload {
  positionListStatus?: string
}

export interface IDryDockUpdatePayload {
  comment?: string | null
  nextDryDock?: string | null
}

export interface ILastThreeCargosPayload {
  comment?: string | null
  lastThreeCargoes?: string | null
}

export function userUpdateAction<T, U>(
  payload: T,
  urlParams?: Params,
): Promise<U> {
  return apiUpdateAction<T, U>(
    ApiRoutes.POSITION_LIST.PATCH,
    payload,
    urlParams,
  )
}
