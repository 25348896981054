import { FunctionComponent, ReactNode } from 'react'

import { StyledWrapper } from './wrapper.styles'

type WrapperProps = {
  children: ReactNode
}

const Wrapper: FunctionComponent<WrapperProps> = ({ children, ...props }) => (
  <StyledWrapper {...props}>{children}</StyledWrapper>
)

export default Wrapper
