import { UseQueryResult } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

interface DataFetcher<T> {
  (arg?: any): UseQueryResult<T[], Error>
}

export function useDynamicTitle<T>(
  titleBase: string,
  dataPath: string,
  dataFetcher: DataFetcher<T>,
): string {
  const { pathname } = useLocation()
  const { data } = dataFetcher()

  if (!pathname.startsWith(dataPath)) {
    return titleBase
  }

  const itemCount = data?.length && data.length > 0 ? `(${data.length})` : ''
  return `${titleBase} ${itemCount}`
}
