import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'

declare module '@mui/material/TableRow' {
  interface MuiTableRowVariantOverrides {
    lightGray: true
    darkGray: true
  }
}

export interface MuiTableRowData {
  defaultProps?: ComponentsProps['MuiTableRow']
  styleOverrides?: ComponentsOverrides['MuiTableRow']
  variants?: ComponentsVariants['MuiTableRow']
}

export const MuiTableRow: MuiTableRowData = {
  styleOverrides: {
    root: {
      '.MuiTableCell-root': {
        color: COLORS.secondary.darkBlue,
      },
      variants: [
        {
          props: {
            variant: 'lightGray',
          },
          style: {
            backgroundColor: COLORS.greys.ultraLight,
            verticalAlign: 'top',
            '&: hover': {
              backgroundColor: COLORS.primary.white,
              boxShadow: '0px 0px 8px 1px rgba(108,127,141,0.1)',
              transition: 'background-color 300ms ease-in-out',
              cursor: 'pointer',
            },
          },
        },
        {
          props: {
            variant: 'darkGray',
          },
          style: {
            backgroundColor: COLORS.greys.light,
            verticalAlign: 'top',
            '&: hover': {
              backgroundColor: COLORS.primary.white,
              boxShadow: '0px 0px 8px 1px rgba(108,127,141,0.1)',
              transition: 'background-color 300ms ease-in-out',
              cursor: 'pointer',
            },
          },
        },
        {
          props: {
            variant: 'lightGrayNoHover',
          },
          style: {
            backgroundColor: COLORS.greys.ultraLight,
            verticalAlign: 'top',
            '& > td': {
              border: 'none',
            },
          },
        },
      ],
    },
  },
}
