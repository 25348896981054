import styled from 'styled-components/macro'

import { Colors, Fonts } from '~theme/deprecated-vt/deprecated-vt-theme'

interface IStyledTextInput {
  autoFocus?: boolean
  hasIcon?: boolean
  isDisabled?: boolean
  isLocked?: boolean
  isSmall?: boolean
}

export const StyledBasicTextInput = styled.input.attrs<IStyledTextInput>(
  (props) => {
    return {
      disabled: props.isDisabled || props.isLocked,
      tabIndex: '0',
      autoFocus: props.autoFocus && !props.isDisabled && !props.isLocked,
    }
  },
)<IStyledTextInput>`
  width: 100%;
  padding: 0 40px 0 0;
  padding-left: ${(props) => {
    return props.hasIcon
      ? props.isSmall
        ? '40px'
        : '50px'
      : props.isSmall
        ? '10px'
        : '20px'
  }};
  border-width: 2px;
  border-style: solid;
  height: ${(props) => {
    return props.isSmall ? '40px' : '50px'
  }};
  line-height: ${(props) => {
    return props.isSmall ? '40px' : '50px'
  }};
  background-color: ${(props) => {
    return props.isDisabled ? 'transparent' : Colors.white
  }};
  border-color: ${(props) => {
    return props.isDisabled ? Colors.greys.light : Colors.blues.deep
  }};
  font-family: ${Fonts.families.headlines.regular};
  font-size: ${(props) => {
    return props.isSmall ? Fonts.sizes.body : Fonts.sizes.body
  }};
  color: ${(props) => {
    return props.isDisabled ? Colors.greys.dark : Colors.body
  }};

  :focus {
    outline: none;
    background-color: ${Colors.white};
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${Colors.greys.base};
  }

  :-ms-input-placeholder {
    color: ${Colors.greys.base};
  }
`

export const StyledVesselSearchTextInput = styled(StyledBasicTextInput)`
  border: 1px solid ${Colors.blues.maersk};
  border-radius: 4px;
  font-size: ${Fonts.sizes.small};
  color: ${Colors.blues.base};
  padding: 2px 0 0 16px;
`

export const StyledFilterTextInput = styled(StyledBasicTextInput)`
  border: 1px solid ${Colors.blues.maersk};
  border-radius: 4px;
  font-size: ${Fonts.sizes.small};
  color: ${Colors.blues.base};
  padding: 0 48px 0 16px;
`

export const StyledTextInputIconWrapper = styled.div<IStyledTextInput>`
  position: absolute;
  top: 0;
  right: 0;
  height: ${(props) => {
    return props.isSmall ? '40px' : '50px'
  }};
  width: ${(props) => {
    return props.isSmall ? '40px' : '50px'
  }};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledTextInputClearWrapper = styled.div<IStyledTextInput>`
  position: absolute;
  top: 0;
  right: 0;
  height: ${(props) => {
    return props.isSmall ? '40px' : '50px'
  }};
  width: ${(props) => {
    return props.isSmall ? '26px' : '32px'
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const StyledTextInputSearchableList = styled.ul`
  position: absolute;
  border: 1px solid black;
  width: 100%;
  margin: 0;
  list-style: none;
  padding: 0.5em 0;
  border-top: 0;
  background-color: #fff;
  max-height: 30vh;
  overflow-y: auto;
  z-index: 2;
`

interface IStyledTestInputSearchableListOptions {
  isFocused?: boolean
}

export const StyledTextInputSearchableListOption = styled.li<IStyledTestInputSearchableListOptions>`
  padding: 0.75em 1.375em;

  background-color: ${(props) => {
    return props.isFocused ? Colors.blues.lighter : ''
  }};

  :hover {
    background-color: ${Colors.blues.lighter};
    cursor: pointer;
  }
`
