import { COLORS } from '~theme/colors'
import { Typography, theme } from '@maersktankersdigital/web-components'
import { Props as TooltipProps } from 'recharts/types/component/DefaultTooltipContent'
import styled from 'styled-components/macro'

import {
  OpenVessels,
  useExposureFilters,
} from '~pages/pages-behind-login/position-list/contexts/exposure-filter-provider'
import { formatDate } from '~utils/dates'

export const TooltipWrapper = styled.div`
  padding: ${theme.SPACINGS[2]};
  border-radius: 4px;
  box-shadow: ${theme.SHADOWS.cardShadow};
  background-color: ${COLORS.primary.white};
  display: flex;
  flex-direction: column;
`

const TooltipLabel = styled.label`
  color: ${COLORS.secondary.darkBlue};
  font-family: ${theme.FONTS.families.bold};
  font-size: ${theme.FONTS.sizes.xs};
  text-transform: uppercase;
  padding-bottom: ${theme.SPACINGS[1]};
  display: inline-block;
`

const TooltipNumber = styled.div<{ color?: string }>`
  font-size: ${theme.FONTS.sizes.xs};
  color: ${({ color }) => (color ? color : COLORS.secondary.darkBlue)};
  font-family: ${theme.FONTS.families.regular};
`

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.SPACINGS[1]};
`

export const TableTooltip = ({
  payload,
  ...rest
}: TooltipProps<number, string>) => {
  const { filters } = useExposureFilters()

  if (!payload?.length) return null
  const { vesselPercentage, vesselCount, exposureArea, fromDate }: OpenVessels =
    payload?.[0]?.payload

  if (!vesselCount.total) return null

  const label = filters?.totalExposure
    ? exposureArea
    : `week from ${fromDate && typeof fromDate === 'string' && formatDate(fromDate)}`

  const totalVesselsValue = filters?.showPercentage
    ? `${vesselPercentage.total.toFixed(2)}% in total`
    : `${vesselCount.total} ${vesselCount.total > 1 ? 'vessels' : 'vessel'} in total`
  const dirtyVesselsValue = filters?.showPercentage
    ? `${vesselPercentage.dirty?.toFixed(2)}% DPP`
    : `${vesselCount.dirty} DPP`
  const cleanVesselsValue = filters?.showPercentage
    ? `${vesselPercentage.clean?.toFixed(2)}% CPP`
    : `${vesselCount.clean} CPP`
  const unknownVesselsValue = filters?.showPercentage
    ? `${vesselPercentage.unknown?.toFixed(2)}% missing cargo grade`
    : `${vesselCount.unknown} missing cargo grade`

  return (
    <TooltipWrapper data-cy="tooltip">
      <TooltipLabel>
        {label}
        {filters?.totalExposure && (
          <Typography variant="menu">Total Exposure</Typography>
        )}
      </TooltipLabel>
      <TooltipContent>
        <TooltipNumber>{totalVesselsValue}</TooltipNumber>
        {vesselCount.clean ? (
          <TooltipNumber color={COLORS.primary.maerskBlue}>
            {cleanVesselsValue}
          </TooltipNumber>
        ) : null}
        {vesselCount.dirty ? (
          <TooltipNumber color={COLORS.greys.ultraDark}>
            {dirtyVesselsValue}
          </TooltipNumber>
        ) : null}
        {vesselCount.unknown ? (
          <TooltipNumber color={COLORS.feedback.red.error}>
            {unknownVesselsValue}
          </TooltipNumber>
        ) : null}
      </TooltipContent>
    </TooltipWrapper>
  )
}
