import {
  Button,
  ClickPoint,
  MultiSelect,
  StyledButton,
  theme,
} from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

export const StyledClickPoint = styled(ClickPoint)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

export const ExportModalContent = styled.div`
  width: 400px;
  height: 543px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: ${theme.SPACINGS[4]};
`

export const ExportButton = styled(Button)`
  align-self: center;
`

export const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 33px;
  width: 400px;
  grid-auto-rows: minmax(130px, auto);
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-items: start;
`

export const MultiSelectWrapper = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: space-between;
`

export const StyledMultiSelect = styled(MultiSelect)``

export const OverflowWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PlusNText = styled.span`
  font-family: ${theme.FONTS.families.bold};
  color: ${COLORS.secondary.midBlue};
`

export const InnerMultiWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledMultiButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ExportVesselsWrapper = styled.div`
  align-self: flex-start;
`
