import { useEffect, useRef } from 'react'

/**
 * will return you the old (previous) value of the passed one.
 * depends on the useRef, which allows mutation & not triggering rerender,
 * and useEffect, which runs for the next rerender, not the current one.
 * @param value {T} value that you want the old one of
 */
const useOld = <T>(value: T): T | undefined => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default useOld
