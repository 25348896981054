export const graphqlBase = () => {
  const { location } = window
  return import.meta.env.VITE_GRAPHQL_URL
    ? `${import.meta.env.VITE_GRAPHQL_URL}`
    : `${location.protocol}//graphql.${location.host}`
}
export const apiBase = () =>
  import.meta.env.VITE_API_URL ? `${import.meta.env.VITE_API_URL}/api` : '/api'
export const apiBaseV1 = () => import.meta.env.VITE_API_BASE_V1 || '/api/v1'
export const apiBaseV2 = () => import.meta.env.VITE_API_BASE_V2 || '/api/v2'
