export const dashboardSummaryMockData = [
  {
    date: '2024-05-01T00:00:00',
    tce: 26063.83,
    tceFixedRatio: null,
    bunkerPrice: 671.57,
    bunkerPriceWithScrubber: 541.06,
    isForecast: false,
    isScrubber: true,
  },
  {
    date: '2024-06-01T00:00:00',
    tce: 23762.44,
    tceFixedRatio: null,
    bunkerPrice: 665.94,
    bunkerPriceWithScrubber: 544.96,
    isForecast: false,
    isScrubber: true,
  },
  {
    date: '2024-07-01T00:00:00',
    tce: 24833.8,
    tceFixedRatio: null,
    bunkerPrice: 661.43,
    bunkerPriceWithScrubber: 526.57,
    isForecast: false,
    isScrubber: true,
  },
  {
    date: '2024-08-01T00:00:00',
    tce: 25302.48,
    tceFixedRatio: 0.98,
    bunkerPrice: 661.43,
    bunkerPriceWithScrubber: 526.57,
    isForecast: true,
    isScrubber: true,
  },
  {
    date: '2024-09-01T00:00:00',
    tce: 24645.03,
    tceFixedRatio: 0.29,
    bunkerPrice: 661.43,
    bunkerPriceWithScrubber: 526.57,
    isForecast: true,
    isScrubber: true,
  },
]
