import { useEffect, useState } from 'react'
import { OpenVesselData } from '~api/gql/generated/graphql'
import {
  CargoGroupCount,
  useExposureFilters,
} from '~pages/pages-behind-login/position-list/contexts/exposure-filter-provider'

export interface ExposureCount {
  exposureArea: string
  vessels: OpenVesselData[]
  vesselCount: CargoGroupCount
  vesselPercentage: CargoGroupCount
}

function updateCountsAndPercentages(
  existingArea: ExposureCount,
  newCounts: CargoGroupCount,
  newPercentages: CargoGroupCount,
) {
  Object.keys(newCounts).forEach((key) => {
    const typedKey = key as keyof CargoGroupCount
    if (existingArea.vesselCount[typedKey] !== undefined) {
      existingArea.vesselCount[typedKey] += newCounts[typedKey]
    }
  })

  Object.keys(newPercentages).forEach((key) => {
    const typedKey = key as keyof CargoGroupCount
    if (existingArea.vesselPercentage[typedKey] !== undefined) {
      existingArea.vesselPercentage[typedKey] += newPercentages[typedKey]
    }
  })
}

export function useTotalExposureCount() {
  const [totalExposure, setTotalExposure] = useState<ExposureCount[]>([])
  const { filteredData } = useExposureFilters()

  useEffect(() => {
    const calculateTotalExposure = () => {
      if (!filteredData?.openVessels) return []

      const exposureMap = new Map<string, ExposureCount>()

      filteredData.openVessels.forEach((openVessel) => {
        const { exposureArea, Maersk, vesselCount, vesselPercentage } =
          openVessel

        if (!exposureMap.has(exposureArea)) {
          exposureMap.set(exposureArea, {
            exposureArea,
            vessels: [],
            vesselCount: {
              total: 0,
              clean: 0,
              unknown: 0,
              dirty: 0,
              dirtyWithUnknown: 0,
              cleanWithUnknown: 0,
            },
            vesselPercentage: {
              total: 0,
              clean: 0,
              dirty: 0,
              unknown: 0,
              dirtyWithUnknown: 0,
              cleanWithUnknown: 0,
            },
          })
        }

        const existingArea = exposureMap.get(exposureArea)

        if (existingArea) {
          existingArea.vessels.push(...Maersk)
          updateCountsAndPercentages(
            existingArea,
            vesselCount,
            vesselPercentage,
          )
        }
      })

      return Array.from(exposureMap.values())
    }

    const result = calculateTotalExposure()
    setTotalExposure(result)
  }, [filteredData])

  return totalExposure
}
