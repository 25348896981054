import { Component, ErrorInfo, ReactNode } from 'react'

import { isChildrenRenderFunction } from '../../../../utils/type-utils/react'

type ErrorBoundaryProps = {
  children: ReactNode
  errorComponent?: ReactNode | ((error: Error) => ReactNode)
  onError?: (error: Error, info: ErrorInfo) => void
}

type ErrorBoundaryState = {
  error: Error | null
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public static getDerivedStateFromError(error: Error) {
    return { error }
  }

  public static defaultProps = { errorComponent: null }

  public constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    this.props.onError?.(error, info)
    console.error('Caught by error boundary - ', error, info.componentStack)
  }

  public render() {
    const { error } = this.state
    const { errorComponent, children } = this.props

    if (error) {
      return isChildrenRenderFunction(errorComponent)
        ? // eslint-disable-next-line @typescript-eslint/ban-types
          (errorComponent as Function)(error)
        : (errorComponent ?? null)
    }

    return children || null
  }
}
