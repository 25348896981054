import styled, { css } from 'styled-components/macro'

import { COLORS } from '~theme/colors'

interface RouterLinkProps {
  noUnderline?: boolean
  to?: string
}

export const StyledRouterLink = styled.span<RouterLinkProps>`
  a {
    text-decoration: none;
    display: inline-block;
    ${({ to }) => {
      return to === '' && 'cursor: auto;'
    }}
    color: ${COLORS.secondary.midBlue};
  }

  p,
  span {
    cursor: pointer;
  }

  ${({ noUnderline }) =>
    noUnderline &&
    css`
      a:hover {
        text-decoration: none;
        border-bottom: none !important;
      }

      a.active {
        border-bottom: none !important;
      }
    `}
`
