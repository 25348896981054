import { apiDeleteAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'
import { ISuccessResponse } from '~api/types'

export interface IFileDeleteResponse {
  statusCode?: number
  success?: number
}

export function fileDeleteAction(id: string): Promise<IFileDeleteResponse> {
  return apiDeleteAction<undefined, ISuccessResponse>(
    ApiRoutes.Files.delete,
    undefined,
    { id },
  )
}
