import { ExactScopeName } from '~hooks/permissions/use-has-scope'
import {
  poolPartnerPermissions,
  technicalManagerPermissions,
} from '../constants'

export function findPermissions(roles: string[]) {
  if (roles.includes(ExactScopeName.technicalManager))
    return technicalManagerPermissions
  if (
    roles.includes(ExactScopeName.poolPartner) ||
    roles.includes(ExactScopeName.maerskTankersUser)
  )
    return poolPartnerPermissions
  return []
}
