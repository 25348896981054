import { TextButton, Typography } from '@maersktankersdigital/web-components'
import { TagFilterWrapperProps } from '../tag-filter'
import { StyledTagFilter, StyledTagFilterTag } from '../tag-filter.styles'

interface TagFilterRendererProps extends TagFilterWrapperProps {
  onTagClick: (tag: { id: string }) => void
  onClearClick: () => void
  isTagActive: (tag: { id: string }) => boolean
}

export function TagFilterRenderer({
  filters,
  onTagClick,
  onClearClick,
  isTagActive,
}: TagFilterRendererProps) {
  return (
    <StyledTagFilter>
      <Typography as="span" variant="label">
        See only
      </Typography>
      <div>
        {filters.map(({ name, id }) => (
          <StyledTagFilterTag
            key={id}
            isActive={isTagActive({ id })}
            onClick={() => {
              onTagClick({ id })
            }}
          >
            <Typography as="span" variant="paragraph2">
              {name}
            </Typography>
          </StyledTagFilterTag>
        ))}

        <TextButton onClick={onClearClick}>Clear filters</TextButton>
      </div>
    </StyledTagFilter>
  )
}
