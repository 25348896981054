import { NavHashLink } from '@xzar90/react-router-hash-link'
import { FC, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Box } from '~components/atoms/box'
import { Text } from '~components/atoms/text'
import { IQuestionnaireListSection } from '~components/organisms/lists/vessel-questionnaires'

interface IVesselQuestionnaireMissingFields {
  section: IQuestionnaireListSection
}
export const rebuildCurrentFieldsValuesObject = (
  fieldsValuesObject: Record<string, unknown>,
) => {
  for (const prop in fieldsValuesObject) {
    if (fieldsValuesObject.hasOwnProperty(prop)) {
      fieldsValuesObject[prop.split('_')[0]] = fieldsValuesObject[prop]
      fieldsValuesObject[prop.split('_')[0]] = {
        [prop.split('_')[1]]: fieldsValuesObject[prop],
      }
      delete fieldsValuesObject[prop]
    }
  }
}

export const VesselQuestionnaireMissingFields: FC<
  IVesselQuestionnaireMissingFields
> = ({ section }) => {
  const methods = useFormContext()
  const currentFieldsValues = methods.watch()
  rebuildCurrentFieldsValuesObject(currentFieldsValues)

  const [isFieldDependantAndActive, setIsFieldDependantAndActive] =
    useState(false)

  const dependantField = section.fields.find((field) => field.dependsOn)
  const affectingValue =
    dependantField &&
    methods.watch(
      `${dependantField?.dependsOn?.id}_value`,
      section.fields.find((field) => field.id === dependantField?.dependsOn?.id)
        ?.value,
    )

  useEffect(() => {
    affectingValue &&
      setIsFieldDependantAndActive(
        dependantField.dependsOn?.value === affectingValue,
      )
  }, [affectingValue])

  const missingFields = useMemo(
    () =>
      section.fields.filter((field) => {
        const currentFieldValue = currentFieldsValues[field.id]

        const isCurrentFieldValueMissing =
          (!currentFieldValue?.value ||
            currentFieldValue?.value === false ||
            currentFieldValue?.value.length === 0) &&
          !currentFieldValue?.notApplicable

        return (
          isCurrentFieldValueMissing &&
          ((field.missing && !field.dependsOn) ||
            (field.dependsOn && isFieldDependantAndActive))
        )
      }),
    [isFieldDependantAndActive, currentFieldsValues],
  )

  return (
    <>
      <Box mt={3} mb={2}>
        <div>
          {!!missingFields.length && (
            <>
              <Text
                size="h6"
                text={`Please note. There ${
                  missingFields.length === 1 ? 'is' : 'are'
                } `}
              />
              <Text size="h6" weight="bold" text={`${missingFields.length}`} />
              <Text
                size="h6"
                text={` ${
                  missingFields.length === 1 ? 'field' : 'fields'
                } missing info.`}
              />
            </>
          )}
        </div>
      </Box>

      {missingFields.map((field) => {
        return (
          <Box key={field.id}>
            <NavHashLink
              style={{ cursor: 'pointer', textDecoration: 'none' }}
              to={`${window.location.pathname}${window.location.search}#${field.id}`}
              replace
            >
              <Text color="blues.mid" text={`- ${field.name}`} />
            </NavHashLink>
          </Box>
        )
      })}
    </>
  )
}
