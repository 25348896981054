import { Auth0Provider } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import LoadComponent from '~components/molecules/load-component/load-component'
import { apiBase } from '../utils/base-url'
import { PageUnavailable } from './page-unavailable'

export interface FetchAuth0ConfigRes {
  audience: string
  domain: string
  clientId: string
  scope: string
}

export function fetchAuth0Config(): Promise<FetchAuth0ConfigRes> {
  const requestUrl = `${apiBase()}/auth0`
  return fetch(requestUrl).then((response) => {
    if (response.ok) {
      return response.json()
    }
    throw new Error(response.statusText)
  })
}

export function Auth0ProviderWithConfig({
  children,
}: {
  children: React.ReactNode
}) {
  const navigate = useNavigate()
  const { data, isLoading, isError } = useQuery({
    queryKey: ['/auth0'],
    queryFn: fetchAuth0Config,
  })
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || '/')
  }

  if (isLoading) {
    return <LoadComponent />
  }

  if (isError || !data) {
    return <PageUnavailable />
  }

  const auth0ProviderConfig = {
    domain: data.domain,
    clientId: data.clientId,
    authorizationParams: {
      redirect_uri: `${window.location.origin}/callback`,
      audience: data.audience,
      scope: data.scope,
    },
    onRedirectCallback,
    useRefreshTokens: true,
  }

  return <Auth0Provider {...auth0ProviderConfig}>{children}</Auth0Provider>
}
