import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'

declare module '@mui/material/ListItem' {
  interface ListItemPropsVariantOverrides {
    active: true
  }
}
export interface MuiListItemData {
  defaultProps?: ComponentsProps['MuiListItem']
  styleOverrides?: ComponentsOverrides['MuiListItem']
  variants: ComponentsVariants['MuiListItem']
}

export const MuiListItem: MuiListItemData = {
  styleOverrides: {
    root: {
      padding: '20px 0 20px 32px',
      '& path': {
        fill: COLORS.primary.white,
      },
      '& a, span': {
        color: COLORS.primary.white,
        textDecoration: 'none',
        transition: 'color .3s ease',
      },
      '&:hover': {
        '& path': {
          fill: COLORS.primary.maerskBlue,
        },
        '& a, span': {
          color: COLORS.primary.maerskBlue,
        },
      },
    },
  },
  variants: [
    {
      props: {
        // @ts-ignore
        variant: 'active',
      },
      style: {
        '& path': {
          fill: COLORS.primary.maerskBlue,
        },
        '& a, span': {
          color: COLORS.primary.maerskBlue,
        },
      },
    },
  ],
}
