import { Button } from '@maersktankersdigital/web-components'
import { Typography } from '@mui/material'
import type { FunctionComponent } from 'react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { PageRoute } from '../../../../routes/routes-behind-login/vt-routing/constants/page-route'
import { StyledErrorDisplay } from './error-display.styles'

type ErrorDisplayProps = {
  heading?: string
  isPageError?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  text?: string
}

const ErrorDisplay: FunctionComponent<ErrorDisplayProps> = ({
  heading,
  text,
  isPageError,
  onClick,
}) => {
  const navigate = useNavigate()

  return (
    <StyledErrorDisplay>
      {heading && (
        <Typography
          sx={{ py: 4, px: 2 }}
          variant={isPageError ? 'h1' : 'h4'}
          component="h1"
        >
          {heading}
        </Typography>
      )}

      {text && (
        <Typography
          sx={{ py: 4, px: 2 }}
          variant={isPageError ? 'h2' : 'paragraph1'}
          component="p"
        >
          {text}
        </Typography>
      )}

      {isPageError && (
        <Button
          variant="primary"
          onClick={(e) => {
            if (onClick) {
              onClick?.(e)
            } else {
              navigate(PageRoute.LANDING)
            }
          }}
        >
          Go to front page
        </Button>
      )}
    </StyledErrorDisplay>
  )
}

export default ErrorDisplay
