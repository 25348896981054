import { Alert } from '@maersktankersdigital/web-components'
import { useSpring } from '@react-spring/web'
import { easeCircleInOut } from 'd3-ease'

import {
  StyledAnimatedAlert,
  StyledAnimatedWrapper,
} from '~components/molecules/animated-alert/animated-alert.styles'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import useRefClientBounds from '~hooks/use-ref-client-bounds'

export const AnimatedAlert = () => {
  const { isVisible } = useAnimatedAlert()

  return isVisible ? <AnimatedAlertInner /> : null
}

const AnimatedAlertInner = () => {
  const [bounds, setBounds] = useRefClientBounds()
  const {
    variant: newVariant,
    text: newText,
    setIsVisible,
    delay,
  } = useAnimatedAlert()

  const animation = useSpring({
    from: { bottom: `-${bounds.height}px` },
    to: async (next) => {
      await next({
        bottom: '16px',
      })
      await new Promise((resolve) => setTimeout(resolve, delay))
      await next({
        bottom: `-${bounds.height}px`,
        onResolve: () => setIsVisible(false),
      })
    },
    config: { easing: easeCircleInOut, duration: 200 },
  })

  return (
    <StyledAnimatedAlert>
      <StyledAnimatedWrapper ref={setBounds} style={animation}>
        <Alert variant={newVariant} text={newText} />
      </StyledAnimatedWrapper>
    </StyledAnimatedAlert>
  )
}
