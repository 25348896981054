import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import {
  apiGetFileAction,
  apiRemoveFileAction,
  apiUpdateAction,
  apiUploadFileAction,
} from '~api/actions'
import { IFileResponse } from '~api/types'
import { IQuestionnairesResponse } from '~api/vessels/read'

export interface IVesselsQuestionnaireUpdateFieldPayload {
  comment?: string | null
  id: string
  value?: string | null
}

export interface IVesselsQuestionnaireUpdatePayload {
  fields: IVesselsQuestionnaireUpdateFieldPayload[]
  id: string
}

export interface IVesselsQuestionnaireUploadFilePayload {
  file: File
  id: string
}

export interface IVesselsQuestionnaireApprovePayload {
  id: string
}

export interface IVesselsQuestionnaireRejectPayload {
  id: string
}

export function vesselsQuestionnaireUpdateAction(
  payload: IVesselsQuestionnaireUpdatePayload,
  urlParams?: Params,
): Promise<IQuestionnairesResponse> {
  return apiUpdateAction<
    IVesselsQuestionnaireUpdatePayload,
    IQuestionnairesResponse
  >(ApiRoutes.Vessels.Questionnaire.update, payload, urlParams)
}

export function vesselsQuestionnaireUploadFileAction(
  payload: IVesselsQuestionnaireUploadFilePayload,
  urlParams?: Params,
): Promise<IQuestionnairesResponse> {
  return apiUploadFileAction<
    IVesselsQuestionnaireUploadFilePayload,
    IQuestionnairesResponse
  >(ApiRoutes.Vessels.Questionnaire.uploadFile, payload, urlParams)
}

export function vesselsQuestionnaireRemoveFileAction(
  urlParams: Params,
): Promise<IQuestionnairesResponse> {
  return apiRemoveFileAction<IQuestionnairesResponse>(
    ApiRoutes.Vessels.Questionnaire.removeFile,
    urlParams,
  )
}
export function vesselsQuestionnaireApproveAction(
  payload: IVesselsQuestionnaireApprovePayload,
  urlParams?: Params,
): Promise<IQuestionnairesResponse> {
  return apiUpdateAction<
    IVesselsQuestionnaireApprovePayload,
    IQuestionnairesResponse
  >(ApiRoutes.Vessels.Questionnaire.approve, payload, urlParams)
}

export function vesselsQuestionnaireRejectAction(
  payload: IVesselsQuestionnaireRejectPayload,
  urlParams?: Params,
): Promise<IQuestionnairesResponse> {
  return apiUpdateAction<
    IVesselsQuestionnaireRejectPayload,
    IQuestionnairesResponse
  >(ApiRoutes.Vessels.Questionnaire.reject, payload, urlParams)
}

export function vesselsQuestionnaireDownloadFileAction(
  urlParams?: Params,
): Promise<IFileResponse> {
  return apiGetFileAction<IFileResponse>(
    ApiRoutes.Vessels.Questionnaire.downloadFile,
    urlParams,
  )
}
