import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'

import { spacing } from '../global'

export interface MuiListItemIconData {
  defaultProps?: ComponentsProps['MuiListItemIcon']
  styleOverrides?: ComponentsOverrides['MuiListItemIcon']
  variants?: ComponentsVariants['MuiListItemIcon']
}

export const MuiListItemIcon: MuiListItemIconData = {
  styleOverrides: {
    root: {
      minWidth: '20px',
      marginRight: spacing[4],
      svg: {
        width: '20px',
        height: '20px',
      },
    },
  },
}
