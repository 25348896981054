import { addMonths, isWithinInterval, parseISO } from 'date-fns'

export function isRedeliveryWithin3Months(redeliveryDate: string) {
  const now = new Date()
  const date = parseISO(redeliveryDate)
  if (!date) return false

  return isWithinInterval(date, {
    start: now,
    end: addMonths(now, 3),
  })
}

export function replaceStringInPathname(
  oldString: string,
  newString: string,
): string {
  const { pathname = '' } = window.location
  // Replacing the oldString characters with newString.
  return pathname.replace(new RegExp(`/${oldString}/`, 'gm'), `/${newString}/`)
}
