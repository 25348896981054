const cityMock = [
  {
    id: 21,
    name: 'Clean',
    poolPercentage: 68,
    marketPercentage: 40,
    imoCargo: null,
    showExposures: true,
  },
  {
    id: 22,
    name: 'IMO',
    poolPercentage: 32,
    marketPercentage: 60,
    imoCargo: null,
    showExposures: true,
  },
]

const mrMock = [
  {
    id: 1,
    name: 'Asia',
    poolPercentage: 34,
    marketPercentage: 34,
    imoCargo: null,
    showExposures: true,
  },
  {
    id: 2,
    name: 'AG',
    poolPercentage: 21,
    marketPercentage: 16,
    imoCargo: null,
    showExposures: true,
  },
  {
    id: 3,
    name: 'Europe',
    poolPercentage: 18,
    marketPercentage: 20,
    imoCargo: null,
    showExposures: true,
  },
  {
    id: 4,
    name: 'US',
    poolPercentage: 27,
    marketPercentage: 30,
    imoCargo: null,
    showExposures: true,
  },
]

export const mockCity = [
  {
    id: 21,
    name: 'Clean',
    poolPercentage: 0,
    marketPercentage: 40,
    imoCargo: null,
    showExposures: true,
  },
  {
    id: 22,
    name: 'IMO',
    poolPercentage: 100,
    marketPercentage: 60,
    imoCargo: null,
    showExposures: true,
  },
]

export const mockMarket = mockCity
