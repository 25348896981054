import { Box, Divider, Typography } from '@mui/material'
import { aboutCookies } from '../content/about-cookies'
import { AcceptAllButton } from './accept-all-button'
import { StyledSecondaryButton } from './cookie-settings'

export function CookieDeclaration({
  onClickHandler,
  closeModal,
}: {
  onClickHandler: () => void
  closeModal: () => void
}) {
  return (
    <>
      <Typography variant="paragraphBold2">
        Your consent applies to the following domains:
      </Typography>
      <Typography sx={{ pt: 2, pb: 3, pl: 3 }}>
        maersktankers.digital
      </Typography>
      <Typography variant="paragraphBold2">
        Cookie declaration last updated on 05.08.2024
      </Typography>
      <Divider sx={{ my: 5 }} />
      <Box sx={{ display: 'flex', gap: 4 }}>
        <StyledSecondaryButton variant="secondary" onClick={onClickHandler}>
          Cookie category consent settings
        </StyledSecondaryButton>
        <AcceptAllButton closeModal={closeModal} />
      </Box>
      <Divider sx={{ my: 5 }} />
      <Typography component="article">
        <Box
          dangerouslySetInnerHTML={{
            __html: aboutCookies.article,
          }}
        />
      </Typography>
    </>
  )
}
