import { IconButton, PlayerIcon } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { Image } from './image'

export function VideoThumbnail({
  imageUrl,
  imageName,
  onClickHandler,
}: {
  imageUrl: string
  imageName: string
  onClickHandler: () => void
}) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          '& button': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            p: 3,
            pl: '18px',
          },
        }}
      >
        <IconButton onClick={onClickHandler} ariaLabel="play">
          <PlayerIcon />
        </IconButton>
      </Box>
      <Image src={imageUrl} alt={imageName} />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      />
    </Box>
  )
}
