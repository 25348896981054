import { MutableRefObject, useRef } from 'react'

export function useRefMap(): [
  MutableRefObject<{ [index: number]: HTMLDivElement }>,
  (index: number) => (element: HTMLDivElement) => void,
] {
  const refMap = useRef<{ [index: number]: HTMLDivElement }>({})
  const setRefMap = (index: number) => (element: HTMLDivElement) => {
    refMap.current = { ...refMap.current, [index]: element }
  }

  return [refMap, setRefMap]
}
