import { FC } from 'react'

import { Box } from '~components/atoms/box'
import { FormError } from '~components/forms/form-error/form-error'
import { CheckboxGroup } from '~components/molecules/checkbox-group/checkbox-group'
import { StyledLabel } from '~components/organisms/form/form.styles'
import { IOption } from '~types/components'

interface ICheckboxGroupField {
  defaultValue?: string[] | null
  isDisabled?: boolean
  label?: string
  name: string
  options: IOption[]
  required?: boolean
}

export const CheckboxGroupField: FC<ICheckboxGroupField> = ({
  name,
  label,
  options,
  defaultValue,
  isDisabled,
  required,
}) => {
  return (
    <>
      {label && <StyledLabel label={label} />}
      <CheckboxGroup
        name={name}
        label={label}
        required={required}
        options={options}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
      />
      <Box>
        <FormError name={name} />
      </Box>
    </>
  )
}
