export const reportTypeMock = [
  {
    id: '40d95512-51c4-4e93-883d-c7a966ed9e68',
    name: 'TEST.EUETS-Report',
    type: 'EUETS-Report',
    webUrl:
      'https://app.powerbi.com/groups/54d53aa7-4be7-49ef-949f-8fabf626f730/reports/40d95512-51c4-4e93-883d-c7a966ed9e68',
    embedUrl:
      'https://app.powerbi.com/reportEmbed?reportId=40d95512-51c4-4e93-883d-c7a966ed9e68&groupId=54d53aa7-4be7-49ef-949f-8fabf626f730&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLU5PUlRILUVVUk9QRS1LLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
    datasetId: 'a1a22751-afe7-401f-ac85-533893cb0806',
  },
]

export const reportWithIdMock = {
  id: '40d95512-51c4-4e93-883d-c7a966ed9e68',
  name: 'TEST.EUETS-Report',
  type: 'EUETS-Report',
  webUrl:
    'https://app.powerbi.com/groups/54d53aa7-4be7-49ef-949f-8fabf626f730/reports/40d95512-51c4-4e93-883d-c7a966ed9e68',
  embedUrl:
    'https://app.powerbi.com/reportEmbed?reportId=40d95512-51c4-4e93-883d-c7a966ed9e68&groupId=54d53aa7-4be7-49ef-949f-8fabf626f730&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLU5PUlRILUVVUk9QRS1LLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  datasetId: 'a1a22751-afe7-401f-ac85-533893cb0806',
  token:
    'H4sIAAAAAAAEACWTRa6FBgAA7_K3NMGtSRe4Ozxkh7s8HJrevb_pfjYzyfz9Y6fPMKfFz58_6cDwaZGol-bLXbg_GsnFY6jQoKZNlE0BSPJy7nNnNf-Sk_qBZrFU1l3PGYuW7T3L8yDLn_sccU9ahh1HQ1cGbIlKE9WFtcq0wgytx1r3ArFpNN61ksbrWxoCUQfVtw7f2NEOg0Dhv-UkZxTuZYmWH7CGBOCEdmCErBJ0klmrxFQTjR16OttBwjhU4pP43TQ5MiuTsxgDqo2PEcWZNJIDQV26NYjl7PeVtWRHC-OACO8WpVBxyAfLMs2cxJL7bRvLe29XDTudyQKxJdLF-3xRVvuq9rRDRsXngIbQYEYvPDgXlO720yewVKQ0k3hZnGgGqtJe5KioLjJkMRhdNshZr-FyEZiJYGdug4B4xii8TWL0sMP3QSayDSUzi1YgfP-Ka5f5lFMt0fWkOzrsp-aR6E9EiKnCEdYtFAyPUOf85XhCf7vKtTnjG4hOWDmwf7GoZ29OqlPzfrVMY82fWP9cSbuzq508vers6eEpIU-H9BwlQi13gNMUQZf1JtIaYEsZvNE2eyFePXES_ogMiECT-kD7LKMp8XkYwHYaZYTirFnu-CrzPnbAC4d-K2ww26uOSxv149F819aGw8QxFqAyQfX7Bsz8iTrNTRwJsEi5PRAXjaJYxZ2SRVZ-yJKcWm-k6WREqPCLbZGebS_28J-GEdJAthRXoRgvoxZ2GfeRd4No1b83z_eKD0X-FKwmJDxWqJ70PUloq6yf_QDU2ZuI2qACo6Ues6Qj9T2gcXmwe4BY64jx9pIXlEJ8WvccwYq9QZGfwCIAYQIWqk9vZAtHQYmjj9QOW6QKiXMkt1A3cDpwUMX3aBMEyKgWZp7wHk_HD3___PHDrc-yz1r5_O6kMccg2tdnuFmbFElvHDBpNxuuduxrPc62NG_tbFekwyeNvE7Lbrk8IlUOUOGD5EtsW8gxhMdBucvY7JJQ05FD2oOhCwwSbQmrGO0JtZdJu2rZp0_y2wqFbPdC85UzhVwtuKFsjK_vmiO_ktXTXS1spMhLLgUJRUgAKJpKo4agg7Xfylj0UL5sOT8hdwc6BLoQJTaM29teN3_o_lS_gAWNOCKkv--FaN5JgNmDako856fmjmAEDmDZ28Wow4mx5SsPTEjl3PmBGmrdIPN0Ods9ED2UUracWrni72Kn3D3fs831GBRzrzGNWV7s9vcqyoSGRdCcRNPwLORaRjHsGH_AGOavv_7L_CxNuSqf38oSJu8p8yuKwX1tnUFYc9f4P-W19ZTux1r-YubNHj3U1Csvw5PC8FH5fDsMOia6TzyVUK0Q2H1b7SqU3sWW8HOBqoEPJA85n817_DJS8O5OSF17FkSeOXNvAu_2tHGlfO0pTgGZijUJjQa7cxOlMOzGtbK_KwFj9yHdnHAh4rRnBMfmg4dWLKDexjCgzhURWyFQ0SPTtRizSj4izaLT2nOykwd5IeQ1gwULeSbRgWBrgHICag3kepjdNzhp_COBVfkcb6SsDna1EhDZmN8MBozhJ1_T-j4rFlI6z-CtINXV7nOVWvZNZzEPX6r0ppjL7rbPw4cDmfyUgBKklICyQzDpD2aRUN2NBaFJ8L66CY_cFIwM67i7XiUX52y9fjP_8y9hzyihQgYAAA==.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLU5PUlRILUVVUk9QRS1LLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJleHAiOjE3MzAxMTIzMTksImFsbG93QWNjZXNzT3ZlclB1YmxpY0ludGVybmV0Ijp0cnVlfQ==',
}
