import { animated } from '@react-spring/web'
import styled from 'styled-components/macro'
import { Z_INDEX } from '~types/z-index'

export const StyledAnimatedAlert = styled.div`
  position: fixed;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: ${Z_INDEX.Popup};
`

export const StyledAnimatedWrapper = styled(animated.div)`
  position: absolute;
  height: fit-content;
  bottom: 1rem;
  right: 1rem;
`
