import { Children, FC, ReactNode } from 'react'

import { Box, IBox } from '~components/atoms/box'

import { StyledGroup } from './group.styles'

interface IGroup {
  alignItems?: IBox['alignItems']
  children?: ReactNode
  flex?: number | string
  flexDirection?: IBox['flexDirection']
  flexWrap?: IBox['flexWrap']
  innerElementFlex?: number | string
  justifyContent?: IBox['justifyContent']
  spacing: number
}
export const Group: FC<IGroup> = ({
  spacing,
  flexDirection,
  justifyContent,
  alignItems,
  flexWrap,
  children,
  flex,
  innerElementFlex,
}) => {
  // Filtering out null children
  const validChildren = Children.toArray(children)
  const isRow = flexDirection === 'row'
  const margin = isRow ? 'ml' : 'mt'

  return (
    <StyledGroup
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexWrap={flexWrap}
      flex={flex}
    >
      {validChildren.map((element, index) => {
        const marginProps = {
          [margin]: index === 0 ? 0 : spacing,
        }

        return (
          <Box
            flex={innerElementFlex}
            {...marginProps}
            key={`group-box-${index}`}
          >
            {element}
          </Box>
        )
      })}
    </StyledGroup>
  )
}
