import styled from 'styled-components/macro'

import { easeDecelerate } from '../../../theme/deprecated-vt/deprecated-vt-styles/transitions/eases'

export const StyledAccordionList = styled.div``

export const StyledAccordionItemHeader = styled.div`
  color: currentColor;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

type StyledAccordionItemBodyProps = {
  $animationDuration?: number
  disableAnimation?: boolean
  isOpen: boolean
}

export const StyledAccordionItemBody = styled.div<StyledAccordionItemBodyProps>`
  position: relative;

  overflow: hidden;
  transition-property: max-height;
  transition-duration: ${(p) =>
    p.disableAnimation ? 0 : p.$animationDuration}ms;
  transition-timing-function: ${easeDecelerate};

  will-change: max-height;
`
