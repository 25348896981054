import {
  PortFunction,
  Voyage,
  VoyageItinerary,
  VoyageStatus,
} from '~types/itinerary.types'
import { capitalizeStringWords } from '~utils/capitalize-string-words'

export function getActiveItineraryItem(itineraryData: VoyageItinerary[]) {
  const currentDateTime = new Date().getTime()

  return (
    // Find the active (first) item based on `arrDepstatus === 'AR'
    itineraryData.filter((data) => data.arrDepStatus === 'AR')[0] ??
    // Otherwise, fall back to ship arrived after current time, leaves after current time
    itineraryData.find(({ arrivalLocal, departureLocal }) => {
      if (!arrivalLocal || !departureLocal) return

      return (
        new Date(arrivalLocal).getTime() <= currentDateTime &&
        new Date(departureLocal).getTime() > currentDateTime
      )
    })
  )
}

export function getUpcomingItineraryItems(itineraryData: VoyageItinerary[]) {
  const activeItineraryItem = getActiveItineraryItem(itineraryData)
  const currentDateTime = new Date().getTime()

  return activeItineraryItem
    ? itineraryData.slice(
        itineraryData.indexOf(activeItineraryItem) + 1,
        itineraryData.length,
      )
    : itineraryData.filter(
        ({ arrivalLocal }) =>
          arrivalLocal && currentDateTime < new Date(arrivalLocal).getTime(),
      )
}

export function getNextItineraryItem(itineraryData: VoyageItinerary[]) {
  const activeItineraryItem = getActiveItineraryItem(itineraryData)

  return !activeItineraryItem
    ? getUpcomingItineraryItems(itineraryData)?.[0]
    : null
}

export function getCurrentVoyageStatus(currentVoyage: Voyage) {
  const itineraryData = currentVoyage.itineraries

  if (!itineraryData) return

  const activeItineraryItem = getActiveItineraryItem(itineraryData)
  const nextItineraryItem = getNextItineraryItem(itineraryData)

  // If the ship is not on its way to a port (it is at the port), return the port function or voyage status
  if (!nextItineraryItem) {
    if (activeItineraryItem) {
      return activeItineraryItem.portFunction
    }

    return currentVoyage.status
  }

  const transformedStatus = capitalizeStringWords(
    nextItineraryItem.portFunction,
  )

  // Otherwise, determine and return where it's heading
  if (transformedStatus === PortFunction.LOADING) {
    return 'In Ballast'
  }

  if (transformedStatus === PortFunction.DISCHARGING) {
    return 'In Laden'
  }

  return 'Sailing'
}

export function getCurrentVoyage(voyages: Voyage[] | undefined) {
  if (!voyages || voyages.length === 0) return

  const sortByNewestDate = (arr: Voyage[]) =>
    arr.sort(
      (a, b) =>
        new Date(b.commenceDate).getTime() - new Date(a.commenceDate).getTime(),
    )

  const voyagesWithCommencedDate = voyages.filter(
    (voyage) => voyage.commenceDate,
  )

  if (voyagesWithCommencedDate.length === 0) return

  const commencedVoyages = voyagesWithCommencedDate.filter(
    (voyage) => voyage.status === VoyageStatus.COMMENCED,
  )

  if (commencedVoyages.length > 0) {
    return sortByNewestDate(commencedVoyages)[0]
  }

  return sortByNewestDate(voyages)[0]
}

export class VoyageNavigator {
  private voyages: Voyage[]
  private currentIndex: number

  constructor(voyages: Voyage[] | undefined) {
    if (!voyages || voyages.length === 0) {
      throw new Error('No voyages provided')
    }

    this.voyages = voyages.sort(
      (a, b) =>
        new Date(b.commenceDate).getTime() - new Date(a.commenceDate).getTime(),
    )
    this.currentIndex = 0

    // Update currentIndex to point to the current voyage on initial load
    this.getCurrentVoyageIndex()
  }

  private getCurrentVoyageIndex(): void {
    const currentVoyage = getCurrentVoyage(this.voyages)
    if (!currentVoyage) {
      throw new Error('No current voyage')
    }
    this.currentIndex = this.voyages.indexOf(currentVoyage)
  }

  updateCurrentIndex(newIndex: number): void {
    if (newIndex >= 0 && newIndex < this.voyages.length) {
      this.currentIndex = newIndex
    } else {
      throw new Error('Invalid index')
    }
  }

  getSelectedVoyage(): Voyage {
    return this.voyages[this.currentIndex]
  }

  getNextVoyage(): Voyage | null {
    if (this.currentIndex > 0) {
      this.currentIndex--
      return this.getSelectedVoyage()
    } else {
      return null
    }
  }

  getPreviousVoyage(): Voyage | null {
    if (this.currentIndex < this.voyages.length - 1) {
      this.currentIndex++
      return this.getSelectedVoyage()
    } else {
      return null
    }
  }

  hasNextVoyage(): boolean {
    return this.currentIndex > 0
  }

  hasPreviousVoyage(): boolean {
    return this.currentIndex < this.voyages.length - 1
  }
}
