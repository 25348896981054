import { ExactScopeName } from '~hooks/permissions/use-has-scope'

export const dummyUserId = {
  sub: '1234567890',
  user: 'johndoe',
  app: 'exampleApp',
  scope: [ExactScopeName.maerskTankersUser],
  profitCenterPools: ['city'],
  isInternal: true,
  legacy_authorization: {},
  id: 'abc123',
  Name: 'John Doe',
  Email: 'johndoe@example.com',
  PhoneNumber: null,
  Title: null,
  CountryCode: null,
  PartnerId: null,
  avatar: null,
  CompanyRole: 'Developer',
  alias: 'jdoe',
  AxCode: null,
  PartnerName: null,
  IsStaff: 1,
}
