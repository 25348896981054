import { MultiSelect } from '@maersktankersdigital/web-components'
import {
  addWeeks,
  eachWeekOfInterval,
  endOfWeek,
  getWeek,
  startOfWeek,
} from 'date-fns'
import { useRef } from 'react'
import { usePoolSelectOptions } from '~hooks/use-pool-select-options'

import { useExposureFilters } from '~pages/pages-behind-login/position-list/contexts/exposure-filter-provider'
import {
  FiltersWrapper,
  StyledTextButton,
  Wrapper,
} from '~pages/pages-behind-login/position-list/exposure-page/exposure-filter-bar/exposure-filter-bar.styles'
import {
  cargoGroupOptions,
  defaultOptions,
  openAreaOptions,
} from '~pages/pages-behind-login/position-list/exposure-page/exposure-filter-bar/filter-options'

interface Props {
  onFilterChange?: (
    event:
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
      | React.FocusEvent<Element, Element>
      | null,
    newValue: string[] | null,
    name?: string,
  ) => void
}

function ExposureFilterBar({ onFilterChange }: Props) {
  const { filters, saveFilters, clearFilters } = useExposureFilters()
  const pools = usePoolSelectOptions()

  const startDate = new Date(
    startOfWeek(new Date(), { weekStartsOn: 1 }).setHours(0, 0, 0, 0),
  )
  const endDate = endOfWeek(addWeeks(startDate, 3))

  const weekOptions = useRef(
    eachWeekOfInterval({ start: startDate, end: endDate }).map((date) => {
      const weekNumber = getWeek(date)
      return {
        label: String(weekNumber),
        value: String(weekNumber),
      }
    }),
  )

  const handleSaveFilters = () => {
    saveFilters()
  }

  const handleClearFilters = () => {
    clearFilters()
  }

  return (
    <Wrapper>
      <FiltersWrapper>
        <MultiSelect
          name="exposureArea"
          options={openAreaOptions}
          label="World Area"
          value={filters?.exposureArea}
          onChange={onFilterChange}
        />
        <MultiSelect
          name="pool"
          options={[...defaultOptions, ...pools]}
          label="Pool"
          value={filters?.pool}
          onChange={onFilterChange}
        />
        <MultiSelect
          name="cargoGroup"
          options={cargoGroupOptions}
          label="Cargo grade"
          value={filters?.cargoGroup}
          onChange={onFilterChange}
        />
        <MultiSelect
          name="weeks"
          options={[{ label: 'All', value: 'ALL' }, ...weekOptions.current]}
          label="weeks"
          value={filters?.weeks}
          disabled={filters?.totalExposure}
          onChange={onFilterChange}
        />
        <StyledTextButton
          onClick={handleSaveFilters}
          variant="primary"
          data-cy="save-filters"
        >
          Save filters
        </StyledTextButton>
        <StyledTextButton
          onClick={handleClearFilters}
          variant="tertiary"
          data-cy="clear-filters"
        >
          Clear filters
        </StyledTextButton>
      </FiltersWrapper>
    </Wrapper>
  )
}

export default ExposureFilterBar
