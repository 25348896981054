import { FC, ReactNode } from 'react'

import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useIsHighLevelUser } from '~hooks/permissions/use-is-high-level-user'

interface IMTPPUsersGuard {
  alternateContent?: ReactNode
  children?: ReactNode
}

// MT and PP Users allowed only
export const MTPPUsersGuard: FC<IMTPPUsersGuard> = ({
  children,
  alternateContent,
}) => {
  const isInternalUser = useHasScope(ExactScopeName.maerskTankersUser)
  const isHighLevelUser = useIsHighLevelUser()
  const isPoolPartner = useHasScope(ExactScopeName.poolPartner)
  const isSuperReader = useHasScope(ExactScopeName.superReader)

  return (
    <>
      {isHighLevelUser || isSuperReader || isInternalUser || isPoolPartner
        ? children
        : alternateContent}
    </>
  )
}
