import {
  CheckIcon,
  ClickPoint,
  DeleteIcon,
  TextButton,
  theme,
  typographyStyles,
} from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

import { PopupContentWrapper } from '~pages/pages-behind-login/position-list/position-list.styles'

export const ListWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  margin-top: ${theme.SPACINGS[3]};
  padding: 0;
`

export const StyledTextButton = styled(TextButton)`
  padding: 0;
  font-weight: ${theme.FONTS.weights.bold};
  font-size: ${theme.FONTS.sizes.xs};

  & svg {
    height: 16px;
    width: 16px;
    path {
      fill: none !important;
    }
  }
`

export const SecondaryTextButton = styled(TextButton)`
  padding: 0;
  font-size: ${theme.FONTS.sizes.xs};
  & svg {
    height: 16px;
    width: 16px;
    path {
      fill: none !important;
    }
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  /* Optical alignment of icon */
  padding-left: 6px;
  gap: ${theme.SPACINGS[1]};
`

export const StyledDeleteIcon = styled(DeleteIcon)`
  color: ${COLORS.feedback.red.error};
`

export const StyledClickPoint = styled(ClickPoint)`
  display: none;
  padding: 4px;
`

export const TopWrapper = styled.div`
  padding: 0 ${theme.SPACINGS[3]};
`

export const MenuItem = styled.li`
  ${typographyStyles.paragraph2};
  font-size: ${theme.FONTS.sizes.xs};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.SPACINGS[1]};
  padding: 0 ${theme.SPACINGS[3]};
  height: 35px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.secondary.blueTint};

    ${StyledClickPoint} {
      display: inline-flex;
      &:hover {
        background-color: ${COLORS.red.tint2};
      }
    }
  }
`

export const NameWrapper = styled.div``

export const CompanyListName = styled.div`
  ${typographyStyles.label};
  padding: 0 ${theme.SPACINGS[3]};
  margin: ${theme.SPACINGS[2]} 0;
`

export const ListName = styled.span`
  padding: 4px 8px 4px 0;
`

export const StyledCheckIcon = styled(CheckIcon)`
  display: inline-block;
  color: ${COLORS.primary.maerskBlue};
  margin-right: 12px;
`

export const MyListsMenuContentWrapper = styled(PopupContentWrapper)`
  background-color: ${COLORS.primary.white};
  width: 272px;
  display: flex;
  flex-direction: column;
  padding: ${theme.SPACINGS[1]} 0;
`

export const FooterActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

// Duplicate of TextButton styles from web-components
export const IconWrapper = styled.span`
  margin-left: -${theme.SPACINGS[0]};
  margin-right: 7px;

  > svg {
    vertical-align: -0.2em;
  }

  & path {
    fill: ${COLORS.secondary.midBlue};
  }
`

export const StyledFileInputLabel = styled.label`
  font-family: MaerskTextRegular;
  font-size: ${theme.FONTS.sizes.sm};
  color: ${COLORS.secondary.midBlue};
  line-height: ${theme.FONTS.lineHeights.md};
  padding: 8px 22px;
  max-width: 200px;
  width: max-content;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0;
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid ${COLORS.secondary.lightBlue};
  }

  &:hover {
    color: ${COLORS.secondary.hoverBlue};
    ${IconWrapper} {
      path {
        fill: ${COLORS.secondary.hoverBlue};
      }
    }
  }

  &:active {
    color: ${COLORS.secondary.actionBlue};
    ${IconWrapper} {
      path {
        fill: ${COLORS.secondary.actionBlue};
      }
    }
  }

  &:disabled {
    color: ${COLORS.secondary.lightBlue};
    pointer-events: none;
    ${IconWrapper} {
      path {
        fill: ${COLORS.secondary.lightBlue};
      }
    }
  }
`
// End of duplicate

export const StyledFileInput = styled.input`
  display: none;
`
