import { FC, PropsWithChildren } from 'react'

import { iconsMap } from '~assets/svgs'
import { Icon } from '~components/atoms/icon'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { ParagraphSize } from '~components/atoms/typography/paragraph/paragraph.styles'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import {
  StyledNavButton,
  StyledNavButtonBorderBottom,
} from './nav-button.styles'

export type NavButtonTypes = 'with-background' | 'with-border-bottom'

export type NavButtonProps = {
  colors?: [string, string]
  icon?: keyof typeof iconsMap
  isSelected?: boolean
  size?: ParagraphSize
  type?: NavButtonTypes
}

// These sizes have not been determined anywhere and are free to be adjusted (as of 15-12-2022)
const iconSizes = { xSmall: 18, small: 24, medium: 30, large: 36, xLarge: 42 }

export const NavButton: FC<PropsWithChildren<NavButtonProps>> = ({
  isSelected,
  icon,
  colors = [Colors.white, Colors.blues.base],
  type = 'with-background',
  children,
  size = 'small',
}) => {
  const textColor = colors[isSelected ? 1 : 0]

  return (
    <StyledNavButton
      bgColor={colors[isSelected ? 0 : 1]}
      type={type}
      data-test="tab"
    >
      {icon && <Icon name={icon} size={iconSizes[size]} fill={textColor} />}

      <Paragraph color={textColor} size={size}>
        {children}
      </Paragraph>

      {type === 'with-border-bottom' && (
        <StyledNavButtonBorderBottom
          color={textColor}
          isSelected={Boolean(isSelected)}
        />
      )}
    </StyledNavButton>
  )
}
