import { apiUploadFileAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'
import { ISuccessResponse } from '~api/types'

export type IFileUpdatePayload = {
  field?: string
  file?: File
  imoNumber?: string
  pool?: string
  subtype?: 'general' | 'vessel' | 'pool'
  type: 'onboarding'
}

export function fileUpdateAction(
  id: string,
  payload: IFileUpdatePayload,
): Promise<ISuccessResponse> {
  return apiUploadFileAction<IFileUpdatePayload, ISuccessResponse>(
    ApiRoutes.Files.update,
    payload,
    { id },
    true,
  )
}
