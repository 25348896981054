import { dummyVessel } from '../vessels'

export const poolPartnerList = [
  {
    poolPartnerKey: 'BES',
    name: 'Besiktas Group',
  },
  {
    poolPartnerKey: 'BLY',
    name: 'Blystad',
  },
  {
    poolPartnerKey: dummyVessel.poolPartner,
    name: dummyVessel.poolPartnerName,
  },
]
