import {
  theme,
  Typography,
  typographyStyles,
} from '@maersktankersdigital/web-components'
import { FC } from 'react'
import styled from 'styled-components/macro'
import { PopupContentWrapper } from '~pages/pages-behind-login/position-list/position-list.styles'
import { Consumption } from '~pages/pages-behind-login/position-list/types/position-list-types'

interface ITooltipContent {
  bunkerConsumption: Consumption
}

const ConsumptionPopupContent = styled(PopupContentWrapper)`
  width: 145px;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  gap: 12px;
`

const StyledHeadline = styled(Typography).attrs({
  variant: 'caption',
})``

const ConsumptionLine = styled.div`
  display: flex;
  align-items: center;
`

const ConsumptionNumber = styled.div`
  ${typographyStyles.paragraphBold2}

  ::after {
    content: ' mt/day';
    ${typographyStyles.paragraph2}
    font-size: ${theme.FONTS.sizes.xxs};
    font-weight: ${theme.FONTS.weights.normal};
  }
`

export const PositionListConsumptionTooltipContent: FC<ITooltipContent> = ({
  bunkerConsumption,
}) => {
  return (
    <ConsumptionPopupContent>
      <StyledHeadline>LADEN CONSUMPTION</StyledHeadline>
      <ConsumptionLine>
        <ConsumptionNumber>
          {bunkerConsumption.ladenTonsPerDay.toFixed(2)}
        </ConsumptionNumber>
      </ConsumptionLine>

      <StyledHeadline>POOL AVERAGE</StyledHeadline>
      <ConsumptionLine>
        <ConsumptionNumber>
          {bunkerConsumption.ladenTonsPerDayPoolAvg.toFixed(2)}
        </ConsumptionNumber>
      </ConsumptionLine>
    </ConsumptionPopupContent>
  )
}
