import {
  DeactivateUserIcon,
  TextButton,
} from '@maersktankersdigital/web-components'
import { FC } from 'react'
import { Box } from '~components/atoms/box'
import { Link } from '~components/atoms/link'
import { RouterLink } from '~components/atoms/router-link'
import { StyledTd } from '~components/atoms/table-cell'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { Text } from '~components/atoms/text'
import { Table } from '~components/molecules/table/table'
import { StyledTr } from '~components/molecules/table-row/styled-tr'
import { PageRoute } from '~routing/constants/page-route'

export interface IUserTableRow {
  email: string
  name: string
  poolPartners: string[][]
  sub: string
  vessels: string[][]
}

interface IUserTable {
  rows: IUserTableRow[]
  onDeactivateUser: (id: string) => void
}

export const UserTable: FC<IUserTable> = ({ rows, onDeactivateUser }) => {
  const isAccountManager = useHasScope(ExactScopeName.accountManager)
  const tableHeaders = ['Name', 'Email', 'Pool partner']
  if (isAccountManager) {
    tableHeaders.splice(1, 0, 'Deactivate user')
  }

  return (
    <Table
      rows={rows}
      renderRow={(row: IUserTableRow) => (
        <StyledTr key={generateUniqueKey()}>
          <StyledTd>
            <RouterLink
              to={PageRoute.USER}
              params={{ id: row.sub }}
              state={{ name: row.name }}
            >
              <Link>
                <Text text={row.name} size="h6" color="blues.mid" />
              </Link>
            </RouterLink>
          </StyledTd>

          {isAccountManager && (
            <StyledTd>
              <TextButton
                variant="primary"
                icon={<DeactivateUserIcon fontSize={24} />}
                onClick={() => onDeactivateUser(row.sub)}
              >
                Deactivate user
              </TextButton>
            </StyledTd>
          )}

          <StyledTd>
            <RouterLink
              to={PageRoute.USER}
              params={{ id: row.sub }}
              state={{ name: row.name }}
            >
              <Link>
                <Text text={row.email} size="body" color="blues.mid" />
              </Link>
            </RouterLink>
          </StyledTd>
          <StyledTd>
            {[...new Set(row.poolPartners)].map((poolPartner) => {
              return (
                <Box key={`${poolPartner[0]}-${poolPartner[1]}`}>
                  <RouterLink
                    to={PageRoute.POOL_PARTNER}
                    params={{ poolPartnerKey: poolPartner[1], id: row.sub }}
                    state={{ name: row.name }}
                  >
                    <Link>
                      <Text
                        text={poolPartner[0]}
                        key={poolPartner[0]}
                        size="body"
                        color="blues.mid"
                      />
                    </Link>
                  </RouterLink>
                </Box>
              )
            })}
          </StyledTd>
        </StyledTr>
      )}
      headers={tableHeaders}
    />
  )
}

export function generateUniqueKey(): string {
  return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
}
