import type { DependencyList } from 'react'
import { useState } from 'react'

import type { Bounds } from './use-client-bounds'
import { useClientBounds } from './use-client-bounds'

/**
 * The useRefClientBounds hook is a simple Wrapper around
 * the useClientBounds and useState hooks, which allows you
 * to set an HTMLElement and then get it's bounds in a single line
 *
 * @returns [the element bounds, the setter for use in a ref prop (<... ref={setter}/>)
 * and the actual element received from the setter]
 *
 * @example ```jsx
 * const Component = () => {
 *   const [bounds, setContainer] = useRefClientBounds();
 *
 *   return (
 *     <div ref={setContainer}>
 *       <p>I&apos;m a child!</p>
 *     </div>
 *   );
 * };
 *```
 */
const useRefClientBounds = <E extends HTMLElement>(
  deps?: DependencyList,
  updateOnScroll?: boolean,
): [Bounds, (instance: E | null) => void, E | null] => {
  const [element, setElement] = useState<E | null>(null)
  const bounds = useClientBounds(element, updateOnScroll, deps)

  return [bounds, setElement, element!]
}

export default useRefClientBounds
