import { FC, ReactNode } from 'react'
import { iconsMap } from '~assets/svgs'
import { Box } from '~components/atoms/box'
import { Icon } from '~components/atoms/icon'
import { IText } from '~components/atoms/text'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { StyledFieldTemplate } from './field-template.styles'

interface IFieldTemplate {
  children?: ReactNode
  isFilter?: boolean
  label?: string
  labelColor?: 'string'
  labelIcon?: keyof typeof iconsMap
  labelTextProps?: Partial<IText>
  name: string
  required?: boolean
}

export const FieldTemplate: FC<IFieldTemplate> = ({
  name,
  label,
  labelIcon,
  isFilter,
  children,
  required,
}) => {
  return (
    <StyledFieldTemplate>
      {label && (
        <Box mb={isFilter ? 1.2 : 0.6} flexDirection="row" alignItems="center">
          {labelIcon && <Box flex={0} mr={1.6} />}
          {labelIcon && <Icon name={labelIcon} fill={Colors.blues.base} />}
        </Box>
      )}
      {children}
    </StyledFieldTemplate>
  )
}
