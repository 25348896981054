import styled from 'styled-components/macro'
import { StyledRouterLink } from '~components/atoms/router-link/styles'
import { StyledNavButton } from '~components/atoms/tab/nav-button.styles'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { Z_INDEX } from '~types/z-index'

export const StyledVesselPageNav = styled.nav`
  position: relative;
  flex-shrink: 0;
  background: ${Colors.blues.base};
  width: 208px;
  height: 100%;
  z-index: ${Z_INDEX.VesselNav};
`

export const StyledVesselPageNavStickyWrapper = styled.div`
  position: sticky;
  padding-top: 40px;
  width: 100%;
  top: 88px;
  left: 0;
  text-align: right;

  ${StyledNavButton} {
    padding: 21px 30px;
  }

  ${StyledRouterLink} {
    display: block;
    width: 100%;

    a {
      width: 100%;
      letter-spacing: 1px;
    }
  }

  ${StyledNavButton} {
    justify-content: flex-start;
  }
`
