import { TextArea } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { FC } from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { assembleValidations } from '~utils/form-validations'

interface ITextareaField {
  defaultValue?: string | null
  label?: string
  name: string
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  validations?: RegisterOptions
}

export const TextareaField: FC<ITextareaField> = ({
  name,
  defaultValue,
  validations,
  required,
  placeholder,
  label,
  readOnly,
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()
  const { ref } = register(name, assembleValidations(required, validations))

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Box
            sx={{
              // style override for the wrapper of TextArea
              '& > div:first-of-type': {
                width: 344,
              },
            }}
          >
            <TextArea
              label={label}
              name={name}
              defaultValue={defaultValue ?? undefined}
              placeholder={placeholder}
              error={
                errors[name] ? (errors[name]?.message as string) : undefined
              }
              value={value}
              onChange={onChange}
              readOnly={readOnly}
              required={required}
              ref={ref}
            />
          </Box>
        )
      }}
    />
  )
}
