import { useAuth0 } from '@auth0/auth0-react'
import { Alert } from '@maersktankersdigital/web-components'
import LoadComponent from '~components/molecules/load-component/load-component'

const CallbackPage = () => {
  const { error } = useAuth0()
  console.log('🚀 ~ CallbackPage ~ error:', error)

  if (error) {
    return (
      <Alert
        variant="error"
        text="Oops! There was an error with the login. Please reload the page or try again later."
      />
    )
  }
  return <LoadComponent />
}

export default CallbackPage
