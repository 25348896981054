import styled from 'styled-components/macro'

import { StyledBox } from '~components/atoms/box/styles'

export const StyledCopyDashboardToClipboardTooltipContent = styled.div`
  ${StyledBox} {
    height: 40px;

    label {
      height: 40px;
    }
  }
`
