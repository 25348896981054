import { ApiRoutes } from '~api'

import { apiPostAction } from '../actions'

export interface IUserInviteError {
  error?: string
  message?: string
  statusCode: number
}

export interface IUserInviteSuccess {
  invite: string
}

export type IUserInviteResponse = IUserInviteSuccess | IUserInviteError

export type UserRole = ({ grant: string } | { revoke: string }) &
  ({ vesselId: string } | { poolPartnerKey: string })

export interface IUserInvitePayload {
  email: string
  name: string
  phone_number: string
  roles?: UserRole[]
}

export interface IUserCreateError {
  error?: string
  message?: string
  statusCode: number
}

export interface IUserCreateSuccess {
  invite: string
}

export type IUserCreateResponse = IUserCreateSuccess | IUserCreateError

export interface IUserCreatePayload {
  email: string
  invite: string
  name?: string
  password: string
  phone_number?: string
  repeat_password: string
}

export function userInviteAction(
  payload: IUserInvitePayload,
): Promise<IUserInviteResponse> {
  return apiPostAction<IUserInvitePayload, IUserInviteResponse>(
    ApiRoutes.User.invite,
    payload,
  )
}

export function userCreateAction(
  payload: IUserCreatePayload,
): Promise<IUserCreateResponse> {
  return apiPostAction<IUserCreatePayload, IUserCreateResponse>(
    ApiRoutes.User.create,
    payload,
    undefined,
    false,
  )
}
