import { CSSProperties, FC } from 'react'

import { StyledText } from './styles'

export interface IText {
  color?:
    | 'blues.base'
    | 'blues.deep'
    | 'blues.light'
    | 'blues.mid'
    | 'blues.maersk'
    | 'white'
    | 'greens.light'
    | 'greens.base'
    | 'reds.base'
    | 'greys.dark'
    | 'greys.darker'
    | 'greys.base'
    | 'greys.ultraDark'
    | 'body'
    | 'black'
    | 'red'
    | 'currentColor'
    | 'blues.secondaryDark'
  headline?: boolean
  isOnboarded?: boolean
  size?: 'caption' | 'small' | 'body' | 'h6' | 'h5' | 'h4' | 'h3' | 'h2' | 'h1'
  text: string
  textAlign?: CSSProperties['textAlign']
  textTransform?: 'capitalize' | 'uppercase' | 'none'
  weight?: 'light' | 'regular' | 'bold'
  whiteSpace?: CSSProperties['whiteSpace']
}

export const Text: FC<IText> = ({
  weight = 'regular',
  size = 'body',
  headline,
  isOnboarded,
  color,
  text,
  ...props
}) => {
  return (
    <StyledText
      {...props}
      weight={weight}
      size={size}
      headline={headline}
      color={color ?? (isOnboarded ? 'white' : 'body')}
    >
      {text}
    </StyledText>
  )
}
