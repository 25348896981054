import { localStorageItemKeys } from '~constants/local-storage-items'

export function getStoredCookies() {
  const storedItemLS = localStorage.getItem(
    localStorageItemKeys.retainedPostLogout.allowedCookies,
  )
  const storedItemSS = sessionStorage.getItem(
    localStorageItemKeys.retainedPostLogout.allowedCookies,
  )

  return [
    ...JSON.parse(storedItemLS || '[]'),
    ...JSON.parse(storedItemSS || '[]'),
  ]
}
