import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

const TOOLBAR_HEIGHT = 75

export const styles = {
  toolbar: {
    display: 'flex',
    height: TOOLBAR_HEIGHT,
    backgroundColor: Colors.blues.deep,
    alignItems: 'center',
  },
  close: {
    display: 'flex',
    cursor: 'pointer',
    height: TOOLBAR_HEIGHT,
    width: TOOLBAR_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
  },
}
