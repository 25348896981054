import { apiPostAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'

export interface IPoolPartnerAddResponse {
  _id?: string
  createdAt?: string
  message?: string
  name?: string
  partnerManager?: string
  poolPartnerKey: string
  statusCode?: number
  updatedAt?: string
}

export interface IPoolPartnerAddPayload {
  name: string
  poolPartnerKey: string
}

export function poolPartnerAddAction(
  payload: IPoolPartnerAddPayload,
): Promise<IPoolPartnerAddResponse> {
  return apiPostAction<IPoolPartnerAddPayload, IPoolPartnerAddResponse>(
    ApiRoutes.POOL_PARTNERS.ALL,
    payload,
  )
}
