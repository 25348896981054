import { ApiRoutes } from '~api'
import { apiGetCsvAction } from '~api/actions'
import { generatePathName } from '~utils/generate-path-name'

export const voyagesCSVFileReadAction = (
  vesselId: string,
  filtersQuery = '',
): Promise<string> => {
  return apiGetCsvAction(
    generatePathName(ApiRoutes.Voyages.allCsv, { vesselId }) + filtersQuery,
  )
}
