import { apiPostAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'
import { ISuccessResponse } from '~api/types'

export interface IUserRoleDeleteResponse {
  ok?: number
  statusCode?: number
}

export type IUserRoleDeletePayload = {
  revoke: string
  sub: string
} & ({ vesselId: string } | { poolPartnerKey: string })

export function userRoleDeleteAction(
  payload: IUserRoleDeletePayload,
): Promise<IUserRoleDeleteResponse> {
  return apiPostAction<IUserRoleDeletePayload, ISuccessResponse>(
    ApiRoutes.User.Roles.delete,
    payload,
  )
}
