import { FC } from 'react'

import { StyledHtmlMarkup } from '~components/atoms/inner-html/styles'

export interface IInnerHtml {
  html: string
}

export const InnerHtml: FC<IInnerHtml> = ({ html }) => {
  function createMarkup() {
    return { __html: html }
  }
  return <StyledHtmlMarkup dangerouslySetInnerHTML={createMarkup()} />
}
