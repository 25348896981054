import { Input } from '@maersktankersdigital/web-components'
import * as React from 'react'
import { ChangeEvent, useEffect, useRef } from 'react'

import ErrorBoundary from '~components/layout/error/error-boundary/error-boundary'
import { getFilteredAutoCompleteOptions } from '~components/molecules/auto-complete-input/auto-complete-input.utils'
import { useAutoCompleteInputState } from '~components/molecules/auto-complete-input/auto-complete-input-provider'
import AutoCompleteInputResults from '~components/molecules/auto-complete-input/auto-complete-input-results/auto-complete-input-results'
import { useMount } from '~hooks/use-mount'

import { AutoCompleteInputOptions } from './auto-complete.types'
import { StyledAutoCompleteInputWrapper } from './auto-complete-input.styles'

type AutoCompleteInputProps = {
  error?: string
  id: string
  label?: string
  maxLength?: number
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  options: AutoCompleteInputOptions[]
  placeholder?: string
}

export const AutoCompleteInput = ({
  id,
  placeholder,
  options,
  label,
  onFocus,
  onBlur,
  onChange,
  maxLength,
  error,
}: AutoCompleteInputProps) => {
  const state = useAutoCompleteInputState()
  const {
    activeResult,
    inputValue,
    results,
    isLoading,
    setActiveResult,
    setInputValue,
    setResultsById,
    setIsOpen,
    setOptions,
  } = state

  const inputElem = useRef<HTMLInputElement | null>(null)
  const isInputElemFocused = inputElem.current === document.activeElement

  useMount(() => setOptions(options))

  useEffect(() => {
    if (activeResult || !isInputElemFocused) return

    const filteredOptions = (
      inputValue
        ? getFilteredAutoCompleteOptions(options, inputValue, maxLength)
        : []
    ) as AutoCompleteInputOptions[]

    setResultsById(id, filteredOptions)

    setIsOpen(filteredOptions && filteredOptions.length > 0)
  }, [inputValue, activeResult, isInputElemFocused])

  const filteredOptions = results[id] || []

  return (
    <ErrorBoundary>
      <StyledAutoCompleteInputWrapper>
        <Input
          ref={inputElem}
          name={id}
          label={label}
          placeholder={placeholder}
          value={inputValue || ''}
          error={error}
          onChange={(e) => {
            const { value } = e.target

            setActiveResult(null)
            setInputValue(value)

            onChange?.(e)
          }}
          onFocus={(e) => {
            setIsOpen(Boolean(inputValue))
            onFocus?.(e)
          }}
          onBlur={(e) => {
            onBlur?.(e)
          }}
          autoComplete="off"
          variant="search"
        />

        {state.isOpen && filteredOptions.length > 0 && (
          <AutoCompleteInputResults id={id} results={filteredOptions} />
        )}
      </StyledAutoCompleteInputWrapper>
    </ErrorBoundary>
  )
}

export default AutoCompleteInput
