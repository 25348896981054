import { apiGetAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'

export interface UserRole {
  entity: string
  id: string
  imoNumber?: string
  name?: string
  pool?: string
  poolPartner?: string
  poolPartnerKey?: string
  role: string
  roleDisplayName: string
  vesselName?: string
}

export interface PoolPartnerAssociation {
  name?: string
  poolPartnerKey?: string
  partnerId?: string
}

export interface IUsersResponse {
  email: string
  inviteSentAt: Date
  name: string
  phoneNumber?: string
  roles: UserRole[]
  poolpartners: PoolPartnerAssociation[]
  secondaryPhoneNumber?: string
  sub: string
}

export function usersAllAction(): Promise<IUsersResponse[]> {
  return apiGetAction<IUsersResponse[]>(ApiRoutes.Users.all)
}
