import { FALLBACK_STRING_DASH } from 'src/constants/global-strings'
import { TableRowProps } from '~components/organisms/table/table.types'
import { DataTransformerTableCellConfig } from '~components/organisms/table/table-data-transformer/table-data-transformer.types'
import { toDateFormat } from '~utils/dates'

export function searchRecursively(
  obj: Record<any, any>,
  searchKey: string,
  results: any[] = [],
): any[] {
  const r: any[] = results

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    if (key === searchKey) {
      const recursiveVals = searchRecursively(value, searchKey)

      r.push([value, ...recursiveVals])
    } else if (typeof value === 'object') {
      searchRecursively(value, searchKey, r)
    }
  })

  return r
}

const filterOutEmptyTableRows = (tableRows: TableRowProps[]) =>
  tableRows.filter(
    (row) => !row.cells.every((cell) => !cell.data && !cell.component),
  )

type DataTransformerTableCellConfigArray<AD> =
  DataTransformerTableCellConfig<AD>[]

// The use of `any` types is on purpose
// We cannot predict the shape of each object being passed to this function, as it
// may be any type and shape of api data
export function getExpandableRowsRecursively<AD>(
  tableCellConfigs: DataTransformerTableCellConfig<AD>[],
  apiSourcedRowData: Record<string, any[]>[],
  tableData: any,
  tableRowItem: any,
  rows: any = [],
): any[] {
  const rowConfigs = (
    tableCellConfigs as DataTransformerTableCellConfigArray<AD>[]
  ).map((configs) => configs?.[0])
  const expandedRowConfigs = (
    tableCellConfigs as DataTransformerTableCellConfigArray<AD>[]
  ).map((configs) => configs?.[1])
  const headerCellConfigs = rowConfigs.map((config) => config?.headerCellConfig)
  const rowData = tableRowItem.rowData ?? []

  return filterOutEmptyTableRows([
    ...rows,
    ...rowData.flatMap((tableRowItem: any, tableRowItemIndex: number) => {
      // Adds the newly sourced api data (set in config) to each existing field object
      const mergedRowItem = {
        ...tableRowItem,
        ...apiSourcedRowData.reduce(
          (_, apiData) =>
            Object.keys(apiData).reduce((prevValue, key) => {
              const filteredData = apiData[key].filter(
                (data) =>
                  tableRowItem.id &&
                  (data.field === tableRowItem.id ||
                    data.id === tableRowItem.id),
              )
              return {
                ...prevValue,
                ...(filteredData.length > 0 ? { [key]: filteredData } : {}),
              }
            }, {}),
          {},
        ),
      }

      return [
        // Add header cells (non-expandable, additional row)
        headerCellConfigs.filter(Boolean).length > 0 && {
          cells: headerCellConfigs.map((cellConfig) =>
            getRowCellsByConfig(
              cellConfig,
              tableData,
              rowData,
              mergedRowItem,
              tableRowItemIndex,
            ),
          ),
        },
        {
          cells: rowConfigs.map((cellConfig) =>
            getRowCellsByConfig(
              cellConfig,
              tableData,
              rowData,
              mergedRowItem,
              tableRowItemIndex,
            ),
          ),
          // If more expandable row config children are found, continue building expandable rows
          ...(expandedRowConfigs.length > 0 && {
            expandableRows: getExpandableRowsRecursively(
              expandedRowConfigs,
              apiSourcedRowData,
              tableData,
              mergedRowItem,
            ),
          }),
        },
      ].filter(Boolean)
    }),
  ])
}

export function getCellValue<AD>(
  config: DataTransformerTableCellConfig<AD> | undefined,
  tableData: any,
  tableRowList: AD[],
  tableRowItem: AD,
  tableRowItemIndex: number,
) {
  if (!config) return FALLBACK_STRING_DASH

  const cellKey = config.key

  if (cellKey) {
    const value = tableRowItem[cellKey as keyof AD]

    return value !== undefined && value !== null
      ? config.isDateValue
        ? // @ts-ignore
          toDateFormat(value)
        : value
      : FALLBACK_STRING_DASH
  }

  const customCellValue = config?.customCellValueFn?.(
    tableData,
    tableRowList,
    tableRowItem,
    tableRowItemIndex,
  )

  if (customCellValue) {
    return customCellValue.toString()
  }

  return null
}

export const getRowCellsByConfig = <AD>(
  config: DataTransformerTableCellConfig<AD> | undefined,
  tableData: any,
  tableRowList: AD[],
  tableRowItem: AD,
  tableRowItemIndex: number,
) => ({
  ...config,
  data: getCellValue(
    config,
    tableData,
    tableRowList,
    tableRowItem,
    tableRowItemIndex,
  ),
  component: config?.componentFn?.(
    tableData,
    tableRowList,
    tableRowItem,
    tableRowItemIndex,
  ),
  colSpan: config?.colSpan,
})
