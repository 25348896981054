export const shortComment = {
  id: '1',
  publishDate: '2024-04-18T14:04:13.892Z',
  relevantDate: '2024-04-18T00:00:00.000Z',
  comment: 'short text',
}

export const longComment = {
  id: '2',
  publishDate: '2024-04-18T14:04:13.892Z',
  relevantDate: '2024-04-18T00:00:00.000Z',
  comment:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac odio et turpis aliquet pharetra eget et lacus. Vivamus efficitur ornare rutrum. Etiam egestas odio a ipsum consectetur, quis porttitor purus vulputate. Mauris fringilla dolor tortor, nec vestibulum nisi ullamcorper quis. Sed lacinia tincidunt est, at hendrerit mi cursus a. Nam auctor nulla quis sapien lobortis, a finibus metus suscipit. Sed ullamcorper diam ligula, in sodales justo sagittis efficitur. Morbi eget porttitor nunc. Duis suscipit ligula leo, vitae blandit purus consequat at. Curabitur fringilla, nisi ut eleifend maximus, nibh ligula semper nunc, vitae dignissim arcu mauris id mi. Donec ultrices posuere varius. Nam sit amet vestibulum massa. Donec condimentum arcu at ultrices dignissim. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer in quam faucibus, bibendum nisi vel, blandit neque. Etiam molestie dolor porttitor tempor ultrices. Proin molestie nisl non elit lacinia aliquam. Maecenas imperdiet interdum nibh vehicula dignissim. Ut vel maximus sapien. Aliquam sit amet ultricies mi, vitae commodo arcu. Donec eleifend nibh at elementum imperdiet. Proin suscipit ex quis lacinia euismod. Mauris porta in risus ut eleifend. Sed sagittis porttitor tincidunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra volutpat magna, nec vestibulum neque mollis euismod. Duis massa nibh, pretium eget neque sit amet, mollis rutrum purus.',
}

export const forecastComments = [
  shortComment,
  longComment,
  {
    id: '1714553901404',
    publishDate: '2024-05-01T08:58:21.404Z',
    relevantDate: '2024-05-01T08:58:21.404Z',
    subject: 'Adding subjects before it was cool',
    comment:
      '<p>This is the content with a link to <a href="https://nodejs.org/en/blog/release/v22.0.0" rel="noreferrer noopener" title="https://nodejs.org/en/blog/release/v22.0.0" target="_blank">https://nodejs.org/en/blog/release/v22.0.0</a></p>\n<p>&nbsp;</p>\n<p>And an image:</p>\n<p><img alt="Dog and cat - Meme by Greatest :) Memedroid" src="https://graph.microsoft.com/v1.0/teams/0ca6440e-30f9-4198-9d52-04af7660a9e9/channels/19:2e79fce6b73b44129e49b78f01927206@thread.tacv2/messages/1714553901404/hostedContents/aWQ9eF8wLXdldS1kMjAtMGQwOWQwNDJmNWM4M2YyOTBhMWVjMTQyMmVmMzg0Y2MsdHlwZT0xLHVybD1odHRwczovL2V1LWFwaS5hc20uc2t5cGUuY29tL3YxL29iamVjdHMvMC13ZXUtZDIwLTBkMDlkMDQyZjVjODNmMjkwYTFlYzE0MjJlZjM4NGNjL3ZpZXdzL2ltZ28=/$value" width="1" height="NaN" itemid="0-weu-d20-0d09d042f5c83f290a1ec1422ef384cc"></p>\n<p>&nbsp;</p>\n<p><img aria-label="has context menu" alt="Top 50 Dog and Cat Memes: Can Dog and Cat be friends?" src="https://graph.microsoft.com/v1.0/teams/0ca6440e-30f9-4198-9d52-04af7660a9e9/channels/19:2e79fce6b73b44129e49b78f01927206@thread.tacv2/messages/1714553901404/hostedContents/aWQ9eF8wLXdldS1kMTctN2Y2N2QwMjcwZWY3ZGNhOTI3MmZiNzQxZmQ0NWExNzUsdHlwZT0xLHVybD1odHRwczovL2V1LWFwaS5hc20uc2t5cGUuY29tL3YxL29iamVjdHMvMC13ZXUtZDE3LTdmNjdkMDI3MGVmN2RjYTkyNzJmYjc0MWZkNDVhMTc1L3ZpZXdzL2ltZ28=/$value" width="259.86842105263156" height="250" itemid="0-weu-d17-7f67d0270ef7dca9272fb741fd45a175"></p>\n<p>&nbsp;</p>\n<p><img alt="Top 50 Dog and Cat Memes: Can Dog and Cat be friends?" src="https://graph.microsoft.com/v1.0/teams/0ca6440e-30f9-4198-9d52-04af7660a9e9/channels/19:2e79fce6b73b44129e49b78f01927206@thread.tacv2/messages/1714553901404/hostedContents/aWQ9eF8wLXdldS1kMjAtZmJjZTgxY2U1NTMxZGZjZTk5MjMxYzJmNjk3NTAyOTEsdHlwZT0xLHVybD1odHRwczovL2V1LWFwaS5hc20uc2t5cGUuY29tL3YxL29iamVjdHMvMC13ZXUtZDIwLWZiY2U4MWNlNTUzMWRmY2U5OTIzMWMyZjY5NzUwMjkxL3ZpZXdzL2ltZ28=/$value" width="1" height="NaN" itemid="0-weu-d20-fbce81ce5531dfce99231c2f69750291"></p>\n<p>&nbsp;</p>',
    contentType: 'html',
  },
  {
    id: '1713439928390',
    publishDate: '2024-04-30T11:32:08.390Z',
    relevantDate: '2024-04-30T11:32:08.390Z',
    subject: 'Sara testing intermediate',
    comment:
      '<p><span style="font-size:inherit">CPP NEW: Mild activity with around 6 cargoes in the market, mostly will not directly affect outlook of the tonnage supply. Position list is longer this week. Short term sentiment is flat with signs of possible softening. Handies remains steady, inters will be subject to how that plays out.</span><br>\n<br>\n<span style="font-size:inherit">CPP MED: Our units are the only direct intermediates open in this market in the 7 days window, however no cargoes have been quoted in the market so far. Handies are down by abt WS 15 points. Sentiment is soft.</span><br>\n<br>\n<span style="font-size:inherit">DPP EU: Market continues the same trend compares to last, fairly quiet with just enough cargoes to keep the ships ticking, sentiment is slightly soft. Expects market to go sideways with simple strategy to target the best paying runs. Demurrage plays could be beneficial with rates slightly above TCE levels.</span><br>\n<br>\n<span style="font-size:inherit">ASIA: More activities on palm oil shipments to India especially for 2H April, though rates remained sideways. Forward enquiries are out but remain largely untested. CPP in SEA continues to show weakness due to low demand with rates maintains soft. Positions in the FEA remains very limited due to weak northbound from SEA and owners prefers to stay within FEA. Southbound market continues to see new market cargoes prompt and forward with strong rates.</span></p>',
    contentType: 'html',
  },
  {
    id: '50',
    publishDate: '2024-04-18T14:04:13.892Z',
    relevantDate: '2024-04-18T00:00:00.000Z',
    comment:
      'Intermediate\nWe come to the close of the week with the situation slightly better vs when we first started the week. On paper both Aframax AG/East and Indo/Oz indexes have been printing downwards a couple of points but in reality, “last done” rates for most routes have pretty much held at similar levels. The position list though has tilted significant (stressing that am referring to the current fixing window) in owner’s favour, so expecting any outstanding 3rd decade April cargo to face a limited number of ships available. But unfortunately, due to a lack of longhaul cargoes, the list does start to repopulate again come the early parts of May. So, the best case for owners, would be to see a couple of charterers get caught having to pay up before the we move into the May fixing window.. In essence to set the last done rates at a higher level before we move into the May fixing window where rates will forseeably come under pressure again.',
  },
  {
    id: '51',
    publishDate: '2024-04-18T14:04:13.892Z',
    relevantDate: '2024-04-18T00:00:00.000Z',
    comment: '<p>test</p>',
    contentType: 'html',
  },
]
