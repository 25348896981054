import { Button, Typography } from '@maersktankersdigital/web-components'

import { Modal } from '~components/molecules/modal/modal'
import { ModalFooter } from '~components/molecules/modal/modal.styles'

interface Props {
  cancelButtonText?: string
  confirmButtonText?: string
  content: string
  header?: string
  isVisible: boolean
  onClose?: () => void
  onConfirm?: () => void
  onCancel?: () => void
  disableCancel?: boolean
}

const ConfirmModal = ({
  content,
  header = 'Are you sure?',
  confirmButtonText = 'Yes',
  cancelButtonText = 'No',
  onConfirm,
  onClose,
  onCancel,
  isVisible,
  disableCancel,
}: Props) => {
  const handleConfirm = () => {
    onConfirm?.()
    onClose?.()
  }

  const handleCancel = () => {
    onCancel?.()
    onClose?.()
  }

  return (
    <Modal
      variant="confirm"
      open={isVisible}
      onClose={onClose}
      title={header}
      disableCloseIcon
    >
      <>
        <Typography variant="paragraph2">{content}</Typography>
        <ModalFooter>
          {!disableCancel && (
            <Button onClick={handleCancel} variant="secondary">
              {cancelButtonText}
            </Button>
          )}
          <Button onClick={handleConfirm}>{confirmButtonText}</Button>
        </ModalFooter>
      </>
    </Modal>
  )
}

export default ConfirmModal
