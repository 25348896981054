import { Box, Typography } from '@mui/material'

export interface CookieDetailsAccordionProps {
  title: string
  description: string
  cookies: {
    dataProcessor: string
    purpose: string
    dataProcessorPrivacyPolicy: {
      label: string
      link: string
    }
    expiry: string
    cookieName: string
    provider: string
    note?: string
  }[]
}

export function LabelValue({
  label,
  value,
  valueLink,
}: {
  label: string
  value: string
  valueLink?: string
}) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flexStart', pt: 1 }}>
      <Typography
        variant="label"
        component="p"
        sx={{ flexBasis: 150, flexShrink: 0 }}
      >
        {label}:
      </Typography>

      <Typography
        variant="caption"
        component={valueLink ? 'a' : 'p'}
        href={valueLink}
        target="_blank"
        sx={{ flexGrow: 1 }}
      >
        {value}
      </Typography>
    </Box>
  )
}
