import { ChangeEvent, FC, KeyboardEvent, useRef, useState } from 'react'
import * as React from 'react'

import { iconsMap } from '~assets/svgs'
import { Icon } from '~components/atoms/icon'
import { IOption } from '~types/components'

import {
  StyledBasicTextInput,
  StyledFilterTextInput,
  StyledTextInputClearWrapper,
  StyledTextInputIconWrapper,
  StyledTextInputSearchableList,
  StyledTextInputSearchableListOption,
  StyledVesselSearchTextInput,
} from './style'

export enum Variants {
  basic = 'basic',
  filters = 'filters',
  vessel = 'vessel',
}

export interface ITextInput {
  accesskey?: string
  autoFocus?: boolean
  dropdownMode?: boolean
  icon?: keyof typeof iconsMap
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef?: any
  isClearable?: boolean
  isDisabled?: boolean
  isLocked?: boolean
  isNativeAutocompleteOn?: boolean
  isSearchable?: boolean
  name: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onClear?: () => void
  placeholder?: string
  searchableOptions?: IOption[]
  setValue?: (value: string) => void
  size?: 'regular' | 'small'
  type?: 'text' | 'password'
  value: string
  variant?: keyof typeof Variants
}

const textInputVariants = {
  basic: StyledBasicTextInput,
  filters: StyledFilterTextInput,
  vessel: StyledVesselSearchTextInput,
}

export const TextInput: FC<ITextInput> = ({
  accesskey,
  autoFocus = true,
  dropdownMode,
  innerRef,
  name,
  placeholder,
  value,
  isLocked,
  isDisabled,
  isSearchable,
  searchableOptions = [],
  icon,
  isClearable,
  onClear,
  onChange,
  setValue,
  size,
  type = 'text',
  isNativeAutocompleteOn = true,
  variant = Variants.basic,
}) => {
  const StyledVariantTextInput = textInputVariants[variant]

  const isSmall = size === 'small'
  const [isFocused, setFocused] = useState(false)
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(0)
  const [isOnDropdown, setIsOnDropdown] = useState(false)
  const [isSearchableListOpen, setIsSearchableListOpen] = useState(false)
  const [hoveredOptionIndex, setHoveredOptionIndex] = useState<number | null>(
    null,
  )
  const optionListRef = useRef<HTMLUListElement>(null)
  const onFocus = () => {
    return setFocused(true)
  }
  const onBlur = () => {
    if (!isOnDropdown) {
      removeFocus()
    }
  }
  const removeFocus = (force = false) => {
    setFocused(false)
    if (force) {
      document.body.focus()
    }
  }

  const handleScrollingSearchableList = (
    listElement: HTMLUListElement,
    direction: 'down' | 'up',
  ) => {
    const hasScroll =
      (listElement?.scrollHeight ?? 0) > (listElement?.clientHeight ?? 0)
    if (hasScroll) {
      const focusedOption = document.getElementsByClassName(
        `searchable-option-${focusedOptionIndex + 1}`,
      )[0] as HTMLLIElement
      const isOptionOutsideOfVisibleArea =
        listElement &&
        focusedOption &&
        listElement.clientHeight < focusedOption.offsetTop

      if (isOptionOutsideOfVisibleArea) {
        switch (direction) {
          case 'up':
            listElement.scrollTop =
              listElement.scrollTop - focusedOption.clientHeight
            break
          case 'down':
            listElement.scrollTop =
              listElement.scrollTop + focusedOption.clientHeight
            break
          default:
            break
        }
      }
    }
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault()
    }

    if (isSearchable && isFocused && event.key) {
      if (event.key === 'Enter') {
        if (searchableOptions?.length && setValue) {
          const indexOfOptionToSet =
            hoveredOptionIndex !== null
              ? hoveredOptionIndex
              : focusedOptionIndex
          handleSettingValue(searchableOptions[indexOfOptionToSet].value)
        }
      } else if (event.key === 'ArrowDown' && hoveredOptionIndex === null) {
        if (
          searchableOptions.length &&
          focusedOptionIndex < searchableOptions.length - 1
        ) {
          optionListRef.current &&
            handleScrollingSearchableList(optionListRef.current, 'down')
          setFocusedOptionIndex(focusedOptionIndex + 1)
        }
      } else if (event.key === 'ArrowUp' && hoveredOptionIndex === null) {
        if (focusedOptionIndex > 0 && searchableOptions.length) {
          optionListRef.current &&
            handleScrollingSearchableList(optionListRef.current, 'up')
          setFocusedOptionIndex(focusedOptionIndex - 1)
        }
      }
    }
  }

  const handleSettingValue = (valueToSet: string) => {
    if (setValue) {
      setValue(valueToSet)
    }
    setIsOnDropdown(false)
    removeFocus(true)
    setIsSearchableListOpen(false)
    setHoveredOptionIndex(null)
    setFocusedOptionIndex(0)
  }

  const options = searchableOptions.filter(
    (searchableOption, index) =>
      searchableOptions.findIndex(
        (option) =>
          option.value === searchableOption.value &&
          option.label === searchableOption.label,
      ) === index,
  )

  return (
    <div style={{ position: 'relative' }}>
      {icon && iconsMap[icon] && !isClearable && (
        <StyledTextInputIconWrapper isSmall={isSmall}>
          <Icon name={icon} size={32} />
        </StyledTextInputIconWrapper>
      )}
      <StyledVariantTextInput
        id={name}
        name={name}
        accessKey={accesskey}
        type={type}
        value={value}
        ref={innerRef}
        placeholder={placeholder}
        isSmall={isSmall}
        isLocked={isLocked}
        isDisabled={isDisabled}
        hasIcon={!!icon}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.target.value?.length && !isFocused && setFocused(true)
          if (onChange) {
            onChange(e)
          }
        }}
        autoComplete={isNativeAutocompleteOn ? 'on' : 'off'}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
      />
      {dropdownMode && (
        <StyledTextInputClearWrapper
          isSmall={isSmall}
          onClick={() => setIsSearchableListOpen(!isSearchableListOpen)}
        >
          <Icon name="chevronDown" size={isSmall ? 16 : 22} />
        </StyledTextInputClearWrapper>
      )}
      {!dropdownMode && isClearable && onClear && !isLocked && (
        <StyledTextInputClearWrapper isSmall={isSmall} onClick={onClear}>
          <Icon name="close" size={isSmall ? 16 : 22} />
        </StyledTextInputClearWrapper>
      )}
      {isSearchable &&
        !!options.length &&
        (!!value.length || isSearchableListOpen) &&
        !!setValue &&
        (isFocused || isSearchableListOpen) &&
        (!(options.length == 0) || isSearchableListOpen) && (
          <StyledTextInputSearchableList
            ref={optionListRef}
            onMouseEnter={() => {
              return setIsOnDropdown(true)
            }}
            onMouseLeave={() => {
              setHoveredOptionIndex(null)
              return setIsOnDropdown(false)
            }}
          >
            {options.map((option, index) => {
              return (
                <StyledTextInputSearchableListOption
                  className={`searchable-option-${index}`}
                  isFocused={
                    hoveredOptionIndex === null && focusedOptionIndex === index
                  }
                  key={option.value}
                  dangerouslySetInnerHTML={{
                    __html: option.label.replaceAll(
                      new RegExp(`\\b(${value})`, 'ig'),
                      '<strong>$1</strong>',
                    ),
                  }}
                  onMouseEnter={() => {
                    return setHoveredOptionIndex(index)
                  }}
                  onMouseLeave={() => {
                    return setHoveredOptionIndex(null)
                  }}
                  onClick={() => handleSettingValue(option.value)}
                />
              )
            })}
          </StyledTextInputSearchableList>
        )}
    </div>
  )
}
