import { format, parseISO } from 'date-fns'
import { useMemo } from 'react'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { useApi } from 'src/hooks/use-api'

import { ApiRoutes } from '~api'
import { IVesselsReadResponse } from '~api/vessels/read'
import { Box } from '~components/atoms/box'
import { InfoLabel } from '~components/atoms/info-label/info-label'

import { pagesWithReDeliveryLabel } from '../constants'
import { isRedeliveryWithin3Months } from '../utils'

export function RedeliveryLabel() {
  const { vesselId } = useParams()
  const { pathname } = useLocation()

  const { data: vesselData } = useApi<IVesselsReadResponse>(
    ApiRoutes.Vessels.read,
    {
      vesselId,
    },
  )

  const isPageWithReDeliveryLabel = useMemo(
    () => pagesWithReDeliveryLabel.some((i) => matchPath(i, pathname)),
    [pathname],
  )

  if (
    !vesselData?.redeliveryDate ||
    !isPageWithReDeliveryLabel ||
    !isRedeliveryWithin3Months(vesselData?.redeliveryDate)
  )
    return <></>

  return (
    <Box flex={0} alignSelf="flex-start">
      <InfoLabel
        text={`To be re-delivered on ${format(
          parseISO(vesselData.redeliveryDate),
          'd-M-yyyy',
        )} `}
        variant="VesselDashboard"
      />
    </Box>
  )
}
