import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

import { Typography } from '@mui/material'
import { CustomTickProps } from '~pages/pages-behind-login/position-list/types/exposure-types'

export const StyledRegionTick = styled.text`
  font-family: ${theme.FONTS.families.regular};
  font-size: ${theme.FONTS.sizes.xxs};
  letter-spacing: 0.7px;
`

export const CustomRegionTick = ({
  x = 0,
  y,
  payload,
  index,
  visibleTicksCount,
  ...rest
}: CustomTickProps) => {
  let adjustedX = x
  if (index === 0) {
    adjustedX = Number(x) - 25
  }

  if (index && index + 1 === visibleTicksCount) {
    adjustedX = Number(x) + 25
  }

  return (
    <g>
      <Typography
        x={adjustedX}
        y={y}
        dy={21}
        component="text" // SVG element
        textAnchor="middle"
        variant="caption"
        sx={{ fontSize: '10px' }}
      >
        {payload?.value}
      </Typography>
    </g>
  )
}
