import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'

export interface MuiBackdropData {
  defaultProps?: ComponentsProps['MuiBackdrop']
  styleOverrides?: ComponentsOverrides['MuiBackdrop']
  variants?: ComponentsVariants['MuiBackdrop']
}

export const MuiBackdrop: MuiBackdropData = {
  styleOverrides: {
    root: {
      background: 'hsla(201, 100%, 19%, 0.75)',
    },
  },
}
