import { Box, Skeleton } from '@mui/material'
import { useState } from 'react'

export function Image({ src, alt }: { src: string; alt: string }) {
  const [loaded, setLoaded] = useState(false)

  return (
    <>
      {!loaded && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={300}
          animation="pulse"
        />
      )}

      <Box
        component="img"
        src={src}
        alt={alt}
        width="100%"
        height="100%"
        style={{ display: loaded ? 'block' : 'none' }}
        onLoad={() => setLoaded(true)}
      />
    </>
  )
}
