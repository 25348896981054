import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { apiUpdateAction, apiUploadFileAction } from '~api/actions'

export interface IEditRejectionResponse {
  canImpairEarnings: boolean
  isOngoing: boolean
  error?: string
}

export type IEditRejectionPayload = {
  closingDate?: Date
  comment?: string
  file?: File
  isOngoing?: boolean
}

export interface IEditSireCdiResponse {
  comment?: string
  error?: string
  id: string
  inspectionDate: Date
  plan?: string
  q88Update?: boolean
}

export type IEditSireCdiPayload = {
  comment?: string
  id?: string
  inspectionDate?: Date
  plan?: string
  q88Update?: boolean
  remote?: boolean
  status: string
  type: string
}

export function vesselTabsEditRejectionAction(
  payload: IEditRejectionPayload,
  urlParams?: Params,
): Promise<IEditRejectionResponse> {
  return apiUploadFileAction<IEditRejectionPayload, IEditRejectionResponse>(
    ApiRoutes.Vettings.update,
    payload,
    urlParams,
    true,
  )
}

export function vesselTabsEditSireCdiAction(
  payload: IEditSireCdiPayload,
  urlParams?: Params,
): Promise<IEditSireCdiResponse> {
  return apiUpdateAction<IEditSireCdiPayload, IEditSireCdiResponse>(
    ApiRoutes.Vettings.updateSireCdi,
    payload,
    urlParams,
  )
}
