import { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

interface ContextState {
  activePool: string | undefined
}

const ActivePoolContext = createContext<ContextState>({
  activePool: undefined,
})

export function useActivePoolContext() {
  return useContext(ActivePoolContext)
}

export function ActivePoolProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const params = useParams()
  const [activePool, setActivePool] = useState<string>()

  useEffect(() => {
    if (params.pool) {
      setActivePool(params.pool)
    }
  }, [params])

  return (
    <ActivePoolContext.Provider value={{ activePool }}>
      {children}
    </ActivePoolContext.Provider>
  )
}
