import { tenMinutes } from 'src/constants/constants'
import { defaultFetcher } from './fetcher'

const DOMAIN = import.meta.env.VITE_API_URL || ''

export const ApiConfig = {
  domain: DOMAIN,
  swrDefaults: {
    dedupingInterval: tenMinutes,
    refreshInterval: 0,
    revalidateOnFocus: false,
    fetcher: defaultFetcher,
  },
}
