import { PostVesselsRes } from '~hooks/queries/vessels/use-post-vessels'

export const postVesselsResMock: PostVesselsRes = {
  imoNumber: '9149524',
  poolPartner: 'ACE',
  pool: 'Panamax',
  track: 'pool',
  partnerManager: 'auth0|660e6ab8c6fc95e032f1b2c5',
  status: 'onboarding',
  createdAt: '2024-11-25T12:36:48.657Z',
  vesselName: 'Stolt Span',
  progress: 0,
  vesselId: '67446f603406d85e642acc81',
  vesselCode: null,
  euaInfo: {},
  partnerManagerUser: {
    sub: 'auth0|660e6ab8c6fc95e032f1b2c5',
    name: 'accountmanager@test.dk',
  },
}
