import { COLORS } from '~theme/colors'
import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

import { Z_INDEX } from '~types/z-index'

import { pageHeaderHeights } from 'src/constants/constants'
import { StyledWrapper } from '~components/layout/wrapper/wrapper.styles'

export const StyledPageHeader = styled.header`
  position: sticky;
  background: ${COLORS.greys.ultraLight};
  top: ${pageHeaderHeights.mobile};
  left: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: ${Z_INDEX.PageHeader};

  @media only screen and (${theme.BREAKPOINTS.sm}) {
    top: ${pageHeaderHeights.desktop};
  }

  > ${StyledWrapper} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

export const StyledHeadlineAndActionsContainer = styled.div`
  display: flex;
  column-gap: ${theme.SPACINGS[3]};
  margin-bottom: ${theme.SPACINGS[1]};

  & > div {
    padding: ${theme.SPACINGS[1]} 0;
  }
`
export const StyledHeaderLabelsContainer = styled.div`
  display: flex;
  gap: ${theme.SPACINGS[2]};
  margin-top: ${theme.SPACINGS[3]};

  &:empty {
    margin-top: 0;
  }
`
export const StyledVesselPageHeaderInner = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: ${theme.SPACINGS[5]};
  row-gap: 20px;

  @media only screen and (min-width: 1400px) {
    column-gap: ${theme.SPACINGS[10]};
    row-gap: 30px;
  }
`
