import { Params } from 'react-router-dom'

import { apiUpdateAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'

export interface IPoolPartnerUpdateResponse {
  _id?: number
  createdAt?: string
  message?: string
  name?: string
  partnerManager?: string
  poolPartnerKey: string
  statusCode?: number
  updatedAt?: string
}

export interface IPoolPartnerUpdatePayload {
  name: string
  partnerManager: string
  poolPartnerKey: string
}

export function poolPartnerUpdateAction(
  payload: IPoolPartnerUpdatePayload,
  urlParams?: Params,
): Promise<IPoolPartnerUpdateResponse> {
  return apiUpdateAction<IPoolPartnerUpdatePayload, IPoolPartnerUpdateResponse>(
    ApiRoutes.POOL_PARTNERS.UPDATE,
    payload,
    urlParams,
  )
}
