import {
  PageHeader as WcPageHeader,
  PageHeaderProps as WcPageHeaderProps,
} from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { NotificationsBanner } from './notifications/notifications-banner'

interface PageHeaderProps extends WcPageHeaderProps {}

export function PageHeader(props: PageHeaderProps) {
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <WcPageHeader {...props} />
      </Box>
      <NotificationsBanner />
    </>
  )
}
