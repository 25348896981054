import { FunctionComponent, lazy } from 'react'
import CallbackPage from '~pages/auth/callback-page'
import { PageRoute } from './page-route'

const PoolPartnerPage = lazy(
  () => import('~pages/pages-behind-login/pool-partner-page/pool-partner-page'),
)
const RejectionsPage = lazy(
  () => import('~pages/pages-behind-login/rejections-page/rejections-page'),
)
const SirePage = lazy(
  () => import('~pages/pages-behind-login/sire-page/sire-page'),
)
const VoyagesPage = lazy(
  () =>
    import(
      '~pages/pages-behind-login/onboarded-vessel/voyages-page/voyages-page'
    ),
)
const VettingPage = lazy(
  () =>
    import(
      '~pages/pages-behind-login/onboarded-vessel/vetting/vetting-page-wrapper'
    ),
)
const QuestionnairePage = lazy(
  () =>
    import(
      '~pages/pages-behind-login/onboarded-vessel/questionnaire-page/questionnaire-page'
    ),
)
const PortEssentialsPage = lazy(
  () =>
    import('~pages/pages-behind-login/onboarded-vessel/port-essentials-page'),
)
const PoolRestrictionsPage = lazy(
  () =>
    import(
      '~pages/pages-behind-login/onboarded-vessel/pool-restrictions-page/pool-restrictions-page'
    ),
)
const OpsDashboardPage = lazy(
  () =>
    import(
      '~pages/pages-behind-login/onboarded-vessel/ops-dashboard-page/ops-dashboard-page'
    ),
)
const BasicVesselDataPage = lazy(
  () =>
    import(
      '~pages/pages-behind-login/onboarded-vessel/basic-vessel-data-page/basic-vessel-data-page'
    ),
)
const LandingPage = lazy(
  () => import('~pages/open-pages/landing-page/landing-page'),
)
const CdiPage = lazy(() => import('~pages/pages-behind-login/cdi/cdi-page'))

const VesselsPage = lazy(
  () => import('~pages/pages-behind-login/vessels-page/vessels-page'),
)

const UsersPage = lazy(
  () => import('~pages/pages-behind-login/users-page/users-page'),
)
const UserPage = lazy(
  () => import('~pages/pages-behind-login/user-page/user-page'),
)
const VesselPage = lazy(
  () => import('~pages/pages-behind-login/vessel-page/vessel-page'),
)
const UnauthorizedPage = lazy(() => import('~pages/auth/unauthorized-page'))
const CreateUserPage = lazy(
  () => import('~pages/pages-behind-login/create-user-page/create-user-page'),
)
const VerifyEmailPage = lazy(
  () => import('~pages/pages-behind-login/verify-email-page/verify-email-page'),
)
const PoolPartnersPage = lazy(
  () =>
    import('~pages/pages-behind-login/pool-partners-page/pool-partners-page'),
)
const MapPage = lazy(
  () => import('~pages/pages-behind-login/position-list/map-page/map-page'),
)
const VesselDashboardPage = lazy(
  () =>
    import(
      '~pages/pages-behind-login/onboarded-vessel/vessel-dashboard-page/vessel-dashboard-page'
    ),
)
const PartnerDashboardPage = lazy(
  () =>
    import(
      '~pages/pages-behind-login/onboarded-vessel/partner-dashboard-page/partner-dashboard-page'
    ),
)
const PoolPointsPage = lazy(
  () =>
    import(
      '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-page'
    ),
)
const DocumentsPage = lazy(
  () =>
    import(
      '~pages/pages-behind-login/onboarded-vessel/documents-page/documents-page'
    ),
)

const ProfilePage = lazy(
  () => import('~pages/pages-behind-login/profile-page/profile-page'),
)

type ChildrenAttributes = {
  element: React.FunctionComponent<any>
  index?: boolean
  parent?: {
    component: React.FunctionComponent<any>
    props?: any
  }
  path?: string
}

export type StaticPageAttributes = {
  children?: ChildrenAttributes[]
  component: FunctionComponent<any>
  id?: string
  title: string
}

export const STATIC_PAGE_ATTRIBUTES: Partial<
  Record<PageRoute, StaticPageAttributes>
> = {
  [PageRoute.LANDING]: {
    component: LandingPage,
    title: 'Landing',
    id: 'landing',
  },
  [PageRoute.AUTH_CALLBACK]: {
    component: CallbackPage,
    title: 'Auth Callback',
    id: 'authCallback',
  },
  [PageRoute.CREATE_USER]: {
    component: CreateUserPage,
    title: 'Create User',
    id: 'createUser',
  },
  [PageRoute.VERIFY_EMAIL]: {
    component: VerifyEmailPage,
    title: 'Verify E-mail',
    id: 'verifyEmail',
  },
  [PageRoute.POOL_PARTNERS]: {
    component: PoolPartnersPage,
    title: 'Pool Partners',
    id: 'poolPartners',
  },
  [PageRoute.VESSELS]: {
    component: VesselsPage,
    title: 'Vessels',
    id: 'vessels',
  },
  [PageRoute.USERS]: {
    component: UsersPage,
    title: 'Users',
    id: 'users',
  },
  [PageRoute.REJECTIONS]: {
    component: RejectionsPage,
    title: 'Rejections',
    id: 'rejections',
  },
  [PageRoute.SIRE]: {
    component: SirePage,
    title: 'Sire',
    id: 'sire',
  },
  [PageRoute.CDI]: {
    component: CdiPage,
    title: 'CDI',
    id: 'cdi',
  },
  [PageRoute.POOL_PARTNER]: {
    component: PoolPartnerPage,
    title: 'Pool Partner',
    id: 'poolPartner',
  },
  [PageRoute.VESSEL]: {
    component: VesselPage,
    title: 'Vessel',
    id: 'vessel',
  },
  [PageRoute.USER]: {
    component: UserPage,
    title: 'User',
    id: 'user',
  },
  [PageRoute.UNAUTHORIZED]: {
    component: UnauthorizedPage,
    title: 'Unauthorized',
    id: 'unauthorized',
  },
  [PageRoute.VESSEL_DASHBOARD]: {
    component: VesselDashboardPage,
    title: 'Vessel Dashboard',
    id: 'vesseldashboard',
  },
  [PageRoute.PARTNER_DASHBOARD]: {
    component: PartnerDashboardPage,
    title: 'Vessel Dashboard',
    id: 'poolpartnerdashboard',
  },
  [PageRoute.OPS_DASHBOARD]: {
    component: OpsDashboardPage,
    title: 'Ops Dashboard',
    id: 'opsdashboard',
  },
  [PageRoute.QUESTIONNAIRE]: {
    component: QuestionnairePage,
    title: 'Questionnaire',
    id: 'questionnaire',
  },
  [PageRoute.BASIC_VESSEL_DATA]: {
    component: BasicVesselDataPage,
    title: 'Basic Vessel Data',
    id: 'basicvesseldata',
  },
  [PageRoute.PORT_ESSENTIALS]: {
    component: PortEssentialsPage,
    title: 'Port Essentials',
    id: 'portessentials',
  },
  [PageRoute.POOL_RESTRICTIONS]: {
    component: PoolRestrictionsPage,
    title: 'Restrictions',
    id: 'restrictions',
  },
  [PageRoute.VETTING]: {
    component: VettingPage,
    title: 'Vetting',
    id: 'vetting',
  },
  [PageRoute.DOCUMENTS]: {
    component: DocumentsPage,
    title: 'Documents',
    id: 'certificates',
  },
  [PageRoute.VOYAGES]: {
    component: VoyagesPage,
    title: 'Voyages',
    id: 'voyages',
  },
  [PageRoute.POOL_POINTS]: {
    component: PoolPointsPage,
    title: 'Pool Points',
    id: 'poolpoints',
  },
  [PageRoute.MAP]: {
    component: MapPage,
    title: 'Map',
    id: 'map',
  },
  [PageRoute.PROFILE]: {
    component: ProfilePage,
    title: 'Profile',
    id: 'profile',
  },
}

// App routes that are only visible when the user is authorized
export const AUTH_ROUTES = [
  PageRoute.POOL_PARTNER,
  PageRoute.USER,
  PageRoute.POOL_PARTNERS,
  PageRoute.VESSELS,
  PageRoute.USERS,
  PageRoute.REJECTIONS,
  PageRoute.SIRE,
  PageRoute.CDI,
  PageRoute.FEATURE_FLAG,
  PageRoute.POSITION_LIST,
  PageRoute.MAP,
  PageRoute.PROFILE,
]

export const VESSEL_PAGE_ROUTES = [
  PageRoute.VESSEL_DASHBOARD,
  PageRoute.PARTNER_DASHBOARD,
  PageRoute.OPS_DASHBOARD,
  PageRoute.QUESTIONNAIRE,
  PageRoute.BASIC_VESSEL_DATA,
  PageRoute.PORT_ESSENTIALS,
  PageRoute.POOL_RESTRICTIONS,
  PageRoute.POOL_POINTS,
  PageRoute.VETTING,
  PageRoute.DOCUMENTS,
  PageRoute.VOYAGES,
]

export const UNAUTH_ROUTES = [
  PageRoute.LANDING,
  PageRoute.AUTH_CALLBACK,
  PageRoute.UNAUTHORIZED,
  PageRoute.CREATE_USER,
]
