import { SVGProps as ReactSvgProps } from 'react'
import styled from 'styled-components/macro'

interface SVGProps extends ReactSvgProps<SVGSVGElement> {
  className?: string
  size?: number
  title?: string
}

const SVG = (props: SVGProps) => (
  <svg
    width={props.size || '1em'}
    height={props.size || '1em'}
    viewBox="0 0 2 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path d="M1 0v12" stroke="#42B0D5" strokeWidth={0.6} />
  </svg>
)
const StyledSvgVector90 = styled(SVG)`
  vertical-align: middle;
  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
`
StyledSvgVector90.displayName = 'SvgVector90'
export default StyledSvgVector90
