import {
  AddIcon,
  EditIcon,
  LockIcon,
  TextButton,
  theme,
  Button as WebCButton,
} from '@maersktankersdigital/web-components'
import { Box as MuiBox, Typography } from '@mui/material'
import dot from 'dot-object'
import * as React from 'react'
import { FC, ReactElement, useEffect, useState } from 'react'
import {
  Controller,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { queryClient } from 'src/'
import styled from 'styled-components/macro'
import { mutate } from 'swr'
import { ApiClient, ApiRoutes } from '~api'
import { IQuestionnaireField, IQuestionnairesResponse } from '~api/vessels/read'
import { Box } from '~components/atoms/box'
import { Button } from '~components/atoms/button'
import { Checkbox } from '~components/atoms/checkbox'
import { Icon } from '~components/atoms/icon'
import { InnerHtml } from '~components/atoms/inner-html'
import { Link } from '~components/atoms/link'
import { Text } from '~components/atoms/text'
import { AdditionalFilesField } from '~components/forms/additional-files-field/additional-files-field'
import { TextareaField } from '~components/forms/textarea-field/textarea-field'
import { Comment } from '~components/molecules/comment/comment'
import { Group } from '~components/molecules/group/group'
import { IQuestionnaireListSection } from '~components/organisms/lists/vessel-questionnaires'
import { rebuildCurrentFieldsValuesObject } from '~components/organisms/modals/earnings-simulator/questionnaire-missing-fields'
import registerToast from '~components/templates/toasts/toast-register'
import { COLORS } from '~theme/colors'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { generatePathName } from '~utils/generate-path-name'
import { gtm } from '~utils/gtm'
import { useFocusWorkaround } from '../../../../hooks/use-focus-workaround'

interface IVesselQuestionaireFieldTypeResolver {
  field: IQuestionnaireField
  hideButtons?: boolean
  hideStatus?: boolean
  render: ({
    isEditing,
    setIsCompleted,
  }: {
    isEditing: boolean
    setIsCompleted?: React.Dispatch<React.SetStateAction<boolean>>
  }) => ReactElement
  rights?: IVesselQuestionaireFieldTypeResolverRights
  section: IQuestionnaireListSection
}

export interface IVesselQuestionaireFieldTypeResolverRights {
  canApprove?: boolean
  canEdit?: boolean
  canSee: boolean
}

const StyledLockIcon = styled(LockIcon)`
  margin-left: ${theme.SPACINGS[1]};
  font-size: 20px;
  color: ${COLORS.secondary.darkBlue};
`
export const VesselQuestionaireField: FC<
  IVesselQuestionaireFieldTypeResolver
> = ({ field, render, hideButtons, hideStatus, section }) => {
  const {
    control,
    handleSubmit,
    formState,
    reset,
    getValues,
    setValue,
    register,
  } = useFormContext()
  const [dirtyFields, setDirtyFields] = useState(dot.dot(formState.dirtyFields))
  const { focusInput } = useFocusWorkaround(field.id)
  const { vesselId } = useParams()
  const isFieldInInitialState =
    !field.value && !dirtyFields[`${field.id}_value`]
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRejectLoading, setIsRejectLoading] = useState<boolean>(false)
  const [isCommenting, setIsCommenting] = useState<boolean>(false)
  const [isUploadingAdditionalFile, setIsUploadingAdditionalFile] =
    useState<boolean>(field.files !== undefined && field.files.length > 0)

  // Temporary until the API returns the same response on PATCH
  const [isWaitingForApproval, setIsWaitingForApproval] = useState<boolean>(
    !!field.waitingForApproval,
  )
  const [isCompleted, setIsCompleted] = useState<boolean>(
    !!field.approved || (!field.waitingForApproval && field.complete),
  )
  const [isApprovalRequired] = useState<boolean>(!!field.approvalRequired)
  //for the filefield there should be no possibility to add addtional files,
  //these are exclusive for other field types (expand this for other field types if necessary)
  const [isFileField] = useState<boolean>(field.type === 'downl_upl')
  const userCanApprove = section.userRights?.canApprove
  const isApprovedFile = Boolean(field.files?.[0]?.approvedAt)

  useEffect(() => {
    if (isEditing) {
      focusInput()
    }
  }, [isEditing])

  useEffect(() => {
    setIsEditing(
      field.type !== 'downl_upl' &&
        !!section.userRights?.canEdit &&
        isFieldInInitialState,
    )
  }, [isApprovedFile])

  useEffect(() => {
    setDirtyFields(dot.dot(formState.dirtyFields))
  }, [formState])

  if (field.type === 'number') {
    register(`${field.id}_value`, {
      pattern: {
        value: /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/,
        message:
          'Please provide correct number (you need to use dot as a separator)',
      },
    })
  }

  const onSaveComment: SubmitHandler<FieldValues> = async (formValues) => {
    setIsLoading(true)
    const fields = []
    for (const prop in formValues) {
      if (formValues.hasOwnProperty(prop)) {
        fields.push({
          id: prop.split('_')[0],
          [prop.split('_')[1]]: formValues[prop],
        })
      }
    }

    const filteredFields = fields
      .map((fieldToSave) => {
        return {
          ...fieldToSave,
          value: !dirtyFields[`${fieldToSave.id}_notApplicable`]
            ? fieldToSave.value === '' && dirtyFields[`${fieldToSave.id}_value`]
              ? null
              : fieldToSave.value
            : undefined,
          notApplicable: dirtyFields[`${fieldToSave.id}_notApplicable`]
            ? fieldToSave.notApplicable
            : undefined,
          comment:
            dirtyFields[`${fieldToSave.id}_comment`] ||
            fieldToSave.comment === null
              ? fieldToSave.comment
              : undefined,
        }
      })
      .filter((fieldToSave) => {
        return (
          ((fieldToSave.value ||
            fieldToSave.value === null ||
            fieldToSave.comment === null ||
            fieldToSave.comment) &&
            (dirtyFields[`${fieldToSave.id}_value`] ||
              dirtyFields[`${fieldToSave.id}_comment`])) ||
          fieldToSave.comment === null
        )
      })

    const payload = {
      id: section.id,
      fields: filteredFields,
    }

    await ApiClient.Vessels.Questionnaire.update(payload, {
      vesselId,
    })
      .then((response) => {
        queryClient.invalidateQueries({
          queryKey: [`/vessels/${vesselId}/data?tab=opsdashboard`],
        })
        queryClient.invalidateQueries({
          queryKey: [`/vessels/${vesselId}/questionnaire`],
        })
        if (response?.length) {
          reset({ ...getValues() })
          setIsCommenting(false)
          setIsEditing(false)
          updateVesselData()

          /* Copied from VesselQuestionnaireModal as the code isn't built for this behavior */
          const updatedSectionProgress =
            response
              .find(
                (responseCategory) =>
                  responseCategory.name === section.categoryName,
              )
              ?.sections?.find(
                (responseSection) => responseSection.id === section.id,
              )?.progress || ''

          gtm.pushEvent('section_edited', {
            section_category: section.categoryName,
            section_name: section.name,
            section_progress: updatedSectionProgress,
            vessel_data: {
              progress: response[0].questionnaireOverallProgress || '',
            },
          })
          /* End copy */
        } else {
          registerToast({
            content: 'Saving form failed',
            type: 'error',
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleFileDownload = async (fileId: string) => {
    await ApiClient.Files.read(fileId)
      .then((response) => {
        if (response.signedUrl) {
          window.location.href = response.signedUrl
        } else {
          registerToast({
            content: 'An Error occurred when opening the file',
            type: 'error',
          })
        }
      })
      .catch((e: SyntaxError) => {
        registerToast({
          content: `An Error occurred when opening the file. ${e}`,
          type: 'error',
        })
      })
  }

  const onApprove: SubmitHandler<FieldValues> = async (formValues) => {
    rebuildCurrentFieldsValuesObject(formValues)
    setIsLoading(true)
    const payload = {
      id: section.id,
      fields: [
        {
          id: field.id,
          value: formValues[field.id].value || field.value,
        },
      ],
    }

    await ApiClient.Vessels.Questionnaire.approve(payload, {
      vesselId,
    })
      .then((response) => {
        if (response?.length) {
          reset({ ...getValues() })
          setIsCommenting(false)
          setIsEditing(false)
          setIsWaitingForApproval(false)
          setIsCompleted(true)
          updateVesselData()
        } else {
          registerToast({
            content: 'Approval failed',
            type: 'error',
          })
        }
      })
      .finally(() => {
        return setIsLoading(false)
      })
  }

  const onReject: SubmitHandler<FieldValues> = async (formValues) => {
    rebuildCurrentFieldsValuesObject(formValues)
    setIsRejectLoading(true)

    const payload = {
      id: section.id,
      fields: [
        {
          id: field.id,
          value: formValues[field.id].value || field.value,
        },
      ],
    }
    await ApiClient.Vessels.Questionnaire.reject(payload, {
      vesselId,
    })
      .then((response) => {
        if (response?.length) {
          setIsCommenting(false)
          setIsEditing(false)
          setIsWaitingForApproval(false)
          setIsCompleted(false)

          updateVesselData()
        } else {
          registerToast({
            content: 'Rejection failed',
            type: 'error',
          })
        }

        return response
      })
      .then((response: IQuestionnairesResponse) => {
        if (!response || response.length === 0) return
        const previousFieldData = response
          .flatMap(({ sections }) => sections)
          .filter((responseSection) => responseSection.id === section.id)
          .flatMap(({ fields }) => fields)
          .find((responseField) => responseField.id === field.id)
        const previousValue = previousFieldData?.value

        setValue(`${field.id}_value`, previousValue)
      })
      .finally(() => {
        setIsRejectLoading(false)
      })
  }

  const updateVesselData = () => {
    mutate(generatePathName(ApiRoutes.Vessels.Questionnaire.read, { vesselId }))
  }

  // TODO: Type the values
  const onError: SubmitErrorHandler<FieldValues> = (err) => {
    setIsLoading(false)
    return console.log({ err })
  }

  const saveComment = () => {
    handleSubmit(onSaveComment, onError)() // Invoke () the submit manually
  }

  const approve = () => {
    handleSubmit(onApprove, onError)() // Invoke () the submit manually
  }

  const reject = () => {
    handleSubmit(onReject, onError)() // Invoke () the submit manually
  }

  const checkForAdditionalFiles = () => {
    if (field.files === undefined || field.files.length === 0)
      setIsUploadingAdditionalFile(false)
  }

  const openForEditing = () => {
    if (field.type === 'downl_upl') {
      edit()
    } else {
      setIsCompleted(false)
      setIsCommenting(false)
    }
    checkForAdditionalFiles()
  }

  const edit = () => {
    setIsEditing(true)
    setIsCommenting(false)
    checkForAdditionalFiles()
  }

  const setComment = () => {
    setIsCommenting(!isCommenting)
    checkForAdditionalFiles()
  }

  const setAdditionalFileUploading = () => {
    if (field.files === undefined || field.files.length === 0) {
      setIsUploadingAdditionalFile(!isUploadingAdditionalFile)
      setIsCommenting(false)
    }
  }

  const cancel = () => {
    setValue(`${field.id}_value`, field.value)

    setIsEditing(false)
    setIsCommenting(false)
    checkForAdditionalFiles()
  }

  function getLatestComment() {
    const { comments } = field
    if (!comments || comments.length === 0) return null

    const latestComment = comments[0].value !== null ? comments[0] : null // if the value of latest comment is null, it means the comment was deleted
    return latestComment
  }

  function renderLatestComment() {
    const latestComment = getLatestComment()
    if (!latestComment || isCommenting) return null

    return (
      <Box>
        <Typography sx={{ mb: 2, mt: 4 }} variant="label">
          Comment
        </Typography>
        <Comment
          name={`${field.id}_comment`}
          key={`${field.id}_comment`}
          comment={latestComment}
          save={saveComment}
          canRemove={
            !!(section.userRights?.canEdit || section.userRights?.canApprove)
          }
        />
      </Box>
    )
  }

  function renderAddCommentButton() {
    const hasComment = !!getLatestComment()
    if (!field.allowComments || hasComment) return null

    return (
      <MuiBox>
        <TextButton
          disabled={isCommenting}
          icon={<AddIcon />}
          onClick={(e) => {
            e.preventDefault()
            setComment()
          }}
          name="Add Comment"
        >
          Add comment
        </TextButton>
      </MuiBox>
    )
  }

  function renderCommentEditor() {
    if (!isCommenting) return null

    return (
      <>
        <Typography sx={{ mb: 1, mt: 2 }} variant="label">
          Comment
        </Typography>
        <MuiBox sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
          <TextareaField
            name={`${field.id}_comment`}
            placeholder="Add comment"
          />
          <MuiBox
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 5,
              alignItems: 'center',
            }}
          >
            <TextButton
              onClick={(e) => {
                e.preventDefault()
                setIsCommenting(false)
              }}
            >
              Cancel
            </TextButton>
            <WebCButton
              onClick={(e) => {
                e.preventDefault()
                saveComment()
              }}
              isLoading={isLoading}
            >
              Send
            </WebCButton>
          </MuiBox>
        </MuiBox>
      </>
    )
  }

  function renderFilesAndFileUploader() {
    return (
      <>
        {field.allowFiles && isUploadingAdditionalFile && !isFileField && (
          <>
            <Typography sx={{ mb: 1, mt: 2 }} variant="label">
              Files
            </Typography>
            <AdditionalFilesField
              field={field}
              section={section}
              onCancelClick={() => {
                if (field.files === undefined || field.files.length === 0) {
                  setIsUploadingAdditionalFile(false)
                }
              }}
            />
          </>
        )}
      </>
    )
  }

  return (
    <>
      {field.id && (
        <Box
          mb={field.type === 'free_text' ? 0 : 1}
          flexDirection="row"
          alignItems="center"
        >
          <Typography variant="label">{field.name}</Typography>
          {field.readOnly && <StyledLockIcon />}
        </Box>
      )}
      <Box flexDirection="row">
        {field.type === 'free_text' ? (
          ''
        ) : (
          <Box flexDirection="row" flex={3} justifyContent="space-between">
            <Typography variant="caption">{field.description}</Typography>
          </Box>
        )}
        <Box flex={1} />
      </Box>
      {field.details && (
        <Box flexDirection="row">
          <Box flex={3}>
            <InnerHtml html={field.details} />
          </Box>
          <Box flex={1} />
        </Box>
      )}
      {field.downloadLink?._id && (
        <Box flexDirection="row">
          <Box flex={3}>
            <Link
              noUnderline
              onClick={() => {
                handleFileDownload(field.downloadLink._id)
              }}
            >
              <Text text={field.name} color="blues.mid" size="small" />
            </Link>
          </Box>
          <Box flex={1} />
        </Box>
      )}
      <Box mt={field.type === 'free_text' ? 0.6 : 2} flexDirection="row">
        <Box flex={3}>{render({ isEditing, setIsCompleted })}</Box>
        <Box ml={2} flex={1} flexDirection="row" alignItems="flex-start">
          {(!hideButtons || field.type === 'downl_upl') &&
            isEditing &&
            section.userRights?.canEdit && (
              <Group
                spacing={2}
                flexDirection={field.type === 'downl_upl' ? 'column' : 'row'}
                alignItems="center"
              >
                {(!isFieldInInitialState ||
                  (field.type === 'downl_upl' &&
                    Boolean(field.files?.length))) &&
                  (isApprovedFile || field.type !== 'downl_upl') && (
                    <WebCButton
                      onClick={(e) => {
                        e.preventDefault()
                        cancel()
                      }}
                      variant="secondary"
                    >
                      Cancel
                    </WebCButton>
                  )}
                {field.type === 'downl_upl' && <Box />}
              </Group>
            )}
          {!field.readOnly &&
            !hideButtons &&
            !isEditing &&
            !isWaitingForApproval &&
            !isCompleted &&
            field.type !== 'downl' &&
            section.userRights?.canEdit && (
              <WebCButton
                onClick={(e) => {
                  e.preventDefault()
                  edit()
                }}
                variant="secondary"
              >
                Edit
              </WebCButton>
            )}
          {(!hideStatus || (field.type === 'downl_upl' && userCanApprove)) &&
            !isEditing && (
              <Box ml={2} alignItems="flex-start">
                {!field.value &&
                  field.type !== 'downl' &&
                  field.type !== 'downl_upl' && (
                    <Text
                      text="MISSING INFO"
                      weight="bold"
                      whiteSpace="nowrap"
                    />
                  )}
                {isWaitingForApproval && !section.userRights?.canApprove && (
                  <Text
                    text="READY FOR APPROVAL"
                    weight="bold"
                    whiteSpace="nowrap"
                    color="blues.maersk"
                  />
                )}
                {isWaitingForApproval &&
                  section.userRights?.canApprove &&
                  field.type !== 'downl_upl' && (
                    <Group
                      spacing={4}
                      flex={0}
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Button
                        isLoading={isRejectLoading}
                        text="Reject"
                        variant="outlineDanger"
                        onClick={reject}
                      />
                      <Button
                        isLoading={isLoading}
                        onClick={approve}
                        text="Approve"
                        variant="solidSecondary"
                      />
                    </Group>
                  )}
                {isCompleted && (
                  <Group spacing={3} flexDirection="row" alignItems="center">
                    {isApprovalRequired && field.type !== 'downl_upl' && (
                      <Group
                        spacing={1}
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Icon name="secure" size={30} />
                        <Text
                          text="APPROVED"
                          weight="bold"
                          whiteSpace="nowrap"
                          color="greens.light"
                        />
                      </Group>
                    )}
                    {isApprovalRequired && field.type !== 'downl_upl' && (
                      <div
                        style={{
                          width: 2,
                          height: 46,
                          backgroundColor: Colors.greys.base,
                        }}
                      />
                    )}
                    {!field.readOnly &&
                      section.userRights?.canEdit &&
                      field.type !== 'downl' &&
                      (isApprovedFile || field.type !== 'downl_upl') && (
                        <Group spacing={1} flexDirection="row">
                          <TextButton
                            icon={<EditIcon />}
                            name="Open for editing"
                            onClick={(e) => {
                              e.preventDefault()
                              openForEditing()
                            }}
                          >
                            Open for editing
                          </TextButton>

                          {field.type === 'downl_upl' && <Box mt={5} />}
                        </Group>
                      )}
                  </Group>
                )}
              </Box>
            )}
        </Box>
      </Box>
      {(field.allowComments || field.allowFiles) && (
        <Box>
          {(section.userRights?.canEdit || section.userRights?.canApprove) && (
            <Box alignItems="flex-start" mt={1.7} ml={1.7}>
              <Group spacing={2} flexDirection="row">
                {renderAddCommentButton()}
                {field.allowFiles && !isFileField && (
                  <TextButton
                    icon={<AddIcon />}
                    disabled={isUploadingAdditionalFile}
                    name="Add file"
                    onClick={(e) => {
                      e.preventDefault()
                      setAdditionalFileUploading()
                    }}
                  >
                    Add file
                  </TextButton>
                )}
              </Group>
            </Box>
          )}
          {renderLatestComment()}
          {renderCommentEditor()}
          {renderFilesAndFileUploader()}
        </Box>
      )}
      {(field.allowNotApplicable || field.notApplicable) && (
        <Box mt={2} flexDirection="row">
          <Controller
            control={control}
            name={`${field.id}_notApplicable`}
            defaultValue={field.notApplicable}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                name={`${field.id}_notApplicable`}
                value={value}
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                label="Not applicable"
                variant="basic"
                isDisabled={!field.allowNotApplicable && field.notApplicable}
              />
            )}
          />
        </Box>
      )}
      {dirtyFields[field.id] && (
        <Text
          text="Unsaved changes will be lost."
          size="small"
          color="reds.base"
        />
      )}
    </>
  )
}

interface Comment {
  value: string | null
  createdBy: string
  createdAt: string
  authorName: string
}
