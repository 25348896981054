import {
  Button,
  DatePicker,
  EditIcon,
  TextButton,
  Typography,
} from '@maersktankersdigital/web-components'
import { Popper } from '@mui/base'
import * as React from 'react'
import { useState } from 'react'
import { useSWRConfig } from 'swr'
import {
  IDryDockUpdatePayload,
  IDryDockUpdateResponse,
  userUpdateAction,
} from '~api/position-list/update'
import { ApiRoutes } from '~api/routes'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import ConfirmModal from '~components/molecules/modal/confirm-modal/confirm-modal'
import { Modal } from '~components/molecules/modal/modal'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import {
  ActiveDryDock,
  ButtonWrapper,
  ModalContent,
  NextDateWrapper,
  NextDryDockPopupContentWrapper,
  StyledDeleteIcon,
  StyledTextArea,
  StyledTextButton,
  UserDateWrapper,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/next-dry-dock-field/next-dry-dock-field.styles'
import { formatDate } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table-content/position-list-table-content'
import { TableCell } from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/position-list-table.styles'
import { PositionVesselData } from '~pages/pages-behind-login/position-list/types/position-list-types'

interface Props {
  vessel: PositionVesselData
  width: number
}

function NextDryDockField({ vessel, width }: Props) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const [openModal, setOpenModal] = useState(false)

  const [nextDryDockComment, setNextDryDockComment] = useState('')
  const [dateError, setDateError] = useState('')
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const { mutate } = useSWRConfig()
  const { setStatus } = useAnimatedAlert()
  const open = Boolean(anchor)
  const activeDryDock =
    vessel.nextDryDockInternal.nextDryDock || vessel.nextDryDock
  const formatedActiveDryDockDate = formatDate(activeDryDock)
  const hasInternalDryDock = !!vessel.nextDryDockInternal.nextDryDock
  const [nextDryDockDate, setnextDryDockDate] = useState<Date>(
    hasInternalDryDock
      ? new Date(vessel.nextDryDockInternal.nextDryDock)
      : new Date(vessel.nextDryDock),
  )
  const isPositionListUser = useHasScope(ExactScopeName.positionListUpdate)

  const saveDryDockDateToDB = async () => {
    setIsConfirmationModalOpen(false)
    setAnchor(null)
    if (!nextDryDockDate) return
    const payload = {
      nextDryDock: nextDryDockDate.toISOString(),
      comment: nextDryDockComment || null,
    }

    await userUpdateAction<IDryDockUpdatePayload, IDryDockUpdateResponse>(
      payload,
      { vesselId: vessel.vesselId },
    )
      .then((response) => {
        if (response.statusCode) {
          throw new Error()
        }
        setnextDryDockDate(new Date(response.nextDryDock))
        setStatus(
          'You have successfully updated the next dry dock',
          5000,
          'success',
        )
        mutate(ApiRoutes.POSITION_LIST.ALL)
      })
      .catch((error: Error) => {
        console.error(error)
        setStatus(
          'Something went wrong when updating the next dry dock ',
          5000,
          'error',
        )
      })
  }

  const deleteDryDockDate = async () => {
    const payload = {
      nextDryDock: null,
      comment: null,
    }
    setIsDeleteModalOpen(false)
    handleCloseModal()

    await userUpdateAction<IDryDockUpdatePayload, IDryDockUpdateResponse>(
      payload,
      { vesselId: vessel.vesselId },
    )
      .then((response) => {
        if (response.statusCode) {
          throw new Error()
        }
        mutate(ApiRoutes.POSITION_LIST.ALL)

        setStatus(
          'You have successfully deleted the next dry dock date',
          5000,
          'success',
        )
      })
      .catch((error: Error) => {
        console.error(error)
        setStatus(
          'Something went wrong when deleting next dry dock date ',
          5000,
          'error',
        )
      })
  }

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setAnchor(null)
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setAnchor(null)
    setOpenModal(false)
  }

  const handleisDeleteModalOpen = () => {
    setIsDeleteModalOpen(true)
  }

  const handleSaveDryDockDate = () => {
    if (!nextDryDockDate) {
      return
    }
    if (nextDryDockDate < new Date()) {
      setDateError('Please select a future date')
      return
    }

    if (hasInternalDryDock) {
      handleCloseModal()
      saveDryDockDateToDB()
    } else {
      setOpenModal(false)
      setIsConfirmationModalOpen(true)
    }
  }

  const handleChangeNextDryDockDate = (date: Date) => {
    setnextDryDockDate(date)
  }

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNextDryDockComment(e.target.value)
  }

  return (
    <TableCell
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseOver}
      $width={width}
    >
      {hasInternalDryDock && (
        <Typography variant="menu">{formatDate(vessel.nextDryDock)}</Typography>
      )}
      <ActiveDryDock hasInternalDryDock={hasInternalDryDock}>
        {formatedActiveDryDockDate}
      </ActiveDryDock>
      <Popper
        open={open && !!isPositionListUser}
        anchorEl={anchor}
        placement="auto"
        style={{ zIndex: 2 }}
      >
        <NextDryDockPopupContentWrapper>
          {hasInternalDryDock && (
            <>
              <UserDateWrapper>
                <Typography variant="label">
                  {vessel.nextDryDockInternal.responsibleUser?.name}
                </Typography>
                <Typography variant="menu">
                  {formatDate(vessel.nextDryDockInternal.createdAt)}
                </Typography>
              </UserDateWrapper>
              {vessel.nextDryDockInternal?.comment && (
                <Typography variant="caption">
                  {vessel.nextDryDockInternal.comment}
                </Typography>
              )}
            </>
          )}

          <TextButton icon={<EditIcon size={20} />} onClick={handleOpenModal}>
            Edit Next Dry Dock
          </TextButton>
        </NextDryDockPopupContentWrapper>
      </Popper>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        title="Edit Next Dry Dock"
      >
        <ModalContent>
          <DatePicker
            name="currentDate"
            disabled
            placeholder={formatedActiveDryDockDate}
            onChange={() => null}
            label={
              hasInternalDryDock
                ? 'current dry dock date'
                : 'current Q88 dry dock date'
            }
          />
          <NextDateWrapper>
            <DatePicker
              name="newDate"
              selected={nextDryDockDate}
              placeholder="Select date"
              onChange={handleChangeNextDryDockDate}
              label="New dry dock date"
              error={dateError}
              required
            />
            {hasInternalDryDock && (
              <StyledTextButton
                icon={<StyledDeleteIcon size={18} />}
                onClick={handleisDeleteModalOpen}
              >
                Delete date
              </StyledTextButton>
            )}
          </NextDateWrapper>
          <StyledTextArea
            name="dryDockComment"
            label="Comment (optional)"
            value={nextDryDockComment}
            onChange={handleCommentChange}
          />
          <ButtonWrapper>
            <TextButton onClick={handleCloseModal}>Cancel</TextButton>
            <Button
              variant="secondary"
              onClick={handleSaveDryDockDate}
              disabled={!nextDryDockDate}
            >
              Save
            </Button>
          </ButtonWrapper>
        </ModalContent>
      </Modal>
      <ConfirmModal
        isVisible={isConfirmationModalOpen}
        content="By entering this information, you are actively overwriting
              existing Q88 information. Please note that your changes will be replaced by Q88 data upon
              completed dry dock."
        cancelButtonText="Cancel"
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={saveDryDockDateToDB}
      />
      <ConfirmModal
        isVisible={isDeleteModalOpen}
        content="Please note that by deleting this date, your changes will be
              replaced by Q88 data."
        cancelButtonText="Cancel"
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={deleteDryDockDate}
      />
    </TableCell>
  )
}

export default NextDryDockField
