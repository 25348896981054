let _tokenGetter: (() => Promise<string>) | null = null

export function setTokenGetter(tokenGetter: () => Promise<string>) {
  _tokenGetter = tokenGetter
}

export async function getTokenSilently(): Promise<string> {
  if (!_tokenGetter) {
    throw new Error('Token getter has not been set.')
  }
  return _tokenGetter()
}
