import { generatePath, useParams } from 'react-router-dom'
import { useActivePoolContext } from '../context/active-pool-context'
import { useGetMeID } from './queries/me/use-get-me-id'
import { useGetMeVT } from './queries/me/use-get-me-vt'
import { GetPoolsResItem, useGetPools } from './queries/pools/use-get-pools'

export function useConstructLinkWithPool(route: string, isV1page?: boolean) {
  const params = useParams()
  const { activePool } = useActivePoolContext()
  const { data: meId } = useGetMeID()
  const { data: meVt } = useGetMeVT()
  const { data: pools } = useGetPools()

  const usersPools = meVt?.activePools || meId?.profitCenterPools

  if (!usersPools || !pools || usersPools.length === 0 || pools?.length === 0) {
    return '/404'
  }
  // the local storage item is set in the V1 page.
  // It is useful when navigating between v1 and v2 pages.
  const poolNameLS = localStorage.getItem('poolName')

  if (params.pool) {
    return generatePath(route, {
      pool: isV1page
        ? findPoolId(params.pool, pools) || findPoolId(usersPools[0], pools)
        : params.pool,
    })
  }

  if (activePool) {
    return generatePath(route, {
      pool: isV1page ? findPoolId(activePool, pools) : activePool,
    })
  }

  if (poolNameLS) {
    return generatePath(route, {
      pool: isV1page
        ? findPoolId(poolNameLS as string, pools)
        : poolNameLS.toLowerCase(),
    })
  }

  return generatePath(route, {
    pool: isV1page
      ? findPoolId(usersPools[0], pools)
      : usersPools[0].toLocaleLowerCase(),
  })
}

function findPoolId(poolName: string, pools: GetPoolsResItem[]) {
  return pools.find(
    (pool) => pool.name.toLowerCase() === poolName.toLowerCase(),
  )?.id
}
