import styled from 'styled-components/macro'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

interface IStyledCheckbox {
  isDisabled?: boolean
}

export const StyledBasicCheckbox = styled.input.attrs<IStyledCheckbox>(
  (props) => {
    return {
      disabled: props.isDisabled,
      tabIndex: '0',
    }
  },
)<IStyledCheckbox>`
  &[type='checkbox']:checked,
  &[type='checkbox']:not(:checked) {
    display: none;
  }
  &[type='checkbox']:checked + label,
  &[type='checkbox']:not(:checked) + label {
    position: relative;
    padding-left: 56px;
    display: inline-flex;
    align-items: center;
    height: 38px;
  }
  &[type='checkbox']:checked + label:before,
  &[type='checkbox']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => {
      return props.isDisabled ? Colors.greys.dark : Colors.blues.deep
    }};
    border-radius: 3px;
    background: ${(props) => {
      return props.isDisabled ? Colors.greys.lightest : Colors.white
    }};
  }
  &[type='checkbox']:checked + label:after,
  &[type='checkbox']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 20px;
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-color: ${(props) => {
      return props.isDisabled ? Colors.greys.dark : Colors.blues.deep
    }};
    position: absolute;
    top: 8px;
    left: 14px;

    transform: rotate(35deg);
  }
  &[type='checkbox']:not(:checked) + label:after {
    opacity: 0;
  }
  &[type='checkbox']:checked + label:after {
    opacity: 1;
  }
`

export const StyledFileCheckbox = styled(StyledBasicCheckbox)`
  &[type='checkbox']:checked + label,
  &[type='checkbox']:not(:checked) + label {
    width: 15px;
    height: 15px;
    padding: 0;
    border: 1px solid ${Colors.blues.maersk};
    border-radius: 2px;
    background-color: ${Colors.white};
    cursor: pointer;
  }
  &[type='checkbox']:checked + label:before,
  &[type='checkbox']:not(:checked) + label:before {
    left: -1px;
    top: -1px;
    border: 1px solid ${Colors.blues.maersk};
    width: 15px;
    height: 15px;
    border-radius: 2px;
  }
  &[type='checkbox']:checked + label:after,
  &[type='checkbox']:not(:checked) + label:after {
    border-color: ${Colors.white};
    width: 5px;
    height: 9px;
    top: 1px;
    left: 4px;
  }
  &[type='checkbox']:checked + label:before {
    background-color: ${Colors.blues.maersk};
  }
`

export const StyledFilterCheckbox = styled(StyledBasicCheckbox)`
  &[type='checkbox']:checked + label,
  &[type='checkbox']:not(:checked) + label {
    padding-left: 50px;
  }
  &[type='checkbox']:checked + label:before,
  &[type='checkbox']:not(:checked) + label:before {
    border: 1px solid ${Colors.blues.maersk};
    border-radius: 4px;
    background-color: ${Colors.white};
  }
`
