import { Icon } from '~components/atoms/icon'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import AccordionItem from '~components/molecules/accordion/accordion-item'
import {
  StyledTable,
  StyledTableAccordionItemHeaderInner,
  StyledTableCell,
  StyledTableCellContentWrapper,
  StyledTableHead,
  StyledTableRow,
} from '~components/organisms/table/table.styles'
import {
  TableAccordionOptions,
  TableCellProps,
  TableConfig,
  TableRowConfig,
  TableRowProps,
} from '~components/organisms/table/table.types'

const TableCell = ({
  fontWeight,
  fontSize,
  textColor,
  data,
  component,
  colSpan,
  ...props
}: TableCellProps) => (
  <StyledTableCell
    hasComponent={Boolean(component)}
    colSpan={colSpan || 1}
    {...props}
  >
    <StyledTableCellContentWrapper>
      {data !== undefined && data !== null && (
        <Paragraph size={fontSize} color={textColor} weight={fontWeight}>
          {data}
        </Paragraph>
      )}
      {component && component}
    </StyledTableCellContentWrapper>
  </StyledTableCell>
)

type AccordionRowProps = {
  accordionOptions?: TableAccordionOptions
  expandableRows?: TableRowConfig[]
  tableId: string
}

const TableRow = ({
  expandableRows,
  tableId,
  accordionOptions,
  rowKey,
  rowIndex,
  cells,
}: TableRowProps & AccordionRowProps) =>
  expandableRows && expandableRows.length > 0 ? (
    <StyledTableRow>
      <td
        colSpan={cells
          .map(({ colSpan }) => colSpan ?? 1)
          .reduce((a, b) => a + b, 0)}
      >
        <AccordionItem.Header
          id={`${tableId}-${rowIndex}`}
          {...(accordionOptions?.header ?? {})}
        >
          {({ isOpen }) => (
            <StyledTableAccordionItemHeaderInner isOpen={isOpen}>
              <StyledTable>
                <tbody>
                  <StyledTableRow key={`${tableId}-header-${rowIndex}`}>
                    {cells?.map((props, dataIndex) => (
                      <TableCell
                        key={`data-row-${rowIndex}-${dataIndex}`}
                        {...props}
                      />
                    ))}
                  </StyledTableRow>
                </tbody>
              </StyledTable>

              <Icon name="chevronLeft" height={11} />
            </StyledTableAccordionItemHeaderInner>
          )}
        </AccordionItem.Header>

        <AccordionItem.Body
          id={`${tableId}-${rowIndex}`}
          {...(accordionOptions?.body ?? {})}
        >
          {accordionOptions?.body?.children ?? (
            <StyledTable>
              <tbody>
                {expandableRows.map(
                  ({ cells }, expandableRowIndex) =>
                    cells &&
                    cells.length > 0 && (
                      <StyledTableRow
                        key={`${tableId}-body-${rowIndex}-row-${expandableRowIndex}`}
                      >
                        {cells?.map((props, dataIndex) => (
                          <TableCell
                            key={`data-row-${rowIndex}-${dataIndex}`}
                            {...props}
                          />
                        ))}
                      </StyledTableRow>
                    ),
                )}
              </tbody>
            </StyledTable>
          )}
        </AccordionItem.Body>
      </td>
    </StyledTableRow>
  ) : (
    <StyledTableRow key={rowKey ?? `data-row-${rowIndex}`}>
      {cells?.map((props, dataIndex) => (
        <TableCell key={`data-row-${rowIndex}-${dataIndex}`} {...props} />
      ))}
    </StyledTableRow>
  )

type TableHeadProps = { tableHeadConfig?: TableConfig }

const TableHead = ({ tableHeadConfig }: TableHeadProps) =>
  tableHeadConfig ? (
    <StyledTableHead>
      {tableHeadConfig.rows?.map(({ cells }, rowIndex) => (
        <StyledTableRow key={`heading-row-${rowIndex}`}>
          {cells?.map((props, headingIndex) => (
            <TableCell
              key={`heading-row-${rowIndex}-${headingIndex}`}
              {...props}
            />
          ))}
        </StyledTableRow>
      ))}
    </StyledTableHead>
  ) : null

export default {
  Head: TableHead,
  Row: TableRow,
  Cell: TableHead,
}
