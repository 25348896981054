import { theme, Typography } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

export const StyledHeaderDetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${theme.SPACINGS[4]};
`

export const StyledLabelValueRenderer = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${theme.SPACINGS[1]};
`
export const StyledLabel = styled(Typography)`
  min-width: 130px;
`

export const HeaderDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.SPACINGS[1]};
`
