import { useState } from 'react'

type CopiedValue = string | null
type CopyFn = (text: string, asHTML?: boolean, callback?: () => void) => void

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)

  const copy: CopyFn = (text, asHTML = false, callback) => {
    setCopiedText(text)

    const listener = (e: ClipboardEvent) => {
      if (e.clipboardData) {
        if (asHTML) {
          e.clipboardData.setData('text/html', text)
        }
        e.clipboardData.setData('text/plain', text)
      }
      e.preventDefault()
    }

    document.addEventListener('copy', listener)
    document.execCommand('copy')
    document.removeEventListener('copy', listener)

    if (callback) {
      callback()
    }
  }

  return [copiedText, copy]
}
