import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { apiPostAction } from '~api/actions'
import { IEditSireCdiPayload, IEditSireCdiResponse } from '~api/vettings/update'

export interface IAddRejectionResponse {
  createdAt: string
  createdBy: string
  hash: string
  isOngoing: boolean
  title: string
}

export type IAddRejectionPayload = {
  canImpairEarnings: boolean
  comment?: string
  oilMajor: string
  rejectionCause: string
  rejectionDate: Date
}

export function vesselTabsAddRejectionAction(
  payload: IAddRejectionPayload,
  urlParams?: Params,
): Promise<IAddRejectionResponse> {
  return apiPostAction<IAddRejectionPayload, IAddRejectionResponse>(
    ApiRoutes.Vettings.add,
    payload,
    urlParams,
  )
}

export function vesselTabsAddSireCdiAction(
  payload: IEditSireCdiPayload,
  urlParams?: Params,
): Promise<IEditSireCdiResponse> {
  return apiPostAction<IEditSireCdiPayload, IEditSireCdiResponse>(
    ApiRoutes.Vettings.addSireCdi,
    payload,
    urlParams,
  )
}
