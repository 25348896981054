import { Fragment, Suspense } from 'react'
import { Route } from 'react-router-dom'
import LoadComponent from '~components/molecules/load-component/load-component'
import { ITabsReadResponse } from '~hooks/queries/vessels/data/types/tabs-read'
import { PageRoute } from './constants/page-route'
import {
  STATIC_PAGE_ATTRIBUTES,
  StaticPageAttributes,
} from './constants/routes'

export const getRouteComponents = (routeList: PageRoute[]) =>
  routeList.map((route) => {
    const Component = STATIC_PAGE_ATTRIBUTES[route]?.component || Fragment

    return (
      <Route key={route} path={route} element={<Component />}>
        {STATIC_PAGE_ATTRIBUTES[route]?.children?.map((child) => (
          <Route
            key={child.path}
            index={child.index}
            path={child.path}
            element={
              <Suspense fallback={<LoadComponent />}>
                {child.parent ? (
                  <child.parent.component {...child.parent.props}>
                    <child.element />
                  </child.parent.component>
                ) : (
                  <child.element />
                )}
              </Suspense>
            }
          />
        ))}
      </Route>
    )
  })

export const getRoutes = (routeList: PageRoute[]) =>
  routeList.map((route) => {
    const Component = STATIC_PAGE_ATTRIBUTES[route]?.component || Fragment

    return {
      path: route,
      element: (
        <Suspense>
          <Component />
        </Suspense>
      ),
    }
  })

export const getFilteredVesselPageRoutes = (
  allRoutes: PageRoute[],
  allowedTabs: ITabsReadResponse[],
): Record<string, StaticPageAttributes> => {
  return allRoutes
    .filter(
      (key) =>
        key === PageRoute.QUESTIONNAIRE ||
        allowedTabs.some((tab) => tab.id === STATIC_PAGE_ATTRIBUTES[key]!.id),
    )
    .reduce(
      (prevValue, key) => ({
        ...prevValue,
        [key]: STATIC_PAGE_ATTRIBUTES[key],
      }),
      {},
    )
}
