import { useSearchParams } from 'react-router-dom'
import { filtersParamName, TagFilterWrapperProps } from '../tag-filter'
import { TagFilterRenderer } from './tag-filter-renderer'

export function DefaultTagFilterWrapper({ filters }: TagFilterWrapperProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  function onTagClick({ id }: { id: string }) {
    const selectedFilters = searchParams.getAll(filtersParamName)
    const newSearchParams: string[] = selectedFilters.includes(id)
      ? selectedFilters.filter((filter) => filter !== id)
      : selectedFilters.concat(id)

    setSearchParams({ [filtersParamName]: newSearchParams })
  }

  function onClearClick() {
    searchParams.delete(filtersParamName)
    setSearchParams(searchParams)
  }

  function isTagActive({ id }: { id: string }) {
    return searchParams.getAll(filtersParamName).includes(id)
  }

  return (
    <TagFilterRenderer
      filters={filters}
      onClearClick={onClearClick}
      onTagClick={onTagClick}
      isTagActive={isTagActive}
    />
  )
}
