import {
  Button,
  CloseIcon,
  Typography,
} from '@maersktankersdigital/web-components'

import { usePositionListView } from '~pages/pages-behind-login/position-list/contexts/position-list-view-provider'
import {
  DeleteListModalContent,
  HeaderWrapper,
  ModalButtonWrapper,
  ModalTitle,
  StyledClickPoint,
  StyledModal,
} from '~pages/pages-behind-login/position-list/position-list-page/modals/delete-list-modal/delete-list-modal.styles'

interface Props {
  listToBeDeleted: string
  setShowDeleteListModal: (open: boolean) => void
  showDeleteListModal: boolean
}

function DeleteListModal({
  setShowDeleteListModal,
  showDeleteListModal,
  listToBeDeleted,
}: Props) {
  const { deleteView } = usePositionListView()

  const handleCloseModal = () => setShowDeleteListModal(false)

  const handleDeleteView = () => {
    deleteView(listToBeDeleted, 'myLists')
    setShowDeleteListModal(false)
  }

  return (
    <StyledModal open={showDeleteListModal} handleClose={handleCloseModal}>
      <DeleteListModalContent>
        <StyledClickPoint ariaLabel="close modal" onClick={handleCloseModal}>
          <CloseIcon size={36} />
        </StyledClickPoint>
        <HeaderWrapper>
          <ModalTitle>Are you sure?</ModalTitle>
        </HeaderWrapper>
        <Typography variant="paragraph2">
          Are you sure you want to delete this list?
        </Typography>
        <ModalButtonWrapper>
          <Button
            variant="primary"
            onClick={handleDeleteView}
            data-cy="confirm-delete-button"
          >
            Yes
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </ModalButtonWrapper>
      </DeleteListModalContent>
    </StyledModal>
  )
}

export default DeleteListModal
