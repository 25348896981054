import { theme, typographyStyles } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

export const Wrapper = styled.div`
  background-color: hsl(0, 0%, 99%);
`

export const VoyageTrackerWrapper = styled.div`
  padding-left: 80px;
`

export const VoyageTrackerHeaderWrapper = styled.div`
  padding-top: ${theme.SPACINGS[3]};
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  gap: ${theme.SPACINGS[2]};
  padding-bottom: ${theme.SPACINGS[1]};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: ${theme.SPACINGS[2]};
    right: ${theme.SPACINGS[2]};
    bottom: 0;
    border-bottom: 1px solid ${COLORS.greys.light};
  }
`

export const HeaderItem = styled.div`
  ${typographyStyles.paragraph2}
`

export const VoyageNavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`

export const HeaderItemValue = styled.span`
  font-weight: ${theme.FONTS.weights.bold};
`

export const HeaderItemVoyageNoValue = styled(HeaderItemValue)`
  color: ${COLORS.secondary.midBlue};
`

export const HeaderItemDate = styled(HeaderItem)`
  display: flex;
  gap: 6px;
`

export const HeaderItemDateSeparator = styled.span`
  display: flex;
  align-items: center;
  margin-top: 2px;
  &::after {
    content: '';
    height: ${theme.FONTS.sizes.sm};
    width: 1px;
    background-color: ${COLORS.primary.maerskBlue};
  }
`
