import { Button } from '@maersktankersdigital/web-components'
import dot from 'dot-object'
import { FC, useEffect, useState } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { queryClient } from 'src/'
import { mutate } from 'swr'

import { ApiClient, ApiRoutes } from '~api'
import { Box } from '~components/atoms/box'
import { Text } from '~components/atoms/text'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { Group } from '~components/molecules/group/group'
import { IQuestionnaireListSection } from '~components/organisms/lists/vessel-questionnaires'
import { VesselQuestionnaireFieldWrapper } from '~components/organisms/modals/earnings-simulator/field-resolver'
import { VesselQuestionnaireMissingFields } from '~components/organisms/modals/earnings-simulator/questionnaire-missing-fields'
import { ModalTemplate } from '~components/templates/modal/modal-template'
import registerToast from '~components/templates/toasts/toast-register'
import { useVesselPageState } from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import { generatePathName } from '~utils/generate-path-name'
import { gtm } from '~utils/gtm'

interface IVesselQuestionnaireModal {
  close?: () => void
  section: IQuestionnaireListSection
}

interface IVesselQuestionnaireModalContent {
  section: IQuestionnaireListSection
}

export const VesselQuestionnaireModalContent: FC<
  IVesselQuestionnaireModalContent
> = ({ section }) => {
  return (
    <>
      <Box mb={6}>
        <Text text={section.name} size="h4" headline />
      </Box>
      {section.fields.map((field, index) => {
        return (
          <div
            className="vessel-questionnaire-field-router-wrapper"
            id={`${field.id}`}
            key={field.id}
          >
            <VesselQuestionnaireFieldWrapper
              key={field.id}
              section={section}
              field={field}
              index={index}
            />
          </div>
        )
      })}
    </>
  )
}

export const VesselQuestionnaireModal: FC<IVesselQuestionnaireModal> = ({
  section,
  close,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const methods = useForm()
  const dirtyFields = dot.dot(methods.formState.dirtyFields)
  const { vesselId } = useParams()
  const vesselPageState = useVesselPageState()
  useEffect(() => {
    gtm.pushEvent('section_edit_clicked', {
      section_category: section.categoryName,
      section_name: section.name,
    })
  }, [])

  const onSave: SubmitHandler<FieldValues> = async (formValues) => {
    setIsLoading(true)
    const fields = []
    for (const prop in formValues) {
      if (formValues.hasOwnProperty(prop)) {
        fields.push({
          id: prop.split('_')[0],
          [prop.split('_')[1]]: formValues[prop],
        })
      }
    }

    const fieldsToSave = fields
      .map((fieldToSave) => {
        return {
          ...fieldToSave,
          value:
            !dirtyFields[`${fieldToSave.id}_notApplicable`] ||
            (dirtyFields[`${fieldToSave.id}_notApplicable`] &&
              dirtyFields[`${fieldToSave.id}_value`])
              ? fieldToSave.value === '' &&
                dirtyFields[`${fieldToSave.id}_value`]
                ? null
                : fieldToSave.value
              : undefined,
          notApplicable:
            !dirtyFields[`${fieldToSave.id}_notApplicable`] ||
            (dirtyFields[`${fieldToSave.id}_notApplicable`] &&
              dirtyFields[`${fieldToSave.id}_value`])
              ? undefined
              : fieldToSave.notApplicable,
        }
      })
      .filter((fieldToSave) => {
        return (
          (fieldToSave.value ||
            fieldToSave.value === null ||
            fieldToSave.notApplicable ||
            methods.watch(`${fieldToSave.id}_notApplicable`) === false) &&
          (dirtyFields[`${fieldToSave.id}_value`] ||
            dirtyFields[`${fieldToSave.id}_notApplicable`])
        )
      })

    if (fieldsToSave.length) {
      const payload = {
        id: section.id,
        fields: fieldsToSave,
      }

      await ApiClient.Vessels.Questionnaire.update(payload, {
        vesselId,
      })
        .then((response) => {
          queryClient.invalidateQueries({
            queryKey: [`/vessels/${vesselId}/data?tab=opsdashboard`],
          })
          queryClient.invalidateQueries({
            queryKey: [`/vessels/${vesselId}/questionnaire`],
          })

          if (response?.length) {
            methods.reset({ ...methods.getValues() })
            mutate(
              generatePathName(ApiRoutes.Vessels.Questionnaire.read, {
                vesselId,
              }),
            )

            const updatedSectionProgress =
              response
                .find(
                  (responseCategory) =>
                    responseCategory.name === section.categoryName,
                )
                ?.sections?.find(
                  (responseSection) => responseSection.id === section.id,
                )?.progress || ''

            gtm.pushEvent('section_edited', {
              section_category: section.categoryName,
              section_name: section.name,
              section_progress: updatedSectionProgress,
              vessel_data: {
                progress: response[0].questionnaireOverallProgress || '',
              },
            })
          } else {
            registerToast({
              content: 'Saving form failed',
              type: 'error',
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
          if (close) {
            close()
          }
        })
    } else {
      setIsLoading(false)
      if (close) {
        close()
      }
    }
  }

  const onError: SubmitErrorHandler<FieldValues> = (err) => {
    setIsLoading(false)
    return console.log({ err })
  }

  const save = () => {
    gtm.pushEvent('section_edited', {
      section_category: section.categoryName,
      section_name: section.name,
      section_progress: section.progress,
      vessel_data: {
        progress: vesselPageState.vessel?.progress || '',
      },
    })

    methods.handleSubmit(onSave, onError)()
  }

  return (
    <FormProvider {...methods}>
      <ModalTemplate
        content={
          <form onSubmit={save}>
            <VesselQuestionnaireModalContent section={section} />
          </form>
        }
        sidebar={
          <Box mh={2.4} mt={5}>
            <Group spacing={2} flexDirection="row" alignItems="center">
              {section.userRights?.canEdit && (
                <Button
                  isLoading={isLoading}
                  onClick={() => {
                    return save()
                  }}
                >
                  <Paragraph>Save and close</Paragraph>
                </Button>
              )}
              <Button
                onClick={() => {
                  gtm.pushEvent('section_editing_abandoned', {
                    section_category: section.categoryName,
                    section_name: section.name,
                    section_progress: section.progress,
                    vessel_data: {
                      progress: vesselPageState.vessel?.progress || '',
                    },
                  })
                  if (close) {
                    close()
                  }
                }}
                variant="secondary"
              >
                <Paragraph>Close without save</Paragraph>
              </Button>
            </Group>
            <Group flexDirection="column" spacing={1}>
              <VesselQuestionnaireMissingFields section={section} />
            </Group>
          </Box>
        }
      />
    </FormProvider>
  )
}
