import { FC, MouseEvent, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { IVesselFile } from '~api/vessels/read'
import { Box } from '~components/atoms/box'
import { Button } from '~components/atoms/button'
import { Icon } from '~components/atoms/icon'
import { Link } from '~components/atoms/link'
import { Text } from '~components/atoms/text'
import { Group } from '~components/molecules/group/group'

import { StyledDropzone } from './styles'

type FileBoxStatuses =
  | 'empty'
  | 'pending_upload'
  | 'uploaded'
  | 'approve_or_reject'
  | 'approved'
  | 'pending_rejection'
  | 'rejected'

export interface IAdditionalFileBox {
  file?: IVesselFile
  isLoading?: boolean
  name: string
  onDownload?: (file?: IVesselFile) => void
  onRemove?: (file?: IVesselFile) => void
  onUpload?: (file?: File) => void
  status?: FileBoxStatuses
}

export const AdditionalFileBox: FC<IAdditionalFileBox> = ({
  onUpload,
  onRemove,
  file,
  onDownload,
  name,
  isLoading,
  status,
}) => {
  const [uploadFile, setUploadFile] = useState<File>()
  const [uploadStatus, setUploadStatus] = useState<FileBoxStatuses>(
    status ?? 'empty',
  )

  const onDrop = useCallback(
    (files: File[]) => {
      setUploadFile(files[0])
      setUploadStatus('pending_upload')
    },
    [setUploadFile],
  )

  const handleRemove = (event: MouseEvent) => {
    event.stopPropagation()
    setUploadFile(undefined)
    setUploadStatus('empty')
    onRemove?.(file)
  }
  const handleUpload = (event: MouseEvent) => {
    event.stopPropagation()
    onUpload?.(uploadFile)
  }

  const handleDownload = (event: MouseEvent) => {
    event.stopPropagation()
    onDownload?.(file)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  return (
    <StyledDropzone
      className="additional-file-box"
      {...getRootProps()}
      isDragActive={isDragActive}
    >
      <Box ph={4}>
        {uploadStatus === 'empty' && (
          // @ts-ignore
          <input id="file" name={name} style={{}} {...getInputProps()} />
        )}
        {/* 1. Choose a file */}
        {uploadStatus === 'empty' && (
          <Link>
            <Box
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
            >
              <Box flex=" 0 1 40px" mr={0.1}>
                <Icon name="plus" size={24} />
              </Box>
              <Text
                text="Click here or drag and drop to add a file"
                color="blues.mid"
              />
              <Group
                spacing={4}
                flex={0}
                flexDirection="row"
                alignItems="center"
              />
            </Box>
          </Link>
        )}
        {/* 2. Has picked a file */}
        {uploadStatus === 'pending_upload' && (
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box
              flexDirection="row"
              flex={1}
              justifyContent="space-between"
              alignItems="center"
              mr={4}
            >
              <Box flexDirection="row" alignItems="center" flex={0}>
                <Icon name="document" size={46} />
                <Text
                  text={uploadFile?.name ?? ''}
                  color="blues.mid"
                  whiteSpace="nowrap"
                />
              </Box>
              <Box flexDirection="row" alignItems="center" ml={2} flex={0}>
                <Icon name="close" size={16} />
                <Link onClick={handleRemove}>
                  <Text text="Remove" color="blues.mid" />
                </Link>
              </Box>
            </Box>
            <Button
              isLoading={isLoading}
              onClick={handleUpload}
              text="Upload"
              variant="solidSecondary"
            />
          </Box>
        )}
        {/* 3. Upload complete */}
        {(uploadStatus === 'uploaded' ||
          uploadStatus === 'approve_or_reject' ||
          uploadStatus === 'approved' ||
          uploadStatus === 'pending_rejection') && (
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box
              flexDirection="row"
              justifyContent="space-between"
              flex={1}
              alignItems="center"
            >
              <Box flexDirection="row" flex={0}>
                <Link onClick={handleDownload}>
                  <Text
                    text={file?.filename ?? ''}
                    color="blues.mid"
                    whiteSpace="nowrap"
                  />
                </Link>
              </Box>
              <Box flexDirection="row" alignItems="center" flex={0}>
                <Icon name="close" size={16} />
                <Link onClick={handleRemove}>
                  <Text text="Remove" color="blues.mid" />
                </Link>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </StyledDropzone>
  )
}
