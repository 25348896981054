import { useMatches } from 'react-router-dom'

export function useNavAndFooterConstants() {
  const matches = useMatches()
  // @ts-ignore
  const routeWithHamburgerMenu = matches.some((x) => x.handle?.withHamburger)

  const routeWithoutLeftNav = matches.some(
    // @ts-ignore
    (x) => x.handle?.disableLeftNavigation,
  )

  return {
    footerHeight: { xs: '168px', lg: '72px' },
    topNavigationHeight: {
      xs: routeWithHamburgerMenu ? '118px' : '102px',
      sm: routeWithHamburgerMenu ? '118px' : '102px',
      md: routeWithHamburgerMenu ? '103px' : '96px',
      lg: routeWithHamburgerMenu ? '103px' : '96px',
      xl: '72px',
      xxl: '72px',
      xxxl: '72px',
      xxxxl: '72px',
      xxxxxl: '72px',
    },
    topNavigationHeightRaw: {
      xs: routeWithHamburgerMenu ? 118 : 102,
      sm: routeWithHamburgerMenu ? 118 : 102,
      md: routeWithHamburgerMenu ? 103 : 96,
      lg: routeWithHamburgerMenu ? 103 : 96,
      xl: 72,
      xxl: 72,
      xxxl: 72,
      xxxxl: 72,
      xxxxxl: 72,
    },
    leftNavigationWidth: { xs: 0, md: routeWithoutLeftNav ? 0 : '208px' },
  }
}
