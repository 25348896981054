import { CSSProperties } from 'react'
import styled, { CSSObject } from 'styled-components/macro'

import { Colors, Fonts } from '~theme/deprecated-vt/deprecated-vt-theme'

import { IText } from './index'

export const textColorMap = {
  'blues.base': Colors.blues.base,
  'blues.light': Colors.blues.light,
  'blues.mid': Colors.blues.mid,
  'blues.maersk': Colors.blues.maersk,
  white: Colors.white,
  'greens.light': Colors.greens.light,
  'greens.base': Colors.greens.base,
  'reds.base': Colors.reds.base,
  'blues.deep': Colors.blues.deep,
  'greys.dark': Colors.greys.dark,
  'greys.darker': Colors.greys.darker,
  'greys.ultraDark': Colors.greys.ultraDark,
  'greys.base': Colors.greys.base,
  black: Colors.black,
  red: Colors.reds.base,
  body: Colors.body,
  currentColor: 'currentColor',
  'blues.secondaryDark': Colors.blues.secondaryDark,
}

type StyledTextProps = Pick<
  IText,
  | 'color'
  | 'weight'
  | 'headline'
  | 'size'
  | 'whiteSpace'
  | 'textTransform'
  | 'textAlign'
>

export const styles = {
  text: ({
    weight,
    size,
    color,
    headline,
    whiteSpace,
    textTransform,
    textAlign,
  }: StyledTextProps): CSSProperties => {
    const fontFamilyMap = headline ? Fonts.families.headlines : Fonts.families

    return {
      whiteSpace,
      textTransform,
      textAlign,
      fontFamily: (weight && fontFamilyMap[weight]) || fontFamilyMap.regular,
      fontSize: (size && Fonts.sizes[size]) || Fonts.sizes.body,
      lineHeight: (size && Fonts.lineHeights[size]) || Fonts.lineHeights.body,
      color: textColorMap[color as keyof typeof textColorMap],
    }
  },
}

export const StyledText = styled.span<StyledTextProps>`
  ${(p: StyledTextProps) => styles.text(p) as CSSObject}
`
