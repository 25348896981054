import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from 'src/constants/constants'
import { ApiConfig } from '~api/config'
import { IUserRolesReadResponse } from '~api/user/roles/read'
import { useFetch } from '~utils/fetch-request'

export function UseGetRoles() {
  const fetchRequest = useFetch<IUserRolesReadResponse>()
  return useQuery({
    queryKey: ['roles'],
    queryFn: () => fetchRequest(`${ApiConfig.domain}/api/roles`),
    staleTime: tenMinutes,
  })
}
