import { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { Text } from '~components/atoms/text'

interface IFormError {
  message?: string
  name: string
  type?: string
}

export const FormError: FC<IFormError> = ({ name, type, message }) => {
  const {
    setError,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    setError(name, {
      type: type,
      message: message,
    })
  }, [setError])

  return errors[name] ? (
    <Text
      text={errors[name]?.message as unknown as string}
      size="small"
      color="reds.base"
    />
  ) : null
}
