import { PaletteOptions } from '@mui/material'
import { COLORS } from '~theme/colors'

// MUI components use the palette colors by default
export const palette: PaletteOptions = {
  mode: 'light',
  common: {
    black: COLORS.secondary.darkBlue,
    white: COLORS.primary.white,
  },
  primary: {
    main: COLORS.primary.maerskBlue,
    light: COLORS.secondary.hoverBlue,
    dark: COLORS.secondary.darkBlue,
    contrastText: COLORS.primary.white,
  },
  error: {
    main: COLORS.red.tint2,
    contrastText: COLORS.secondary.darkBlue,
  },
  warning: {
    main: COLORS.orange.tint2,
    contrastText: COLORS.secondary.darkBlue,
  },
  info: {
    main: COLORS.secondary.lightBlue,
    contrastText: COLORS.secondary.darkBlue,
  },
  success: {
    main: COLORS.citrus.tint2,
    contrastText: COLORS.secondary.darkBlue,
  },
}
