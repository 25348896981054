import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { StyledParagraph } from '~components/atoms/typography/paragraph/paragraph.styles'

export const StyledAutoCompleteInputWrapper = styled.div`
  position: relative;

  > ${StyledParagraph} {
    margin-bottom: 10px;
    text-transform: uppercase;
  }
`

export const StyledMainPageAutoCompleteInput = styled(
  StyledAutoCompleteInputWrapper,
)`
  position: relative;
  width: 366px;
  max-width: 100%;
  margin-bottom: ${theme.SPACINGS[4]};
`
