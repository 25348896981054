import { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'

import { Text } from '~components/atoms/text'
import { Colors, Fonts } from '~theme/deprecated-vt/deprecated-vt-theme'

const StyledDivRT = styled.div`
  padding: 8px 12px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  text-transform: none;
  font-weight: 400;
  font-family: ${Fonts.families.regular};

  ul {
    margin: 0;
    padding: 0;
    text-align: left;

    li {
      list-style-type: none;
    }
  }

  table {
    th {
      text-transform: uppercase;
    }

    td,
    th {
      font-size: 12px;
      color: ${Colors.white};
      text-align: left;
    }
  }
`

export interface ITooltipContent {
  children?: ReactNode
  text?: string
}

export const TabTooltipDynamicContent: FC<ITooltipContent> = ({
  text,
  children,
}) => {
  return (
    <StyledDivRT>
      {text && <Text text={text} color="white" size="caption" />}
      {children && children}
    </StyledDivRT>
  )
}
