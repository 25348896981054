import { useBreakpoint, useTopBar } from '@maersktankersdigital/web-components'
import { Drawer, Toolbar } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useMatches } from 'react-router-dom'
import { useNavAndFooterConstants } from '~hooks/use-nav-and-footer-constants'
import { LeftNavigation } from './left-navigation/left-navigation'
import { TopNavigation } from './top-navigation/top-navigation'

const drawerWidth = 208

export function Navigation() {
  const [isOpen, setIsOpen] = useState(false)
  const { isMobileSize } = useBreakpoint()
  const matches = useMatches()
  const { handleOpenHamburgerMenu, isHamburgerMenuOpen } = useTopBar()
  const { topNavigationHeight } = useNavAndFooterConstants()
  const location = useLocation()
  useEffect(() => {
    if (isMobileSize && isHamburgerMenuOpen) {
      handleOpenHamburgerMenu()
    }
  }, [location])

  const routeWithoutLeftNav = matches.some(
    // @ts-ignore
    (x) => x.handle?.disableLeftNavigation,
  )

  return (
    <>
      <TopNavigation />
      {!routeWithoutLeftNav && (
        <Drawer
          anchor={isMobileSize ? 'top' : 'left'}
          variant={isMobileSize ? 'temporary' : 'permanent'}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            ['& .MuiDrawer-paper']: {
              width: isMobileSize ? '100%' : drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          open={isHamburgerMenuOpen}
          onClose={() => setIsOpen(!isOpen)}
        >
          <Toolbar sx={{ minHeight: topNavigationHeight }} />
          <LeftNavigation />
        </Drawer>
      )}
    </>
  )
}
