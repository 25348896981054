import { FC, forwardRef } from 'react'
import * as React from 'react'

import {
  StyledBasicCheckbox,
  StyledFileCheckbox,
  StyledFilterCheckbox,
} from '~components/atoms/checkbox/style'
import { Text } from '~components/atoms/text'

export enum Variants {
  basic = 'basic',
  file = 'file',
  filters = 'filters',
}

export interface ICheckbox {
  checked?: boolean
  innerRef?: React.ForwardedRef<HTMLInputElement>
  isControlledManually?: boolean
  isDisabled?: boolean
  label?: string
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  value: string
  variant?: keyof typeof Variants
}

const checkboxComponentVariants = {
  basic: StyledBasicCheckbox,
  file: StyledFileCheckbox,
  filters: StyledFilterCheckbox,
}
export const Checkbox: FC<ICheckbox> = forwardRef<HTMLInputElement, ICheckbox>(
  (
    {
      name,
      onChange,
      onClick,
      value,
      checked,
      label,
      isDisabled,
      isControlledManually,
      variant = Variants.basic,
    },
    innerRef,
  ) => {
    const StyledVariantCheckbox = checkboxComponentVariants[variant]
    return (
      <>
        {checked}
        <StyledVariantCheckbox
          type="checkbox"
          id={`${name}-${value}`}
          name={name}
          defaultChecked={!isControlledManually ? checked : undefined}
          checked={isControlledManually ? checked : undefined}
          ref={innerRef}
          value={value}
          isDisabled={isDisabled}
          onChange={onChange}
          onClick={onClick}
        />
        <label htmlFor={`${name}-${value}`}>
          <Text
            text={label || value}
            size={variant === 'filters' ? 'small' : 'body'}
            whiteSpace="nowrap"
          />
        </label>
      </>
    )
  },
)
