import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { apiUpdateAction } from '~api/actions'

export interface IChangeRequestResponse {
  createdAt: string
  explanation: string
  title: string
}

export type IChangeRequestPayload = {
  explanation: string
  title: string
}

export function vesselTabsChangeRequestAction(
  payload: IChangeRequestPayload,
  urlParams?: Params,
): Promise<IChangeRequestResponse> {
  return apiUpdateAction<IChangeRequestPayload, IChangeRequestResponse>(
    ApiRoutes.Vessels.Tabs.changeRequest,
    payload,
    urlParams,
  )
}
