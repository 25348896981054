import styled from 'styled-components/macro'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export const StyledDivider = styled.div<{ index: number }>`
  display: inline-block;
  margin: 60px 0 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    180deg,
    ${Colors.greys.lightest} 50%,
    ${Colors.greys.light} 50.5%
  );
  ${({ index }) => {
    return (
      index % 2 &&
      `background: linear-gradient(
            180deg,
            ${Colors.greys.light} 50%,
            ${Colors.greys.lightest} 50.5%
        );`
    )
  }}
`
