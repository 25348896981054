import { PoolPointsVersion } from '~types/pool-points.types'

export function getDateToVersion(date: Date) {
  const year = date.toLocaleString('default', { year: 'numeric' })
  const month = date.toLocaleString('default', { month: '2-digit' })
  const day = date.toLocaleString('default', { day: '2-digit' })

  return year + month + day
}

export function getVersionToDate(version: string) {
  // Version format: 'YYYYMMDD'
  const year = version.substring(0, 4)
  const month = version.substring(4, 6)
  const day = version.substring(6, 8)

  return new Date(`${year}-${month}-${day}`)
}

export function getHighlightedDatepickerDates(
  versions: PoolPointsVersion[] | undefined,
) {
  if (!versions || versions.length === 0) return []

  const versionDates = Array.from(versions).map(({ version }) =>
    getVersionToDate(version),
  )

  return versionDates as Date[]
}
