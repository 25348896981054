export const patchVesselsStatusResMock = {
  _id: '6744781b3406d85e642acc87',
  imoNumber: '1111131',
  poolPartner: 'ALM',
  track: 'earnings-simulation',
  pool: 'Flexi',
  partnerManager: 'auth0|660e6ab8c6fc95e032f1b2c5',
  status: 'onboarding',
  createdAt: '2024-11-25T13:14:03.851Z',
  createdBy: 'auth0|660e6ab8c6fc95e032f1b2c5',
  vesselName: 'Test Corniche QA',
  progress: null,
}
