import { SVGProps } from 'react'
import styled from 'styled-components/macro'

interface ISVGProps extends SVGProps<SVGSVGElement> {
  className?: string
  size?: number
  title?: string
}

export const Save = (props: ISVGProps) => (
  <svg
    width={props.size || '1em'}
    height={props.size || '1em'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M12.667 14H3.333A1.334 1.334 0 0 1 2 12.667V3.333A1.333 1.333 0 0 1 3.333 2h7.334L14 5.333v7.334A1.334 1.334 0 0 1 12.667 14Z"
      stroke="currentColor"
      strokeWidth={0.8}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.333 14V8.667H4.667V14M4.667 2v3.333H10"
      stroke="currentColor"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const SaveIcon = styled(Save)`
  vertical-align: middle;
  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
`
