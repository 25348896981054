import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@maersktankersdigital/web-components'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <Button
      data-cy="login-button"
      variant="primary"
      onClick={() => {
        loginWithRedirect({ appState: { target: 'redirectURL' } })
      }}
    >
      Log In
    </Button>
  )
}

export default LoginButton
