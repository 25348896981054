import {
  EditIcon,
  FileCopyIcon,
  TextButton,
  Typography,
} from '@maersktankersdigital/web-components'
import { format } from 'date-fns'
import * as React from 'react'
import { useRef } from 'react'
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard'

import { RouterLink } from '~components/atoms/router-link'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import {
  VoyageCell,
  VoyageHeader,
  VoyageHeaderWrapper,
  VoyagesPopupContentWrapper,
  VoyageTable,
} from '~pages/pages-behind-login/position-list/position-list-page/position-list-table/l3ccontent/l3ccontent.styles'
import { trackCopiedVoyages } from '~pages/pages-behind-login/position-list/tracking/track-copied-voyages'
import { LastThreeCargoes } from '~pages/pages-behind-login/position-list/types/position-list-types'
import { Voyage } from '~types/itinerary.types'
import { capitalizeStringWords } from '~utils/capitalize-string-words'

interface Props {
  internalLastThreeCargoes?: LastThreeCargoes
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  vesselName: string
  voyages?: Voyage[]
}

const tableHeaders = [
  'CP Date',
  'Charterer',
  'Load Port',
  'Discharge port',
  'Cargo Grade',
]

const renderAsAHTML = (htmlString: string) => ({ __html: htmlString })

function VoyagesPopupContent({
  internalLastThreeCargoes,
  setEditModalOpen,
  vesselName,
  voyages,
}: Props) {
  const hiddenAreaWithCopiedDataRef = useRef<HTMLDivElement>(null)
  const [, copy] = useCopyToClipboard()
  const { setStatus } = useAnimatedAlert()
  const isPositionListUser = useHasScope(ExactScopeName.positionListUpdate)
  const internalLastThreeCargoesArray =
    internalLastThreeCargoes?.lastThreeCargoes?.split('/')

  const handleCopy = () => {
    const html = hiddenAreaWithCopiedDataRef.current?.innerHTML || ''
    copy(html, true, () => setStatus('Copied to clipboard!'))

    trackCopiedVoyages(vesselName)
  }

  const handleOpenEditModal = () => {
    setEditModalOpen(true)
  }

  return (
    <VoyagesPopupContentWrapper>
      <VoyageHeaderWrapper>
        <Typography variant="headline3">Last three voyages</Typography>
        <TextButton
          variant="primary"
          onClick={() => handleCopy()}
          icon={<FileCopyIcon size={16} />}
        >
          Copy to clipboard
        </TextButton>
      </VoyageHeaderWrapper>
      <VoyageTable>
        <thead>
          <tr>
            {tableHeaders.map((header) => (
              <VoyageHeader key={header}>{header}</VoyageHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {voyages?.map((voyage: Voyage, i: number) => {
            if (!voyage.cpDate) return null

            return (
              <tr key={i}>
                <VoyageCell>
                  <RouterLink
                    to={`https://veslink.com/#operations/voyages/${voyage.vesselCode}/${voyage.voyageNumber}`}
                    target="_blank"
                    externalLink
                  >
                    {format(new Date(voyage.cpDate), 'dd-MM-yyyy')}
                  </RouterLink>
                </VoyageCell>
                <VoyageCell>
                  {capitalizeStringWords(
                    voyage.chartererShortName.split('_')[0],
                  )}
                </VoyageCell>
                <VoyageCell>
                  {capitalizeStringWords(voyage.loadPort)}
                </VoyageCell>
                <VoyageCell>
                  {capitalizeStringWords(voyage.dischargePort)}
                </VoyageCell>
                <VoyageCell>{voyage.productCodes}</VoyageCell>
              </tr>
            )
          })}
        </tbody>
      </VoyageTable>
      {isPositionListUser && (
        <TextButton
          variant="primary"
          onClick={handleOpenEditModal}
          icon={<EditIcon size={16} />}
          style={{ marginLeft: 'auto' }}
        >
          Edit L3C
        </TextButton>
      )}

      <div style={{ display: 'none' }} ref={hiddenAreaWithCopiedDataRef}>
        {voyages?.map((voyage, i) => {
          if (!voyage.cpDate) return null

          return (
            <div key={i}>
              <span
                dangerouslySetInnerHTML={renderAsAHTML(
                  voyage.chartererShortName + ' / ',
                )}
              />
              <span
                dangerouslySetInnerHTML={renderAsAHTML(voyage.loadPort + ' / ')}
              />
              <span
                dangerouslySetInnerHTML={renderAsAHTML(
                  voyage.dischargePort + ' / ',
                )}
              />
              <span
                dangerouslySetInnerHTML={renderAsAHTML(
                  internalLastThreeCargoesArray?.[i]?.trim() ||
                    voyage.productCodes,
                )}
              />
              <br />
            </div>
          )
        })}
      </div>
    </VoyagesPopupContentWrapper>
  )
}

export default VoyagesPopupContent
