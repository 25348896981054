import styled from 'styled-components/macro'

export const StyledSpinner = styled.svg`
  animation: rotation 0.7s infinite linear;
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
`
