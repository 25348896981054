import {
  NotificationProps,
  NotificationVariant,
  Notification as WCNotification,
} from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { isAfter, isBefore } from 'date-fns'
import { useEffect, useState } from 'react'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { useIsActiveUser } from '~hooks/permissions/use-is-active-user'
import { localStorageItemKeys } from '../../constants/local-storage-items'
import {
  AlertNotification,
  NotificationVariantAPI,
} from '../../hooks/queries/use-notification-banner'
import { usePoolId } from '../../hooks/use-pool-id-and-name'
import { getNotificationsFromLocalStorage } from './notifications-banner'

export function Notification({
  id,
  type,
  startDate,
  endDate,
  headline,
  text,
  canDismiss,
  isActive,
  pools,
  pages,
}: AlertNotification) {
  const [isActivePeriod, setIsActivePeriod] = useState<boolean>(false)
  const [isNotificationHidden, setIsNotificationHidden] =
    useState<boolean>(false)
  const [isAllowedPage, setIsAllowedPage] = useState<boolean>(true)
  const location = useLocation()
  const poolId = usePoolId()
  const { pool: poolParam } = useParams()
  const { isActiveUser } = useIsActiveUser(poolParam)

  useEffect(() => {
    if (!isActiveUser) {
      setIsAllowedPage(false)
      return
    }

    setIsAllowedPage(isPageWithNotification({ poolId, pages, poolIds: pools }))

    if (isActive) {
      const isTodayAfterStart = isAfter(new Date(), new Date(startDate))
      // though the endDate is not provided the notification should be shown
      const isTodayBeforeEnd = endDate
        ? isBefore(new Date(), new Date(endDate))
        : true
      setIsActivePeriod(isTodayAfterStart && isTodayBeforeEnd)
    }
  }, [location.pathname, poolId, isActiveUser])

  function hideNotification() {
    setIsNotificationHidden(true)
    const hiddenNotifications = getNotificationsFromLocalStorage()
    localStorage.setItem(
      localStorageItemKeys.notificationBanners,
      JSON.stringify([...hiddenNotifications, id]),
    )
  }

  if (
    !headline ||
    !text ||
    isNotificationHidden ||
    !isActive ||
    !isActivePeriod ||
    !isAllowedPage
  ) {
    return null
  }

  const mapAPItype = (type: NotificationVariantAPI): NotificationVariant => {
    switch (type) {
      case 'information':
        return 'info'
      case 'critical':
        return 'error'
      case 'warning':
        return 'warning'
      case 'success':
        return 'success'
      default:
        return 'info'
    }
  }

  const WCNotificationProps: NotificationProps = {
    headline,
    text,
    variant: mapAPItype(type),
    onClick: canDismiss ? hideNotification : undefined,
  }

  return (
    <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
      <WCNotification {...WCNotificationProps} />
    </Box>
  )
}

export function isPageWithNotification({
  poolId,
  pages,
  poolIds,
}: {
  poolId: number | undefined
  pages: string[]
  poolIds: number[]
}) {
  if (poolId) {
    if (!poolIds.includes(poolId)) {
      return false
    }
  }

  for (const page of pages) {
    const match = matchPath(page, location.pathname)

    if (match) {
      return true
    }
  }

  return false
}
