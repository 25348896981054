import { apiGetAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'

import { Role } from '../../../context/user-provider'

export interface IUserRolesReadResponse {
  current: string[]
  poolpartner: Role[]
  scope: string[]
  sub: string
  vessel: Role[]
}

export function userRolesReadAction(): Promise<IUserRolesReadResponse> {
  return apiGetAction<IUserRolesReadResponse>(ApiRoutes.User.Roles.read)
}
