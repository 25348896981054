import { lazy } from 'react'

import { ExtendedRouteObject } from './routes'

export const openPages: ExtendedRouteObject[] = [
  {
    path: 'callback',
    Component: lazy(() => import('../pages/auth/callback-page')),
    handle: { disableLeftNavigation: true },
  },
  {
    path: 'contact',
    Component: lazy(() => import('../pages/open-pages/contact/contact')),
    handle: { disableLeftNavigation: true },
  },
  {
    path: 'terms-and-conditions',
    Component: lazy(
      () =>
        import('../pages/open-pages/terms-and-conditions/terms-and-conditions'),
    ),
    handle: { disableLeftNavigation: true },
  },
  {
    path: 'legal-notice',
    Component: lazy(
      () => import('../pages/open-pages/legal-notice/legal-notice'),
    ),
    handle: { disableLeftNavigation: true },
  },
  {
    path: 'privacy-policy',
    Component: lazy(
      () => import('../pages/open-pages/privacy-policy/privacy-policy'),
    ),
    handle: { disableLeftNavigation: true },
  },
]
