import { apiPostAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'
import { ISuccessResponse } from '~api/types'

export type IUserRoleAddPayload = {
  sub: string
} & ({ grant: string } | { revoke: string } | { sub: string }) &
  ({ imo: string } | { poolPartnerKey: string } | { sub: string })

export function userRoleAddAction(
  payload: IUserRoleAddPayload,
): Promise<ISuccessResponse> {
  return apiPostAction<IUserRoleAddPayload, ISuccessResponse>(
    ApiRoutes.User.Roles.add,
    payload,
  )
}
