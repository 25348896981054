import { ApiRoutes } from '~api'
import { apiPostAction } from '~api/actions'

import { IUserSettings } from '../read'

export interface IUserInfoEditResponse {
  email: string
  name?: string
  phoneNumber?: string
  secondaryPhoneNumber?: string
  statusCode?: string
  sub: string
}

export interface IUserInfoEditPayload {
  name?: string
  phoneNumber?: string
  secondaryPhoneNumber?: string
  settings?: IUserSettings
}

export function userInfoEditAction(
  payload: IUserInfoEditPayload,
): Promise<IUserInfoEditResponse> {
  return apiPostAction<IUserInfoEditPayload, IUserInfoEditResponse>(
    ApiRoutes.User.Info.update,
    payload,
  )
}
