import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  gap: ${theme.SPACINGS[0]};
`
