import { PP_BASE_POINTS } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-table/pool-points-table.config'
import { PoolPoints } from '~types/pool-points.types'

export const getMaxPossiblePoints = (poolPoints: PoolPoints | undefined) =>
  Object.values(poolPoints || {})
    // @ts-ignore
    .map((data) => data?.range?.max)
    .filter(Boolean)
    .reduce((prevVal, range) => prevVal + range, 0)

export const getPoolPointsResultLiteral = (
  poolPoints: PoolPoints,
  result: number | string,
  addBasePoints?: boolean,
) =>
  `${result.toString()} out of ${
    getMaxPossiblePoints(poolPoints) + (addBasePoints ? PP_BASE_POINTS : 0)
  } possible points`
