import { Params } from 'react-router-dom'

import { PageRoute } from '../routes/routes-behind-login/vt-routing/constants/page-route'

export function getActiveRoute(
  pathname: string,
  params?: Readonly<Params<string>>,
): [string | null, PageRoute | null] {
  if (pathname === PageRoute.LANDING) return ['LANDING', PageRoute.LANDING]

  const routeKey = Object.keys(PageRoute).find((key) => {
    const route = PageRoute[key as keyof typeof PageRoute]

    if (pathname === PageRoute.LANDING) return true

    if (params) {
      const usesPageSlug = Object.keys(params).includes('*')

      if (usesPageSlug) {
        return params['*']?.split('/').some((str) => str === route)
      }

      const matchingUrl = route
        .split('/')
        .map((routeChunk: string) => {
          const isChunkParam = routeChunk.includes(':')

          if (isChunkParam) {
            const paramKeyMatch = Object.keys(params).find(
              (key) => key === routeChunk.replace(':', ''),
            )

            if (paramKeyMatch) {
              const urlParamReplacement = params[paramKeyMatch]

              if (urlParamReplacement) {
                return encodeURIComponent(urlParamReplacement)
              }
            }
          }

          return routeChunk
        })
        .join('/')

      return pathname === matchingUrl
    }

    return pathname === route
  })

  return [
    routeKey ?? null,
    routeKey ? PageRoute[routeKey as keyof typeof PageRoute] : null,
  ]
}
