import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'

import { StyledParagraph } from '~components/atoms/typography/paragraph/paragraph.styles'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

export const StyledTagFilter = styled.div`
  line-height: 1;
  user-select: none;
  margin-right: ${theme.SPACINGS[4]};

  ${StyledParagraph} {
    display: inline-block;
  }

  p {
    text-transform: uppercase;
  }

  span {
    cursor: pointer;
  }
`

export const StyledTagFilterTag = styled.div<{ isActive: boolean }>`
  display: inline-block;
  background: ${(p) =>
    p.isActive ? COLORS.primary.maerskBlue : COLORS.greys.ultraLight};
  margin-right: 8px;
  padding: 16px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 8px;

  :hover {
    background: ${(p) =>
      p.isActive ? COLORS.secondary.actionBlue : COLORS.secondary.lightBlue};
    span {
      color: ${Colors.body};
    }
  }

  ${StyledParagraph} {
    text-transform: none;
  }

  span {
    color: ${(p) => (p.isActive ? Colors.white : Colors.body)};
  }
`
