import { FC, memo } from 'react'

import { iconsMap } from '~assets/svgs'
import { Box } from '~components/atoms/box'
import { Icon } from '~components/atoms/icon'
import {
  StyledActiveCDIsLabel,
  StyledBulletLabel,
  StyledDashboardLabel,
  StyledDocumentsTabLabel,
  StyledRejectionLabel,
  StyledVesselOnboardedLabel,
  StyledVettingDischargeLabel,
  StyledVettingIdleLabel,
  StyledVettingLoadLabel,
  StyledVettingNALabel,
  StyledVettingRemoteLabel,
  StyledVettingUnplannedLabel,
} from '~components/atoms/info-label/styles'

import { Text } from '../text'

export enum Variants {
  Bullet = 'Bullet',
  Rejection = 'Rejection',
  DocumentsTab = 'DocumentsTab',
  Load = 'Load',
  Idle = 'Idle',
  Unplanned = 'Unplanned',
  Planned = 'Planned',
  Discharge = 'Discharge',
  Remote = 'Remote',
  NotApplicable = 'NotApplicable',
  Onboarded = 'Onboarded',
  ActiveCDIs = 'ActiveCDIs',
  VesselDashboard = 'VesselDashboard',
}

interface IInfoLabel {
  currentRejection?: boolean | string
  icon?: keyof typeof iconsMap
  iconMargin?: number
  isQ88Source?: boolean
  labelColor?: string
  text?: string
  variant: keyof typeof Variants
}

const labelComponentVariants = {
  Bullet: StyledBulletLabel,
  Rejection: StyledRejectionLabel,
  DocumentsTab: StyledDocumentsTabLabel,
  Load: StyledVettingLoadLabel,
  Idle: StyledVettingIdleLabel,
  Unplanned: StyledVettingUnplannedLabel,
  // planned is something strange - out of the design
  Planned: StyledVettingUnplannedLabel,
  Discharge: StyledVettingDischargeLabel,
  Remote: StyledVettingRemoteLabel,
  NotApplicable: StyledVettingNALabel,
  Onboarded: StyledVesselOnboardedLabel,
  ActiveCDIs: StyledActiveCDIsLabel,
  VesselDashboard: StyledDashboardLabel,
}

export const InfoLabel: FC<IInfoLabel> = memo(
  ({
    icon,
    iconMargin,
    text,
    variant = Variants.DocumentsTab,
    currentRejection,
    isQ88Source,
    labelColor,
  }) => {
    const StyledVariantLabel = labelComponentVariants[variant]

    if (!text) return null

    return (
      <StyledVariantLabel
        currentRejection={!!currentRejection}
        isQ88Source={isQ88Source}
        labelColor={labelColor}
      >
        {icon && <Icon name={icon} fill="currentColor" size={10} />}
        {icon && <Box mr={iconMargin || 0.8} />}
        <Text
          text={text}
          size="caption"
          color="currentColor"
          weight={variant === Variants.VesselDashboard ? 'bold' : 'regular'}
        />
      </StyledVariantLabel>
    )
  },
)
