import { COLORS } from '~theme/colors'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

interface footerLinksI {
  link: string
  label: string
}

const footerLinks: footerLinksI[] = [
  { link: '/contact', label: 'Contact' },
  { link: '/terms-and-conditions', label: 'Terms & Conditions' },
  { link: '/legal-notice', label: 'Legal Notice' },
  { link: '/privacy-policy', label: 'Privacy Policy' },
]

export function FooterLinks() {
  const { isAuthenticated } = useAuth0()

  if (isAuthenticated) {
    const index = footerLinks.findIndex((x) => x.label === 'Contact')
    if (index > -1) {
      footerLinks.splice(index, 1)
    }
  }

  return (
    <Box
      sx={{
        display: { xs: 'flex', lg: 'initial' },
        flexDirection: 'column',
      }}
    >
      {footerLinks.map(({ link, label }) => {
        return (
          <Link
            key={label}
            component={RouterLink}
            to={link}
            sx={{
              mt: { xs: 3, lg: 0 },
              ml: { xs: 0, lg: 6 },
              textDecoration: 'none',
              color: COLORS.primary.white,
              '&: hover': { color: COLORS.primary.maerskBlue },
            }}
            variant="paragraph1"
          >
            {label}
          </Link>
        )
      })}
    </Box>
  )
}
