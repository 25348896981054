import { useEventListener } from '@maersktankersdigital/web-components'
import { Fragment, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { AutoCompleteInputOptions } from '~components/molecules/auto-complete-input/auto-complete.types'
import { useAutoCompleteInputState } from '~components/molecules/auto-complete-input/auto-complete-input-provider'

import {
  StyledAutoCompleteInputOptionValue,
  StyledAutoCompleteInputResult,
  StyledAutoCompleteInputResults,
} from './auto-complete-input-results.styles'

type AutoCompleteInputResultsProps = {
  id: string
  results: AutoCompleteInputOptions[]
}

const AutoCompleteInputResults = ({
  id,
  results,
}: AutoCompleteInputResultsProps) => {
  const state = useAutoCompleteInputState()
  const {
    activeResult,
    setActiveResult,
    setInputValue,
    setResultsById,
    setIsOpen,
    setSelectedResult,
  } = state

  const [wrapperEl, setWrapperEl] = useState<HTMLElement | null>(null)
  const [activeItemEl, setActiveItemEl] = useState<HTMLLIElement | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [activeKey, setActiveKey] = useState<{
    key: string
  } | null>(null)

  const handleResultClick = (config: AutoCompleteInputOptions, id: string) => {
    const newActiveResult = config?.searchableItems
    if (!newActiveResult?.length) return
    const newInputValue = newActiveResult
      ?.filter((result) => !result.isHidden)
      .map(({ value }) => value)
      .join(' ')

    setActiveResult(newActiveResult)
    setInputValue(newInputValue)

    setIsOpen(false)

    setResultsById(id, [config])
  }

  useEventListener('keydown', (e) => {
    setActiveKey({ key: e.key })

    const isEsc = e.key === 'Escape' || e.key === 'Esc'
    const isTab = e.key === 'Tab'
    const isEnter = e.key === 'Enter'
    const isArrowUp = e.key === 'ArrowUp'
    const isArrowDown = e.key === 'ArrowDown'

    if (isArrowUp || isArrowDown) {
      // Handles which item is selected in the results dropdown
      if (isArrowUp) {
        setSelectedIndex(Math.max(selectedIndex - 1, 0))
      } else {
        setSelectedIndex(Math.min(selectedIndex + 1, results.length - 1))
      }
    }

    if (isEsc || isTab || isEnter) {
      if ((isEnter && selectedIndex === 0) || selectedIndex) {
        handleResultClick(results[selectedIndex], id)
      }

      setSelectedIndex(0)
      return setIsOpen(false)
    }

    return
  })

  // Handles up/down key events and the scroll position of the results list
  useEffect(() => {
    if (!activeKey) return

    setSelectedResult(selectedIndex)

    if (!activeItemEl || !wrapperEl) return

    const isArrowUp = activeKey.key === 'ArrowUp'
    const isArrowDown = activeKey.key === 'ArrowDown'

    if (isArrowDown) {
      const scrollDownSum =
        activeItemEl.offsetTop - wrapperEl.scrollTop + activeItemEl.clientHeight

      if (scrollDownSum <= wrapperEl.clientHeight) return

      wrapperEl.scrollTo(0, wrapperEl.scrollTop + activeItemEl.clientHeight)
    }

    if (isArrowUp) {
      const scrollUpSum = activeItemEl.offsetTop - wrapperEl.scrollTop

      if (scrollUpSum >= 0) return

      wrapperEl.scrollTo(0, wrapperEl.scrollTop + scrollUpSum)
    }
  }, [activeKey, activeItemEl, selectedIndex])

  return (
    <StyledAutoCompleteInputResults ref={setWrapperEl}>
      {results.map((config, index) => {
        const OptionValueWrapper = config.href ? NavLink : Fragment
        const optionIndex = `${id}-${index}`

        return (
          <StyledAutoCompleteInputResult
            key={optionIndex}
            // @ts-ignore
            ref={config.isSelected ? setActiveItemEl : null}
            onClick={() => handleResultClick(config, id)}
            isSelected={config.isSelected}
          >
            {/* @ts-ignore */}
            <OptionValueWrapper {...(config.href && { to: config.href })}>
              {config.searchableItems.map(
                (searchableItem, searchableItemIndex) => {
                  if (searchableItem.isHidden) return

                  const optionValueIndex = `${optionIndex}-${searchableItemIndex}`
                  const { chunkConfigList, value, color } = searchableItem

                  return (
                    <StyledAutoCompleteInputOptionValue
                      key={optionValueIndex}
                      color={color}
                    >
                      {!activeResult &&
                      chunkConfigList &&
                      chunkConfigList.length > 0 ? (
                        chunkConfigList.map(
                          ({ fontWeight, value }, chunkConfigIndex) => {
                            const optionValueChunkIndex = `${optionValueIndex}-${chunkConfigIndex}`

                            return (
                              <Paragraph
                                key={optionValueChunkIndex}
                                as="span"
                                weight={fontWeight}
                                size="xSmall"
                              >
                                {value}
                              </Paragraph>
                            )
                          },
                        )
                      ) : (
                        <Paragraph
                          as="span"
                          weight={activeResult ? 'bold' : 'normal'}
                          size="xSmall"
                        >
                          {value}
                        </Paragraph>
                      )}
                    </StyledAutoCompleteInputOptionValue>
                  )
                },
              )}
            </OptionValueWrapper>
          </StyledAutoCompleteInputResult>
        )
      })}
    </StyledAutoCompleteInputResults>
  )
}

export default AutoCompleteInputResults
