import { format, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'
import { useApi } from 'src/hooks/use-api'
import { COLORS } from '~theme/colors'

import { ApiRoutes } from '~api'
import { IVesselsReadResponse } from '~api/vessels/read'
import { Box } from '~components/atoms/box'
import { InfoLabel } from '~components/atoms/info-label/info-label'

export function DeliveryLabel() {
  const { vesselId } = useParams()

  const { data: vesselData } = useApi<IVesselsReadResponse>(
    ApiRoutes.Vessels.read,
    {
      vesselId,
    },
  )

  // Delivery data only shown on vessels with TCIN status "Fixed".

  if (
    !vesselData?.deliveryDate ||
    !vesselData?.tcin ||
    vesselData?.tcin.length === 0 ||
    vesselData?.tcin[vesselData?.tcin.length - 1].status !== 'Fixed'
  )
    return <></>

  return (
    <Box flex={0} alignSelf="flex-start">
      <InfoLabel
        labelColor={COLORS.orange.tint1}
        text={`To be delivered on ${format(
          parseISO(vesselData.deliveryDate),
          'd-M-yyyy',
        )} `}
        variant="VesselDashboard"
      />
    </Box>
  )
}
