export const spacing = [
  0, // 0
  4, // 1
  8, // 2
  16, // 3
  24, // 4
  32, // 5
  40, // 6
  48, // 7
  56, // 8
  64, // 9
  72, // 10
  80, // 11
  88, // 12
  96, // 13
  104, // 14
  112, // 15
  120, // 16
  128, // 17
  136, // 18
  144, // 19
  152, // 20
  160, // 21
  168, // 22
  176, // 23
  184, // 24
  192, // 25
  200, // 26
  208, // 27
  216, // 28
  224, // 29
  232, // 30
]
