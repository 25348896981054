import { apiUploadFileAction } from '~api/actions'
import { ApiRoutes } from '~api/routes'
import { ISuccessResponse } from '~api/types'

export type IFileAddPayload = {
  field?: string
  file?: File
  imoNumber?: string
  pool?: string
  subtype?: 'general' | 'vessel' | 'pool'
  type: 'onboarding'
}

export function fileAddAction(
  payload: IFileAddPayload,
): Promise<ISuccessResponse> {
  return apiUploadFileAction<IFileAddPayload, ISuccessResponse>(
    ApiRoutes.Files.add,
    payload,
  )
}
