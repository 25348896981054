import { ChevronDownIcon } from '@maersktankersdigital/web-components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material'
import { LabelValue } from './label-value'

export interface CookieDetailsAccordionProps {
  title: string
  description: string
  cookies: {
    dataProcessor: string
    purpose: string
    dataProcessorPrivacyPolicy: {
      label: string
      link: string
    }
    expiry: string
    cookieName: string
    provider: string
    note?: string
  }[]
}

export function CookieDetailsAccordion({
  title,
  description,
  cookies,
}: CookieDetailsAccordionProps) {
  return (
    <Accordion variant="secondary" sx={{ pt: 3 }}>
      <AccordionSummary
        expandIcon={<ChevronDownIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="paragraphBold2" sx={{ mb: 2 }}>
            {title}
          </Typography>
          <Typography variant="caption">{description}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ pb: '16px !important' }}>
        {cookies.length === 0 && (
          <Typography variant="caption">
            The website does not use any {title} cookies.
          </Typography>
        )}
        {cookies.map((item, index) => {
          const {
            cookieName,
            dataProcessor,
            purpose,
            dataProcessorPrivacyPolicy,
            expiry,
            provider,
            note,
          } = item

          return (
            <Box key={cookieName}>
              {index !== 0 && <Divider sx={{ my: 3 }} />}
              <LabelValue label="Data Processor" value={dataProcessor} />
              <LabelValue label="Purpose" value={purpose} />
              <LabelValue
                label="Data Processor Privacy Policy"
                value={dataProcessorPrivacyPolicy.label}
                valueLink={dataProcessorPrivacyPolicy.link}
              />
              <LabelValue label="Expiry" value={expiry} />
              <LabelValue label="Cookie Name" value={cookieName} />
              <LabelValue label="Provider" value={provider} />
              {note && (
                <Typography variant="caption" component="p" sx={{ pt: 2 }}>
                  * {note}
                </Typography>
              )}
            </Box>
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}
