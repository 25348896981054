import { Role } from '~api/user/read'
import { useGetMeVT } from '../queries/me/use-get-me-vt'

export function useHasRole(...props: Role[]) {
  const { data, isLoading } = useGetMeVT()
  if (isLoading) return undefined
  if (!data?.roles) return false
  return props.some((prop) => hasRole({ role: prop, roles: data.roles }))
}

export function hasRole({ role, roles }: { role: Role; roles?: Role[] }) {
  if (!roles?.length) return false

  const { entity, id, role: userRole } = role
  const entityMatch = roles.some((item) => item.entity === entity)
  const idMatch = !id ? true : roles.some((item) => item.id === id)
  const roleMatch = !role ? true : roles.some((item) => item.role === userRole)

  return entityMatch && idMatch && roleMatch
}
