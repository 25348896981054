import { getTokenSilently } from '~utils/auth'
import { ApiConfig } from './config'

export const defaultFetcher = async (
  url: string,
  args: any,
): Promise<unknown> => {
  try {
    const token: string = await getTokenSilently()

    const handleUnauthorized = () => {
      // We don't have access to the router hooks here, so we need to manually use `browserHistory`.
      // Changing window.location.href would cause an infinite redirect
      // browserHistory.push(PageRoute.UNAUTHORIZED)
      return
    }

    if (!token) {
      return handleUnauthorized()
    } else {
      const res = await fetch(ApiConfig.domain + url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...args,
      })

      return res.json().then(function (response) {
        if (res.status === 401) {
          handleUnauthorized()
        }
        if (response.error || !res.ok) {
          throw new Error(response.statusCode)
        }
        return response
      })
    }
  } catch (err) {
    throw new Error(url)
  }
}
