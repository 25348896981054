import styled from 'styled-components/macro'

import { Box } from '~components/atoms/box'
import { StyledParagraph } from '~components/atoms/typography/paragraph/paragraph.styles'

export const StyledErrorDisplay = styled(Box)<{ isPageError?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${StyledParagraph} {
    margin: 60px auto;
    line-height: 1;
    text-align: center;
  }

  button {
    width: auto;
    max-width: unset;

    ${StyledParagraph} {
      margin: 0;
    }
  }
`
