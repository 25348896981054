import { Params } from 'react-router-dom'

import { ApiRoutes } from '~api'
import { IVesselData } from '~types/vessel.types'

import { apiUpdateAction } from '../actions'
import { IVesselsErrorResponse } from './error'

export interface IVesselsUpdateVesselResponse extends IVesselData {
  _id?: string
  createdAt?: string
}

export interface IVesselsUpdateVesselPayloadBody extends IVesselData {}

export interface IVesselsUpdateVesselPayload {
  _id?: string
  body: IVesselsUpdateVesselPayloadBody
}

export interface IVesselStatusUpdatePayloadBody {
  archivedReason?: string
  status: string
}

export interface IVesselStatusUpdatePayload extends IVesselData {
  _id?: string
  body: IVesselStatusUpdatePayloadBody
}

export interface IVesselStatusUpdateResponse extends IVesselData {
  _id?: string
  onboardedDate?: string
}

export function vesselsUpdateAction(
  payload: IVesselsUpdateVesselPayload,
  urlParams?: Params,
): Promise<IVesselsUpdateVesselResponse | IVesselsErrorResponse> {
  return apiUpdateAction<
    IVesselsUpdateVesselPayloadBody,
    IVesselsUpdateVesselResponse
  >(ApiRoutes.Vessels.update, payload.body, urlParams)
}

export function vesselStatusUpdateAction(
  payload: IVesselStatusUpdatePayload,
  urlParams?: Params,
): Promise<IVesselsUpdateVesselResponse | IVesselsErrorResponse> {
  return apiUpdateAction<
    IVesselStatusUpdatePayloadBody,
    IVesselStatusUpdateResponse
  >(ApiRoutes.Vessels.status, payload.body, urlParams)
}
