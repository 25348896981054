import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'

import Wrapper from '~layout/wrapper/wrapper'
import { COLORS } from '~theme/colors'

export const axisStyles = {
  fontSize: theme.FONTS.sizes.xxs,
  fontFamily: theme.FONTS.families.light,
  color: COLORS.greys.dark,
}

export const StyledWrapper = styled(Wrapper)`
  .week-x-label {
    ${axisStyles}
  }

  .recharts-responsive-container {
    margin-bottom: ${theme.SPACINGS[6]};
  }

  padding-bottom: ${theme.SPACINGS[6]};
`

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.SPACINGS[1]};
  justify-content: space-between;
`

export const StyledTitle = styled.h2`
  font-family: ${theme.FONTS.families.regular};
  font-size: ${theme.FONTS.sizes.xl};
  font-weight: normal;
  color: ${COLORS.secondary.darkBlue};
  margin: 0;
`

export const TitleWrapper = styled.div`
  gap: ${theme.SPACINGS[1]};
  display: flex;
  align-items: center;
`

export const FormControls = styled.div`
  display: flex;
  gap: ${theme.SPACINGS[4]};
`
