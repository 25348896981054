export const mockEarningsQuarterly = {
  averagePoolEarnings: {
    names: ['MR'],
    poolPoints: 0.0,
    bunkerAdjustmentPerDay: null,
    earnings: 34116.7034,
    bunkerAdjustment: 0.0,
    scrubberBonus: 834.7457,
    specialCompensations: -80.4938,
    wafDta: 39.2123,
    tce: 34910.1677,
  },
  averageFilteredEarnings: null,
  vesselsEarnings: [
    {
      id: 180475,
      vesselCode: 'KR4',
      partner: {
        id: 124,
        name: 'Atlantic Asiatic Lloyd',
        axCode: 'ATL',
      },
      name: 'Falcon Bay',
      hasScrubber: false,
      poolPoints: 115.7,
      bunkerAdjustmentPerDay: 1.7231,
      earnings: 33930.1168,
      bunkerAdjustment: -1309.0938,
      scrubberBonus: 0.0,
      specialCompensations: -26005.1899,
      tce: 6777.1235,
      wafDta: 161.2903,
    },
    {
      id: 180468,
      vesselCode: 'KQ6',
      partner: {
        id: 123,
        name: 'Bahri Chemicals',
        axCode: 'BHC',
      },
      name: 'Bahri Tulip',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: -0.3898,
      earnings: 32558.4236,
      bunkerAdjustment: 340.6763,
      scrubberBonus: 0.0,
      specialCompensations: -123.4048,
      tce: 33109.0285,
      wafDta: 333.3333,
    },
    {
      id: 180449,
      vesselCode: 'KQ7',
      partner: {
        id: 123,
        name: 'Bahri Chemicals',
        axCode: 'BHC',
      },
      name: 'Bahri Jasmine',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: 0.9688,
      earnings: 32558.4236,
      bunkerAdjustment: -746.6441,
      scrubberBonus: 0.0,
      specialCompensations: -362.2316,
      tce: 31449.548,
      wafDta: 0.0,
    },
    {
      id: 180457,
      vesselCode: 'KQ8',
      partner: {
        id: 123,
        name: 'Bahri Chemicals',
        axCode: 'BHC',
      },
      name: 'Bahri Rose',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: 1.1019,
      earnings: 32558.4236,
      bunkerAdjustment: -861.2595,
      scrubberBonus: 0.0,
      specialCompensations: -1617.8664,
      tce: 30079.2977,
      wafDta: 0.0,
    },
    {
      id: 180445,
      vesselCode: 'KQ9',
      partner: {
        id: 123,
        name: 'Bahri Chemicals',
        axCode: 'BHC',
      },
      name: 'Bahri Iris',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: 0.1137,
      earnings: 32557.4843,
      bunkerAdjustment: -69.643,
      scrubberBonus: 0.0,
      specialCompensations: -355.8916,
      tce: 32131.9498,
      wafDta: 0.0,
    },
    {
      id: 180849,
      vesselCode: 'KJ2',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Pro Onyx',
      hasScrubber: true,
      poolPoints: 118.0,
      bunkerAdjustmentPerDay: 1.4989,
      earnings: 34628.9999,
      bunkerAdjustment: -1269.4686,
      scrubberBonus: 5421.6798,
      specialCompensations: 50.3476,
      tce: 38831.5587,
      wafDta: 0.0,
    },
    {
      id: 180443,
      vesselCode: 'KK1',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Lian Xi Hu',
      hasScrubber: false,
      poolPoints: 118.0,
      bunkerAdjustmentPerDay: 0.5218,
      earnings: 34628.9999,
      bunkerAdjustment: -435.4148,
      scrubberBonus: 0.0,
      specialCompensations: 131.8531,
      tce: 34486.7285,
      wafDta: 161.2903,
    },
    {
      id: 180643,
      vesselCode: 'KK5',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Ion M',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.2734,
      earnings: 34481.1015,
      bunkerAdjustment: 171.0781,
      scrubberBonus: 4074.2429,
      specialCompensations: 915.0294,
      tce: 39641.4519,
      wafDta: 0.0,
    },
    {
      id: 180956,
      vesselCode: 'LC6',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Gwn 2',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.692,
      earnings: 34477.5331,
      bunkerAdjustment: 519.1576,
      scrubberBonus: 4279.4243,
      specialCompensations: 1282.1657,
      tce: 40558.2807,
      wafDta: 0.0,
    },
    {
      id: 180988,
      vesselCode: 'LI4',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Wisco Adventure',
      hasScrubber: true,
      poolPoints: 118.11,
      bunkerAdjustmentPerDay: 0.0862,
      earnings: 34658.7233,
      bunkerAdjustment: -43.7818,
      scrubberBonus: 6612.5132,
      specialCompensations: 758.8984,
      tce: 41986.3531,
      wafDta: 0.0,
    },
    {
      id: 180989,
      vesselCode: 'LJ3',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Gwn 3',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.7859,
      earnings: 34481.1015,
      bunkerAdjustment: 636.03,
      scrubberBonus: 3471.8576,
      specialCompensations: -63.1153,
      tce: 38525.8737,
      wafDta: 0.0,
    },
    {
      id: 181067,
      vesselCode: 'LX8',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Stamatia',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.8842,
      earnings: 34481.1015,
      bunkerAdjustment: 657.1728,
      scrubberBonus: 8060.5704,
      specialCompensations: -688.4047,
      tce: 42510.4401,
      wafDta: 0.0,
    },
    {
      id: 181081,
      vesselCode: 'NC9',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Dee4 Ilex',
      hasScrubber: false,
      poolPoints: 118.0,
      bunkerAdjustmentPerDay: -0.9638,
      earnings: 34628.9999,
      bunkerAdjustment: 771.3756,
      scrubberBonus: 0.0,
      specialCompensations: -88.0209,
      tce: 35312.3546,
      wafDta: 0.0,
    },
    {
      id: 181098,
      vesselCode: 'NH8',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Sandpiper Pacific',
      hasScrubber: false,
      poolPoints: 116.5,
      bunkerAdjustmentPerDay: 0.2453,
      earnings: 34184.8987,
      bunkerAdjustment: -187.6292,
      scrubberBonus: 0.0,
      specialCompensations: -418.7135,
      tce: 33578.556,
      wafDta: 0.0,
    },
    {
      id: 181104,
      vesselCode: 'NI8',
      partner: {
        id: 120,
        name: 'Cargill',
        axCode: 'CAR',
      },
      name: 'Largo Eden',
      hasScrubber: false,
      poolPoints: 58.25,
      bunkerAdjustmentPerDay: 0.0,
      earnings: 32873.8635,
      bunkerAdjustment: 0.0,
      scrubberBonus: 0.0,
      specialCompensations: 0.0,
      tce: 32873.8635,
      wafDta: 0.0,
    },
    {
      id: 180787,
      vesselCode: 'LY3',
      partner: {
        id: 155,
        name: 'CDBL',
        axCode: 'CDB',
      },
      name: 'CL Huaiyang',
      hasScrubber: false,
      poolPoints: 118.69,
      bunkerAdjustmentPerDay: -1.2123,
      earnings: 34833.0996,
      bunkerAdjustment: 992.1087,
      scrubberBonus: 0.0,
      specialCompensations: -45.9519,
      tce: 35940.5468,
      wafDta: 161.2903,
    },
    {
      id: 181076,
      vesselCode: 'NA8',
      partner: {
        id: 155,
        name: 'CDBL',
        axCode: 'CDB',
      },
      name: 'CL Fugou',
      hasScrubber: false,
      poolPoints: 118.19,
      bunkerAdjustmentPerDay: -2.3468,
      earnings: 34685.201,
      bunkerAdjustment: 1846.0548,
      scrubberBonus: 0.0,
      specialCompensations: 351.7913,
      tce: 36883.0471,
      wafDta: 0.0,
    },
    {
      id: 180784,
      vesselCode: 'TA2',
      partner: {
        id: 155,
        name: 'CDBL',
        axCode: 'CDB',
      },
      name: 'CL Zhaoge',
      hasScrubber: false,
      poolPoints: 117.72,
      bunkerAdjustmentPerDay: -1.3412,
      earnings: 34546.177,
      bunkerAdjustment: 1067.3467,
      scrubberBonus: 0.0,
      specialCompensations: -73.8774,
      tce: 35539.6463,
      wafDta: 0.0,
    },
    {
      id: 181030,
      vesselCode: 'TA3',
      partner: {
        id: 155,
        name: 'CDBL',
        axCode: 'CDB',
      },
      name: 'CL Yingdu',
      hasScrubber: false,
      poolPoints: 118.22,
      bunkerAdjustmentPerDay: -1.8257,
      earnings: 34694.075,
      bunkerAdjustment: 1457.7612,
      scrubberBonus: 0.0,
      specialCompensations: -43.2665,
      tce: 36108.5697,
      wafDta: 0.0,
    },
    {
      id: 180786,
      vesselCode: 'TA5',
      partner: {
        id: 155,
        name: 'CDBL',
        axCode: 'CDB',
      },
      name: 'CL Xunyang',
      hasScrubber: false,
      poolPoints: 118.23,
      bunkerAdjustmentPerDay: -2.2927,
      earnings: 34697.0332,
      bunkerAdjustment: 1817.8095,
      scrubberBonus: 0.0,
      specialCompensations: 148.8718,
      tce: 36663.7145,
      wafDta: 0.0,
    },
    {
      id: 180557,
      vesselCode: 'KA3',
      partner: {
        id: 117,
        name: 'Celsius Shipping',
        axCode: 'CEL',
      },
      name: 'Celsius Riga',
      hasScrubber: false,
      poolPoints: 114.15,
      bunkerAdjustmentPerDay: -0.3394,
      earnings: 33490.183,
      bunkerAdjustment: 291.7872,
      scrubberBonus: 0.0,
      specialCompensations: -706.1464,
      tce: 33075.8238,
      wafDta: 0.0,
    },
    {
      id: 180971,
      vesselCode: 'LG4',
      partner: {
        id: 117,
        name: 'Celsius Shipping',
        axCode: 'CEL',
      },
      name: 'Celsius Ravenna',
      hasScrubber: false,
      poolPoints: 112.87,
      bunkerAdjustmentPerDay: 1.8092,
      earnings: 33111.5633,
      bunkerAdjustment: -1548.3614,
      scrubberBonus: 0.0,
      specialCompensations: 1484.1899,
      tce: 33047.3918,
      wafDta: 0.0,
    },
    {
      id: 180471,
      vesselCode: 'KQ5',
      partner: {
        id: 55,
        name: 'Cido Shipping',
        axCode: 'CID',
      },
      name: 'Atlantic Eagle',
      hasScrubber: false,
      poolPoints: 112.13,
      bunkerAdjustmentPerDay: 1.9559,
      earnings: 32885.9729,
      bunkerAdjustment: -1527.7887,
      scrubberBonus: 0.0,
      specialCompensations: -1885.8587,
      tce: 29472.3255,
      wafDta: 0.0,
    },
    {
      id: 180947,
      vesselCode: 'LB4',
      partner: {
        id: 55,
        name: 'Cido Shipping',
        axCode: 'CID',
      },
      name: 'Atlantic Crown',
      hasScrubber: false,
      poolPoints: 112.13,
      bunkerAdjustmentPerDay: 0.7363,
      earnings: 32892.6738,
      bunkerAdjustment: -600.6328,
      scrubberBonus: 0.0,
      specialCompensations: -438.5349,
      tce: 31853.5061,
      wafDta: 0.0,
    },
    {
      id: 180999,
      vesselCode: 'LK8',
      partner: {
        id: 55,
        name: 'Cido Shipping',
        axCode: 'CID',
      },
      name: 'Gran Couva',
      hasScrubber: false,
      poolPoints: 115.13,
      bunkerAdjustmentPerDay: 2.0219,
      earnings: 33780.0636,
      bunkerAdjustment: -1552.7846,
      scrubberBonus: 0.0,
      specialCompensations: -1028.0623,
      tce: 31199.2167,
      wafDta: 0.0,
    },
    {
      id: 181013,
      vesselCode: 'LN4',
      partner: {
        id: 76,
        name: 'CST',
        axCode: 'CST',
      },
      name: 'Green Point',
      hasScrubber: false,
      poolPoints: 109.51,
      bunkerAdjustmentPerDay: 0.4638,
      earnings: 32117.6866,
      bunkerAdjustment: -356.8803,
      scrubberBonus: 0.0,
      specialCompensations: -1172.8148,
      tce: 30587.9916,
      wafDta: 0.0,
    },
    {
      id: 180900,
      vesselCode: 'KL7',
      partner: {
        id: 102,
        name: 'DSD Shipping',
        axCode: 'DSD',
      },
      name: 'Stavanger Pioneer',
      hasScrubber: true,
      poolPoints: 117.49,
      bunkerAdjustmentPerDay: -1.1517,
      earnings: 34478.1435,
      bunkerAdjustment: 927.3539,
      scrubberBonus: 2180.8915,
      specialCompensations: 1334.144,
      tce: 38920.5329,
      wafDta: 0.0,
    },
    {
      id: 180953,
      vesselCode: 'LC4',
      partner: {
        id: 102,
        name: 'DSD Shipping',
        axCode: 'DSD',
      },
      name: 'Stavanger Pearl',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.5436,
      earnings: 34480.1468,
      bunkerAdjustment: 415.1234,
      scrubberBonus: 7501.866,
      specialCompensations: 311.403,
      tce: 42708.5392,
      wafDta: 0.0,
    },
    {
      id: 180954,
      vesselCode: 'LC5',
      partner: {
        id: 102,
        name: 'DSD Shipping',
        axCode: 'DSD',
      },
      name: 'Stavanger Poseidon',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -1.6072,
      earnings: 34481.1015,
      bunkerAdjustment: 1209.9376,
      scrubberBonus: 6217.5074,
      specialCompensations: -166.1006,
      tce: 41742.4459,
      wafDta: 0.0,
    },
    {
      id: 180974,
      vesselCode: 'LG1',
      partner: {
        id: 102,
        name: 'DSD Shipping',
        axCode: 'DSD',
      },
      name: 'Stavanger Pride',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: 0.3891,
      earnings: 34481.1015,
      bunkerAdjustment: -322.47,
      scrubberBonus: 6615.2563,
      specialCompensations: 926.5199,
      tce: 41700.4077,
      wafDta: 0.0,
    },
    {
      id: 181039,
      vesselCode: 'LU7',
      partner: {
        id: 176,
        name: 'Egyptian Tanker Company',
        axCode: 'ETC',
      },
      name: 'ETC Nefertari',
      hasScrubber: false,
      poolPoints: 111.26,
      bunkerAdjustmentPerDay: 1.8401,
      earnings: 32635.3307,
      bunkerAdjustment: -1501.177,
      scrubberBonus: 0.0,
      specialCompensations: 295.7096,
      tce: 31596.53,
      wafDta: 166.6667,
    },
    {
      id: 181000,
      vesselCode: 'LI7',
      partner: {
        id: 80,
        name: 'Fuyo Kaiun',
        axCode: 'FUY',
      },
      name: 'Rich Rainbow',
      hasScrubber: false,
      poolPoints: 118.0,
      bunkerAdjustmentPerDay: -1.364,
      earnings: 34634.6283,
      bunkerAdjustment: 1113.8853,
      scrubberBonus: 0.0,
      specialCompensations: -530.3582,
      tce: 35218.1553,
      wafDta: 0.0,
    },
    {
      id: 180981,
      vesselCode: 'LI1',
      partner: {
        id: 157,
        name: 'Gotland Tankers',
        axCode: 'GOT',
      },
      name: 'Gotland Carolina',
      hasScrubber: false,
      poolPoints: 60.75,
      bunkerAdjustmentPerDay: 1.6626,
      earnings: 37021.4317,
      bunkerAdjustment: -2900.9838,
      scrubberBonus: 0.0,
      specialCompensations: -172.1701,
      tce: 33948.2778,
      wafDta: 0.0,
    },
    {
      id: 181038,
      vesselCode: 'LU5',
      partner: {
        id: 157,
        name: 'Gotland Tankers',
        axCode: 'GOT',
      },
      name: 'Gotland Aliya',
      hasScrubber: false,
      poolPoints: 124.0,
      bunkerAdjustmentPerDay: 1.4543,
      earnings: 36394.5506,
      bunkerAdjustment: -1110.6252,
      scrubberBonus: 0.0,
      specialCompensations: -2605.0378,
      tce: 32678.8876,
      wafDta: 0.0,
    },
    {
      id: 181043,
      vesselCode: 'LU6',
      partner: {
        id: 157,
        name: 'Gotland Tankers',
        axCode: 'GOT',
      },
      name: 'Gotland Marieann',
      hasScrubber: false,
      poolPoints: 124.0,
      bunkerAdjustmentPerDay: 0.1358,
      earnings: 37795.5203,
      bunkerAdjustment: -236.7476,
      scrubberBonus: 0.0,
      specialCompensations: -57.894,
      tce: 37500.8788,
      wafDta: 0.0,
    },
    {
      id: 180747,
      vesselCode: 'LT6',
      partner: {
        id: 171,
        name: 'Hadley Shipping',
        axCode: 'HAD',
      },
      name: 'Cepolis',
      hasScrubber: false,
      poolPoints: 112.02,
      bunkerAdjustmentPerDay: -0.2573,
      earnings: 32860.1362,
      bunkerAdjustment: 229.7419,
      scrubberBonus: 0.0,
      specialCompensations: -537.6756,
      tce: 32552.2026,
      wafDta: 0.0,
    },
    {
      id: 181078,
      vesselCode: 'NB7',
      partner: {
        id: 171,
        name: 'Hadley Shipping',
        axCode: 'HAD',
      },
      name: 'Calandria',
      hasScrubber: false,
      poolPoints: 109.95,
      bunkerAdjustmentPerDay: -0.559,
      earnings: 32247.8371,
      bunkerAdjustment: 461.7792,
      scrubberBonus: 0.0,
      specialCompensations: -37.2392,
      tce: 32839.0438,
      wafDta: 166.6667,
    },
    {
      id: 180847,
      vesselCode: 'J8T',
      partner: {
        id: 68,
        name: 'Island Navigation',
        axCode: 'ISL',
      },
      name: 'Pine Express',
      hasScrubber: false,
      poolPoints: 115.21,
      bunkerAdjustmentPerDay: 0.1508,
      earnings: 33803.7275,
      bunkerAdjustment: -104.6258,
      scrubberBonus: 0.0,
      specialCompensations: -287.7437,
      tce: 33411.3581,
      wafDta: 0.0,
    },
    {
      id: 180935,
      vesselCode: 'KO5',
      partner: {
        id: 68,
        name: 'Island Navigation',
        axCode: 'ISL',
      },
      name: 'Challenge Prelude',
      hasScrubber: false,
      poolPoints: 111.56,
      bunkerAdjustmentPerDay: -0.1042,
      earnings: 32724.0698,
      bunkerAdjustment: 106.2524,
      scrubberBonus: 0.0,
      specialCompensations: -64.8333,
      tce: 33343.4459,
      wafDta: 577.957,
    },
    {
      id: 181092,
      vesselCode: 'NG3',
      partner: {
        id: 193,
        name: 'Leonhardt & Blumberg',
        axCode: 'LNB',
      },
      name: 'Hansa Oslo',
      hasScrubber: false,
      poolPoints: 115.5,
      bunkerAdjustmentPerDay: 0.3693,
      earnings: 33889.5083,
      bunkerAdjustment: -259.4286,
      scrubberBonus: 0.0,
      specialCompensations: -671.066,
      tce: 32959.0138,
      wafDta: 0.0,
    },
    {
      id: 181090,
      vesselCode: 'NG6',
      partner: {
        id: 195,
        name: 'Manta Denizcilik',
        axCode: 'MAN',
      },
      name: 'Manta Galatasaray',
      hasScrubber: false,
      poolPoints: 115.5,
      bunkerAdjustmentPerDay: -0.2322,
      earnings: 33889.5083,
      bunkerAdjustment: 214.5255,
      scrubberBonus: 0.0,
      specialCompensations: -533.8307,
      tce: 33570.2031,
      wafDta: 0.0,
    },
    {
      id: 180994,
      vesselCode: 'LJ8',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Capri',
      hasScrubber: false,
      poolPoints: 118.92,
      bunkerAdjustmentPerDay: 0.0132,
      earnings: 34901.1327,
      bunkerAdjustment: 3.0601,
      scrubberBonus: 0.0,
      specialCompensations: -221.7889,
      tce: 34682.4039,
      wafDta: 0.0,
    },
    {
      id: 181003,
      vesselCode: 'LL5',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Callao',
      hasScrubber: false,
      poolPoints: 118.89,
      bunkerAdjustmentPerDay: -1.027,
      earnings: 34892.2588,
      bunkerAdjustment: 836.692,
      scrubberBonus: 0.0,
      specialCompensations: -252.9269,
      tce: 35476.0238,
      wafDta: 0.0,
    },
    {
      id: 181005,
      vesselCode: 'LL9',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Curacao',
      hasScrubber: false,
      poolPoints: 118.92,
      bunkerAdjustmentPerDay: -1.8741,
      earnings: 34901.1327,
      bunkerAdjustment: 1493.0155,
      scrubberBonus: 0.0,
      specialCompensations: 867.7708,
      tce: 37261.9191,
      wafDta: 0.0,
    },
    {
      id: 181006,
      vesselCode: 'LM2',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Corsica',
      hasScrubber: false,
      poolPoints: 118.89,
      bunkerAdjustmentPerDay: -1.3668,
      earnings: 34892.2588,
      bunkerAdjustment: 1080.1827,
      scrubberBonus: 0.0,
      specialCompensations: 1060.125,
      tce: 37032.5665,
      wafDta: 0.0,
    },
    {
      id: 180775,
      vesselCode: 'MA5',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Tacoma',
      hasScrubber: false,
      poolPoints: 118.83,
      bunkerAdjustmentPerDay: -0.114,
      earnings: 34874.511,
      bunkerAdjustment: 100.6002,
      scrubberBonus: 0.0,
      specialCompensations: 272.6132,
      tce: 35247.7243,
      wafDta: 0.0,
    },
    {
      id: 180779,
      vesselCode: 'MA6',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Tangier',
      hasScrubber: false,
      poolPoints: 117.83,
      bunkerAdjustmentPerDay: -0.7405,
      earnings: 34578.7144,
      bunkerAdjustment: 565.1149,
      scrubberBonus: 0.0,
      specialCompensations: 1000.4045,
      tce: 36305.5241,
      wafDta: 161.2903,
    },
    {
      id: 180781,
      vesselCode: 'MA7',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Teesport',
      hasScrubber: false,
      poolPoints: 118.8,
      bunkerAdjustmentPerDay: -1.778,
      earnings: 34865.6372,
      bunkerAdjustment: 1362.7948,
      scrubberBonus: 0.0,
      specialCompensations: 541.6397,
      tce: 36770.0716,
      wafDta: 0.0,
    },
    {
      id: 180777,
      vesselCode: 'MA8',
      partner: {
        id: 116,
        name: 'Maersk Product Tankers',
        axCode: 'MPT',
      },
      name: 'Maersk Tampa',
      hasScrubber: false,
      poolPoints: 118.83,
      bunkerAdjustmentPerDay: -0.4667,
      earnings: 34874.511,
      bunkerAdjustment: 385.1221,
      scrubberBonus: 0.0,
      specialCompensations: 165.5397,
      tce: 35425.1728,
      wafDta: 0.0,
    },
    {
      id: 180731,
      vesselCode: '4R9',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Magellan',
      hasScrubber: false,
      poolPoints: 118.99,
      bunkerAdjustmentPerDay: -0.0501,
      earnings: 34921.8385,
      bunkerAdjustment: 71.9435,
      scrubberBonus: 0.0,
      specialCompensations: -581.5505,
      tce: 34740.1885,
      wafDta: 327.957,
    },
    {
      id: 180735,
      vesselCode: 'KG4',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Maru',
      hasScrubber: false,
      poolPoints: 114.52,
      bunkerAdjustmentPerDay: -0.2186,
      earnings: 33599.6277,
      bunkerAdjustment: 182.0104,
      scrubberBonus: 0.0,
      specialCompensations: -29.0224,
      tce: 33913.906,
      wafDta: 161.2903,
    },
    {
      id: 180785,
      vesselCode: 'O65',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Tokyo',
      hasScrubber: false,
      poolPoints: 118.69,
      bunkerAdjustmentPerDay: -1.6762,
      earnings: 34832.0658,
      bunkerAdjustment: 1342.2907,
      scrubberBonus: 0.0,
      specialCompensations: 338.1307,
      tce: 36512.4873,
      wafDta: 0.0,
    },
    {
      id: 180745,
      vesselCode: 'U04',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Mississippi',
      hasScrubber: false,
      poolPoints: 113.295,
      bunkerAdjustmentPerDay: -0.1832,
      earnings: 33235.0816,
      bunkerAdjustment: 178.6582,
      scrubberBonus: 0.0,
      specialCompensations: -225.3892,
      tce: 33188.3506,
      wafDta: 0.0,
    },
    {
      id: 180732,
      vesselCode: 'U93',
      partner: {
        id: 56,
        name: 'Maersk Tankers SIN',
        axCode: 'MT SIN',
      },
      name: 'Maersk Malaga',
      hasScrubber: false,
      poolPoints: 119.0,
      bunkerAdjustmentPerDay: -1.1675,
      earnings: 34919.7629,
      bunkerAdjustment: 1069.066,
      scrubberBonus: 0.0,
      specialCompensations: 1242.5165,
      tce: 37231.3454,
      wafDta: 0.0,
    },
    {
      id: 180978,
      vesselCode: 'LE4',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'St. Clemens',
      hasScrubber: false,
      poolPoints: 115.5,
      bunkerAdjustmentPerDay: -0.8304,
      earnings: 33889.5083,
      bunkerAdjustment: 675.6254,
      scrubberBonus: 0.0,
      specialCompensations: 463.3265,
      tce: 35028.4602,
      wafDta: 0.0,
    },
    {
      id: 181053,
      vesselCode: 'LV4',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'St. Petri',
      hasScrubber: false,
      poolPoints: 116.5,
      bunkerAdjustmentPerDay: 0.4369,
      earnings: 34185.3051,
      bunkerAdjustment: -341.1492,
      scrubberBonus: 0.0,
      specialCompensations: -240.4656,
      tce: 33603.6903,
      wafDta: 0.0,
    },
    {
      id: 181061,
      vesselCode: 'LW4',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'St.Michaelis',
      hasScrubber: false,
      poolPoints: 116.5,
      bunkerAdjustmentPerDay: 0.3139,
      earnings: 34185.3051,
      bunkerAdjustment: -239.3399,
      scrubberBonus: 0.0,
      specialCompensations: -334.2364,
      tce: 33611.7288,
      wafDta: 0.0,
    },
    {
      id: 181050,
      vesselCode: 'LW5',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'St. Nikolai',
      hasScrubber: false,
      poolPoints: 115.5,
      bunkerAdjustmentPerDay: 0.346,
      earnings: 33889.5083,
      bunkerAdjustment: -278.4474,
      scrubberBonus: 0.0,
      specialCompensations: -130.521,
      tce: 33480.5399,
      wafDta: 0.0,
    },
    {
      id: 181059,
      vesselCode: 'LY1',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'Blue Grass Mariner',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.4,
      earnings: 34481.1015,
      bunkerAdjustment: 302.5117,
      scrubberBonus: 5176.8345,
      specialCompensations: -273.4997,
      tce: 39686.948,
      wafDta: 0.0,
    },
    {
      id: 181057,
      vesselCode: 'LY2',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'Yellow Stars',
      hasScrubber: false,
      poolPoints: 118.0,
      bunkerAdjustmentPerDay: -0.6999,
      earnings: 34628.9999,
      bunkerAdjustment: 542.8011,
      scrubberBonus: 0.0,
      specialCompensations: -222.5226,
      tce: 34949.2784,
      wafDta: 0.0,
    },
    {
      id: 181065,
      vesselCode: 'LZ5',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'Grand Winner 3',
      hasScrubber: false,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: -0.9397,
      earnings: 34481.1015,
      bunkerAdjustment: 780.5257,
      scrubberBonus: 0.0,
      specialCompensations: 633.3676,
      tce: 35894.9948,
      wafDta: 0.0,
    },
    {
      id: 181077,
      vesselCode: 'LZ8',
      partner: {
        id: 115,
        name: 'MT AS',
        axCode: 'MTA_TC',
      },
      name: 'Alexandros',
      hasScrubber: true,
      poolPoints: 117.5,
      bunkerAdjustmentPerDay: 0.1546,
      earnings: 34481.1015,
      bunkerAdjustment: -143.9141,
      scrubberBonus: 6154.7435,
      specialCompensations: 83.1015,
      tce: 40741.699,
      wafDta: 166.6667,
    },
    {
      id: 180522,
      vesselCode: 'F2Y',
      partner: {
        id: 72,
        name: 'Nan Fung',
        axCode: 'NAF',
      },
      name: 'Bright Dawn',
      hasScrubber: false,
      poolPoints: 115.0,
      bunkerAdjustmentPerDay: -0.1847,
      earnings: 33740.6744,
      bunkerAdjustment: 157.7163,
      scrubberBonus: 0.0,
      specialCompensations: -1419.115,
      tce: 32479.2757,
      wafDta: 0.0,
    },
    {
      id: 180806,
      vesselCode: 'F2Z',
      partner: {
        id: 72,
        name: 'Nan Fung',
        axCode: 'NAF',
      },
      name: 'New Dawn',
      hasScrubber: false,
      poolPoints: 114.0,
      bunkerAdjustmentPerDay: -0.2172,
      earnings: 34734.2851,
      bunkerAdjustment: 378.8508,
      scrubberBonus: 0.0,
      specialCompensations: 654.092,
      tce: 35767.2279,
      wafDta: 0.0,
    },
    {
      id: 181102,
      vesselCode: 'NI9',
      partner: {
        id: 72,
        name: 'Nan Fung',
        axCode: 'NAF',
      },
      name: 'Gemini Pearl',
      hasScrubber: false,
      poolPoints: 114.0,
      bunkerAdjustmentPerDay: 0.24,
      earnings: 33440.3113,
      bunkerAdjustment: -191.8253,
      scrubberBonus: 0.0,
      specialCompensations: 249.3488,
      tce: 33497.8349,
      wafDta: 0.0,
    },
    {
      id: 181103,
      vesselCode: 'NJ3',
      partner: {
        id: 72,
        name: 'Nan Fung',
        axCode: 'NAF',
      },
      name: 'Aries Pearl',
      hasScrubber: false,
      poolPoints: 57.5,
      bunkerAdjustmentPerDay: 2.103,
      earnings: 32436.947,
      bunkerAdjustment: -1607.7948,
      scrubberBonus: 0.0,
      specialCompensations: 0.0,
      tce: 30829.1522,
      wafDta: 0.0,
    },
    {
      id: 180435,
      vesselCode: 'KX7',
      partner: {
        id: 129,
        name: 'Swift Tankers',
        axCode: 'NHS',
      },
      name: 'Swift Omaha',
      hasScrubber: false,
      poolPoints: 56.795,
      bunkerAdjustmentPerDay: -0.5343,
      earnings: 34606.7014,
      bunkerAdjustment: 932.2543,
      scrubberBonus: 0.0,
      specialCompensations: -167.9129,
      tce: 35371.0429,
      wafDta: 0.0,
    },
    {
      id: 181079,
      vesselCode: 'NB6',
      partner: {
        id: 129,
        name: 'Swift Tankers',
        axCode: 'NHS',
      },
      name: ' Swift Winchester',
      hasScrubber: false,
      poolPoints: 113.59,
      bunkerAdjustmentPerDay: -0.0326,
      earnings: 33325.9104,
      bunkerAdjustment: 98.5257,
      scrubberBonus: 0.0,
      specialCompensations: 1105.4755,
      tce: 34529.9116,
      wafDta: 0.0,
    },
    {
      id: 181017,
      vesselCode: 'LP6',
      partner: {
        id: 162,
        name: 'Pan Ocean',
        axCode: 'PAN',
      },
      name: 'Grand Ace1',
      hasScrubber: false,
      poolPoints: 112.49,
      bunkerAdjustmentPerDay: 0.891,
      earnings: 32999.1605,
      bunkerAdjustment: -730.1892,
      scrubberBonus: 0.0,
      specialCompensations: 342.2574,
      tce: 32611.2287,
      wafDta: 0.0,
    },
    {
      id: 181064,
      vesselCode: 'LZ1',
      partner: {
        id: 162,
        name: 'Pan Ocean',
        axCode: 'PAN',
      },
      name: 'Grand Ace5',
      hasScrubber: false,
      poolPoints: 111.68,
      bunkerAdjustmentPerDay: 0.5699,
      earnings: 32759.5654,
      bunkerAdjustment: -461.6802,
      scrubberBonus: 0.0,
      specialCompensations: -784.7147,
      tce: 31513.1704,
      wafDta: 0.0,
    },
    {
      id: 180459,
      vesselCode: 'KU1',
      partner: {
        id: 127,
        name: 'Pacific Carriers Limited',
        axCode: 'PCL',
      },
      name: 'Plover Pacific',
      hasScrubber: false,
      poolPoints: 116.5,
      bunkerAdjustmentPerDay: 0.3926,
      earnings: 34183.6917,
      bunkerAdjustment: -305.0119,
      scrubberBonus: 0.0,
      specialCompensations: 64.1594,
      tce: 33942.8393,
      wafDta: 0.0,
    },
    {
      id: 180964,
      vesselCode: 'LR3',
      partner: {
        id: 169,
        name: 'Shandong Weihe Tanker',
        axCode: 'SHA',
      },
      name: 'Shandong Weihe',
      hasScrubber: false,
      poolPoints: 106.9,
      bunkerAdjustmentPerDay: 1.1745,
      earnings: 31347.7112,
      bunkerAdjustment: -899.6262,
      scrubberBonus: 0.0,
      specialCompensations: -1122.8225,
      tce: 29325.2625,
      wafDta: 0.0,
    },
    {
      id: 181088,
      vesselCode: 'NF1',
      partner: {
        id: 196,
        name: 'SK Shipping',
        axCode: 'SKS',
      },
      name: 'Grand Ace10',
      hasScrubber: false,
      poolPoints: 114.66,
      bunkerAdjustmentPerDay: 0.6943,
      earnings: 33641.0392,
      bunkerAdjustment: -561.5254,
      scrubberBonus: 0.0,
      specialCompensations: 851.2047,
      tce: 33930.7185,
      wafDta: 0.0,
    },
    {
      id: 181012,
      vesselCode: 'LO8',
      partner: {
        id: 167,
        name: 'Silk Searoad',
        axCode: 'SSR',
      },
      name: 'Riviera II',
      hasScrubber: false,
      poolPoints: 111.0,
      bunkerAdjustmentPerDay: 2.1403,
      earnings: 32558.4236,
      bunkerAdjustment: -1652.5181,
      scrubberBonus: 0.0,
      specialCompensations: 111.3303,
      tce: 31178.5262,
      wafDta: 161.2903,
    },
    {
      id: 181047,
      vesselCode: 'LW1',
      partner: {
        id: 167,
        name: 'Silk Searoad',
        axCode: 'SSR',
      },
      name: 'Evian',
      hasScrubber: false,
      poolPoints: 111.43,
      bunkerAdjustmentPerDay: 0.5384,
      earnings: 32701.1128,
      bunkerAdjustment: -440.3207,
      scrubberBonus: 0.0,
      specialCompensations: 133.2704,
      tce: 32394.0626,
      wafDta: 0.0,
    },
    {
      id: 180498,
      vesselCode: 'J7J',
      partner: {
        id: 110,
        name: 'Transocean Maritime',
        axCode: 'TRO',
      },
      name: 'Atalanta T',
      hasScrubber: false,
      poolPoints: 111.47,
      bunkerAdjustmentPerDay: -0.2141,
      earnings: 32697.448,
      bunkerAdjustment: 197.1727,
      scrubberBonus: 0.0,
      specialCompensations: -8.8602,
      tce: 32885.7606,
      wafDta: 0.0,
    },
    {
      id: 180486,
      vesselCode: 'J8S',
      partner: {
        id: 110,
        name: 'Transocean Maritime',
        axCode: 'TRO',
      },
      name: 'Alcyone T',
      hasScrubber: false,
      poolPoints: 111.45,
      bunkerAdjustmentPerDay: -0.5352,
      earnings: 32691.5321,
      bunkerAdjustment: 460.3996,
      scrubberBonus: 0.0,
      specialCompensations: -540.6414,
      tce: 32611.2903,
      wafDta: 0.0,
    },
    {
      id: 181011,
      vesselCode: 'LN7',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Artemis',
      hasScrubber: false,
      poolPoints: 121.5,
      bunkerAdjustmentPerDay: 2.3919,
      earnings: 35664.2881,
      bunkerAdjustment: -1950.1459,
      scrubberBonus: 0.0,
      specialCompensations: 123.2209,
      tce: 33837.363,
      wafDta: 0.0,
    },
    {
      id: 181007,
      vesselCode: 'LN8',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Aris',
      hasScrubber: false,
      poolPoints: 122.5,
      bunkerAdjustmentPerDay: 3.0606,
      earnings: 35960.0847,
      bunkerAdjustment: -2431.1404,
      scrubberBonus: 0.0,
      specialCompensations: -1306.622,
      tce: 32222.3223,
      wafDta: 0.0,
    },
    {
      id: 181008,
      vesselCode: 'LN9',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Apollon',
      hasScrubber: false,
      poolPoints: 121.5,
      bunkerAdjustmentPerDay: 0.527,
      earnings: 35664.2881,
      bunkerAdjustment: -400.6311,
      scrubberBonus: 0.0,
      specialCompensations: 109.8576,
      tce: 35373.5146,
      wafDta: 0.0,
    },
    {
      id: 181044,
      vesselCode: 'LW2',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Ajax',
      hasScrubber: false,
      poolPoints: 122.5,
      bunkerAdjustmentPerDay: 3.3454,
      earnings: 35960.0847,
      bunkerAdjustment: -2650.7085,
      scrubberBonus: 0.0,
      specialCompensations: -1126.8293,
      tce: 32505.1276,
      wafDta: 322.5806,
    },
    {
      id: 181055,
      vesselCode: 'LX3',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Ariadne',
      hasScrubber: false,
      poolPoints: 121.5,
      bunkerAdjustmentPerDay: 1.8839,
      earnings: 35664.2881,
      bunkerAdjustment: -1575.3788,
      scrubberBonus: 0.0,
      specialCompensations: 933.5632,
      tce: 35022.4724,
      wafDta: 0.0,
    },
    {
      id: 181054,
      vesselCode: 'LX4',
      partner: {
        id: 161,
        name: 'Tsakos',
        axCode: 'TSA',
      },
      name: 'Afrodite',
      hasScrubber: false,
      poolPoints: 121.5,
      bunkerAdjustmentPerDay: 0.1012,
      earnings: 35664.2881,
      bunkerAdjustment: -75.128,
      scrubberBonus: 0.0,
      specialCompensations: 7.6619,
      tce: 35596.822,
      wafDta: 0.0,
    },
    {
      id: 181089,
      vesselCode: 'NF6',
      partner: {
        id: 190,
        name: 'Tradewind Tankers',
        axCode: 'TWT',
      },
      name: 'Tradewind Energy',
      hasScrubber: false,
      poolPoints: 115.63,
      bunkerAdjustmentPerDay: -0.0717,
      earnings: 33927.9618,
      bunkerAdjustment: 67.2597,
      scrubberBonus: 0.0,
      specialCompensations: -419.5037,
      tce: 33575.7179,
      wafDta: 0.0,
    },
    {
      id: 181016,
      vesselCode: 'LP7',
      partner: {
        id: 164,
        name: 'Zodiac Maritime',
        axCode: 'ZMA',
      },
      name: 'Vendome Street',
      hasScrubber: false,
      poolPoints: 110.88,
      bunkerAdjustmentPerDay: 0.1727,
      earnings: 32522.9281,
      bunkerAdjustment: -134.9936,
      scrubberBonus: 0.0,
      specialCompensations: 136.6029,
      tce: 32524.5374,
      wafDta: 0.0,
    },
    {
      id: 181051,
      vesselCode: 'LX2',
      partner: {
        id: 164,
        name: 'Zodiac Maritime',
        axCode: 'ZMA',
      },
      name: 'Canal Street',
      hasScrubber: false,
      poolPoints: 112.99,
      bunkerAdjustmentPerDay: 0.7267,
      earnings: 33145.84,
      bunkerAdjustment: -580.9086,
      scrubberBonus: 0.0,
      specialCompensations: 190.0909,
      tce: 32755.0223,
      wafDta: 0.0,
    },
  ],
}
