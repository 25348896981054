import { theme, typographyStyles } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { ModalFooter } from '~components/molecules/modal/modal.styles'
import { COLORS } from '~theme/colors'

import { PopupContentWrapper } from '~pages/pages-behind-login/position-list/position-list.styles'

export interface BottomBorderProps {
  $isOpen: boolean
}
export const Wrapper = styled.div``

export const VoyagesWrapper = styled.div`
  display: flex;
  position: relative;
`

export const InternalCargoesPopupContentWrapper = styled(PopupContentWrapper)`
  font-family: ${theme.FONTS.families.regular};
  display: flex;
  flex-direction: column;
  font-size: ${theme.FONTS.sizes.xs};
  align-items: space-between;
  gap: 12px;
`

export const UserNameWrapper = styled.div`
  font-family: ${theme.FONTS.families.bold};
`

export const L3C = styled.div`
  cursor: pointer;

  :not(:last-of-type)::after {
    content: ' /';
    color: initial;
  }
`

export const InternalL3CWrapper = styled.div`
  font-weight: ${theme.FONTS.weights.bold};
  color: ${COLORS.secondary.midBlue};
  display: flex;
  position: relative;
`

export const VoyagesPopupContentWrapper = styled.div`
  background-color: ${COLORS.primary.white};
  padding: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: ${theme.SHADOWS.cardShadow};
  width: max-content;
  white-space: nowrap;
`

export const BottomBorder = styled.span<BottomBorderProps>`
  position: absolute;
  width: ${(p) => (p.$isOpen ? '100%' : 0)};
  background-color: ${COLORS.primary.maerskBlue};
  transition: width 0.3s;
  height: 2px;
  bottom: 0px;
`

export const InternalCargoesWrapper = styled.div`
  position: relative;
  display: flex;
`

export const L3CWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`

export const VoyageTable = styled.table`
  border-collapse: separate;
  table-layout: fixed;
`

export const VoyageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const VoyageHeader = styled.th.attrs({ scope: 'col' })`
  ${typographyStyles.caption};
  color: ${COLORS.greys.dark};
  text-align: left;
  padding-top: ${theme.SPACINGS[2]};
  padding-right: 40px;
  white-space: nowrap;
  overflow: hidden;
`

export const VoyageCell = styled.td`
  ${typographyStyles.paragraph2};
  max-width: 200px;
  padding-right: ${theme.SPACINGS[2]};
  word-wrap: break-word;
  white-space: normal;
`

export const StyledModalFooter = styled(ModalFooter)`
  @media only screen and (${theme.BREAKPOINTS.sm}) {
    justify-content: space-between;
  }
`

export const PrimaryButtonsWrapper = styled.div`
  display: flex;
  gap: ${theme.SPACINGS[4]};
  justify-content: flex-end;
`
