import { Collapse, TextButton } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { useState } from 'react'
import { cookieDescription } from '../content/cookie-description'
import { CookieDetailsAccordion } from './cookie-details-accordion'

export function CookieDetails() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <TextButton onClick={() => setIsOpen(!isOpen)}>
        {`${isOpen ? 'Hide' : 'Show'} details`}
      </TextButton>
      <Collapse isOpen={isOpen}>
        <Box sx={{ mt: 3 }}>
          {cookieDescription.map((item) => {
            return <CookieDetailsAccordion key={item.title} {...item} />
          })}
        </Box>
      </Collapse>
    </>
  )
}
