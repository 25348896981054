import { gtm } from '~utils/gtm'

import {
  columnOptions,
  multiSelectLabelValuePairs,
} from '../position-list-page/position-list-table/position-list-table-controls/position-list-table-controls'

export function trackFilterColumnsClick(
  event:
    | React.KeyboardEvent<Element>
    | React.MouseEvent<Element, MouseEvent>
    | React.FocusEvent<Element, Element>,
  newValues: string[],
) {
  let listItemElement = event.target as HTMLElement

  if (listItemElement.tagName !== 'LI') {
    listItemElement = listItemElement.closest('li') as HTMLElement
  }

  const clickedItem = multiSelectLabelValuePairs.find(
    (pair) => pair.label === listItemElement?.textContent,
  )
  if (!clickedItem) return

  const isChecked = newValues.includes(clickedItem.value)
  if (!isChecked) return

  const isDefaultOption = columnOptions.some(
    (option) => option.value === clickedItem.value,
  )
  if (isDefaultOption) return

  gtm.pushEvent('internal_click', {
    click_type: 'Filter columns',
    click_subtype: clickedItem.label,
  })
}
