import { PoolName } from '~constants/pools'

export interface CustomTickProps extends React.SVGProps<SVGTextElement> {
  index?: number
  numBars?: number
  payload?: { value: string }
  visibleTicksCount?: number
}

export enum OpenAreaEnum {
  ALL = 'ALL',
  NWE = 'NWE (Amsterdam)',
  AG = 'AG (Fuj)',
  NORTH_ASIA = 'North Asia (Yosu)',
  SOUTH_ASIA = 'South Asia (Singapore)',
  MED = 'MED (Malta)',
  AMERICAS = 'Americas (Houston)',
}

export const VesselPoolEnum = {
  ALL: 'ALL',
  ...PoolName,
} as const

export type VesselPoolEnum =
  (typeof VesselPoolEnum)[keyof typeof VesselPoolEnum]

export enum CargoTypeEnum {
  ALL = 'ALL',
  CLEAN = 'CPP',
  DIRTY = 'DPP',
}
