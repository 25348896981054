import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'
import { IBasicVesselDataResponse } from './types/basic-vessel-data'
import { OpsDashboardConfigRes } from './types/ops-dashboard'
import { ITabsReadResponse } from './types/tabs-read'
import { VesselDashboardTab } from './types/vessel-dashboard'

// Mapping interface for tab response types
interface TabResponseTypes {
  vesseldashboard: VesselDashboardTab
  basicvesseldata: IBasicVesselDataResponse
  restrictions: ITabsReadResponse
  portessentials: ITabsReadResponse
  opsdashboard: OpsDashboardConfigRes
  poolpartnerdashboard: VesselDashboardTab
}

export function useGetTabData<
  Tab extends
    | 'vesseldashboard'
    | 'basicvesseldata'
    | 'restrictions'
    | 'portessentials'
    | 'opsdashboard'
    | 'poolpartnerdashboard',
>(tab: Tab, vesselId?: string) {
  const fetchRequest = useFetch<TabResponseTypes[Tab]>()
  const url = `/vessels/${vesselId}/data?tab=${tab}`

  return useQuery({
    queryKey: [url],
    queryFn: () => fetchRequest(`${apiBase()}${url}`),
    staleTime: tenMinutes,
    enabled: !!vesselId,
  })
}
