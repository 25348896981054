import { DefaultTagFilterWrapper } from './deault/default-tag-filter-wrapper'
import { DeprecatedTagFilterWrapper } from './deprecated/deprecated-tag-filter-wrapper'

export const filtersParamName = 'tagFilters'

export interface TagFilters {
  id: string
  name: string
}

export interface TagFilterWrapperProps {
  filters: TagFilters[]
}

interface TagFilterProps extends TagFilterWrapperProps {
  variant?: 'default' | 'deprecated'
}

export function TagFilter({ filters, variant }: TagFilterProps) {
  return (
    <>
      {variant === 'deprecated' ? (
        <DeprecatedTagFilterWrapper filters={filters} />
      ) : (
        <DefaultTagFilterWrapper filters={filters} />
      )}
    </>
  )
}
