import { IVesselManager } from '~api/vessels/read'
import { PoolName } from '~constants/pools'

export enum VesselStatus {
  INCOMING = 'incoming',
  ARCHIVED = 'archived',
  ONBOARDED = 'onboarded',
  ONBOARDING = 'onboarding',
  COMMERCIAL_MANAGEMENT = 'commercial-management',
}

export interface ISelect {
  label: string
  value: string
}

const poolNames = Object.values(PoolName)
export const pools: ISelect[] = poolNames.map((name) => ({
  label: name,
  value: name,
}))

export interface IVesselData {
  imoNumber?: string
  manager?: IVesselManager
  pool?: string
  poolPartner?: string
  status?: string
  targetDate?: string
  track?: string
  vesselName?: string
  voyageManager?: string
}
