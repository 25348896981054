import { localStorageItemKeys } from '~constants/local-storage-items'
import { Cookie } from '../cookie-banner-v2'

export function saveCookieSettings(allowedCookies: Set<Cookie>) {
  allowedCookies.add(Cookie.necessary)

  if (!allowedCookies.has(Cookie.statistical)) {
    localStorage.removeItem(
      localStorageItemKeys.retainedPostLogout.allowedCookies,
    )

    sessionStorage.setItem(
      localStorageItemKeys.retainedPostLogout.allowedCookies,
      JSON.stringify(Array.from(allowedCookies)),
    )
  } else {
    localStorage.setItem(
      localStorageItemKeys.retainedPostLogout.allowedCookies,
      JSON.stringify(Array.from(allowedCookies)),
    )
    sessionStorage.removeItem(
      localStorageItemKeys.retainedPostLogout.allowedCookies,
    )
  }
  window.location.reload()
}
