import styled, { css, CSSObject } from 'styled-components/macro'

import { Colors, Fonts } from '~theme/deprecated-vt/deprecated-vt-theme'

import { HeadingType } from './heading'

export type StyledHeadingProps = {
  color?: typeof Colors
  type: HeadingType
  weight?: 'bold' | 'normal' | 'light'
}

const fontSizeConfig: { [Size in HeadingType]: CSSObject } = {
  h1: {
    fontSize: Fonts.sizes.h1,
  },
  h2: {
    fontSize: Fonts.sizes.h2,
  },
  h3: {
    fontSize: Fonts.sizes.h3,
  },
  h4: {
    fontSize: Fonts.sizes.h4,
  },
  h5: {
    fontSize: Fonts.sizes.h5,
  },
  h6: {
    fontSize: Fonts.sizes.h6,
  },
}

export const StyledHeading = styled.h1<StyledHeadingProps>`
  line-height: 1;

  ${(p) => css`
    font-family: ${p.weight
      ? Fonts.families.headlines[p.weight as 'light' | 'bold' | 'regular']
      : Fonts.families.headlines.regular};
    font-weight: ${p.weight === 'bold'
      ? 'bolder'
      : p.weight === 'light'
        ? 'lighter'
        : 'normal'};
    font-size: ${fontSizeConfig[p.type].fontSize};
    margin-bottom: ${fontSizeConfig[p.type].marginBottom ?? 0}px;
    color: ${p.color ?? Colors.blues.base};
  `};
`
