import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'src'
import { IUserInfoReadResponse } from '~api/user/read'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

interface PatchUsersVtPayload {
  ToSAcceptedDate: string
}

export function usePatchUsersVt(userSub?: string) {
  const fetchRequest = useFetch<IUserInfoReadResponse>()
  return useMutation({
    mutationFn: (payload: PatchUsersVtPayload) =>
      fetchRequest(
        `${apiBase()}/users/${userSub}`,
        undefined,
        payload,
        'PATCH',
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ['/me'],
      })
    },
  })
}
